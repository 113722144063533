import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import RequestPreview from './RequestPreview';

import WithUser from './HOC/WithUser';
import { WithTrans } from  '../i18n/i18nWrapper';

class Requests extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pendingRequests: this.props.user.pendingRequests,
      outgoingRequests: this.props.user.outgoingRequests,
      updatedRequests: [],
      childOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    // Display new requests via props from user subscription, but only if a child modal is not already open
    if (this.props.user.pendingRequests !== prevProps.user.pendingRequests && !this.state.childOpen) {
      this.setState({
        pendingRequests: this.props.user.pendingRequests
      });
    }
    if (this.props.user.outgoingRequests !== prevProps.user.outgoingRequests && !this.state.childOpen) {
      this.setState({
        outgoingRequests: this.props.user.outgoingRequests
      });
    }
  }

  render() {
    const pendingRequests = this.state.pendingRequests;
    const outgoingRequests = this.state.outgoingRequests;
    const activeRequests = [];
    const activeOutgoingRequests = [];
    if(pendingRequests) {
      pendingRequests.forEach(request => {
        if (request.status === 'pending') {
          activeRequests.push(request);
        }
      });
    }
    if(outgoingRequests) {
      outgoingRequests.forEach(request => {
        if (!request.archived) {
          activeOutgoingRequests.push(request);
        }
      });
    }
    return (
      <div className="Requests">
        {activeRequests && activeRequests.length > 0 ? (
          <>
            <p className="Requests__type border-gray">{this.props.t('requests.awaiting_your_answer')}:</p>
            <ul className="list-unstyled">
              {activeRequests.map((request) =>
                <RequestPreview request={request} key={request.id} direction="ingoing" childOpen={(open) => {
                  if(!open) {
                    // Update the requests again but once modal is closed, or else it will dissapear on accept/ignore
                    this.setState({
                      pendingRequests: this.props.user.pendingRequests,
                      childOpen: open,
                    });
                  } else {
                    this.setState({
                      childOpen: open,
                    });
                  }
                }}/>
              )}
            </ul>
            {activeRequests.length > 2 ? <span className="Requests__many">{this.props.t('requests.many_request')} <Link to="/profil/mentor">{this.props.t('requests.configure_availability')}</Link></span> : null}
          </>
        ) : null}
        {activeOutgoingRequests && activeOutgoingRequests.length > 0 ? (
          <>
            <p className="Requests__type border-gray">{this.props.t('requests.awaiting_recipient_answer')}:</p>
            <ul className="list-unstyled">
              {activeOutgoingRequests.map((request) =>
                <RequestPreview request={request} key={request.id} direction="outgoing" childOpen={(open) => {
                  if(!open) {
                    // Update the requests again but once modal is closed, or else it will dissapear on accept/ignore
                    this.setState({
                      outgoingRequests: this.props.user.outgoingRequests,
                      childOpen: open,
                    });
                  } else {
                    this.setState({
                      childOpen: open,
                    });
                  }
                }}/>
              )}
            </ul>
          </>
        ) : null}
        {(!activeRequests || activeRequests.length < 1) && (!activeOutgoingRequests || activeOutgoingRequests.length < 1) ? <p className="Requests__none">{this.props.t('requests.no_pending_requests')}<span role="img" aria-label={this.props.t('requests.hourglass')}>⏳</span></p> : null}
      </div>
    );
  }
}

export default WithUser(WithTrans(Requests));