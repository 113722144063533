import React, { Component } from 'react';
import { firestore, storage, auth, firebasefunctions, firebasecore, newNotification, newAchievement } from '../../firebase';

import { WithTrans} from '../../i18n/i18nWrapper';

import TempUserContext from "../../providers/TempUserProvider";
import SignUpInstructions from './SignUpInstructions';
import SignUpSteps from './SignUpSteps';
import LangSwitcher from '../LangSwitcher';

class SignUpFlow extends Component {

  updateTempUser = tempUser => {
    this.setState((prevState) => ({
      tempUser: {...prevState.tempUser, ...tempUser}
    }));
  }

  state = {
    validRequest: true,
    signUpID: this.props.match.params.id,
    signUpEmail: '',
    step: 1,
    userCreated: false,
    tempUser: {
      mentee: false,
      mentor: false,
    },
    updateTempUser: this.updateTempUser
  };

  unsubscribeFromSnapshot = null;

  componentDidMount() {
    this.checkIfValidRequest();
  }

  componentWillUnmount = () => {
    this.unsubscribeFromSnapshot();
  };

  checkIfValidRequest() {
    // Check if this signup exist in the database
    const documentRef = firestore.collection('signups').doc(this.state.signUpID);
    documentRef.get().then((docSnapshot) => {
      if (!docSnapshot.exists) {
        // This signup ID doesn't exist in the DB
        this.setState({ validRequest: false });
        alert(this.props.t('signup.error_not_valid_signup_request'));
      } else {
        // Valid signup request
        this.setState({
          signUpEmail: docSnapshot.data().email,
          tempUser: docSnapshot.data().tempUser,
        });
        // Listen for steps on user document
        this.stepListener = this.listenForStep();
      }
    });
  }

  listenForStep = async () => {
    this.unsubscribeFromSnapshot = firestore.collection('signups').doc(this.state.signUpID).onSnapshot(snapshot => {
      if (snapshot.exists) {
        this.setState({
          step: snapshot.data().step,
        });
      } 
    });
  }

  nextStep = () => {
    // Navigate to next step
    let nextStep = this.state.step + 1;
    if(nextStep > 0 && nextStep < 6) {
      const documentRef = firestore.collection('signups').doc(this.state.signUpID);
      // console.log("Navigate and sync user in the database id: ", documentRef);
      documentRef.get().then((docSnapshot) => {
        if (docSnapshot.exists) {
          documentRef.update({
            step: nextStep,
            tempUser: this.state.tempUser
          });
          if (nextStep === 5) {
            this.createUser();
          }
        }
      });
    }
  }

  createUser = async () => {
    // Get password and email
    const password = atob(this.state.tempUser.password);
    const email = this.state.signUpEmail;
    // Get mentor and mentee fields
    const { mentee, mentor } = this.state.tempUser;
    // Get the generel information
    const { name, birthday, image, linkedInUrl, gender, status, location, company, title } = this.state.tempUser.profile;
    // Get the mentee profile
    let menteeProfile = {};
    if (mentee) {
      const { profileText, preferenceAreas, preferenceExperience, preferenceGender, preferenceCompetencies, preferenceMentorCanBeYounger, preferenceStartupExperience } = this.state.tempUser.menteeProfile;
      menteeProfile = {
        profileText, preferenceAreas, preferenceExperience, preferenceGender, preferenceCompetencies, preferenceMentorCanBeYounger, preferenceStartupExperience
      }
    } else {
      // Populate mentee profile with default values (if they change later)
      menteeProfile = {
        profileText: '', 
        preferenceAreas: [1, 2, 3, 4, 5, 6],
        preferenceExperience: 1, 
        preferenceGender: 'both', 
        preferenceCompetencies: [], 
        preferenceMentorCanBeYounger: true,
        preferenceStartupExperience: false,
      }
    }
    // Get the mentor profile
    let mentorProfile = {};
    if (mentor) {
      const { experience, startupExperience, profileText, competencies} = this.state.tempUser.mentorProfile;
      mentorProfile = {
        profileText, experience, competencies, startupExperience, available: true
      }
    } else {
      mentorProfile = {
        available: true,
        profileText: '', 
        experience: 1, 
        competencies: [],
        startupExperience: false
      }
    }
    // Create user and save user document
    // Create authenticated user
    const { user } = await auth.createUserWithEmailAndPassword(
      email,
      password,
    );
    // Save profile image to user bucket after login (needs to be authenticated)
    let profileImageURL = '';
    let userBucketName = name + '-' + Math.floor((Math.random() * 10000) + 1);;
    if(user) {
      // Get the user profile image and store it

      if(image) {
        // Generate image blob
        try {
          let imageBlob = await fetch(image).then(r => r.blob());

          // Generate file name
          const d = new Date();
          const fileName = name + '-' + d.getTime();

          await storage
          .ref()
          .child('user-profiles')
          .child(userBucketName)
          .child(fileName)
          .put(imageBlob)
          .then(response => response.ref.getDownloadURL())
          .then(photoURL => (profileImageURL = photoURL));
        }
        catch(err) {
          console.log(err);
        }
      }
      // Create the user document
      const userData = {mentee, mentor, name, profileImageURL, userBucketName, birthday, linkedInUrl, gender, status,  location, company, title, menteeProfile, mentorProfile, requestedMentors: [], requestedMentees: [], achievements: [], environment: process.env.NODE_ENV, language: this.props.i18n.language};
      const userId = user.uid;
      const createNewUserDocument = firebasefunctions.httpsCallable('newUserDocument');
      createNewUserDocument({
        userId: userId,
        userData: userData, 
      }).then((success) => {
        // Indicate user creation done
        this.setState({
          userCreated: true,
        });
        // Initial notifcations and achievements
        setTimeout(() => {
          // Send start notification
          newNotification(user.uid, {
            image: 'careermentor',
            text: this.props.t('signup.notification_welcome_to_cm'),
            read: false,
            timestamp: firebasecore.firestore.FieldValue.serverTimestamp(),
            link: '/',
            type: 'welcome'
          });
          // Add early adopter achievement
          const earlyAdopterAchievementId = 3;
          newAchievement(userId, earlyAdopterAchievementId);
          if(mentee) {
            // Send mentee start info notification
            setTimeout(() => {
              newNotification(userId, {
                image: 'careermentor',
                text: this.props.t('signup.notification_complete_your_profile'),
                read: false,
                timestamp: firebasecore.firestore.FieldValue.serverTimestamp(),
                link: '/profil/mentee',
                type: 'welcome'
              });
            }, 500);
            // Add mentee achievement
            const menteeAchievementId = 2;
            newAchievement(userId, menteeAchievementId);
          }
          if(mentor) {
            // Add mentor achievement
            const mentorAchievementId = 1;
            newAchievement(userId, mentorAchievementId);
          }
        }, 7000);
      }).catch((error) => {
        // Indicate error
        console.error(error);
        alert(this.props.t('signup.error_something_went_wrong_with_signup'));
      });
    } else {
      alert(this.props.t('signup.error_something_went_wrong_with_signup'));
    }
  }

  render() {
    return (
      <TempUserContext.Provider value={this.state}>
        <div className="SignUpFlow">
          <div className="SignUpFlow__instructions">
            <SignUpInstructions step={this.state.step} />
          </div>
          <div className="SignUpFlow__content">
            <SignUpSteps
              step={this.state.step} 
              email={this.state.signUpEmail} 
              signUpID={this.state.signUpID} 
              nextStep={this.nextStep} 
              tempUser={this.state.tempUser}
              userCreated={this.state.userCreated}
            />
            <div className="LangSwitcher__wrapper">
              <LangSwitcher loginStyle />
            </div>
          </div>
        </div>
      </TempUserContext.Provider>
    );
  }

}

export default WithTrans(SignUpFlow);