import React from 'react';

import { WithTrans } from  '../../i18n/i18nWrapper';

import Module from '../Module';
import Conversations from '../Conversations';

const PageConversations = (props) => {
  return (
    <div className="Page">
      <Module title={props.t('conversations.title')}>
        <Conversations />
      </Module>
    </div>
  )
}

export default WithTrans(PageConversations);