import React from 'react'

import Achievements from '../Achievements';

const PageAchievements = () => {
  return (
    <div className="Page">
      <Achievements />
    </div>
  )
}

export default PageAchievements;
