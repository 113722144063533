import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { firestore, getAllCompetenceCategoriesFromDB } from '../firebase';
import allCompetenceCategories from './SignUp/competenceCategories.json';

import { getYearsExperience, getLocationName, validateString, getCompetenceNames, getMeetingFrequency } from '../util';

import { WithTrans } from '../i18n/i18nWrapper';

import CompetenceSelector from './SignUp/CompetenceSelector';
import icons from '../assets/icons.svg';
const PencilIcon = icons + '#pencil';
const OwlIcon = icons + '#owl';

class ProfileMentee extends Component {

  constructor(props) {
    super(props);
    this.handleCompetenceSelect = this.handleCompetenceSelect.bind(this);
  }

  state = {
    // Competencies
    competenceCategories: [],
    competencies: [],
    enableLoadCompetencies: false,
    loadingCompetencies: true,
    // New profile fields
    newProfileText: this.props.user.menteeProfile.profileText,
    newMeetingFrequency: this.props.user.menteeProfile.meetingFrequency ? this.props.user.menteeProfile.meetingFrequency : 0,
    newSubjects: this.props.user.menteeProfile.subjects ? this.props.user.menteeProfile.subjects : '',
    newDreams: this.props.user.menteeProfile.dreams ? this.props.user.menteeProfile.dreams : '',
    newFunFact: this.props.user.menteeProfile.funFact ? this.props.user.menteeProfile.funFact : '',
    newHobbies: this.props.user.menteeProfile.hobbies ? this.props.user.menteeProfile.hobbies : '',
    newPreferenceExperience: this.props.user.menteeProfile.preferenceExperience,
    newPreferenceMentorCanBeYounger: this.props.user.menteeProfile.preferenceMentorCanBeYounger,
    newPreferenceStartupExperience: this.props.user.menteeProfile.preferenceStartupExperience,
    newPreferenceGender: this.props.user.menteeProfile.preferenceGender,
    // newPreferenceIndustries: this.props.user.menteeProfile.preferenceIndustries,
    newPreferenceCompetencies: this.props.user.menteeProfile.preferenceCompetencies,
    newPreferenceAreas: this.props.user.menteeProfile.preferenceAreas,
    // State logic
    editMode: false,
    showErrorMessage: false,
    showSuccessMessage: false,
    loading: false,
    validationFailed: false,
    validationErrors: [],
  }

  handleChange = event => {
    const { name } = event.target;
    let { value } = event.target;
    if (name === 'newPreferenceExperience' || name === 'newMeetingFrequency') {
      // convert select string values to integers
      value = parseInt(value);
    }
    this.setState({ [name]: value });
  };

  handleToggle = event => {
    const { name, value } = event.target;
    const bool = value === 'true' ? true : false;
    this.setState({ [name]: bool });
  }

  // handleSelectIndustry = event => {
  //   if(event.target.value) {
  //     const { name, value } = event.target;
  //     const position = parseInt(name) - 1;
  //     let newIndustries = this.state.newPreferenceIndustries;
  //     newIndustries[position] = parseInt(value);
  //     this.setState({ newPreferenceIndustries: newIndustries });
  //   } else {
  //     const { name } = event.target;
  //     const position = parseInt(name) - 1;
  //     let newIndustries = this.state.newPreferenceIndustries;
  //     newIndustries[position] = 0;
  //     this.setState({ newPreferenceIndustries: newIndustries });
  //   }
  // }

  handleCheckboxMulti = event => {
    const { name } = event.target;
    const locationIdToToggle = parseInt(name);
    let newLocations = this.state.newPreferenceAreas;
    if(newLocations.includes(locationIdToToggle)) {
      // Remove id from array
      const filteredLocations = newLocations.filter(item => item !== locationIdToToggle);
      this.setState({ newPreferenceAreas: filteredLocations });
    } else {
      // Add id to array
      newLocations.push(locationIdToToggle);
      this.setState({ newPreferenceAreas: newLocations });
    }    
  };

  handleCompetenceSelect(id, profile, selected) {
    if(profile === 'mentee') {
      // Mentee competencies preference
      if(selected) {
        // Competence already selected (remove it)
        this.setState(prevState => ({ 
          newPreferenceCompetencies: prevState.newPreferenceCompetencies.filter(competence => competence !== id) 
        }));
      } else {
        // Add competence to list of selected competencies
        this.setState(prevState => ({
          newPreferenceCompetencies: [...prevState.newPreferenceCompetencies, id]
        }));
      }
    }
  }

  getCompetenciesFromDB = async () => {
    let competenciesFromDB = await getAllCompetenceCategoriesFromDB();
    console.log(competenciesFromDB);
    // Save to state
    this.setState({ 
      competenceCategories: competenciesFromDB.competenceCategories,
      competencies: competenciesFromDB.allCompetencies
    });
    // Done loading
    setTimeout( () => {
      this.setState({ 
        loadingCompetencies: false,
      });
    }, 500);
  }

  syncCompetenciesDBWithJson(propertyToSync) {
    if(!propertyToSync) {
      // property to sync param is required
      return;
    }
    allCompetenceCategories.forEach((competenceCategory) => {
      if(!competenceCategory[propertyToSync]) {
        // this category don't have the property that you want to sync with db
        return;
      }
      firestore.collection('competencies').doc(competenceCategory.id).update({
        [propertyToSync]: competenceCategory[propertyToSync]
      }).then(() => {
        // Sync sub competencies
        competenceCategory.competencies.forEach((competency) => {
          if(!competency[propertyToSync]) {
            // this competency don't have the property that you want to sync with db
            return;
          }
          firestore.collection('competencies').doc(competenceCategory.id).collection('competencies').doc(competency.id).update({
            [propertyToSync]: competency[propertyToSync]
          }).then(() => {
            // Success
          }).catch((error) => {
            console.error('Error writing to database', error);
          });
        });
      }).catch((error) => {
        console.error('Error writing to database', error);
      });
    });
    alert('Done! But check console for errors.');
  }

  handleSubmit = event => {
    if(this.state.loading) {
      return;
    }
    // Prevent form submit from refreshing page
    event.preventDefault();
    // Reset validation errors if any and indicate loading
    this.setState({ validationErrors: [], validationFailed: false, loading: true, showSuccessMessage: false, showErrorMessage: false });
    // Validate various fields
    this.validateProfileText();
    this.validateExperience();
    this.validateTrueFalse();
    this.validateGender();
    this.validateCompetencies();
    this.validateLocations();
    this.validateFrequency();

    setTimeout(() => {
      // Show errors if failed
      if(this.state.validationFailed) {
        // console.log('validation failed');
        this.setState({ showErrorMessage: true, loading: false });
      }

       // All validation passed, check if anything has changed
       if(!this.state.validationFailed) {
        // console.log("Validation passed");
        const profileTextFieldHasChanged = this.state.newProfileText !== this.props.user.menteeProfile.profileText ? true : false;
        // console.log('profile text field has changed: ', profileTextFieldHasChanged);
        const meetingFrequencyHasChanged = this.state.newMeetingFrequency !== this.props.user.menteeProfile.meetingFrequency ? true : false;
        // console.log('meeting frequency field has changed: ', meetingFrequencyHasChanged);
        const subjectHasChanged = this.state.newSubjects !== this.props.user.menteeProfile.subjects ? true : false;
        // console.log('subjects field has changed: ', subjectHasChanged);
        const dreamsHasChanged = this.state.newDreams !== this.props.user.menteeProfile.dreams ? true : false;
        // console.log('dreams field has changed: ', dreamsHasChanged);
        const funFactHasChanged = this.state.newFunFact !== this.props.user.menteeProfile.funFact ? true : false;
        // console.log('fun fact field has changed: ', funFactHasChanged);
        const hobbiesHasChanged = this.state.newHobbies !== this.props.user.menteeProfile.hobbies ? true : false;
        // console.log('hobbies field has changed: ', hobbiesHasChanged);
        const preferenceExperienceFieldHasChanged = this.state.newPreferenceExperience !== this.props.user.menteeProfile.preferenceExperience ? true : false;
        // console.log('preference experience field has changed: ', preferenceExperienceFieldHasChanged);
        const preferenceYoungerFieldHasChanged = this.state.newPreferenceMentorCanBeYounger !== this.props.user.menteeProfile.preferenceMentorCanBeYounger ? true : false;
        // console.log('preference younger field has changed: ', preferenceYoungerFieldHasChanged);
        const preferenceStartupFieldHasChanged = this.state.newPreferenceStartupExperience !== this.props.user.menteeProfile.preferenceStartupExperience ? true : false;
        // console.log('preference startup field has changed: ', preferenceStartupFieldHasChanged);
        const preferenceGenderFieldHasChanged = this.state.newPreferenceGender !== this.props.user.menteeProfile.preferenceGender ? true : false;
        // console.log('preference gender field has changed: ', preferenceGenderFieldHasChanged);
        const preferenceCompetenciesHasChanged = this.state.newPreferenceCompetencies !== this.props.user.menteeProfile.preferenceCompetencies ? true : false;
        // console.log('preference competencies field has changed: ', preferenceCompetenciesHasChanged);
        const preferenceAreasHasChanged = this.state.newPreferenceAreas !== this.props.user.menteeProfile.preferenceAreas ? true : false;
        // console.log(this.state.newPreferenceAreas);
        // console.log(this.props.user.menteeProfile.preferenceAreas);
        // console.log('preference areas field has changed: ', preferenceAreasHasChanged);
        
        // Check if anything has changed
        if(profileTextFieldHasChanged  || preferenceExperienceFieldHasChanged || preferenceYoungerFieldHasChanged || preferenceStartupFieldHasChanged || preferenceCompetenciesHasChanged || preferenceAreasHasChanged || meetingFrequencyHasChanged || subjectHasChanged || dreamsHasChanged || funFactHasChanged || hobbiesHasChanged || preferenceGenderFieldHasChanged) {
          // console.log("Something has changed!");
          // Something has changed, try saving to database
          const currentUserDocumentRef = firestore.collection('users').doc(this.props.user.uid);
          // Get user document
          currentUserDocumentRef.get().then((docSnapshot) => {
            if (docSnapshot.exists) {
              // console.log("Retrieved user");
              // User exists and is retrieved
              // Update user with changed fields
              currentUserDocumentRef.update({
                ...profileTextFieldHasChanged && { "menteeProfile.profileText": this.state.newProfileText },
                ...preferenceExperienceFieldHasChanged && { "menteeProfile.preferenceExperience": parseInt(this.state.newPreferenceExperience) },
                ...preferenceYoungerFieldHasChanged && { "menteeProfile.preferenceMentorCanBeYounger": this.state.newPreferenceMentorCanBeYounger },
                ...preferenceStartupFieldHasChanged && { "menteeProfile.preferenceStartupExperience": this.state.newPreferenceStartupExperience },
                ...preferenceGenderFieldHasChanged && { "menteeProfile.preferenceGender": this.state.newPreferenceGender },
                ...preferenceCompetenciesHasChanged && { "menteeProfile.preferenceCompetencies": this.state.newPreferenceCompetencies },
                ...preferenceAreasHasChanged && { "menteeProfile.preferenceAreas": this.state.newPreferenceAreas },
                ...meetingFrequencyHasChanged && { "menteeProfile.meetingFrequency": parseInt(this.state.newMeetingFrequency) },
                ...subjectHasChanged && { "menteeProfile.subjects": this.state.newSubjects },
                ...dreamsHasChanged && { "menteeProfile.dreams": this.state.newDreams },
                ...funFactHasChanged && { "menteeProfile.funFact": this.state.newFunFact },
                ...hobbiesHasChanged && { "menteeProfile.hobbies": this.state.newHobbies },
              }).then(() => {
                // User updated
                // console.log("User profile updated");
                // Changes were successfully saved, show success message
                this.setState({ showSuccessMessage: true, loading: false, editMode: false });
              }).catch(function(error) {
                // Something went wrong with update of user (probably timed out or not allowed)
                console.error("Error: ", error);
                this.setState(prevState => ({
                  showErrorMessage: true,
                  loading: false,
                  validationErrors: [...prevState.validationErrors, this.props.t('profile.error_unknown')]
                }))
              });
            }
          }).catch(function(error) {
            // User probably doesn't exist
            console.error("Error: ", error);
            this.setState(prevState => ({
              showErrorMessage: true,
              loading: false,
              validationErrors: [...prevState.validationErrors, this.props.t('profile.error_unknown')]
            }))
          });
        } else {
          // Nothing has changed!
          // console.log("Nothing has changed!");
          this.setState({ loading: false, editMode: false });
        }

      }

    }, 500);
  }

  startEditMode() {
    this.setState({ editMode: true });
    // Check if any competency categories need to be highlighted in a dumb non-reactive way
    setTimeout( () => {
      const CategoryElements = [...document.querySelectorAll('.CompetenceSelector__category')];
      CategoryElements.forEach((category) => {
        const competencies = [...category.querySelectorAll('.Competence')];
        competencies.forEach((competency) => {
          if(competency.classList.contains('Competence--selected')) {
            category.classList.add('CompetenceSelector__category--selected');
          }
        });
      });
    }, 200);
  }

  stopEditMode() {
    this.setState({ editMode: false });
  }

  validateProfileText() {
    const valid = validateString(this.state.newProfileText);
    if(!valid) {
      console.error('not a valid profile text');
      this.setState(prevState => ({
        validationFailed: true,
        validationErrors: [...prevState.validationErrors, this.props.t('profile.error_no_profile_text')]
      }))
    }
  }

  validateExperience() {
    const validExperience = [1,2,3,4,5,6,7];
    if(!validExperience.includes(parseInt(this.state.newPreferenceExperience))) {
      console.error('Not a valid preference');
      this.setState(prevState => ({
        validationFailed: true,
        validationErrors: [...prevState.validationErrors, this.props.t('profile.error_no_experience')]
      }))
    }
  }

  validateFrequency() {
    const validateFrequency = [0,1,2,3,4];
    if(!validateFrequency.includes(parseInt(this.state.newMeetingFrequency))) {
      console.error('Not a valid meeting frequency');
      this.setState(prevState => ({
        validationFailed: true,
        validationErrors: [...prevState.validationErrors, this.props.t('profile.errror_invalid_meeting_freq')]
      }))
    }
  }

  validateTrueFalse() {
    if( typeof this.state.newPreferenceMentorCanBeYounger !== 'boolean' || typeof this.state.newPreferenceStartupExperience !== 'boolean' ) {
      this.setState(prevState => ({
        validationFailed: true,
        validationErrors: [...prevState.validationErrors, this.props.t('profile.error_invalid_younger_preference')]
      }))
    }
  }

  validateGender() {
    const validGenders = ['male', 'female', 'both'];
    if(!validGenders.includes(this.state.newPreferenceGender)) {
      console.error('Not a valid gender');
      this.setState(prevState => ({
        validationFailed: true,
        validationErrors: [...prevState.validationErrors, this.props.t('profile.error_no_gender')]
      }))
    }
  }

  validateCompetencies() {
    if(!Array.isArray(this.state.newPreferenceCompetencies)) {
      console.error('Competency preferences not valid');
      this.setState(prevState => ({
        validationFailed: true,
        validationErrors: [...prevState.validationErrors, this.props.t('profile.error_invalid_mentor_competency')]
      }))
    }
  }

  validateLocations() {
    if(this.state.newPreferenceAreas.length < 1) {
      console.error('Locations not valid');
      this.setState(prevState => ({
        validationFailed: true,
        validationErrors: [...prevState.validationErrors, this.props.t('profile.error_no_location')]
      }))
    }
  }

  render() {
    const editMode = this.state.editMode;

    // Old state
    const { menteeProfile } = this.props.user;
    const { profileText, meetingFrequency, subjects, dreams, funFact, hobbies, preferenceExperience, preferenceMentorCanBeYounger, preferenceStartupExperience, preferenceGender, preferenceCompetencies, preferenceAreas } = menteeProfile;

    // New state
    const { newProfileText, newMeetingFrequency, newSubjects, newDreams, newFunFact, newHobbies, newPreferenceExperience, newPreferenceMentorCanBeYounger, newPreferenceStartupExperience,newPreferenceGender,  newPreferenceCompetencies, newPreferenceAreas, competencies, competenceCategories, loadingCompetencies, enableLoadCompetencies } = this.state;

    // Get experience value from id
    const experience = getYearsExperience(preferenceExperience);

    // Get meeting frequency from id
    const meeting = getMeetingFrequency(meetingFrequency);

    // Current competencies and their names
    let preferenceCompetenciesNames = getCompetenceNames(preferenceCompetencies);

    return (
      <div className="Profile__mentee">
        {
          enableLoadCompetencies ? (
            <div className="Profile__getCompetencies">
              <button className="Button" onClick={() => this.getCompetenciesFromDB()}>Get all competencies as JSON from db</button>
              <p style={{
                maxWidth: 650,
                lineHeight: 1.2,
                fontSize: 16,
                marginTop: -8
              }}>The database is the source, but we fetch it as json and save it manually to files to avoid having to look up the db all the time for this static data. Get the data from the database below and copy paste it to the files (competencies.json and competenceCategories.json) to sync with db.</p>
              <div>
                <span>All competence categories</span>
                <code>{ !loadingCompetencies && competenceCategories && competenceCategories.length > 0 ? JSON.stringify(competenceCategories) : 'JSON OUTPUT HERE'}</code>
              </div>
              <div>
                <span>All competencies</span>
                <code>{ !loadingCompetencies && competencies && competencies.length > 0 ? JSON.stringify(competencies) : 'JSON OUTPUT HERE'}</code>
              </div>
              <hr/>
              <button className="Button" onClick={() => this.syncCompetenciesDBWithJson('title_en')}>Sync db with JSON data (title_en property)</button>
              <p style={{
                maxWidth: 650,
                lineHeight: 1.2,
                fontSize: 16,
                marginTop: -8
              }}>This will sync the database with the contents of competenceCategories.json (reverse sync). Remember the database serves as the source, but sometimes it's easier to edit directly in the fetched json and syncing it back to the db.</p>
            </div>
          ) : null
        }
        <form className="Form" onSubmit={(e) => this.handleSubmit(e)}>
          {this.state.showSuccessMessage ? (
            <div className="Form__successMessage Form__successMessage--showMessage">
              <p>{this.props.t('profile.success_updated')}</p>
            </div>
          ) : null }
          {editMode ? (
            <>
              <div className="Profile__save">
                <button className="Button Button--green--outline" onClick={() => this.stopEditMode()}>{this.props.t('profile.cancel')}</button>
                <input type="submit" className="Button" value={this.state.loading ? this.props.t('profile.wait') : this.props.t('profile.save_changes')} disabled={this.state.loading} style={{ cursor: this.state.loading ? 'wait' : 'pointer' }} />
              </div>
              {this.state.showErrorMessage ? (
                <div className="Form__errorMessage Form__errorMessage--showMessage">
                  <ul>
                    {this.state.validationErrors.map((error, i) =>
                      <li key={i}>{error}</li>
                    )}
                  </ul>
                </div>
              ) : null }
            </>
          ) : (
            <button className="Button Button--icon Profile__editbtn" onClick={() => this.startEditMode()}>
              <span>{this.props.t('profile.edit')}</span>
              <svg>
                <use xlinkHref={PencilIcon}></use>
              </svg>
            </button>
          )
          }
          <h3 className="Profile__headline">{this.props.t('profile.mentee_settings')}</h3>
          <hr/>
          <div>
            <p className="Profile__subheadline">{this.props.t('profile.profile_text')}</p>
            <span className="tooltip">{this.props.t('profile.profile_text_mentee_tip')}</span>
            {editMode ? (
              <textarea id="profileText" name="newProfileText" placeholder={this.props.t('profile.profile_text_mentee_placeholder')} required onChange={this.handleChange} defaultValue={newProfileText}></textarea>
            ) : (
              <div className="Profile__text">{profileText ? profileText : this.props.t('profile.profile_text_none')}</div>
            )}
            <div className="Profile__fieldgroup">
              <div className="Profile__infogroup">
                <p className="Profile__subheadline">{this.props.t('profile.goals')}</p>
                <span className="tooltip">{this.props.t('profile.goals_desc')}</span>
              </div>
              <hr/>
              <div>
                <p className="Profile__subheadline">{this.props.t('profile.how_often_mentee')}</p>
                {editMode ? (
                  <div className="Form__selectWrapper">
                    <select id="newMeetingFrequency" name="newMeetingFrequency" onChange={this.handleChange} defaultValue={newMeetingFrequency}>
                      <option disabled value="0">{this.props.t('profile.select')}</option>
                      <option value="1">{getMeetingFrequency(1)}</option>
                      <option value="2">{getMeetingFrequency(2)}</option>
                      <option value="3">{getMeetingFrequency(3)}</option>
                      <option value="4">{getMeetingFrequency(4)}</option>
                    </select>
                  </div>
                ) : (
                  <p className="Profile__info">{meeting}</p>
                )
                }
              </div>
              <hr/>
              <div>
                <p className="Profile__subheadline">{this.props.t('profile.current_subjects')}</p>
                {editMode ? (
                  <textarea id="newSubjects" name="newSubjects" placeholder={this.props.t('profile.current_subjects_placeholder')} onChange={this.handleChange} defaultValue={newSubjects}></textarea>
                ) : (
                <div className="Profile__text">{subjects ? subjects : <span className="tooltip">{this.props.t('profile.current_subjects_placeholder')}</span>}</div>
                )}
              </div>
              <hr/>
              <div>
                <p className="Profile__subheadline">{this.props.t('profile.goals_and_dreams')}</p>
                {editMode ? (
                  <textarea id="newDreams" name="newDreams" placeholder={this.props.t('profile.goals_and_dreams_placeholder')} onChange={this.handleChange} defaultValue={newDreams}></textarea>
                ) : (
                  <div className="Profile__text">{dreams ? dreams : <span className="tooltip">{this.props.t('profile.goals_and_dreams_placeholder')}</span>}</div>
                )}
              </div>
              <hr/>
              <div>
                <p className="Profile__subheadline">{this.props.t('profile.fun_fact')}</p>
                {editMode ? (
                  <textarea id="newFunFact" name="newFunFact" placeholder={this.props.t('profile.fun_fact_placeholder')} onChange={this.handleChange} defaultValue={newFunFact}></textarea>
                ) : (
                  <div className="Profile__text">{funFact ? funFact : <span className="tooltip">{this.props.t('profile.fun_fact_placeholder')}</span>}</div>
                )}
              </div>
              <hr/>
              <div>
                <p className="Profile__subheadline">{this.props.t('profile.hobbies')}</p>
                {editMode ? (
                  <textarea id="newHobbies" name="newHobbies" placeholder={this.props.t('profile.hobbies_placeholder')} onChange={this.handleChange} defaultValue={newHobbies}></textarea>
                ) : (
                  <div className="Profile__text">{hobbies ? hobbies : <span className="tooltip">{this.props.t('profile.hobbies_placeholder')}</span>}</div>
                )}
              </div>
            </div>
          </div>
          <hr/>
          <div className="Profile__preferences">
            <div>
              <p className="Profile__headline">{this.props.t('profile.mentor_preferences')}</p>
              <span className="tooltip">{this.props.t('profile.mentor_preferences_desc')}</span>
            </div>
            <div>
              <p className="Profile__subheadline">{this.props.t('profile.years_of_experience_minimum')}</p>
              {editMode ? (
                <div className="Form__selectWrapper">
                  <select id="menteePreferenceExperience" name="newPreferenceExperience" onChange={this.handleChange} defaultValue={newPreferenceExperience}>
                    <option value="1">{getYearsExperience(1)}</option>
                    <option value="2">{getYearsExperience(2)}</option>
                    <option value="3">{getYearsExperience(3)}</option>
                    <option value="4">{getYearsExperience(4)}</option>
                    <option value="5">{getYearsExperience(5)}</option>
                    <option value="6">{getYearsExperience(6)}</option>
                    <option value="7">{getYearsExperience(7)}</option>
                  </select>
                </div>
              ) : (
                <p className="Profile__info">{experience}</p>
              )
              }
            </div>
            <hr/>
            <div>
              <p className="Profile__subheadline">{this.props.t('profile.mentor_can_be_younger')}</p>
              {editMode ? (
                <div className="Form__radioWrapper">
                  <label>
                    <input type="radio" name="newPreferenceMentorCanBeYounger" value={true} onChange={this.handleToggle} defaultChecked={newPreferenceMentorCanBeYounger === true ? true : false}/>
                    <span>{this.props.t('profile.yes')}</span>
                  </label>
                  <label>
                    <input type="radio" name="newPreferenceMentorCanBeYounger" value={false} onChange={this.handleToggle} defaultChecked={newPreferenceMentorCanBeYounger === false ? true : false}/>
                    <span>{this.props.t('profile.no')}</span>
                  </label>
                </div>
              ) : (
                <p className="Profile__info">{preferenceMentorCanBeYounger === true ? this.props.t('profile.yes') : this.props.t('profile.no')}</p>
              )
              }
            </div>
            <hr/>
            <div>
              <p className="Profile__subheadline">{this.props.t('profile.mentor_experience_entrepreneur')}</p>
              {editMode ? (
                <div className="Form__radioWrapper">
                  <label>
                    <input type="radio" name="newPreferenceStartupExperience" value={true} onChange={this.handleToggle} defaultChecked={newPreferenceStartupExperience === true ? true : false}/>
                    <span>{this.props.t('profile.yes')}</span>
                  </label>
                  <label>
                    <input type="radio" name="newPreferenceStartupExperience" value={false} onChange={this.handleToggle} defaultChecked={newPreferenceStartupExperience === false ? true : false}/>
                    <span>{this.props.t('profile.no')}</span>
                  </label>
                </div>
              ) : (
                <p className="Profile__info">{preferenceStartupExperience === true ? 'Ja' : 'Nej'}</p>
              )
              }
            </div>
            <hr/>
            <div>
              <p className="Profile__subheadline">{this.props.t('profile.mentor_gender')}</p>
              {editMode ? (
                <div className="Form__radioWrapper">
                  <label>
                    <input type="radio" name="newPreferenceGender" value="both" onChange={this.handleChange} defaultChecked={preferenceGender === 'both'}/>
                    <span>{this.props.t('profile.gender_both')}</span>
                  </label>
                  <label>
                    <input type="radio" name="newPreferenceGender" value="male" defaultChecked={newPreferenceGender === 'male'}/>
                    <span>{this.props.t('profile.gender_male')}</span>
                  </label>
                  <label>
                    <input type="radio" name="newPreferenceGender" value="female" defaultChecked={newPreferenceGender === 'female'}/>
                    <span>{this.props.t('profile.gender_female')}</span>
                  </label>
                </div>
              ) : (
                <p className="Profile__info">{preferenceGender === 'both' ? this.props.t('profile.gender_both') : null }{preferenceGender === 'male' ? this.props.t('profile.gender_male') : null }{preferenceGender === 'female' ? this.props.t('profile.gender_female') : null }</p>
              )
              }
            </div>
            <hr/>
            <div>
              <p className="Profile__subheadline">{this.props.t('profile.mentor_comptencies')}</p>
              {editMode ? (
                <CompetenceSelector profile="mentee" handleClick={this.handleCompetenceSelect} competencies={newPreferenceCompetencies}/>
              ) : (
                <ul className="Profile__inlinelist list-unstyled">
                  {preferenceCompetenciesNames && preferenceCompetenciesNames.length > 0 ? (
                    preferenceCompetenciesNames.map((competence, i) =>
                      <li key={competence.id}>{this.props.i18n.language === 'en' ? competence.title_en : competence.title}{i !== preferenceCompetenciesNames.length - 1 ? ', ' : null}</li>
                    )
                  ) : <li>{this.props.t('profile.mentor_no_competencies_selected')}</li> }
                </ul>
              )
              }
            </div>
            <hr/>
            <div>
              <p className="Profile__subheadline">{this.props.t('profile.area')}</p>
              {editMode ? (
                <>
                  <div className="Form__multicheckWrapper Form__multicheckWrapper--width33">
                    <label>
                      <input type="checkbox" name="1" defaultChecked={newPreferenceAreas[0]} onChange={this.handleCheckboxMulti}/>
                      <span>{this.props.t('profile.area_copenhagen')}</span>
                    </label>
                    <label>
                      <input type="checkbox" name="2" defaultChecked={newPreferenceAreas[1]} onChange={this.handleCheckboxMulti}/>
                      <span>{this.props.t('profile.area_zealand')}</span>
                    </label>
                    <label>
                      <input type="checkbox" name="3" defaultChecked={newPreferenceAreas[2]} onChange={this.handleCheckboxMulti}/>
                      <span>{this.props.t('profile.area_funen')}</span>
                    </label>
                    <label>
                      <input type="checkbox" name="4" defaultChecked={newPreferenceAreas[3]} onChange={this.handleCheckboxMulti}/>
                      <span>{this.props.t('profile.area_northern_jutland')}</span>
                    </label>
                    <label>
                      <input type="checkbox" name="5" defaultChecked={newPreferenceAreas[4]} onChange={this.handleCheckboxMulti}/>
                      <span>{this.props.t('profile.area_central_jutland')}</span>
                    </label>
                    <label>
                      <input type="checkbox" name="6" defaultChecked={newPreferenceAreas[5]} onChange={this.handleCheckboxMulti}/>
                      <span>{this.props.t('profile.area_southern_jutland')}</span>
                    </label>
                  </div>
                </>
              ) : (
                <>
                  {preferenceAreas.map((area, index) =>
                    <p className="Profile__info" key={index}>{getLocationName(area)}</p>
                  )}
                </>
              )
              }
            </div> 
          </div>
        </form>
        {!editMode ? (
          <>
            <hr/>
            <Link to={`/profile/${this.props.user.uid}?viewAs=mentor`} className="Button Button--green--outline Button--icon Profile__viewbtn">
              <span>{this.props.t('profile.see_your_profile_as_mentor')}</span>
              <svg>
                <use xlinkHref={OwlIcon}></use>
              </svg>
            </Link>
          </>
        ) : null }
      </div>
    )
  }

}


export default WithTrans(ProfileMentee);