import React, { Component } from 'react';

import { auth } from '../firebase';

import Modal from './Modal';

import { getDateString } from '../util';
import { WithTrans } from '../i18n/i18nWrapper';

class ProfilePreferences extends Component {
  
  state = {
    deleteModalOpen: false,
    editEmail: false,
    currentEmail: this.props.user.email,
    newEmail: '',
    password: '',
    loading: false,
    showError: false,
    showSuccess: false
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ loading: true, showError: false, showSuccess: false });
    let validEmail = this.validateEmail(this.state.newEmail);

    // If valid email
    if(validEmail) {
      this.updateEmail();
    } else {
      setTimeout( () => {
        this.setState({ loading: false });
        alert(this.props.t('signup.error_invalid_email'));
      }, 500);
    }
  }

  validateEmail = email => {
    return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  }

  updateEmail() {
    auth.signInWithEmailAndPassword(this.props.user.email, this.state.password)
    .then((userCredential) => {
      // Logged in
      userCredential.user.updateEmail(this.state.newEmail).then(() => {
        // Updated user email show success
        setTimeout(() => {
          this.setState({
            showSuccess: true,
            loading: false,
            editEmail: false,
            currentEmail: this.state.newEmail
          })
        }, 500);
      }).catch((error) => {
        // Logged in but something went wrong with update
        console.error("Error: ", error);
        // Show error
        setTimeout(() => {
          this.setState({
            showError: true,
            loading: false
          })
        }, 500);
      });
    }).catch((error) => {
      // Something went wrong with login
      console.error("Error: ", error);
      // Show error
      setTimeout(() => {
        this.setState({
          showError: true,
          loading: false
        })
      }, 500);
    });
  }

  toggleEditMode() {
    this.setState(prevState => ({
      editEmail: !prevState.editEmail
    }));
  }

  toggleDeleteModal() {
    this.setState(prevState => ({
      deleteModalOpen: !prevState.deleteModalOpen
    }));
  }

  render() {
    const dateString = getDateString(this.props.user.createdAt, this.props.i18n.language);
    return (
      <div>
        <h3 className="Profile__headline">{this.props.t('profile.more_settings')}</h3>
        <hr/>
        <div>
          <p className="Profile__subheadline">{this.props.t('profile.date_of_signup')}</p>
          <p className="Profile__info">{dateString}</p>
        </div>
        <hr/>
        <div>
          <p className="Profile__subheadline">{this.props.t('profile.email_address')}</p>
          <form className="Form" onSubmit={(e) => this.handleSubmit(e)}>
            {this.state.editEmail 
              ? (
                <>
                  <p className="Profile__info" style={{ marginBottom: 20 }}>{this.props.t('profile.current_email')}: {this.state.currentEmail}</p>
                  <p className="Profile__info">{this.props.t('profile.new_email')}</p>
                  <span className="tooltip" style={{ marginBottom: 10 }}>{this.props.t('profile.email_needs_to_valid')}</span>
                  <input 
                    type="email" 
                    placeholder={this.props.t('signin.email_placeholder')} 
                    name="newEmail" 
                    id="email" 
                    defaultValue={this.state.newEmail}
                    onChange={this.handleChange} 
                    required 
                  />
                  <p className="Profile__info">Password</p>
                  <span className="tooltip" style={{ marginBottom: 10 }}>{this.props.t('profile.you_need_to_retype_password_to_change_email')}</span>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder={this.props.t('signin.password_placeholder')}
                    defaultValue={this.state.password}
                    onChange={this.handleChange}
                    required
                  />
                  <input 
                    type="submit" 
                    className="Button" 
                    value={this.state.loading ? this.props.t('profile.wait') : this.props.t('profile.update_email')} 
                    disabled={this.state.loading} 
                    style={{ cursor: this.state.loading ? 'wait' : 'pointer' }} 
                  />
                  {this.state.showError ? (
                    <div className="Form__errorMessage Form__errorMessage--showMessage"><p>{this.props.t('profile.email_update_error')}</p></div>
                  ) : null }
                </>
              )
              : (
                <>
                  <p className="Profile__info">{this.state.currentEmail}</p>
                  <div className="Button" onClick={() => this.toggleEditMode()} style={{ marginTop: 10 }}>{this.props.t('profile.change_email')}</div>
                  {this.state.showSuccess ? (
                    <div className="Form__successMessage Form__successMessage--showMessage" style={{ marginTop: 20 }}><p>{this.props.t('profile.your_email_has_been_updated')}</p></div>
                  ) : null }
                </>
              )
            }
          </form>
        </div>
        <hr/>
        <div>
          <p className="Profile__subheadline">{this.props.t('profile.delete_profile')}</p>
          <div className="Button Button--red" onClick={() => this.toggleDeleteModal()}>{this.props.t('profile.delete_profile')}</div>
          <Modal toggleModal={() => this.toggleDeleteModal()} open={this.state.deleteModalOpen} title={this.props.t('profile.delete_my_cm_profile')}>
            <div style={{ minWidth: 350 }}>
              <p>{this.props.t('profile.we_are_sorry_to_see_you_leave')} <span role="img" aria-label="Broken heart">💔</span></p>
              <p style={{ lineHeight: 1.3 }}>{this.props.t('profile.write_a_mail_to')} <a href={`mailto:kontakt@careermentor.dk?Subject=${this.props.t('profile.delete_my_cm_profile')}`}>kontakt@careermentor.dk</a> {this.props.t('profile.with_your_name')}</p>
            </div>
          </Modal>
        </div>
      </div>
    )
  }

}

export default WithTrans(ProfilePreferences);