import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { PieChart } from 'react-minimal-pie-chart';
import axios from 'axios';

import { getMenteeUsers, getMentorUsers, getConversationsCount, firebasefunctions } from '../firebase';
import { getCompetenceName, getDateString } from '../util';

import avatarPlaceholder from '../assets/avatar-placeholder.jpg'

import StatItem from './StatItem';

import icons from '../assets/icons.svg';
const DownloadDb = icons + '#downloaddb';

const getCollabs = async () => {
  const restURL = 'https://careermentor.dk/wp-json/wp/v2/';
  let collabs = [];
  await axios.get(restURL + 'collaboration?per_page=100')
  .then(response => {
    // handle success
    collabs = response.data;
  })
  .catch(function (error) {
    // handle error
    console.log('error fetching collabs: ', error);
  })
  return collabs
}
class Stats extends Component {

  state = {
    loading: false,
    dataFetched: false,
    // Users
    allUsers: [],
    userCount: 'X',
    mentorCount: 'X',
    menteeCount: 'X',
    collabsCount: 'X',
    // Gender
    femaleCount: 0,
    maleCount: 0,
    otherCount: 0,
    //Competencies
    mentorCompetencies: [],
    menteeCompetencies: [],
    // Conversations
    conversationsCount: 'X'
  };

  getData = async () => {
    // Set loading
    this.setState({ loading: true });

    // Get mentor / mentee count
    let mentees = await getMenteeUsers();
    let mentors = await getMentorUsers();
    let conversationsCount = await getConversationsCount();
    let collabs = await getCollabs();
    const allUsers = mentors.concat(mentees.filter(mentee => !mentee.mentor));
    const femaleCount = allUsers.filter(user => user.gender === 'female');
    const maleCount = allUsers.filter(user => user.gender === 'male');
    const otherCount = allUsers.filter(user => user.gender === 'other');

    // Mentor competenies
    let mentorCompetencies = [];
    mentors.forEach(mentor => {
      mentor.mentorProfile.competencies.forEach(competency => {
        mentorCompetencies.push(competency);
      });
    });
    let mentorCompetenciesCount = {};
    mentorCompetencies.forEach((x) => { mentorCompetenciesCount[x] = (mentorCompetenciesCount[x] || 0)+1; });
    let mentorCompetenciesWithNames = [];
    for (const property in mentorCompetenciesCount) {
      const name = getCompetenceName(property);
      mentorCompetenciesWithNames.push({
        name: name,
        count: mentorCompetenciesCount[property]
      });
    }
    mentorCompetenciesWithNames.sort((a, b) => b.count - a.count);

    // Mentee competenies
    let menteeCompetencies = [];
    mentees.forEach(mentee => {
      mentee.menteeProfile.preferenceCompetencies.forEach(competency => {
        menteeCompetencies.push(competency);
      });
    });
    let menteeCompetenciesCount = {};
    menteeCompetencies.forEach((x) => { menteeCompetenciesCount[x] = (menteeCompetenciesCount[x] || 0)+1; });
    let menteeCompetenciesWithNames = [];
    for (const property in menteeCompetenciesCount) {
      const name = getCompetenceName(property);
      menteeCompetenciesWithNames.push({
        name: name,
        count: menteeCompetenciesCount[property]
      });
    }
    menteeCompetenciesWithNames.sort((a, b) => b.count - a.count);

    // Sort user by last login date
    allUsers.sort((a, b) => b.lastSignIn - a.lastSignIn);
    console.log(allUsers);

    this.setState({ 
      mentorCount: mentors.length,
      menteeCount: mentees.length,
      userCount: allUsers.length,
      femaleCount: femaleCount.length,
      maleCount: maleCount.length,
      otherCount: otherCount.length,
      collabsCount: collabs.length,
      mentorCompetencies: mentorCompetenciesWithNames,
      menteeCompetencies: menteeCompetenciesWithNames,
      conversationsCount,
      allUsers
    });
    
    // Show results
    this.setState({ 
      loading: false, 
      dataFetched: true 
    });
  }

  retrieveUserEmail = async (userId) => {
    if(this.state.loading) {
      // Request already in progress
      return;
    }
    // Start loading
    this.setState({ loading: true });
    // Accept request via cloud function
    const getUserEmail = firebasefunctions.httpsCallable('getUserEmail');
    getUserEmail({
      userId: userId,
    }).then((response) => {
      console.log(response);
      const email = response.data;
      this.setState({ loading: false });
      document.getElementById('email-' + userId).innerHTML = '<span>' + email + '</span>';
    }).catch((error) => {
      // Indicate error
      console.error(error);
    });
  }

  render() {
    const defaultLabelStyle = {
      fontSize: '5px',
      fontFamily: 'sans-serif',
    };
    const getDataBtnClass = classNames('Button Button--green Button--icon', {
      'Button--loading': this.state.loading
    });
    return (
      <>
        <div className="Stats">
          <button className={getDataBtnClass} onClick={() => this.getData()} style={{marginBottom: 20, marginTop: 10}}>
            <span>Get data</span>
            <svg>
              <use xlinkHref={DownloadDb}></use>
            </svg>
          </button>
          <div className="grid-x grid-padding-x--small">
            <div className="cell small-12 large-4">
              <StatItem name="Total users" value={this.state.userCount} />
            </div>
            <div className="cell small-12 large-4">
              <StatItem name="Mentors" value={this.state.mentorCount} />
            </div>
            <div className="cell small-12 large-4">
              <StatItem name="Mentees" value={this.state.menteeCount} />
            </div>
          </div>
          <div className="grid-x grid-padding-x--small">
            <div className="cell small-12 large-6">
              <StatItem name="Mentor/Mentee ratio">
                {this.state.dataFetched ? (
                  <PieChart
                    data={[
                      { title: 'Mentor', value: this.state.mentorCount, color: '#fe9236' },
                      { title: 'Mentee', value: this.state.menteeCount, color: '#4ea39a' },
                    ]}
                    animate
                    lineWidth={66}
                    label={({ dataEntry }) => `${dataEntry.title} ${Math.round(dataEntry.percentage)} %`}
                    labelStyle={{
                      ...defaultLabelStyle,
                    }}
                  />
                ) : <div className="PieSkeleton"></div>}
              </StatItem>
            </div>
            <div className="cell small-12 large-6">
              <StatItem name="Male/Female ratio">
                {this.state.dataFetched ? (
                  <PieChart
                    data={[
                      { title: 'Female', value: this.state.femaleCount, color: '#fe9236' },
                      { title: 'Male', value: this.state.maleCount, color: '#4ea39a' },
                      { title: 'Other', value: this.state.otherCount, color: '#ebebeb' },
                    ]}
                    animate
                    lineWidth={66}
                    label={({ dataEntry }) => `${dataEntry.title} ${Math.round(dataEntry.percentage)} %`}
                    labelStyle={{
                      ...defaultLabelStyle,
                    }}
                  />
                ) : <div className="PieSkeleton"></div>}
              </StatItem>
            </div>
          </div>
          <div className="grid-x grid-padding-x--small">
            <div className="cell small-12 large-4">
              <StatItem name="Total matches" value={this.state.conversationsCount} />
            </div>
            <div className="cell small-12 large-4">
              <StatItem name="Total collabs" value={this.state.collabsCount} />
            </div>
            <div className="cell small-12 large-4">
              <StatItem name="Top mentor" value="WIP" />
            </div>
          </div>
          <div className="grid-x grid-padding-x--small">
            <div className="cell small-12 large-6">
              <StatItem name="Mentor competencies" expandable>
                <table className="StatsTable">
                  <thead>
                    <tr>
                      <th>Competency</th>
                      <th>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.mentorCompetencies.map((competency, i) =>
                      <tr key={i}>
                        <td>{ competency.name }</td>
                        <td>{ competency.count }</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </StatItem>
            </div>
            <div className="cell small-12 large-6">
              <StatItem name="Mentee preference competencies" expandable>
                <table className="StatsTable">
                  <thead>
                    <tr>
                      <th>Competency</th>
                      <th>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.menteeCompetencies.map((competency, i) =>
                      <tr key={i}>
                        <td>{ competency.name }</td>
                        <td>{ competency.count }</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </StatItem>
            </div>
          </div>
        </div>

        <StatItem name="Alle brugere">
          <table className="UsersTable">
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Title</th>
                <th>Company</th>
                <th>Mentor</th>
                <th>Mentee</th>
                <th>Last login</th>
                <th>Signup date</th>
                <th>Profil</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              
              {this.state.allUsers.map(user =>
                <tr key={user.uid}>
                  <td><img src={user.profileImageURL ? user.profileImageURL : avatarPlaceholder } alt={user.name}/></td>
                  <td>{ user.name }{user.environment === 'development' ? ' (test user)' : '' }</td>
                  <td>{ user.title }</td>
                  <td>{ user.company }</td>
                  <td>{ user.mentor ? 'Yes' : 'No' }</td>
                  <td>{ user.mentee ? 'Yes' : 'No' }</td>
                  <td>{ user.lastSignIn ? getDateString(user.lastSignIn, 'da') : 'unknown' }</td>
                  <td>{ user.createdAt ? getDateString(user.createdAt, 'da') : 'unknown' }</td>
                  <td>
                    <Link to={`/profile/${user.uid}`} target="_blank"><span>Link</span></Link>
                  </td>
                  <td>
                    <div id={'email-' + user.uid}>
                      <button className={getDataBtnClass} onClick={(e) => this.retrieveUserEmail(user.uid)}>
                        <svg>
                          <use xlinkHref={DownloadDb}></use>
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </StatItem>
      </>
    )
  }

}

export default Stats;