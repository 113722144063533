import React, { useContext } from 'react';

import NewsArticle from './NewsArticle';
import Loader from './Loader';

import { NewsContext } from '../providers/NewsProvider';

const News = () => {
  const news = useContext(NewsContext);
  return (
    <div className="News">
      {news && news.length > 0 ? (
        <ul className="list-unstyled">
          {news.map((article, index) =>
            <NewsArticle article={article} key={index} />
          )}
        </ul>
      ) : (
        <Loader color="green" />
      )}
    </div>
  )
}

export default News;