import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { checkForMissingStepThreeInputs } from './CheckStepThreeInputs';
import { WithTrans } from '../../i18n/i18nWrapper';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';

let stepThreeInputs = null;

class SignUpSteps extends Component {

  state = {
    navReady: false,
    validationFailed: false,
    validationErrors: [],
  }

  stepsContainer = React.createRef();


  renderStep() {
    switch(this.props.step) {
      case 1:
        return <StepOne signUpID={this.props.signUpID} email={this.props.email} navReady={this.readyToNavigate} navNotReady={this.notReadyToNavigate} />;
      case 2:
        return <StepTwo tempUser={this.props.tempUser} navReady={this.readyToNavigate} navNotReady={this.notReadyToNavigate} />;
      case 3:
        return <StepThree tempUser={this.props.tempUser} navReady={this.readyToNavigate} navNotReady={this.notReadyToNavigate} getStepThreeInputs={this.getStepThreeInputs} />;
      case 4:
        return <StepFour navReady={this.readyToNavigate} navNotReady={this.notReadyToNavigate} />;
      case 5:
        return <StepFive tempUser={this.props.tempUser} userDocumentReady={this.props.userCreated} />;
      default:
        return <StepOne signUpID={this.props.signUpID} email={this.props.email} navReady={this.readyToNavigate} navNotReady={this.notReadyToNavigate} />;
    }
  }

  readyToNavigate = () => {
    if(!this.state.navReady) {
      // console.log("Ready to navigate!");
      this.setState({ navReady: true });
    }
  }

  notReadyToNavigate = () => {
    if(this.state.navReady) {
      this.setState({ navReady: false });
    }
  }

  getStepThreeInputs(state) {
    stepThreeInputs = state;
  }

  navigate = () => {
    if(this.state.navReady) {
      // Navigate to next step
      this.props.nextStep();
      this.setState({ navReady: false });
      let windowWidth = window.innerWidth || document.documentElement.clientWidth|| document.body.clientWidth;
      // Always scroll to top when navigating
      if(windowWidth > 767) {
        setTimeout( () => { this.stepsContainer.current.scrollTo(0, 0); }, 200);
      } else {
        setTimeout( () => { window.scrollTo(0, 0); }, 200);
      }
      // Clear old validation errors (if any)
      if(this.state.validationErrors && this.state.validationErrors.length > 0) {
        this.setState({ validationErrors: [], validationFailed: false });
      }
    } else {
      // Notify of whats missing if step 3
      if(this.props.step === 3) {
        // Get missing fields
        const missingFields = checkForMissingStepThreeInputs(stepThreeInputs, this.props.tempUser.mentor, this.props.tempUser.mentee, this.props.t);
        this.setState({ validationErrors: missingFields, validationFailed: true });
        // Remove old highlights (if any)
        const allInputs = [...document.querySelectorAll('input')];
        const allSelects = [...document.querySelectorAll('select')];
        const allRadios = [...document.querySelectorAll('.Form__radioWrapper')];
        const missingInputClass = 'missing-input';
        allInputs.forEach((input) => {
          if(input.classList.contains(missingInputClass)) { input.classList.remove(missingInputClass); }
        })
        allSelects.forEach((select) => {
          if(select.classList.contains(missingInputClass)) { select.classList.remove(missingInputClass); }
        })
        allRadios.forEach((radio) => {
          if(radio.classList.contains(missingInputClass)) { radio.classList.remove(missingInputClass); }
        })
        // Highlight missing inputs
        missingFields.forEach((field) => {
          const fieldElement = document.getElementById(field.id);
          if(fieldElement) {
            if(!fieldElement.classList.contains(missingInputClass)) { fieldElement.classList.add(missingInputClass); }
          }
        })
      }
    }
  }

  render() {
    const nextBtnClass = classNames('Button', {
      'disabled': !this.state.navReady && this.props.step !== 3,
    });
    return (
      <div className="SignUpSteps" ref={this.stepsContainer}>
        {this.renderStep()}
        {this.props.step < 5 ? (
          <div className="SignUpSteps__footer">
            {this.state.validationFailed ? (
              <div className="Form__errorMessage Form__errorMessage--showMessage">
                <ul className="list-unstyled">
                  {this.state.validationErrors.map((error, index) =>
                    <li key={index}>{error.message}. <a href={'#' + error.id}>{this.props.t('signup.go_to_field')}</a></li>
                  )}
                </ul>
              </div>
            ) : (null) }
            <button className={nextBtnClass} onClick={this.navigate}>{this.props.t('signup.continue')}</button>
          </div>
        ) : (null) }
      </div>
    );
  }

}

SignUpSteps.propTypes = {
  step: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  signUpID: PropTypes.string.isRequired,
  nextStep: PropTypes.func.isRequired,
  tempUser: PropTypes.object.isRequired,
};

export default WithTrans(SignUpSteps);