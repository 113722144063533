import React from 'react';

import { analytics } from '../firebase';

import icons from '../assets/icons.svg';
const ExternalIcon = icons + '#external';

const Collab = (props) => {
  function showInterest() {
    if(process.env.NODE_ENV === 'production') {
      analytics.logEvent('Collab interest', { name: props.title});
    }
  }
  return (
    <article className="Collab" data-company={props.company}>
      <div className="Collab__top">
        <figure className="Collab__image">
          <img src={props.image} alt={props.title}/>
        </figure>
        <h1 className="Collab__title">{props.title}</h1>
      </div>
      <div className="Collab__inner">
        <div className="Collab__description" dangerouslySetInnerHTML={{ __html: props.description }}>
        </div>
      </div>
      <div className="Collab__footer">
      <div>
        <a 
          className="Button Button--icon" 
          href={props.url} 
          target="_blank" 
          rel="noopener noreferrer" 
          onClick={() => showInterest()}>
            <span>Mere information</span>
            <svg>
              <use xlinkHref={ExternalIcon}></use>
            </svg>
          </a>
      </div>
      </div>
    </article>
  )
}

export default Collab;