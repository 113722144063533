import React from 'react'

import Information from '../Information';
import Module from '../Module';

import { WithTrans } from  '../../i18n/i18nWrapper';

const PageInformation = (props) => {
  return (
    <div className="Page">
      <Module title={props.t('information.information_title')}>
        <Information />
      </Module>
    </div>
  )
}

export default WithTrans(PageInformation);