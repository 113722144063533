import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { WithTrans } from '../../i18n/i18nWrapper';

import Mailbox from './Illustrations/Mailbox';
import Choice from './Illustrations/Choice';
import Info from './Illustrations/Info';
import Password from './Illustrations/Password';
import Celebration from './Illustrations/Celebration';

import LangSwitcher from '../LangSwitcher';

import icons from '../../assets/icons.svg';
const infoIcon = icons + '#info';
const checkMark = icons + '#checkmark';

class SignUpInstructions extends Component {

  renderIllustration() {
    switch(this.props.step) {
      case 1:
        return <Mailbox />;
      case 2:
        return <Choice />;
      case 3:
        return <Info />;
      case 4:
        return <Password />;
      case 5:
        return <Celebration />;
      default:
        return <Mailbox />;
    }
  }

  renderTitle() {
    switch(this.props.step) {
      case 1:
        return this.props.t('signup.step_title_one');
      case 2:
        return this.props.t('signup.step_title_two');
      case 3:
        return this.props.t('signup.step_title_three');
      case 4:
        return this.props.t('signup.step_title_four');
      case 5:
        return this.props.t('signup.step_title_five');
      default:
        return this.props.t('signup.step_title_one');
    }
  }
  

  renderTooltip() {
    switch(this.props.step) {
      case 1:
        return this.props.t('signup.tooltip_one');
      case 2:
        return this.props.t('signup.tooltip_two');
      case 3:
        return this.props.t('signup.tooltip_three');
      case 4:
        return this.props.t('signup.tooltip_four');
      case 5:
        return this.props.t('signup.tooltip_five');
      default:
        return this.props.t('signup.tooltip_one');
    }
  }

  render() {
    const stepOneClasses = classNames({
      'current': this.props.step === 1,
      'done': this.props.step > 1,
    });
    const stepTwoClasses = classNames({
      'current': this.props.step === 2,
      'done': this.props.step > 2,
    });
    const stepThreeClasses = classNames({
      'current': this.props.step === 3,
      'done': this.props.step > 3,
    });
    const stepFourClasses = classNames({
      'current': this.props.step === 4,
      'done': this.props.step > 4,
    });
    const stepFiveClasses = classNames({
      'current': this.props.step === 5,
      'done': this.props.step > 5,
    });
    return (
      <div className="SignUpInstructions">
        <div className="SignUpInstructions__steps">
          <p className={stepOneClasses}>
            <span>{this.props.step > 1 ? <svg><use xlinkHref={checkMark}></use></svg> : '1'}</span>
            <span>{this.props.t('signup.step_title_one')}</span>
          </p>
          <p className={stepTwoClasses}> 
            <span>{this.props.step > 2 ? <svg><use xlinkHref={checkMark}></use></svg> : '2'}</span>
            <span>{this.props.t('signup.step_title_two')}</span>
          </p>
          <p className={stepThreeClasses}>
            <span>{this.props.step > 3 ? <svg><use xlinkHref={checkMark}></use></svg> : '3'}</span>
            <span>{this.props.t('signup.step_title_three')}</span>
          </p>
          <p className={stepFourClasses}>
            <span>{this.props.step > 4 ? <svg><use xlinkHref={checkMark}></use></svg> : '4'}</span>
            <span>{this.props.t('signup.step_title_four')}</span>
          </p>
          <p className={stepFiveClasses}>
            <span>{this.props.step > 5 ? <svg><use xlinkHref={checkMark}></use></svg> : '5'}</span>
            <span>{this.props.t('signup.step_title_five')}</span>
          </p>
        </div>
        <div className="SignUpInstructions__illustration">
          {this.renderIllustration()}
          <h1 className="SignUpInstructions__title">{this.renderTitle()}</h1>
        </div>
        <div>
          <div className="SignUpInstructions__why">
            <svg>
              <use xlinkHref={infoIcon}></use>
            </svg>
            <p>{this.renderTooltip()}</p>
          </div>
          <LangSwitcher loginStyle />
        </div>
      </div>
    );
  }

}

SignUpInstructions.propTypes = {
  step: PropTypes.number.isRequired,
};

export default WithTrans(SignUpInstructions);