import React, { useState } from 'react';
import classNames from 'classnames';

const StatItem = (props) => {
  const [expanded, setExpanded] = useState(false);
  const statClasses = classNames('StatItem', {
    'StatItem--expandable': props.expandable,
    'StatItem--expandable--expanded': expanded,
  });
  return (
    <div className={statClasses}>
      <h3 className="StatItem__title">{props.name}</h3>
      {props.value ? <span className="StatItem__value">{props.value}</span> : null }
      { props.children }
      {props.expandable ? <div><button onClick={() => setExpanded(!expanded)}>See all</button></div> : null }
    </div>
  )
}

export default StatItem;