import React from 'react'
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Tooltip from './Tooltip';
import avatarPlaceholder from '../assets/avatar-placeholder.jpg'

const ConversationShortcut = (props) => {
  const conversation = props.conversation;
  const user = conversation.user;
  const image = user && user.profileImageURL ? user.profileImageURL : avatarPlaceholder;
  const locked = conversation.locked ? true : false;
  const conversationClass = classNames('ConversationShortcut', {
    'ConversationShortcut--locked': locked,
  });
  return (
    <li className={conversationClass}>
      <Link to={`/conversations/${conversation.id}`}>
        <Tooltip text={user ? user.name : 'Bruger ikke fundet'} placement="bottom">
          <figure>
            <img src={image} alt={user ? user.name : 'Bruger ikke fundet'}/>
          </figure>
        </Tooltip>
      </Link>
    </li>
  )
}

export default ConversationShortcut;