import React from 'react';
import { ConversationsContext } from '../../providers/ConversationsProvider';

// This is to give the actual component a name when debugging
const getDisplayName = WrappedComponent => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

const WithConversations = Component => {
  const WrappedComponent = props => {
    return (
      <ConversationsContext.Consumer>
        {conversations => <Component conversations={conversations} {...props} />}
      </ConversationsContext.Consumer>
    );
  };
  WrappedComponent.displayName = `WithConversations(${getDisplayName(
    WrappedComponent,
  )})`;
  return WrappedComponent;
};
export default WithConversations;