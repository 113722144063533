import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
  fallbackLng: 'da',
  resources: {
    da: {
      translations: require('../locales/da/translations.json'),
      language_name: 'Dansk'
    },
    en: {
      translations: require('../locales/en/translations.json'),
      language_name: 'English'
    }
  },
  ns: ['translations'],
  defaultNS: 'translations',
  returnObjects: true,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
}, (err, t) => {
  // console.info('i18next.language', i18next.language)
});

export default i18next;