import React, { Component } from 'react';
import classNames from 'classnames';

import { firestore, firebasefunctions, firebasecore } from '../firebase';
import { validateString } from '../util';

import WithUser from './HOC/WithUser';
import { WithTrans } from '../i18n/i18nWrapper';

import Loader from './Loader';

class Report extends Component {

  state = {
    loading: false,
    reportSuccess: false,
    reportMessage: '',
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  report(userId) {
    //console.log('Report user with id: ', userId)
    if(this.state.loading) {
      // Request already in progress
      return;
    }

    if(!validateString(this.state.reportMessage)) {
      // Requires a message
      alert(this.props.t('conversations.block_message_required'));
      return
    }

    this.setState({ loading: true });

    // Add blocked user to array of blocked users on sender
    const currentUserDocumentRef = firestore.collection('users').doc(this.props.user.uid);
    currentUserDocumentRef.get().then((docSnapshot) => {
      if (!docSnapshot.exists) {
        // This user ID doesn't exist in the DB
        this.setState({
          loading: false,
        });
        alert('Noget gik galt');
      } else {
        // Update user array of blocked users
        currentUserDocumentRef.update({ blockedUsers: firebasecore.firestore.FieldValue.arrayUnion({
          userId: userId,
          reportMessage: this.state.reportMessage,
        })}).then((success) => {
          // User blocked
          // Send email
          const newReportUserMail = firebasefunctions.httpsCallable('reportUserEmail');
          newReportUserMail({
            message: this.state.reportMessage, 
            reportedUser: this.props.userToReport,
            reportingUser: this.props.user.uid,
            conversationId: this.props.conversationId
          }).then(result => {
            // email sent
          });
          // Lock current conversation
          const currentConversationDocumentRef = firestore.collection('conversations').doc(this.props.conversationId);
          if(currentConversationDocumentRef) {
            currentConversationDocumentRef.update({ locked: true }).then((success) => {
              setTimeout( () => {
                // Allow loading for at least 1 second
                this.setState({
                  loading: false,
                  reportSuccess: true,
                });
              }, 1000);
            });
          } else { console.error('conversation not found');}
        }).catch(function(error) {
          console.error("Error: ", error);
          alert('Something went wrong, please refresh and try again');
        });
      }
    });
  
  }

  render() {
    const sendReportBtnClass = classNames('Button Button--green', {
      'Button--loading': this.state.loading
    });
    return (
      <div className="Report">
        <h3>{this.props.t('conversations.block_user')}</h3>
        <p>{this.props.t('conversations.block_user_description')}</p>
        <div className="Form">
          <textarea name="reportMessage" required placeholder={this.props.t('conversations.block_user_placeholder')} onChange={this.handleChange} value={this.state.reportMessage}></textarea>
        </div>
        {!this.state.reportSuccess ? (
          <button className={sendReportBtnClass} onClick={() => this.report(this.props.userToReport)}>
            {this.state.loading ? (
              <Loader/>
            ) : (
              <span>{this.props.t('conversations.block_and_report')}</span>
            ) }
          </button>
        ) : (
          <div className="Form__notifyMessage Form__notifyMessage--showMessage">
            <p>{this.props.t('conversations.block_feedback')}</p>
          </div>
        )}
      </div>
    )
  }

}

export default WithUser(WithTrans(Report));