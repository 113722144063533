import React, { Component } from 'react';

import TempUserContext from "../../providers/TempUserProvider";
import { WithTrans } from  '../../i18n/i18nWrapper';

import icons from '../../assets/icons.svg';
const checkmarkIcon = icons + '#checkmark';
const crossIcon = icons + '#cross';

class StepFour extends Component {

  state = {
    passwordOne: '',
    passwordTwo: '',
    hasSixChars: false,
    hasOneBigChar: false,
    hasOneSmallChar: false,
    hasOneNumber: false,
    hasOneSpecialSign: false,
    matches: false,
  }

  componentDidUpdate(prevProps, prevState) {

    if(prevState !== this.state) {
      const { passwordOne } = this.state;

      // Test length
      if(passwordOne.length > 5) {
        if(!this.state.hasSixChars) {
          this.setState({ hasSixChars: true })
        }
      } else {
        if(this.state.hasSixChars) {
          this.setState({ hasSixChars: false })
        }
      }
  
      // Test for upper case character
      if(passwordOne.toLowerCase() !== passwordOne) {
        if(!this.state.hasOneBigChar) {
          this.setState({ hasOneBigChar: true })
        }
      } else {
        if(this.state.hasOneBigChar) {
          this.setState({ hasOneBigChar: false })
        }
      }
  
      // Test for lower case character
      if(passwordOne.toUpperCase() !== passwordOne) {
        if(!this.state.hasOneSmallChar) {
          this.setState({ hasOneSmallChar: true })
        }
      } else {
        if(this.state.hasOneSmallChar) {
          this.setState({ hasOneSmallChar: false })
        }
      }
  
      // Test if password has number
      if(/\d/.test(passwordOne)) {
        if(!this.state.hasOneNumber) {
          this.setState({ hasOneNumber: true })
        }
      } else {
        if(this.state.hasOneNumber) {
          this.setState({ hasOneNumber: false })
        }
      }
  
      // Test if password has special char
      const specialChars = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/;
      if(specialChars.test(passwordOne)) {
        if(!this.state.hasOneSpecialSign) {
          this.setState({ hasOneSpecialSign: true })
        }
      } else {
        if(this.state.hasOneSpecialSign) {
          this.setState({ hasOneSpecialSign: false })
        }
      }
  
      // Test if passwords match
      if(passwordOne === this.state.passwordTwo && passwordOne.length > 0) {
        if(!this.state.matches) {
          this.setState({ matches: true })
        }
      } else {
        if(this.state.matches) {
          this.setState({ matches: false })
        }
      }
  
      this.checkIfValid()
    }
  }

  checkIfValid() {
    const { hasSixChars, hasOneBigChar, hasOneSmallChar, hasOneNumber, hasOneSpecialSign, matches} = this.state;
    if(hasSixChars && hasOneBigChar && hasOneSmallChar && hasOneNumber && hasOneSpecialSign && matches) {
      // console.log('Password valid and matching')
      const context = this.context;
      const { updateTempUser } = context;
      updateTempUser({
        password: btoa(this.state.passwordOne)
      })
      this.props.navReady();
    } else {
      this.props.navNotReady();
    }
  }

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
 
  render() {
    const { hasSixChars, hasOneBigChar, hasOneSmallChar, hasOneNumber, hasOneSpecialSign, matches} = this.state;
    const validClass = 'valid';
    const notValidClass = 'not-valid';
    return (
      <div className="SignUpStep">
        <div className="SignUpStep__content">
          <h2 className="SignUpStep__title">{this.props.t('signup.type_in_password')}</h2>
          <div className="Form SignUpStep__form">
            <label htmlFor="passwordOne">{this.props.t('signup.password')}</label>
            <input type="password" name="passwordOne" id="passwordOne" required onChange={this.onChange}/>
            <label htmlFor="passwordTwo">{this.props.t('signup.repeat_password')}</label>
            <input type="password" name="passwordTwo" id="passwordTwo" required onChange={this.onChange}/>
            <div className="SignUpStep__passwordCriterias">
              <ul className="list-unstyled">
                <li className={hasSixChars ? validClass : notValidClass}>
                  <svg>
                    <use xlinkHref={hasSixChars ? checkmarkIcon : crossIcon}></use>
                  </svg>
                  <span>{this.props.t('signup.password_min_six_chars')}</span>
                </li>
                <li className={hasOneBigChar ? validClass : notValidClass}>
                  <svg>
                    <use xlinkHref={hasOneBigChar ? checkmarkIcon : crossIcon}></use>
                  </svg>
                  <span>{this.props.t('signup.password_min_one_big_char')}</span>
                </li>
                <li className={hasOneSmallChar ? validClass : notValidClass}>
                  <svg>
                    <use xlinkHref={hasOneSmallChar ? checkmarkIcon : crossIcon}></use>
                  </svg>
                  <span>{this.props.t('signup.password_min_one_small_char')}</span>
                </li>
                <li className={hasOneNumber ? validClass : notValidClass}>
                  <svg>
                    <use xlinkHref={hasOneNumber ? checkmarkIcon : crossIcon}></use>
                  </svg>
                  <span>{this.props.t('signup.password_min_one_number')}</span>
                </li>
                <li className={hasOneSpecialSign ? validClass : notValidClass}>
                  <svg>
                    <use xlinkHref={hasOneSpecialSign ? checkmarkIcon : crossIcon}></use>
                  </svg>
                  <span>{this.props.t('signup.password_min_one_special_char')}</span>
                </li>
                <li className={matches ? validClass : notValidClass}>
                  <svg>
                    <use xlinkHref={matches ? checkmarkIcon : crossIcon}></use>
                  </svg>
                  <span>{this.props.t('signup.passwords_has_to_match')}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )

  }

}

StepFour.contextType = TempUserContext; 

export default WithTrans(StepFour);


