import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { firestore } from '../../firebase';

import { WithTrans } from  '../../i18n/i18nWrapper';

// import icons from '../../assets/icons.svg';
// const resendIcon = icons + '#resend';

class StepOne extends Component {

  state = {
    validated: false,
    resendOnce: false,
  };

  unsubscribe = null;

  componentDidMount() {
    this.listenForValidation();
  };

  listenForValidation = async () => {
    this.unsubscribe = firestore.collection('signups').doc(this.props.signUpID).onSnapshot(snapshot => {
      if (snapshot.exists) {
        this.setState({
          validated: snapshot.data().validated,
        });
      } 
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.validated !== prevState.validated) {
      if (this.state.validated) {
        this.props.navReady();
      } else {
        this.props.navNotReady();
      }
    }
  };

  componentWillUnmount = () => {
    this.unsubscribe();
    this.props.navNotReady();
  }

  render() {
    const indicatorClass = classNames('Form__indicator', {
      'Form__indicator--valid': this.state.validated,
    });
    // const resendBtnClass = classNames('Button Button--green--outline Button--icon SignUpStep__resendBtn', {
    //   'Button--disabled': this.state.resendOnce,
    // });
    // const resendBtnText = this.state.resendOnce ? 'Link er gensendt til din e-mailadresse' : 'Gensend link' ;

    return (
      <div className="SignUpStep">
        <div className="SignUpStep__content">
          <h2 className="SignUpStep__title">{this.props.t('signup.check_your_inbox_for_link')}</h2>
          <div className="Form SignUpStep__form">
            <label htmlFor="email">{this.props.t('signup.email')}</label>
            <input
              className="disabled"
              type="email"
              value={this.props.email}
              placeholder={this.props.t('signin.email_placeholder')}
              disabled
            />
            <label htmlFor="email">{this.props.t('signup.validation')}</label>
            <div className={indicatorClass}>
              <div><span>{this.props.t('signup.validation_status')}:</span></div>
              <div>
                <span>{this.props.t('signup.not_validated')}</span>
                <span>{this.props.t('signup.validated')}</span>
              </div>
            </div>
            {this.state.validated ? (
              <p className="Form__successMessage Form__successMessage--showMessage">{this.props.t('signup.thank_you_your_email_is_validated')}</p>
            ) : (
              <p className="SignUpStep__emailinfo">{this.props.t('signup.you_have_to_validate_email_to_continue')}</p>
            )}
          </div>
        </div>
      </div>
    );
  };

}

StepOne.propTypes = {
  email: PropTypes.string.isRequired,
  signUpID: PropTypes.string.isRequired,
  navReady: PropTypes.func.isRequired,
  navNotReady: PropTypes.func.isRequired,
};

export default WithTrans(StepOne);