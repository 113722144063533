import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';
import 'firebase/performance';

const firebaseConfig = {
  apiKey: "AIzaSyD8j7ghDZm9KD4hTRJ25Y0YyniqSNTp47g",
  authDomain: "careermentor-da3ae.firebaseapp.com",
  databaseURL: "https://careermentor-da3ae.firebaseio.com",
  projectId: "careermentor-da3ae",
  storageBucket: "gs://careermentor-da3ae.appspot.com",
  messagingSenderId: "1037281866207",
  appId: "1:1037281866207:web:cc19b66d29e5b1d3",
  measurementId: "G-L3VPW8D2SJ"
};

firebase.initializeApp(firebaseConfig);

export const firebasecore = firebase;
export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const firebasefunctions = firebase.functions();
export const analytics = firebase.analytics();

if(process.env.NODE_ENV === 'production') { 
  // Only init perfomance trakcing in production
  firebase.performance();
}
if(process.env.NODE_ENV === 'development') { 
  // Dont track analytics in development
  analytics.setAnalyticsCollectionEnabled(false);
}

export const signInWithEmailAndPassword = (email, password) => auth.signInWithEmailAndPassword(email, password).catch(function(error) {
  // Handle Errors here.
  var errorCode = error.code;
  // var errorMessage = error.message;
  // console.log(errorCode);
  // console.log(errorMessage);
  return {error: errorCode};
});

export const forgotPassword = async emailAddress => {
  const test = await auth.sendPasswordResetEmail(emailAddress).then(function() {
    // Email sent.
    return true;
  }).catch(function(error) {
    // An error happened.
    return {error: error};
  });
  return test;
};

export const signOut = () => {
  auth.signOut();
}

export const getUserReference = async uid => {
  if (!uid) return null;
  try {
    return firestore.collection('users').doc(uid);
  } catch (error) {
    console.error('Error fetching user', error.message);
    return false;
  }
};

export const getUserDocument = async uid => {
  if (!uid) return null;
  try {
    const userRef = await firestore.collection('users').doc(uid);
    const userDocument = await userRef.get();
    const userData = await userDocument.data();
    if (userData) {
      return userData;
    } else {
      // error
      return false;
    }
  } catch (error) {
    console.error('Error fetching user', error.message);
    return false;
  }
};

export const getMentorUsers = async () => {
  const mentorsSnapshot = await firestore.collection('users').where("mentor", "==", true).get();

  let mentors = [];

  mentorsSnapshot.forEach((doc) => {
    const mentor = { uid: doc.id, ...doc.data() }
    mentors.push(mentor);
  })

  return mentors;
}

export const getMenteeUsers = async () => {
  const menteesSnapshot = await firestore.collection('users').where("mentee", "==", true).get();

  let mentees = [];

  menteesSnapshot.forEach((doc) => {
    const mentee = { uid: doc.id, ...doc.data() }
    mentees.push(mentee);
  })

  return mentees;
}

export const getConversationsCount = async () => {
  const conversationsSnapshot = await firestore.collection('conversations').get();

  let count = 0;
  conversationsSnapshot.forEach((doc) => {
    count++
  })

  return count;
}

export const getAllCompetenceCategoriesFromDB = async () => {
  const competenciesSnapshot = await firestore.collection('competencies').orderBy("order", "asc").get();

  let competenceCategories = [];
  let competencies = [];

  // Push all parent categories to categories array
  competenciesSnapshot.forEach((doc) => {
    const competenceCategory = {
      "id": doc.id,
      "title": doc.data().title,
      "title_en": doc.data().title_en,
      "competencies": []
    }
    competenceCategories.push(competenceCategory);
  });

  // Now get all competencies that are child of competence categories
  competenceCategories.forEach((category) => {
    firestore.collection('competencies').doc(category.id).collection('competencies').get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // Push competency to parent category array
        category.competencies.push({ 
          "id": doc.id, 
          "title": doc.data().title,
          "title_en": doc.data().title_en
        });
        // Push competency to list of all competencies (we need it in render)
        competencies.push({ 
          "id": doc.id, 
          "title": doc.data().title,
          "title_en": doc.data().title_en
        });
      });
    }).catch(function(error) {
      // Something went wrong
      console.error("Error: ", error);
    });
  })

  const competenciesObject = {
    competenceCategories: competenceCategories,
    allCompetencies: competencies
  };
  
  return competenciesObject;
}

export const newNotification = async (uid, notification) => {
  if (!uid || !notification) return;

  const userNotificationsRef = firestore.collection('users').doc(uid).collection('notifications');

  if(userNotificationsRef) {
    try {
      await userNotificationsRef.add({
        ...notification
      });
    } catch (error) {
      console.error('Error sending notification', error.message);
    }
  }

};

export const newAchievement = async (uid, achievement) => {
  if (!uid || !achievement) return;

  const userRef = firestore.collection('users').doc(uid);

  if(userRef) {
    try {
      await userRef.update({ achievements: firebase.firestore.FieldValue.arrayUnion(achievement)});
    } catch (error) {
      console.error('Error adding achievement', error.message);
    }
  }

};

export const removeAchievement = async (uid, achievement) => {
  if (!uid || !achievement) return;

  const userRef = firestore.collection('users').doc(uid);

  if(userRef) {
    try {
      await userRef.update({ achievements: firebase.firestore.FieldValue.arrayRemove(achievement)});
    } catch (error) {
      console.error('Error removing achievement', error.message);
    }
  }

};

export default firebase;