import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { getDateString } from '../util';

import WithUser from '../components/HOC/WithUser';
import { WithTrans } from '../i18n/i18nWrapper';

class ConversationPreview extends Component {

  render() {
    const conversation = this.props.conversation;
    let isUnread = false;
    const locked = conversation.locked ? true : false;
    // User read key
    const userReadKey = 'lastRead-' + this.props.user.uid;
    // Check if user has a last-read-time on the conversation
    if(conversation[userReadKey]) {
      // The user has at some point read the conversation
      if (conversation.lastMessageTimestamp > conversation[userReadKey]) {
        // The last message is newer than last check time (most be unread)
        isUnread = true;
      } else {
        // The last check time is newer than the last message (already read last message)
        isUnread = false;
      }
    }
    const matchAnimationClass = classNames('ConversationPreview', {
      'ConversationPreview--unread': isUnread,
      'ConversationPreview--locked': locked,
    });
    const dateString = getDateString(conversation.lastMessageTimestamp, this.props.i18n.language);
    const message = conversation.lastMessage.length > 100 ? conversation.lastMessage.substr(0, 100) + '...' :  conversation.lastMessage;
    return (
      <li className={matchAnimationClass}>
        <Link to={`/conversations/${conversation.id}`}>
          <figure className="ConversationPreview__image">
            <img src={conversation.user ? conversation.user.profileImageURL: '#'} alt={conversation.user ? conversation.user.name : 'User not found'}/>
          </figure>
          <div className="ConversationPreview__inner">
            <p className="ConversationPreview__name">{conversation.user ? conversation.user.name : 'User not found'}</p>
            <p className="ConversationPreview__message">{message}</p>
          </div>
          {locked ? <span className="ConversationPreview__locked">{this.props.t('conversations.conversation_locked')} ⚠</span> : null}
          <p className="ConversationPreview__date">{dateString}</p>
          {isUnread ? <span className="ConversationPreview__unread"></span> : null}
        </Link>
      </li>
    )
  }

}

export default WithUser(WithTrans(ConversationPreview));