import React, { Component } from 'react';
import { TweenLite, TimelineLite, Elastic, Power2 } from "gsap/TweenMax";

class Choice extends Component {

  componentDidMount() {
    // Objects
    const boxOne = document.getElementById('Choice_Boxone');
    const boxTwo = document.getElementById('Choice_Boxtwo');
    const man = document.getElementById('Choice_Man');
    const circle = document.getElementById('Choice_circle');

    // Set initial state
    TweenLite.set(boxOne, {
      opacity: 0,
      y: 50,
      scale: 0.7,
      transformOrigin: '50% 100%'
    });
    TweenLite.set(boxTwo, {
      opacity: 0,
      y: 50,
      scale: 0.7,
      transformOrigin: '50% 100%'
    });
    TweenLite.set(man, {
      opacity: 0,
      x: -40,
    });
    TweenLite.set(circle, {
      opacity: 0,
      scale: 0.5,
      y: -20,
      transformOrigin: '50% 50%'
    });

    // // Timeline
    const timeline = new TimelineLite({ paused: true });

    timeline.to(circle, 0.6, {
      opacity: 1,
      y: 0,
      scale: 1,
      ease: Elastic.easeOut.config(1,0.3),
    }).to(man, 0.4, {
      opacity: 1,
      x: 0,
      ease: Power2.easeOut
    },'-=0.2').to(boxOne, 1, {
      opacity: 1,
      y: 0,
      scale: 1,
      ease: Elastic.easeOut.config(0.3,0.3),
    },'-=0.2').to(boxTwo, 1, {
      opacity: 1,
      y: 0,
      scale: 1,
      ease: Elastic.easeOut.config(0.3,0.3),
    },'-=0.7');

    // // Play
    setTimeout(() => {
      timeline.play();
    }, 500);

  }

  render() {
    return (
      <svg id="Choice" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 982 676.5">
        <g id="Choice_circle">
          <ellipse fill="#F2F2F2" cx="134" cy="655" rx="134" ry="21.5"/>
          <ellipse fill="none" stroke="#3F3D56" strokeMiterlimit="10" cx="144.5" cy="642.5" rx="134" ry="21.5"/>
        </g>
        <g id="Choice_Boxone">
          <path fill="#4EA39A" d="M115.8,27.5h357.3c12.6,0,22.8,10.2,22.8,22.8v194.3c0,12.6-10.2,22.8-22.8,22.8H115.8
            c-12.6,0-22.8-10.2-22.8-22.8V50.3C93,37.7,103.2,27.5,115.8,27.5z"/>
          <path fill="none" stroke="#3F3D56" strokeMiterlimit="10" d="M84.8,0.5h357.3c12.6,0,22.8,10.2,22.8,22.8v194.3
            c0,12.6-10.2,22.8-22.8,22.8H84.8c-12.6,0-22.8-10.2-22.8-22.8V23.3C62,10.7,72.2,0.5,84.8,0.5z"/>
        </g>
        <g id="Choice_Boxtwo">
          <path fill="#F4BF92" d="M601.8,217.5h357.3c12.6,0,22.8,10.2,22.8,22.8v194.3c0,12.6-10.2,22.8-22.8,22.8H601.8
            c-12.6,0-22.8-10.2-22.8-22.8V240.3C579,227.7,589.2,217.5,601.8,217.5z"/>
          <path fill="none" stroke="#3F3D56" strokeMiterlimit="10" d="M570.8,190.5h357.3c12.6,0,22.8,10.2,22.8,22.8v194.3
            c0,12.6-10.2,22.8-22.8,22.8H570.8c-12.6,0-22.8-10.2-22.8-22.8V213.3C548,200.7,558.2,190.5,570.8,190.5z"/>
        </g>
        <g id="Choice_Man">
          <path fill="#FFC1C7" d="M222.5,269.5c0,0,2,15-3,21s-13,46-13,46v24c0,0,31-45,37-63s0-32,0-32L222.5,269.5z"/>
          <path fill="#3F3D56" d="M219.5,195.5l0.4,0.5c5.2,5.8,9.2,12.5,12,19.8c6.6,17.4,20.7,60.4,15.6,64.7c-7,6-27,10-28,9s-21-63-21-63 L219.5,195.5z"/>
          <path fill="#3F3D56" d="M187,620.5c0,0,24,5,25,0c0.6-3.1,1.6-6.1,3-9c0,0,21.8,0.9,16.1,20c-0.6,1.9-1.8,3.5-3.5,4.5 c-5.2,3.2-17.4,10.6-23.6,14.5c-4.7,2.9-11.4,0.7-16.1-1.6c-3.5-1.7-5.4-5.6-4.7-9.5L187,620.5z"/>
          <path fill="#3F3D56" d="M118,623.5c0,0-24,5-25,0c-0.6-3.1-1.6-6.1-3-9c0,0-21.7,0.9-16.1,19.9c0.6,1.9,1.8,3.5,3.6,4.6 c5.2,3.2,17.4,10.6,23.5,14.5c5.1,3.2,12.6,0.3,17.4-2.3c2.7-1.4,4.2-4.5,3.6-7.5L118,623.5z"/>
          <path fill="#FFC1C7" d="M88,282.5c0,0-2,15,3,21s13,29,13,29v24c0,0-31-28-37-46s0-32,0-32L88,282.5z"/>
          <circle fill="#FFC1C7" cx="158" cy="134.5" r="25"/>
          <path fill="#FFC1C7" d="M178,144.5c0,0-7,32,0,36s-42,0-42,0s11-27,6-36S178,144.5,178,144.5z"/>
          <path fill="#2F2E41" d="M202,357.5c0,0,9,13,6,53s-6,82-6,82s22,126,15,129s-30,6-33,3s-17-120-17-120l-13-96l-20,110 c0,0-11,107-19,112c-5.7,3.6-17.1,1-23.1-0.7c-2.3-0.7-3.9-2.8-3.8-5.2c0.7-16.8,4-83.2,11.8-124c9-47-9-141,7-143 S202,357.5,202,357.5z"/>
          <path fill="#3F3D56" d="M132,176.5c0,0,4-18,19-16s25,1,28,11s35,18,35,18s8,1,7,14s-21,61-21,61s1,40,6,58s5,40-6,45s-80,23-96,1 c0,0-9-32-3-45s-1-64-1-64l-19-42c0,0,6-20,23-26C114.1,188,123.5,182.9,132,176.5z"/>
          <path fill="#3F3D56" d="M89,208.5l-8,9c0,0-24,59-17,65s27,10,28,9s18-52,18-52L89,208.5z"/>
          <path fill="#2F2E41" d="M187,119.5c0,0-4,3-5,10s-7.1-4-8.6,10.5S148,164.5,139,157.5s-24-51-3-58s42-6,46,1S187,119.5,187,119.5z"/>
          <path opacity="0.1" d="M167.4,140c1.4-14.5,7.6-3.5,8.6-10.5s5-10,5-10s-1-12-5-19
            c-1.9-3.3-7.7-5.3-15.6-5.7c10.8-0.5,19.2,1.5,21.6,5.7c4,7,5,19,5,19s-4,3-5,10s-7.1-4-8.6,10.5c-1.2,12.3-18.6,21.3-29.4,19.5 C154.3,158.1,166.4,150.2,167.4,140z"/>
        </g>
      </svg>
    );
  }

}

export default Choice;