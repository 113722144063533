import React, { useContext } from 'react';

import { ConversationsContext } from '../providers/ConversationsProvider';
import { WithTrans } from '../i18n/i18nWrapper';

import ConversationPreview from './ConversationPreview';
import Loader from './Loader';

const Conversations = (props) => {
  const conversations = useContext(ConversationsContext);
  return (
    <div className="Conversations">
      {conversations && conversations.length > 0 ? (
        <>
          {conversations[0] !== 'loading conversations' ? (
            <ul className="list-unstyled">
              {conversations.map((conversation) =>
                <ConversationPreview conversation={conversation} key={conversation.id} />
              )}
            </ul>
          ) : <Loader color='green'/> }
        </>
      ) : (
        <p className="Conversations__noconversations">{props.t('conversations.no_conversations')} <span role="img" aria-label={props.t('conversations.no_conversation_emoji')}>🔦</span><br></br> {props.t('conversations.no_conversations_tip')}</p>
      )}
    </div>
  )

}

export default WithTrans(Conversations);