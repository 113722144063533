import React, { Component } from 'react';
import classNames from 'classnames';

import { firestore, firebasecore, firebasefunctions, analytics } from '../firebase';
import { getCompetenceNames } from '../util';

import WithUser from './HOC/WithUser';
import { WithTrans } from '../i18n/i18nWrapper';

import Tooltip from './Tooltip';
import Loader from './Loader';

import avatarPlaceholder from '../assets/avatar-placeholder.jpg'

import icons from '../assets/icons.svg';
const chevronIcon = icons + '#chevron';
const owlIcon = icons + '#owl';
const sproutIcon = icons + '#sprout';
const rocketIcon = icons + '#rocket-two';
const linkedInIcon = icons + '#linkedin';

class Match extends Component {

  state = {
    requestSend: false,
    requestSucces: false,
    ignoreMatch: false,
    ignoreMatchSuccess: false,
    loading: false,
    loadingRequest: false,
    loadingIgnore: false,
    requestMessage: ''
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  sendRequest(userId) {
    if(this.state.loading) {
      // Request already in progress
      return;
    }
    // Track request
    if(process.env.NODE_ENV === 'production') {
      if(this.props.requestType === 'mentor') {
        analytics.logEvent('Mentor request');
      }
      if(this.props.requestType === 'mentee') {
        analytics.logEvent('Mentee request');
      }
    }
    // Start loading
    this.setState({ loading: true, loadingRequest: true });
    // Send request via cloud function
    const makeNewMatchRequest = firebasefunctions.httpsCallable('newMatchRequest');
    makeNewMatchRequest({
      sender: this.props.user.uid,
      recipient: userId, 
      requestType: this.props.requestType, 
      message: this.state.requestMessage,
      env: process.env.NODE_ENV,
    }).then(() => {
      // Indicate success
      setTimeout(() => {
        // Allow loading for at least 1 second
        this.setState({
          requestSend: true,
          requestSucces: true,
          loading: false,
          loadingRequest: false
        });
      }, 1000);
    }).catch((error) => {
      // Indicate error
      console.error(error);
      this.setState({
        requestSend: true,
        requestSucces: false,
        loading: false,
      });
    });
  }

  ignoreMatch(userId) {
    if(this.state.loading) {
      // Request already in progress
      return;
    }
    this.setState({ loading: true, loadingIgnore: true });
    // ( We can keep this one client side since user is only editing own data which is always allowed)
    // Add requested persons to array of requested people on sender, so it can be filtered out on new search
    const currentUserDocumentRef = firestore.collection('users').doc(this.props.user.uid);
    currentUserDocumentRef.get().then((docSnapshot) => {
      if (!docSnapshot.exists) {
        // This user ID doesn't exist in the DB
        this.setState({
          ignoreMatch: true,
          ignoreMatchSuccess: false,
          loading: false,
        });
      } else {
        if(this.props.requestType === 'mentor') {
          // Check if mentor match has already been ignored
          if(docSnapshot.data().ignoredMentors && docSnapshot.data().ignoredMentors.includes(userId)) {
            // User id already exist in igored mentors array
            this.setState({
              ignoreMatch: true,
              ignoreMatchSuccess: false,
              loading: false,
            });
            return;
          }
        }
        if(this.props.requestType === 'mentee') {
          // Check if mentee match has already been ignored by mentor
          if(docSnapshot.data().ignoredMentees && docSnapshot.data().ignoredMentees.includes(userId)) {
            // User id already exist in ignored mentees array
            this.setState({
              ignoreMatch: true,
              ignoreMatchSuccess: false,
              loading: false,
            });
            return;
          }
        }
        // Update user array of requested mentors
        if(this.props.requestType === 'mentor') {
          currentUserDocumentRef.update({ ignoredMentors: firebasecore.firestore.FieldValue.arrayUnion(userId) });
        }
        // Update user array of requested mentees
        if(this.props.requestType === 'mentee') {
          currentUserDocumentRef.update({ ignoredMentees: firebasecore.firestore.FieldValue.arrayUnion(userId) });
        }
        setTimeout( () => {
          // Allow loading for at least 1 second
          this.setState({
            ignoreMatch: true,
            ignoreMatchSuccess: true,
            loading: false,
            loadingIgnore: false,
          });
        }, 1000);
      }
    });
  }


  render() {
    const match = this.props.match;
    let available = true;
    let gender = '';
    if(this.props.requestType === 'mentor') {
      if(!match.mentorProfile.available) {
        available = false;
        if(match.gender === 'male') { gender = this.props.t('match.he') }
        if(match.gender === 'female') { gender = this.props.t('match.she') }
        if(match.gender === 'other') { gender = this.props.t('match.heshe') }
      }
    }
    const image = match.profileImageURL ? match.profileImageURL : avatarPlaceholder;
    const matchClass = classNames('Match', {
      'Match--open': this.props.open,
      'Match--unavailable': !available,
    });
    const sendRequestBtnClass = classNames('Button', {
      'Button--loading': this.state.loading && this.state.loadingRequest,
    });
    const ignoreProfileBtnClass = classNames('Button Button--gray', {
      'Button--loading': this.state.loading && this.state.loadingIgnore,
    });
    const linkedInBtnClass = classNames('Button Button--green Button--icon Match__linkedinbtn', {
      'Match__linkedinbtn--none': match.linkedInUrl === '#' ? true : false,
    });
    const linkedInBtnText = match.linkedInUrl === '#' ? this.props.t('match.no_linkedin') : this.props.t('match.linkedin');
    const matchScoreClass = classNames('Match__score', {
      'Match__score--top': match.MentorMatchRating === 2,
      'Match__score--good': match.MentorMatchRating === 1,
      'Match__score--match': match.MentorMatchRating === 0,
    });
    let matchScoreText = this.props.t('match.top_match') ;
    if (match.MentorMatchRating === 1) {matchScoreText = this.props.t('match.good_match'); }
    if (match.MentorMatchRating === 0) {matchScoreText = this.props.t('match.match'); }
    let matchingCompetenciesWithNames = [];
    if(match.MentorMatchCriterias.competencies && match.MentorMatchCriterias.competencies.length > 0) {
      matchingCompetenciesWithNames = getCompetenceNames(match.MentorMatchCriterias.competencies);
    }
    return (
      <li className={matchClass}>
        <div className="Match__top" role="button" tabIndex="0" aria-label={this.props.t('match.open_profile')} data-matchscore={match.MentorMatchScore} onClick={this.props.openMatch}>
          <figure className="Match__image">
            <img src={image} alt={match.name}/>
          </figure>
          <div>
            <h3 className="Match__name">{match.name}</h3>
            <p className="Match__title">{match.title}</p>
            <p className="Match__company">{match.company}</p>
          </div>
          <span className={matchScoreClass}>{matchScoreText}</span>
          <svg className="Match__expand">
            <use xlinkHref={chevronIcon}></use>
          </svg>
        </div>
        <div className="Match__inner">
          <div className="Match__info">
            <div className="Match__info__top">
              {match.linkedInUrl ? (
                <a href={match.linkedInUrl} className={linkedInBtnClass} target="_blank" rel="noopener noreferrer">
                  <span>{linkedInBtnText}</span>
                  <svg>
                    <use xlinkHref={linkedInIcon}></use>
                  </svg>
                </a>
              ) : null }
              <div className="Match__moreinfo">
                {match.mentor ? (
                  <Tooltip text="Mentor" placement="left">
                    <svg className="Match__indicator">
                      <use xlinkHref={owlIcon}></use>
                    </svg>
                  </Tooltip>
                ) : null }
                {match.mentee ? (
                  <Tooltip text="Mentee" placement="left">
                    <svg className="Match__indicator">
                      <use xlinkHref={sproutIcon}></use>
                    </svg>
                  </Tooltip>
                ) : null }
                {match.mentorProfile && match.mentorProfile.startupExperience ? (
                  <Tooltip text={this.props.t('match.startup_experience')} placement="left">
                    <svg className="Match__indicator">
                      <use xlinkHref={rocketIcon}></use>
                    </svg>
                  </Tooltip>
                ) : null }
              </div>
            </div>
            <div className="Match__info__inner">
              <div className="Match__criterias__wrapper">
                {
                  (matchingCompetenciesWithNames && matchingCompetenciesWithNames > 0) || (match.MentorMatchCriterias.criterias && match.MentorMatchCriterias.criterias.length > 0) ? (
                    <>
                      {match.MentorMatchCriterias.criterias && match.MentorMatchCriterias.criterias.length > 0 ? (
                        <div className="Match__criterias">
                          <span>{this.props.t('match.criteria')}:</span>
                          <ul className="list-unstyled">
                            {match.MentorMatchCriterias.criterias.map((criteria, i) =>
                              <li key={i}>{criteria}</li>
                            )}
                          </ul>
                        </div>
                      ) : null
                      }
                      {matchingCompetenciesWithNames && matchingCompetenciesWithNames.length > 0 ? (
                      <div className="Match__criterias">
                        <span>{this.props.t('match.competency_areas')}:</span>
                        <ul className="list-unstyled">
                          {matchingCompetenciesWithNames.map((competence) =>
                            <li key={competence.id}>{this.props.i18n.language === 'en' ? competence.title_en : competence.title}</li>
                          )}
                        </ul>
                      </div>
                      ) : null
                      }
                    </>
                  ) : null
                }
              </div>
              <div className="Match__text">
                <span className="Match__info__headline">{this.props.t('match.profile_text')}</span>
                <p>
                  {this.props.requestType === 'mentor' ? match.mentorProfile.profileText : match.menteeProfile.profileText}
                </p>
              </div>
            </div>
          </div>
          {available ? (
            <div className="Match__request">
              <span className="Match__info__headline">{this.props.t('match.your_message_to')} {this.props.requestType}:</span>
              <div className="Form">
                <textarea name="requestMessage" placeholder={this.props.t('match.there_is_a_bigger_chance') + ' ' + this.props.requestType + ' ' + this.props.t('match.accept_your_request')} onChange={this.handleChange} value={this.state.requestMessage}></textarea>
                {this.state.requestSend ? (
                  <>
                    {this.state.requestSucces ? (
                      <div className="Form__successMessage Form__successMessage--showMessage">
                        <p>{this.props.t('match.request_send')}</p>
                      </div>
                    ) : (
                      <div className="Form__errorMessage Form__errorMessage--showMessage">
                        <p>{this.props.t('match.request_error')}</p>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {!this.state.ignoreMatchSuccess ? (
                      <button className={sendRequestBtnClass} onClick={() => this.sendRequest(match.uid)}>
                        {this.state.loading && this.state.loadingRequest ? (
                          <Loader/>
                        ) : (
                          <span>{this.props.requestType === 'mentor' ? this.props.t('match.send_request_to_mentor') : this.props.t('match.send_request_to_mentee')}</span>
                        ) }
                      </button>
                    ) : null}
                  </>
                ) }
                {this.state.ignoreMatch ? (
                  <>
                    {this.state.ignoreMatchSuccess ? (
                      <div className="Form__notifyMessage Form__notifyMessage--showMessage">
                        <p>{this.props.t('match.remove_result_message')}</p>
                      </div>
                    ) : (
                      <div className="Form__errorMessage Form__errorMessage--showMessage">
                        <p>{this.props.t('match.remove_result_error')}</p>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {!this.state.requestSucces ? (
                      <button className={ignoreProfileBtnClass} onClick={() => this.ignoreMatch(match.uid)}>
                        {this.state.loading && this.state.loadingIgnore ? (
                          <Loader/>
                        ) : (
                          <span>{this.props.t('match.remove_profile')}</span>
                        ) }
                      </button>
                    ) : null}
                  </>
                ) }
              </div>
            </div>
          ) : (
            <div className="Match__unavailable">
              <p>{match.name} {this.props.t('match.set_as')} {gender} {this.props.t('match.unavailable')}</p>
            </div>
          )}
        </div>
      </li>
    )
  }

}

export default WithUser(WithTrans(Match));