import React, { Component } from 'react';
import { TweenLite, TimelineLite, Elastic } from "gsap/TweenMax";

class Mentor2020Trophy extends Component {

  componentDidMount() {
    // Objects
    const illustration = document.getElementById('Mentor2020Trophy');
    const trophy = document.getElementById('Mentor2020Trophy_Trophy');
    const text = document.getElementById('Mentor2020Trophy_Text');
    const fireworksOne = document.getElementById('Mentor2020Trophy_FireworksOne');
    const fireworksTwo = document.getElementById('Mentor2020Trophy_FireworksTwo');
    const fireworksThree = document.getElementById('Mentor2020Trophy_FireworksThree');

    // Check if used in a achievement context and if its locked
    const parentElement = illustration.parentElement;
    if(parentElement.classList.contains('Achievement__illustration--locked')) {
      // Dont play yet
    } else {
      // Set initial state
      TweenLite.set(trophy, {
        opacity: 0,
        y: 50,
        scale: 0,
        transformOrigin: '50% 50%'
      });
      TweenLite.set(text, {
        opacity: 0,
      });
      TweenLite.set(fireworksOne, {
        opacity: 0,
        scale: 0,
        transformOrigin: '50% 50%'
      });
      TweenLite.set(fireworksTwo, {
        opacity: 0,
        scale: 0,
        transformOrigin: '50% 50%'
      });
      TweenLite.set(fireworksThree, {
        opacity: 0,
        scale: 0,
        transformOrigin: '50% 50%'
      });

      // Timeline
      const timeline = new TimelineLite({ paused: true });

      timeline.to(trophy, 0.6, {
        opacity: 1,
        y: 0,
        scale: 1,
        ease: Elastic.easeOut.config(1,0.8),
      }).to(text, 0.3, {
        opacity: 1,
      }).to(fireworksOne, 0.6, {
        opacity: 1,
        scale: 1,
        ease: Elastic.easeOut.config(1,0.8),
      }).to(fireworksTwo, 0.6, {
        opacity: 1,
        scale: 1,
        ease: Elastic.easeOut.config(1,0.8),
      }).to(fireworksThree, 0.6, {
        opacity: 1,
        scale: 1,
        ease: Elastic.easeOut.config(1,0.8),
      });

      // Play
      setTimeout(() => {
        timeline.play();
      }, 500);
    }

  }

  render() {
    return (
      <svg viewBox="0 0 1009.5 766.6" id="Mentor2020Trophy">
        <path opacity="0.1" fill="#fe9236" d="M586.8,156.4c-64.7-1.9-126.4-20-185.2-39.8S284.4,75,221.2,64.5
        c-40.6-6.7-87.1-7.7-119.8,11.2c-31.5,18.1-41.7,49.4-47.2,78.5C50.1,176,47.7,199,59,219.4c7.8,14.2,21.7,26.1,31.4,39.8
        c33.5,47.4,9.8,105.7-26.4,152c-17,21.7-36.8,42.4-49.9,65.5s-19.2,49.5-7.7,73.1c11.4,23.3,38.5,40.9,67.9,53.2
        c59.7,25,130,32.2,198.6,36.3c151.8,9,304.5,5.1,456.7,1.2c56.3-1.4,112.9-2.9,168.3-10.4c30.8-4.2,62.6-10.8,84.9-26.9
        c28.4-20.4,35.4-54.8,16.4-80.4c-31.9-42.8-120-53.5-142.3-99.4c-12.3-25.3,0.3-53.5,18.2-76.9c38.2-50.3,102.3-94.5,105.7-152
        c2.3-39.5-28.5-79.1-76.1-97.8c-49.9-19.6-119.2-17.1-156,15.3C710.6,145.3,643.9,158.2,586.8,156.4z"/>
        <g id="Mentor2020Trophy_Trophy">
          <path fill="#fe9236" d="M740.4,218.3l-0.8,11.4c-0.1,1.5-3.1,38.3-24.8,81.3c-18.7,36.8-55,84.8-123.7,111.8
          c-5.4,2.1-10.9,4.1-16.7,5.9l-6.4-20.2c16.1-5.1,31.7-11.9,46.4-20.3c35-20.2,62.2-49,81.1-86.1c10.1-19.8,17.2-40.8,21.3-62.6
          h-78.1v-21.2H740.4z"/>
          <path fill="#fe9236" d="M445.3,408.5l-6.4,20.2c-5.8-1.8-11.3-3.8-16.7-5.9c-68.6-27-105-75-123.6-111.8
            c-21.8-43-24.7-79.7-24.8-81.3l-0.8-11.4h101.7v21.2h-78.1c4.1,21.8,11.3,42.9,21.4,62.6c19,37.1,46.2,65.9,81.1,86.1
            C413.6,396.7,429.2,403.5,445.3,408.5z"/>
          <path opacity="0.1" fill="#FFFFFF" d="M430.6,422.8c-68.6-27-105-75-123.6-111.8
            c-21.8-43-24.7-79.7-24.8-81.3l-0.8-11.4h-8.7l0.8,11.4c0.1,1.5,3.1,38.3,24.8,81.3c18.7,36.8,55,84.8,123.6,111.8
            c5.4,2.1,10.9,4.1,16.7,5.9l0.8-2.7C436.4,425,433.5,423.9,430.6,422.8z"/>
          <path fill="#fe9236" d="M624.1,603.6v114.2h-235V603.6l7.5-10c76.1-27,91.6-97.5,94.6-125.4c0.5-3.9,0.7-7.7,0.8-11.6h29.1
            c0.1,3.9,0.3,7.8,0.8,11.6c3.1,27.9,18.6,98.4,94.6,125.4L624.1,603.6z"/>
          <rect x="389.2" y="603.6" opacity="0.1" width="235" height="14.3"/>
          <path opacity="0.1" fill="#FFFFFF" d="M409.1,593.6c76.1-27,91.6-97.5,94.6-125.4
            c0.5-3.9,0.7-7.7,0.8-11.6h-12.5c-0.1,3.9-0.3,7.8-0.8,11.6c-3.1,27.9-18.6,98.4-94.6,125.4l-7.5,10v114.2h12.5V603.6L409.1,593.6z"
            />
          <path opacity="0.1" d="M353.5,218.3h21.1v21.2h-19.2C354.6,232.5,354,225.5,353.5,218.3z"/>
          <path opacity="0.1" d="M445.3,408.5l-6.4,20.2c-5.8-1.8-11.3-3.8-16.7-5.9
            c-8.8-10.8-16.6-22.3-23.3-34.5C413.6,396.7,429.2,403.5,445.3,408.5z"/>
          <path opacity="0.1" d="M568,408.5c16.1-5.1,31.7-11.9,46.4-20.3c-6.7,12.2-14.4,23.8-23.3,34.5
            c-5.4,2.1-10.9,4.1-16.7,5.9L568,408.5z"/>
          <path opacity="0.1" d="M659.8,218.3c-0.5,7.1-1.1,14.2-1.9,21.2h-19.2v-21.2H659.8z"/>
          <path opacity="0.1" d="M521.9,468.2c-10.1,2-20.5,2-30.6,0c0.5-3.9,0.7-7.7,0.8-11.6h29.1
            C521.2,460.5,521.5,464.4,521.9,468.2z"/>
          <path fill="#fe9236" d="M356,183.3c0.5,157,67.8,283.9,150.7,283.9s150.1-126.9,150.7-283.9L356,183.3z"/>
          <path opacity="0.1" fill="#FFFFFF" d="M382.7,183.3H356c0.5,157,67.8,283.9,150.7,283.9
            c4.5,0,9-0.4,13.4-1.1C443.4,453.3,383.2,331.7,382.7,183.3z"/>
          <polygon opacity="0.1" fill="#FFFFFF" points="434.2,652.9 586.5,652.9 586.5,647.3 426.7,647.3 
            426.7,688.3 434.2,688.3 "/>
          <circle fill="#F7F7F7" cx="504.5" cy="299.9" r="59.7"/>
          <path fill="#4EA39A" d="M523.1,300.4c-1,0.6-1.9,1.5-2.7,2.2c-2.7,3-4.5,6.8-4.5,11.2c0,4.2,1.6,8.2,4.5,11.1
              c0.9,0.9,1.7,1.6,2.7,2.4c0.2,0.2,0.7,0,0.7-0.4v-26.2C523.7,300.4,523.3,300.1,523.1,300.4z"/>
          <path fill="#4EA39A" d="M523.7,287.6c0-9.7-7.8-17.6-17.4-17.6h-5.1c-9.7,0-17.6,7.8-17.6,17.6v12.1c0,16.6,13.6,30.1,30.1,30.1h6.1
            c0.2,0,0.4-0.2,0.1-0.5c-1.1-0.9-2.1-1.9-3-3c-2.9-3.5-4.5-7.8-4.5-12.6c0-6.4,3-12.1,7.8-15.7c0.9-0.6,1.7-1.2,2.7-1.7
            c0.5-0.2,0.7-0.6,0.7-1.1L523.7,287.6L523.7,287.6z M513.5,296.9c-0.1,0-0.2,0-0.2,0.1h-4.9c-1.2,0-2.6,0.5-3.5,1.5l-0.7,0.7
            c-0.2,0.2-0.6,0.2-0.9,0l-0.7-0.7c-0.9-0.9-2.1-1.5-3.5-1.5h-4.4h-0.1c-4.1-0.2-7.2-3.7-7.1-7.8c0.2-3.7,3.2-6.7,7-7
            c4-0.2,7.5,2.6,8,6.6c0.1,0.7,0.6,1.2,1.4,1.2c0.7,0,1.2-0.5,1.4-1.2c0.5-4,4-6.8,8-6.6c3.6,0.2,6.6,3.2,7,6.8
            C520.2,292.9,517.3,296.4,513.5,296.9z"/>
          <circle fill="#4EA39A" cx="495" cy="289.5" r="3.1"/>
          <circle fill="#4EA39A" cx="512.2" cy="289.5" r="3.1"/>
        </g>
        <g opacity="0.8" id="Mentor2020Trophy_FireworksOne">
          <path opacity="0.5" fill="#FE9236" d="M737.7,69c-2.7-3.7-8.4,0.3-5.8,4.1c0.7,1,1.4,2,2.1,2.9
            c8.4,11.3,12.7,25.1,16.5,38.7c0.9,3.4,1.8,6.9,0.8,10.2c1.5-8.8,0.5-18-1.7-26.6s-5.7-16.9-9.4-25.2
            C739.4,71.7,738.7,70.3,737.7,69z"/>
          <path fill="#FE9236" d="M683.3,92.8c-5.6-0.2-6.4,8.4-0.7,8.9c1.5,0.1,3,0.1,4.4,0.2c17.3,0.5,33.7,6.7,49.4,13.5
            c3.9,1.7,7.9,3.5,10.2,7.1c-7.1-8.2-16.4-14.3-26.4-18.7c-9.9-4.4-20.5-7.3-31.1-10.1C687.2,93.1,685.3,92.8,683.3,92.8z"/>
          <path opacity="0.2" fill="#FE9236" d="M684.9,113.9c-3.6,0.1-2.8,5.2,0.8,5.2c0.9,0,1.9,0,2.8-0.1
            c10.9-0.4,22.1,2.6,33,5.9c2.7,0.8,5.5,1.7,7.4,3.7c-5.8-4.4-12.3-7.8-19.3-9.9c-6.9-2-13.9-3.6-21-4.6
            C687.4,114,686.1,113.9,684.9,113.9z"/>
          <path opacity="0.5" fill="#FE9236" d="M758,208.2c1,3.4,5.7,1.4,4.9-2.1c-0.2-0.9-0.5-1.8-0.8-2.7
            c-3.1-10.5-3-22.1-2.5-33.4c0.1-2.8,0.3-5.7,1.8-8.1c-2.9,6.7-4.5,13.8-4.8,21.1c-0.3,7.2,0,14.3,0.7,21.5
            C757.4,205.8,757.6,207,758,208.2z"/>
          <path opacity="0.2" fill="#FE9236" d="M691.4,64.2c-2.1-0.4-2.3,2.8-0.2,3.2c0.6,0.1,1.1,0.2,1.7,0.3
            c6.6,1.1,12.9,4.3,18.9,7.7c1.6,0.7,2.9,1.8,4,3.2c-2.9-3.4-6.4-6.2-10.3-8.4c-3.8-2.1-7.8-3.9-11.9-5.4
            C692.9,64.6,692.1,64.4,691.4,64.2z"/>
          <path fill="#FE9236" d="M658.2,147.2c-5.5,2.8-0.8,10.5,4.8,8c1.5-0.7,2.9-1.4,4.3-2.1c16.7-8.7,36-11.8,55.1-14.3
            c4.7-0.6,9.7-1.1,14,0.7c-11.8-3.4-24.3-3.6-36.5-2.2c-12.1,1.4-23.9,4.5-35.6,7.7C662.1,145.5,660.1,146.3,658.2,147.2z"/>
          <path opacity="0.5" fill="#FE9236" d="M673.8,177.2c-3.9,3.7,2.2,8,6.3,4.5c1.1-0.9,2.1-1.9,3.1-2.8
            c11.9-11.2,27.7-19.2,43.5-26.6c3.9-1.8,19.7-3.6,24-3.5c-11.1,1-33.7,4.5-43.8,9c-9.9,4.7-19.4,10-28.5,16
            C676.7,174.8,675.2,175.9,673.8,177.2z"/>
          <path fill="#FE9236" d="M701.7,225.1c-1.1,5.2,6.3,5.3,7.7,0.1c0.4-1.4,0.7-2.7,0.9-4.1c3.5-16,11.9-31.6,20.7-46.6
            c2.2-3.7,14.2-14.2,17.7-16.6c-8.6,7.1-25.2,22.8-30.9,32.3c-5.4,9.5-10.3,19.3-14.4,29.4C702.8,221.5,702.2,223.3,701.7,225.1z"/>
          <path fill="#FE9236" d="M758,41.2c-2.7-5.4-10.3-1-7.9,4.6c0.6,1.5,1.3,2.9,2,4.3c8.2,16.6,11,35.9,13.2,54.8
            c0.5,4.6,1,9.6-0.9,13.8c3.4-11.6,3.9-24.1,2.7-36.1s-4-23.7-7-35.4C759.7,45.2,759,43.2,758,41.2z"/>
          <path fill="#FE9236" d="M715.5,50.5c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.5-0.3,0.6-0.6c0.7-1.4,0.3-3.4,1.3-4.5c1,1.3,1.9,2.7,2.5,4.2
            l3.9-1.2c0.4,1.5,0.1,3.2-0.7,4.5c0.7,0.3,1.3,0.7,1.8,1.3c0.5,0.5,0.7,1.3,0.5,2.1c-0.9,0.2-1.8,0.3-2.8,0.3
            c0.1,1.7,0,3.5-0.3,5.2c0,0.3-0.1,0.5-0.3,0.7c-0.3,0.2-0.7,0-1-0.2c-1.3-0.8-2.5-2-3.2-3.4l-1.5,3.3c-0.1,0.4-0.3,0.7-0.7,0.9
            c-0.5,0.2-1,0-1.2-0.4c-0.2-0.4-0.4-0.9-0.4-1.3L714,58c0-0.2-0.1-0.3-0.2-0.5c-0.2-0.1-0.4-0.2-0.6-0.2c-1.4,0-2.8,0-4.2,0
            c-0.2-0.7,0.5-1.4,1.2-1.7s1.5-0.5,1.9-1.2c0.7-1.4-1.1-3.4-0.4-4.6C712.8,48.5,714.3,49.9,715.5,50.5z"/>
          <path opacity="0.5" fill="#FE9236" d="M791.1,69c2.7-3.7,8.4,0.3,5.8,4.1c-0.7,1-1.4,2-2.1,2.9
            c-8.4,11.3-12.7,25.1-16.5,38.7c-0.9,3.4-1.8,6.9-0.8,10.2c-1.5-8.8-0.5-18,1.7-26.6s5.8-16.9,9.4-25.1
            C789.4,71.7,790.2,70.3,791.1,69z"/>
          <path fill="#FE9236" d="M845.5,92.8c5.6-0.2,6.4,8.4,0.7,8.9c-1.5,0.1-3,0.1-4.4,0.2c-17.3,0.5-33.7,6.7-49.4,13.5
            c-3.9,1.7-7.9,3.5-10.2,7.1c7.1-8.2,16.4-14.3,26.4-18.7s20.5-7.3,31.1-10.1C841.7,93.1,843.6,92.8,845.5,92.8z"/>
          <path opacity="0.5" fill="#FE9236" d="M844,113.9c3.6,0.1,2.8,5.2-0.8,5.2c-0.9,0-1.9,0-2.8-0.1
            c-10.9-0.4-22.1,2.6-33,5.9c-2.7,0.8-5.5,1.7-7.4,3.7c5.8-4.4,12.3-7.8,19.3-9.9c6.9-2.1,13.9-3.6,21-4.6
            C841.5,113.9,842.7,113.9,844,113.9z"/>
          <path opacity="0.2" fill="#FE9236" d="M837.5,64.2c2.1-0.4,2.3,2.8,0.2,3.2c-0.6,0.1-1.1,0.2-1.7,0.3
            c-6.6,1.1-12.9,4.3-18.9,7.7c-1.6,0.7-2.9,1.8-4,3.2c2.9-3.4,6.3-6.2,10.2-8.4c3.8-2.1,7.8-3.9,11.9-5.4
            C836,64.6,836.7,64.4,837.5,64.2z"/>
          <path opacity="0.2" fill="#FE9236" d="M781.5,214.7c-0.5,2.1-3.4,1.1-3-1.1c0.1-0.6,0.2-1.1,0.4-1.7
            c1.5-6.5,1-13.6,0.2-20.4c-0.1-1.7-0.5-3.4-1.4-4.9c2,4,3.3,8.3,3.8,12.7c0.5,4.4,0.6,8.7,0.4,13.1
            C781.7,213.2,781.7,214,781.5,214.7z"/>
          <path opacity="0.5" fill="#FE9236" d="M836.1,200.6c1.7,2.9-2.4,2.7-4.3-0.2c-0.5-0.7-0.9-1.5-1.3-2.2
            c-5.1-8.7-12.9-17.3-20.7-25.6c-1.9-2.2-4-4-6.4-5.5c6.1,4.2,11.8,9,16.8,14.4c5,5.3,9.5,10.8,13.8,16.2
            C834.8,198.6,835.5,199.5,836.1,200.6z"/>
          <path opacity="0.2" fill="#FE9236" d="M686.7,200.6c-1.7,2.9,2.4,2.7,4.3-0.2c0.5-0.7,0.9-1.5,1.3-2.2
            c5.1-8.7,12.9-17.3,20.7-25.6c1.9-2.2,4-4,6.4-5.5c-6.1,4.2-11.8,9-16.8,14.4c-5,5.3-9.5,10.8-13.8,16.2
            C688,198.6,687.3,199.5,686.7,200.6z"/>
          <path opacity="0.2" fill="#FE9236" d="M741.3,214.7c0.5,2.1,3.4,1.1,3-1.1c-0.1-0.6-0.2-1.1-0.4-1.7
            c-1.5-6.5-1-13.6-0.2-20.4c0.1-1.7,0.5-3.4,1.4-4.9c-2,4-3.3,8.3-3.7,12.7c-0.5,4.4-0.6,8.7-0.4,13.1
            C741.1,213.2,741.2,214,741.3,214.7z"/>
          <path opacity="0.2" fill="#FE9236" d="M820.3,85.6c2.1-0.4,2.3,2.7,0.2,3.2c-0.6,0.1-1.1,0.2-1.7,0.3
            c-6.6,1.1-12.9,4.4-18.9,7.7c-1.6,0.7-2.9,1.8-4,3.2c2.9-3.4,6.4-6.2,10.3-8.4c3.8-2.1,7.8-3.9,11.9-5.4
            C818.8,85.9,819.5,85.7,820.3,85.6z"/>
          <path opacity="0.2" fill="#FE9236" d="M800.2,43.6c1.9-1.1,3.2,1.7,1.4,2.9c-0.5,0.3-1,0.6-1.4,0.9
            c-5.7,3.5-10.3,8.8-14.7,14.2c-1.2,1.2-2,2.8-2.5,4.4c1.4-4.2,3.6-8.1,6.4-11.6c2.8-3.4,5.8-6.5,9-9.5
            C798.9,44.5,799.5,44,800.2,43.6z"/>
          <path fill="#FE9236" d="M870.7,147.2c5.5,2.8,0.8,10.5-4.8,8c-1.5-0.7-2.9-1.4-4.3-2.1c-16.7-8.7-36.1-11.8-55.1-14.3
            c-4.7-0.6-9.7-1.1-14,0.7c11.8-3.4,24.3-3.6,36.5-2.2c12.1,1.4,23.9,4.5,35.6,7.7C866.7,145.5,868.8,146.3,870.7,147.2z"/>
          <path opacity="0.5" fill="#FE9236" d="M855.1,177.2c3.9,3.7-2.2,8-6.3,4.5c-1.1-0.9-2.1-1.9-3.1-2.8
            c-12-11.2-27.7-19.2-43.5-26.6c-3.9-1.8-19.8-3.6-24-3.5c11.1,1,33.7,4.5,43.8,9c9.9,4.7,19.4,10,28.5,16
            C852.2,174.8,853.7,175.9,855.1,177.2z"/>
          <path fill="#FE9236" d="M808.9,231.1c0.5,5.3-7,4.5-7.7-0.9c-0.2-1.4-0.3-2.8-0.4-4.2c-1.4-16.3-7.9-32.8-14.7-48.8
            c-1.7-3.9-12.3-15.9-15.5-18.6c7.6,8.1,22.1,25.8,26.6,35.9c4.2,10.1,7.8,20.4,10.6,31C808.3,227.3,808.7,229.2,808.9,231.1z"/>
          <path fill="#FE9236" d="M813.4,50.5c-0.1,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.5-0.3-0.6-0.6c-0.7-1.4-0.3-3.4-1.3-4.5
            c-1,1.3-1.9,2.7-2.5,4.2l-3.9-1.2c-0.4,1.5-0.1,3.2,0.7,4.5c-0.7,0.3-1.3,0.7-1.9,1.3c-0.5,0.5-0.7,1.4-0.5,2.1
            c0.9,0.2,1.8,0.3,2.8,0.3c-0.1,1.7,0,3.5,0.3,5.2c0,0.3,0.1,0.5,0.3,0.7c0.3,0.2,0.7,0,1-0.2c1.3-0.8,2.5-2,3.2-3.4l1.5,3.5
            c0.1,0.4,0.3,0.7,0.7,0.9c0.5,0.2,1,0,1.2-0.4c0.2-0.4,0.4-0.9,0.4-1.3l0.5-3.4c0-0.2,0.1-0.3,0.2-0.5c0.2-0.1,0.4-0.2,0.6-0.2
            c1.4,0,2.8,0,4.2,0c0.2-0.7-0.5-1.4-1.2-1.7s-1.5-0.5-1.9-1.2c-0.7-1.4,1.1-3.4,0.3-4.6C816.1,48.5,814.6,49.9,813.4,50.5z"/>
          <path opacity="0.2" fill="#FE9236" d="M825.2,211.9c-0.1,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.5-0.3-0.6-0.6
            c-0.7-1.5-0.3-3.4-1.3-4.6c-1,1.3-1.9,2.7-2.5,4.2l-3.9-1.2c-0.4,1.5-0.1,3.2,0.7,4.5c-0.7,0.3-1.3,0.7-1.8,1.3
            c-0.5,0.5-0.7,1.3-0.5,2.1c0.9,0.2,1.8,0.3,2.8,0.3c-0.1,1.7,0,3.5,0.3,5.1c0,0.3,0.1,0.5,0.3,0.7c0.3,0.2,0.7,0,1-0.2
            c1.3-0.8,2.5-2,3.2-3.4l1.5,3.5c0.1,0.4,0.3,0.7,0.7,0.9c0.5,0.1,0.9,0,1.2-0.4c0.2-0.4,0.4-0.9,0.4-1.3l0.5-3.3
            c0-0.3,0.2-0.6,0.6-0.6c0.1,0,0.1,0,0.2,0c1.4,0,2.8-0.1,4.2,0c0.2-0.7-0.5-1.4-1.2-1.7c-0.7-0.3-1.5-0.5-1.9-1.2
            c-0.7-1.4,1.1-3.4,0.4-4.5C827.9,209.9,826.4,211.3,825.2,211.9z"/>
          <path fill="#FE9236" d="M858.4,103.7c-0.1,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.5-0.3-0.6-0.6c-0.7-1.4-0.3-3.4-1.3-4.6
            c-1,1.3-1.9,2.7-2.5,4.2l-3.9-1.2c-0.4,1.5-0.1,3.2,0.7,4.5c-0.7,0.3-1.3,0.7-1.9,1.3c-0.5,0.6-0.7,1.3-0.5,2.1
            c0.9,0.2,1.8,0.3,2.8,0.3c-0.1,1.7,0,3.4,0.3,5.1c0,0.3,0.1,0.5,0.3,0.7c0.3,0.2,0.7,0,1-0.2c1.3-0.8,2.5-2,3.2-3.4l1.4,3.5
            c0.1,0.4,0.3,0.7,0.7,0.9c0.5,0.2,1,0,1.2-0.4c0.2-0.4,0.4-0.9,0.4-1.3l0.5-3.4c0-0.2,0.1-0.3,0.2-0.5c0.2-0.1,0.4-0.2,0.6-0.2
            c1.4,0,2.8,0,4.1,0c0.2-0.7-0.5-1.4-1.2-1.7s-1.5-0.5-1.9-1.2c-0.7-1.4,1.1-3.4,0.3-4.6C861.1,101.7,859.6,103.1,858.4,103.7z"/>
          <path fill="#FE9236" d="M725.6,228.1c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0-0.7-0.4-0.9-0.8c-0.9-2-0.4-4.6-1.8-6.3
            c-1.5,1.7-2.6,3.7-3.5,5.8l-5.5-1.7c-0.5,2.1-0.1,4.4,1,6.3c-1,0.4-1.8,1-2.6,1.8c-0.7,0.8-1,1.9-0.7,2.9c1.3,0.3,2.6,0.4,3.9,0.4
            c-0.2,2.4-0.1,4.8,0.4,7.2c0,0.4,0.2,0.7,0.5,0.9c0.5,0.2,1,0.1,1.3-0.2c1.9-1.2,3.4-2.8,4.5-4.8l2,4.8c0.1,0.5,0.5,0.9,0.9,1.2
            c0.6,0.2,1.3,0,1.7-0.6c0.3-0.6,0.5-1.2,0.6-1.8l0.8-4.6c0-0.2,0.1-0.5,0.2-0.7c0.2-0.2,0.5-0.3,0.8-0.2c1.9,0,3.9-0.1,5.8,0
            c0.3-1-0.7-2-1.7-2.4c-1-0.4-2.2-0.8-2.6-1.7c-1-2,1.6-4.7,0.5-6.4C729.4,225.3,727.3,227.3,725.6,228.1z"/>
          <path fill="#FE9236" d="M670.4,106.9c-0.2,0.1-0.4,0.2-0.7,0.2c-0.4,0-0.7-0.4-0.9-0.8c-0.9-2-0.4-4.6-1.8-6.3
            c-1.5,1.7-2.6,3.7-3.5,5.8l-5.5-1.7c-0.5,2.1-0.2,4.4,1,6.3c-1,0.4-1.8,1-2.6,1.8c-0.8,0.8-1,1.9-0.7,2.9c1.3,0.3,2.6,0.4,3.9,0.4
            c-0.2,2.4-0.1,4.8,0.4,7.2c0,0.4,0.2,0.7,0.5,0.9c0.4,0.3,0.9,0,1.3-0.2c1.9-1.2,3.4-2.8,4.5-4.8l2,4.8c0.2,0.5,0.5,0.9,0.9,1.2
            c0.6,0.2,1.3,0,1.7-0.6c0.3-0.6,0.5-1.2,0.6-1.8l0.8-4.6c0-0.2,0.1-0.5,0.3-0.7c0.2-0.2,0.5-0.3,0.8-0.2c1.9,0,3.8-0.1,5.8-0.1
            c0.3-1-0.7-2-1.7-2.4c-1-0.4-2.2-0.8-2.6-1.7c-1-2,1.6-4.7,0.5-6.3C674.2,104.2,672.1,106.1,670.4,106.9z"/>
          <path opacity="0.2" fill="#FE9236" d="M838,182c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.5-1-3.4c-0.8,0.9-1.4,2-1.8,3.1l-2.9-0.9c-0.3,1.1-0.1,2.4,0.5,3.4c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.3,1.5
            c0.7,0.2,1.4,0.2,2,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.3,0.5,0.5,0.7c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.5c0-0.2,0.2-0.5,0.4-0.5c0,0,0.1,0,0.1,0
            c1,0,2.1,0,3.1,0c0.2-0.5-0.4-1.1-0.9-1.3c-0.5-0.2-1.1-0.4-1.4-0.9c-0.5-1,0.9-2.5,0.3-3.4C840.2,180.7,838.9,181.5,838,182z"/>
          <path opacity="0.2" fill="#FE9236" d="M763,225.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.5-1-3.4c-0.8,0.9-1.4,2-1.9,3.1l-2.9-0.9c-0.3,1.2-0.1,2.4,0.5,3.4c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.4,1.5
            c0.7,0.2,1.4,0.2,2.1,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.3,0.5,0.5,0.7c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.5c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.3-0.1,0.4-0.1
            c1,0,2,0,3.1,0c0.2-0.5-0.4-1.1-0.9-1.3s-1.1-0.4-1.4-0.9c-0.5-1,0.8-2.5,0.3-3.4C765.1,224,763.9,224.8,763,225.3z"/>
          <path opacity="0.5" fill="#FE9236" d="M690.4,73.6c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.5-1-3.4c-0.8,0.9-1.4,2-1.9,3.1l-2.9-0.9c-0.3,1.2-0.1,2.4,0.5,3.4c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.4,1.5
            c0.7,0.2,1.4,0.2,2.1,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.3,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.2,0.5,0.5,0.6c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.4c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.3-0.1,0.4-0.1
            c1,0,2.1,0,3.1,0c0.2-0.5-0.4-1-0.9-1.3c-0.5-0.2-1.1-0.4-1.4-0.9c-0.5-1,0.9-2.5,0.3-3.4C692.5,72.4,691.3,73.2,690.4,73.6z"/>
          <path opacity="0.5" fill="#FE9236" d="M729,159.1c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.5-1-3.4c-0.8,0.9-1.4,2-1.8,3.1l-2.9-0.9c-0.3,1.2-0.1,2.4,0.5,3.4c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.4,1.5
            c0.7,0.2,1.4,0.2,2,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.2,0.5,0.5,0.7c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.4c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.3-0.1,0.4-0.1
            c1,0,2,0,3.1,0c0.2-0.5-0.4-1.1-0.9-1.3c-0.5-0.2-1.1-0.4-1.4-0.9c-0.5-1,0.8-2.5,0.3-3.4C731.2,157.8,729.9,158.7,729,159.1z"/>
          <path opacity="0.5" fill="#FE9236" d="M701,125.8c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.5-1-3.4c-0.8,0.9-1.4,2-1.8,3.1l-2.9-0.9c-0.3,1.2-0.1,2.4,0.5,3.4c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.3,1.5
            c0.7,0.2,1.4,0.2,2,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.2,0.5,0.5,0.6c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.4c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.3-0.1,0.4-0.1
            c1,0,2,0,3.1,0c0.2-0.5-0.4-1-0.9-1.3c-0.5-0.2-1.1-0.4-1.4-0.9c-0.5-1,0.9-2.5,0.3-3.4C703.1,124.5,701.8,125.3,701,125.8z"/>
          <path opacity="0.5" fill="#FE9236" d="M821.4,133.6c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.5-1-3.4c-0.8,0.9-1.4,2-1.8,3.1l-2.9-0.9c-0.3,1.2-0.1,2.4,0.5,3.4c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.3,1.5
            c0.7,0.2,1.4,0.2,2,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.2,0.5,0.5,0.7c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.4c0-0.2,0.2-0.5,0.4-0.5c0.1,0,0.1,0,0.2,0
            c1,0,2,0,3.1,0c0.2-0.5-0.4-1.1-0.9-1.3s-1.1-0.4-1.4-0.9c-0.5-1,0.9-2.5,0.3-3.4S822.3,133.2,821.4,133.6z"/>
          <path opacity="0.2" fill="#FE9236" d="M763,127.1c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.5-1-3.4c-0.8,0.9-1.4,2-1.9,3.1l-2.9-0.9c-0.3,1.2-0.1,2.4,0.5,3.4c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.4,1.5
            c0.7,0.2,1.4,0.2,2.1,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.2,0.5,0.5,0.6c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.4c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.3-0.1,0.4-0.1
            c1,0,2,0,3.1,0c0.2-0.5-0.4-1-0.9-1.3s-1.1-0.4-1.4-0.9c-0.5-1,0.8-2.5,0.3-3.4C765.1,125.8,763.9,126.6,763,127.1z"/>
          <path opacity="0.3" fill="#FE9236" d="M744.6,135.1c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.5-1-3.4c-0.8,0.9-1.4,2-1.8,3.1l-2.9-0.9c-0.3,1.2-0.1,2.4,0.5,3.4c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.3,1.5
            c0.7,0.2,1.4,0.2,2,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.2,0.5,0.5,0.6c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.4c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.3-0.1,0.4-0.1
            c1,0,2,0,3.1,0c0.2-0.5-0.4-1-0.9-1.3s-1.1-0.4-1.4-0.9c-0.5-1,0.9-2.5,0.3-3.4C746.8,133.8,745.5,134.6,744.6,135.1z"/>
          <path opacity="0.2" fill="#FE9236" d="M727.5,86.8c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.5-1-3.4c-0.8,0.9-1.4,2-1.9,3.1l-2.9-0.9c-0.3,1.2-0.1,2.4,0.5,3.4c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.4,1.5
            c0.7,0.2,1.4,0.2,2.1,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.3,0.5,0.5,0.7c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.4c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.3-0.1,0.4-0.1
            c1,0,2.1,0,3.1,0c0.2-0.5-0.4-1.1-0.9-1.3c-0.5-0.2-1.1-0.4-1.4-0.9c-0.5-1,0.8-2.5,0.3-3.4C729.7,85.5,728.4,86.3,727.5,86.8z"/>
          <path opacity="0.3" fill="#FE9236" d="M761.2,149.8c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.5-1-3.4c-0.8,0.9-1.4,2-1.9,3.1l-2.9-0.9c-0.3,1.2-0.1,2.4,0.5,3.4c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.4,1.5
            c0.7,0.2,1.4,0.2,2.1,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.2,0.5,0.5,0.6c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.4c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.3-0.1,0.4-0.1
            c1,0,2.1,0,3.1,0c0.2-0.5-0.4-1-0.9-1.3s-1.1-0.4-1.4-0.9c-0.5-1,0.8-2.5,0.3-3.4C763.4,148.6,762.1,149.3,761.2,149.8z"/>
        </g>
        <g opacity="0.6" id="Mentor2020Trophy_FireworksTwo">
          <path opacity="0.5" fill="#FE9236" d="M491,64.9c-0.7-0.9-2-1-2.8-0.3c-0.8,0.6-1,1.8-0.4,2.6
            c0.4,0.6,0.8,1.1,1.2,1.6c4.7,6.3,7.1,14,9.2,21.6c0.5,1.9,1,3.9,0.4,5.7c0.7-5,0.4-10.1-1-14.9c-1.4-4.8-3.1-9.5-5.3-14.1
            C491.9,66.5,491.5,65.7,491,64.9z"/>
          <path fill="#FE9236" d="M460.5,78.3c-3.2-0.1-3.6,4.7-0.4,4.9c0.8,0.1,1.7,0.1,2.5,0.1c9.7,0.3,18.9,3.8,27.7,7.6
            c2.2,0.9,4.4,2,5.7,4c-4.1-4.5-9.1-8.1-14.8-10.5c-5.6-2.4-11.5-4.2-17.4-5.6C462.7,78.5,461.6,78.3,460.5,78.3z"/>
          <path opacity="0.2" fill="#FE9236" d="M461.4,90.1c-2,0.1-1.5,2.9,0.5,2.9c0.5,0,1,0,1.5,0
            c6.1-0.2,12.4,1.4,18.5,3.3c1.5,0.3,3,1.1,4.2,2.1c-3.2-2.5-6.9-4.4-10.8-5.5c-3.8-1.2-7.8-2-11.7-2.6
            C462.8,90.1,462.1,90.1,461.4,90.1z"/>
          <path opacity="0.5" fill="#FE9236" d="M502.3,142.9c0.6,1.9,3.2,0.8,2.7-1.2c-0.1-0.5-0.3-1-0.4-1.5
            c-1.8-5.9-1.7-12.3-1.4-18.7c0-1.6,0.3-3.1,1-4.5c-1.6,3.7-2.5,7.7-2.7,11.8c-0.2,4,0,8,0.4,12C502,141.5,502.1,142.2,502.3,142.9z
            "/>
          <path opacity="0.2" fill="#FE9236" d="M465.1,62.3c-1.2-0.2-1.3,1.6-0.1,1.8l0.9,0.2
            c3.7,0.6,7.2,2.4,10.6,4.3c0.9,0.4,1.6,1,2.2,1.8c-1.6-1.9-3.6-3.5-5.7-4.7c-2.2-1.2-4.4-2.2-6.7-3
            C465.9,62.5,465.5,62.4,465.1,62.3z"/>
          <path fill="#FE9236" d="M446.5,108.7c-3,1.6-0.5,5.9,2.7,4.5c0.8-0.4,1.6-0.8,2.4-1.2c9.3-4.9,20.2-6.6,30.8-8
            c2.6-0.3,5.4-0.6,7.8,0.4c-6.7-1.7-13.6-2.1-20.4-1.2c-6.7,0.9-13.4,2.4-19.9,4.3C448.7,107.8,447.6,108.2,446.5,108.7z"/>
          <path opacity="0.5" fill="#FE9236" d="M455.2,125.5c-2.2,2,1.2,4.5,3.5,2.5c0.6-0.5,1.2-1.1,1.7-1.6
            c6.7-6.3,15.5-10.8,24.3-14.9c2.2-1,11-2,13.4-2c-6.2,0.6-18.9,2.5-24.5,5c-5.5,2.6-10.9,5.6-16,9
            C456.8,124.2,456,124.8,455.2,125.5z"/>
          <path fill="#FE9236" d="M470.9,152.4c-0.6,2.9,3.5,3,4.3,0c0.2-0.8,0.4-1.5,0.5-2.3c1.9-9,6.7-17.7,11.6-26.1
            c1.2-2.1,7.9-8,9.9-9.3c-4.8,4-14.1,12.8-17.3,18.1c-3,5.3-5.7,10.8-8,16.5C471.5,150.3,471.1,151.3,470.9,152.4z"/>
          <path fill="#FE9236" d="M502.4,49.4c-1.5-3.1-5.8-0.5-4.4,2.6c0.3,0.8,0.8,1.6,1.1,2.4c4.6,9.3,6.2,20.1,7.4,30.7
            c0.3,2.6,0.5,5.4-0.5,7.8c1.8-6.6,2.3-13.4,1.5-20.2c-0.8-6.7-2.1-13.3-3.9-19.8C503.3,51.6,502.9,50.5,502.4,49.4z"/>
          <path fill="#FE9236" d="M478.5,54.6c0.1,0,0.2,0.1,0.2,0.1c0.2,0,0.3-0.1,0.3-0.3c0.4-0.8,0.2-1.9,0.7-2.6c0.6,0.7,1.1,1.5,1.4,2.3
            l2.2-0.7c0.2,0.9,0.1,1.8-0.4,2.5c0.4,0.2,0.7,0.4,1,0.7c0.3,0.3,0.4,0.8,0.3,1.2c-0.5,0.1-1,0.2-1.5,0.2c0.1,1,0,1.9-0.2,2.9
            c0,0.1-0.1,0.3-0.2,0.4c-0.2,0.1-0.4,0-0.5-0.1c-0.8-0.5-1.4-1.1-1.8-1.9l-0.8,1.9c-0.1,0.2-0.2,0.4-0.4,0.5
            c-0.3,0.1-0.5,0-0.7-0.2c-0.1-0.2-0.2-0.5-0.2-0.7l-0.3-1.9c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.8,0-1.5,0-2.3,0
            c-0.1-0.4,0.3-0.8,0.7-1s0.9-0.3,1.1-0.7c0.4-0.8-0.6-1.9-0.2-2.6S477.9,54.3,478.5,54.6z"/>
          <path opacity="0.5" fill="#FE9236" d="M520.9,64.9c0.6-0.9,1.9-1.1,2.8-0.5c0.9,0.6,1.1,1.9,0.5,2.8
            c-0.4,0.6-0.8,1.1-1.2,1.6c-4.7,6.3-7.1,14-9.2,21.6c-0.5,1.9-1,3.9-0.4,5.7c-0.7-5-0.4-10.1,1-14.9c1.4-4.8,3.1-9.5,5.3-14.1
            C520,66.5,520.4,65.7,520.9,64.9z"/>
          <path fill="#FE9236" d="M551.4,78.3c3.2-0.1,3.6,4.7,0.4,4.9c-0.8,0.1-1.7,0.1-2.5,0.1c-9.7,0.3-18.9,3.8-27.7,7.6
            c-2.2,0.9-4.4,2-5.7,4c4.1-4.5,9.1-8.1,14.8-10.5c5.6-2.4,11.5-4.2,17.4-5.6C549.2,78.5,550.3,78.3,551.4,78.3z"/>
          <path opacity="0.5" fill="#FE9236" d="M550.5,90.1c2,0.1,1.5,2.9-0.5,2.9c-0.5,0-1,0-1.5,0
            c-6.1-0.2-12.4,1.4-18.5,3.3c-1.5,0.3-3,1.1-4.2,2.1c3.2-2.5,6.9-4.4,10.8-5.5c3.8-1.1,7.8-2,11.7-2.6
            C549.1,90.1,549.8,90.1,550.5,90.1z"/>
          <path opacity="0.2" fill="#FE9236" d="M546.8,62.3c1.2-0.2,1.3,1.6,0.1,1.8l-0.9,0.2
            c-3.7,0.6-7.2,2.4-10.6,4.3c-0.9,0.4-1.6,1-2.2,1.8c1.6-1.9,3.5-3.5,5.7-4.7c2.2-1.2,4.4-2.2,6.7-3C546,62.5,546.4,62.4,546.8,62.3
            z"/>
          <path opacity="0.2" fill="#FE9236" d="M515.5,146.5c-0.3,1.2-1.9,0.6-1.7-0.6c0-0.3,0.1-0.6,0.2-0.9
            c0.8-3.6,0.5-7.6,0.1-11.4c0-1-0.3-1.9-0.8-2.7c1.1,2.2,1.8,4.6,2.1,7.1c0.3,2.4,0.3,4.9,0.2,7.3
            C515.7,145.7,515.6,146.1,515.5,146.5z"/>
          <path opacity="0.5" fill="#FE9236" d="M546.1,138.6c0.9,1.6-1.4,1.5-2.4-0.1c-0.3-0.4-0.5-0.8-0.8-1.2
            c-2.9-4.9-7.2-9.7-11.6-14.3c-1-1.2-2.2-2.3-3.6-3.1c3.4,2.3,6.6,5,9.4,8.1c2.8,3,5.3,6,7.7,9.1C545.3,137.5,545.7,138,546.1,138.6
            z"/>
          <path opacity="0.2" fill="#FE9236" d="M462.4,138.6c-0.9,1.6,1.4,1.5,2.4-0.1c0.3-0.4,0.5-0.8,0.7-1.2
            c2.9-4.9,7.2-9.7,11.6-14.3c1-1.2,2.3-2.3,3.6-3.1c-3.4,2.3-6.6,5-9.4,8.1c-2.8,3-5.3,6-7.7,9.1C463.2,137.5,462.8,138,462.4,138.6
            z"/>
          <path opacity="0.2" fill="#FE9236" d="M493,146.5c0.3,1.2,1.9,0.6,1.7-0.6c-0.1-0.3-0.1-0.6-0.2-0.9
            c-0.8-3.6-0.5-7.6-0.1-11.4c0-1,0.3-1.9,0.8-2.7c-1.1,2.2-1.8,4.6-2.1,7.1c-0.3,2.4-0.3,4.9-0.2,7.3
            C492.9,145.7,492.9,146.1,493,146.5z"/>
          <path opacity="0.2" fill="#FE9236" d="M537.2,74.2c1.2-0.2,1.3,1.5,0.1,1.8c-0.3,0.1-0.6,0.1-0.9,0.2
            c-3.7,0.6-7.2,2.4-10.6,4.3c-0.9,0.4-1.6,1-2.2,1.8c1.6-1.9,3.6-3.5,5.7-4.7c2.2-1.2,4.4-2.2,6.7-3
            C536.4,74.5,536.8,74.3,537.2,74.2z"/>
          <path opacity="0.2" fill="#FE9236" d="M526,50.8c1-0.6,1.8,0.9,0.8,1.6l-0.8,0.5c-3.2,2-5.8,4.9-8.2,8
            c-0.7,0.7-1.1,1.5-1.4,2.5c0.8-2.4,2-4.6,3.6-6.5c1.6-1.9,3.2-3.7,5.1-5.3C525.3,51.3,525.6,51,526,50.8z"/>
          <path fill="#FE9236" d="M565.4,108.7c3,1.6,0.5,5.9-2.7,4.5c-0.8-0.4-1.6-0.8-2.4-1.2c-9.3-4.9-20.2-6.6-30.8-8
            c-2.6-0.3-5.4-0.6-7.8,0.4c6.7-1.7,13.6-2.1,20.4-1.2c6.7,0.9,13.4,2.4,19.9,4.3C563.2,107.8,564.3,108.2,565.4,108.7z"/>
          <path opacity="0.5" fill="#FE9236" d="M556.7,125.5c2.2,2-1.2,4.5-3.5,2.5c-0.6-0.5-1.2-1.1-1.7-1.6
            c-6.7-6.3-15.5-10.8-24.3-14.9c-2.2-1-11-2-13.4-2c6.2,0.6,18.9,2.5,24.5,5c5.5,2.6,10.9,5.6,16,9
            C555.1,124.2,555.9,124.8,556.7,125.5z"/>
          <path fill="#FE9236" d="M530.8,155.7c0.3,3-3.9,2.5-4.3-0.5c-0.1-0.8-0.2-1.6-0.2-2.3c-0.8-9.1-4.4-18.4-8.2-27.3
            c-0.9-2.2-6.9-8.9-8.7-10.4c4.2,4.6,12.4,14.4,14.9,20.1c2.4,5.6,4.3,11.4,5.9,17.3C530.5,153.6,530.7,154.6,530.8,155.7z"/>
          <path fill="#FE9236" d="M533.3,54.6c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.2-0.3-0.3c-0.4-0.8-0.2-1.9-0.7-2.6
            c-0.6,0.7-1.1,1.5-1.4,2.3l-2.2-0.7c-0.2,0.9-0.1,1.8,0.4,2.5c-0.4,0.2-0.7,0.4-1,0.7c-0.3,0.3-0.4,0.8-0.3,1.2
            c0.5,0.1,1,0.2,1.5,0.2c-0.1,1,0,1.9,0.2,2.9c0,0.1,0.1,0.3,0.2,0.4c0.2,0.1,0.4,0,0.5-0.1c0.8-0.5,1.4-1.1,1.8-1.9l0.8,1.9
            c0.1,0.2,0.2,0.4,0.4,0.5c0.3,0.1,0.5,0,0.7-0.2c0.1-0.2,0.2-0.5,0.2-0.7l0.3-1.9c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1
            c0.8,0,1.5,0,2.3,0c0.1-0.4-0.3-0.8-0.7-1s-0.9-0.3-1.1-0.7c-0.4-0.8,0.7-1.9,0.2-2.6S534,54.3,533.3,54.6z"/>
          <path opacity="0.2" fill="#FE9236" d="M540,144.9c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.2-0.3-0.3
            c-0.4-0.8-0.2-1.9-0.7-2.5c-0.6,0.7-1.1,1.5-1.4,2.3l-2.2-0.7c-0.2,0.9-0.1,1.8,0.4,2.5c-0.4,0.2-0.7,0.4-1,0.7
            c-0.3,0.3-0.4,0.8-0.3,1.2c0.5,0.1,1,0.2,1.5,0.1c-0.1,1,0,1.9,0.2,2.9c0,0.1,0.1,0.3,0.2,0.4c0.2,0.1,0.4,0,0.5-0.1
            c0.8-0.5,1.4-1.1,1.8-1.9l0.8,1.9c0.1,0.2,0.2,0.4,0.4,0.5c0.3,0.1,0.5,0,0.7-0.2c0.1-0.2,0.2-0.5,0.2-0.7l0.3-1.9
            c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.8,0,1.5,0,2.3,0c0.1-0.4-0.3-0.8-0.7-1s-0.9-0.3-1.1-0.7c-0.4-0.8,0.6-1.9,0.2-2.5
            S540.6,144.6,540,144.9z"/>
          <path fill="#FE9236" d="M558.5,84.4c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.2-0.3-0.3c-0.4-0.8-0.2-1.9-0.7-2.6
            c-0.6,0.7-1.1,1.5-1.4,2.3l-2.2-0.7c-0.2,0.9-0.1,1.8,0.4,2.5c-0.4,0.2-0.7,0.4-1,0.7c-0.3,0.3-0.4,0.8-0.3,1.2
            c0.5,0.1,1,0.2,1.5,0.1c-0.1,1,0,1.9,0.2,2.9c0,0.1,0.1,0.3,0.2,0.4c0.2,0.1,0.4,0,0.5-0.1c0.8-0.5,1.4-1.1,1.8-1.9l0.8,1.9
            c0.1,0.2,0.2,0.4,0.4,0.5c0.3,0.1,0.5,0,0.7-0.2c0.1-0.2,0.2-0.5,0.2-0.7l0.3-1.9c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1
            c0.8,0,1.5,0,2.3,0c0.1-0.4-0.3-0.8-0.7-1s-0.9-0.3-1.1-0.7c-0.4-0.8,0.6-1.9,0.2-2.6S559.2,84.1,558.5,84.4z"/>
          <path fill="#FE9236" d="M484.2,154c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.4-0.2-0.5-0.5c-0.5-1.1-0.2-2.6-1-3.5
            c-0.8,1-1.5,2.1-1.9,3.3l-3.1-0.9c-0.3,1.2-0.1,2.5,0.6,3.5c-0.5,0.2-1,0.6-1.4,1c-0.4,0.4-0.6,1-0.4,1.6c0.7,0.2,1.4,0.3,2.2,0.2
            c-0.1,1.3,0,2.7,0.2,4c0,0.2,0.1,0.4,0.3,0.5c0.2,0.1,0.5,0,0.7-0.1c1.1-0.7,1.9-1.6,2.5-2.7l1.1,2.7c0.1,0.3,0.3,0.5,0.5,0.7
            c0.4,0.2,0.8,0,1-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.6c0-0.3,0.2-0.5,0.5-0.5c0,0,0.1,0,0.1,0c1.1,0,2.2,0,3.2,0
            c0.2-0.6-0.4-1.1-0.9-1.4s-1.2-0.4-1.5-1c-0.6-1.1,0.9-2.6,0.3-3.5C486.3,152.4,485.2,153.5,484.2,154z"/>
          <path fill="#FE9236" d="M453.3,86.2c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.4-0.2-0.5-0.4c-0.5-1.1-0.2-2.6-1-3.6
            c-0.8,1-1.5,2.1-1.9,3.3l-3.1-0.9c-0.3,1.2-0.1,2.5,0.6,3.5c-0.5,0.2-1,0.6-1.4,1c-0.4,0.4-0.6,1.1-0.4,1.6
            c0.7,0.2,1.4,0.2,2.2,0.2c-0.1,1.3,0,2.7,0.2,4c0,0.2,0.1,0.4,0.3,0.5c0.2,0.1,0.5,0,0.7-0.1c1.1-0.7,1.9-1.6,2.5-2.7l1.1,2.7
            c0.1,0.3,0.3,0.5,0.5,0.7c0.4,0.1,0.7,0,1-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.6c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.5-0.1
            c1.1,0,2.2,0,3.2,0c0.2-0.6-0.4-1.1-0.9-1.4s-1.2-0.4-1.5-1c-0.6-1.1,0.9-2.6,0.3-3.6C455.4,84.7,454.3,85.7,453.3,86.2z"/>
          <path opacity="0.2" fill="#FE9236" d="M547.1,128.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.3-0.2
            c-0.3-0.6-0.1-1.4-0.5-1.9c-0.4,0.5-0.8,1.1-1,1.7l-1.6-0.5c-0.2,0.6,0,1.3,0.3,1.9c-0.3,0.1-0.5,0.3-0.8,0.5
            c-0.2,0.2-0.3,0.6-0.2,0.9c0.4,0.1,0.8,0.1,1.1,0.1c-0.1,0.7,0,1.4,0.1,2.1c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.3,0,0.4-0.1
            c0.6-0.3,1-0.8,1.3-1.4l0.6,1.4c0,0.1,0.1,0.3,0.3,0.4c0.2,0.1,0.4,0,0.5-0.2c0.1-0.2,0.1-0.4,0.2-0.6l0.2-1.4
            c0-0.1,0.1-0.3,0.3-0.3c0,0,0,0,0,0c0.6,0,1.1,0,1.7,0c0.1-0.3-0.2-0.6-0.5-0.7s-0.7-0.2-0.8-0.5c-0.3-0.6,0.5-1.4,0.1-1.9
            C548.3,127.4,547.6,127.9,547.1,128.2z"/>
          <path opacity="0.2" fill="#FE9236" d="M505.1,152.4c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.3-0.2
            c-0.3-0.6-0.1-1.4-0.5-1.9c-0.4,0.5-0.8,1.1-1,1.7l-1.6-0.5c-0.2,0.6,0,1.3,0.3,1.9c-0.3,0.1-0.5,0.3-0.8,0.5
            c-0.2,0.2-0.3,0.6-0.2,0.9c0.4,0.1,0.8,0.1,1.1,0.1c-0.1,0.7,0,1.4,0.1,2.1c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.3,0,0.4-0.1
            c0.6-0.3,1-0.8,1.3-1.4l0.6,1.5c0,0.1,0.1,0.3,0.3,0.4c0.2,0.1,0.4,0,0.5-0.2c0.1-0.2,0.2-0.3,0.2-0.5l0.2-1.4
            c0-0.1,0.1-0.3,0.3-0.3c0,0,0,0,0,0c0.6,0,1.1,0,1.7,0c0.1-0.3-0.2-0.6-0.5-0.7s-0.7-0.2-0.8-0.5c-0.3-0.6,0.5-1.4,0.1-1.9
            S505.6,152.2,505.1,152.4z"/>
          <path opacity="0.5" fill="#FE9236" d="M464.5,67.6c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.3-0.2
            c-0.3-0.6-0.1-1.4-0.5-1.9c-0.4,0.5-0.8,1.1-1,1.7l-1.7-0.5c-0.2,0.6-0.1,1.3,0.3,1.9c-0.3,0.1-0.5,0.3-0.8,0.5
            c-0.2,0.2-0.3,0.6-0.2,0.9c0.4,0.1,0.8,0.1,1.2,0.1c-0.1,0.7,0,1.4,0.1,2.1c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.3,0,0.4-0.1
            c0.6-0.3,1-0.8,1.3-1.4l0.6,1.4c0,0.1,0.1,0.3,0.3,0.4c0.2,0.1,0.4,0,0.5-0.2c0.1-0.2,0.2-0.4,0.2-0.6l0.2-1.4c0-0.1,0-0.1,0.1-0.2
            c0.1-0.1,0.2-0.1,0.2-0.1c0.6,0,1.1,0,1.7,0c0.1-0.3-0.2-0.6-0.5-0.7s-0.6-0.2-0.8-0.5c-0.3-0.6,0.5-1.4,0.2-1.9
            S465,67.3,464.5,67.6z"/>
          <path opacity="0.5" fill="#FE9236" d="M486.1,115.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.3-0.2
            c-0.3-0.6-0.1-1.4-0.5-1.9c-0.4,0.5-0.8,1.1-1,1.7l-1.6-0.5c-0.2,0.6,0,1.3,0.3,1.9c-0.3,0.1-0.5,0.3-0.8,0.5
            c-0.2,0.2-0.3,0.6-0.2,0.9c0.4,0.1,0.8,0.1,1.2,0.1c-0.1,0.7,0,1.4,0.1,2.1c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.3,0,0.4-0.1
            c0.6-0.3,1-0.8,1.3-1.4l0.6,1.4c0,0.1,0.1,0.3,0.3,0.4c0.2,0.1,0.4,0,0.5-0.2c0.1-0.2,0.2-0.4,0.2-0.6l0.2-1.4c0-0.1,0-0.1,0.1-0.2
            c0.1,0,0.2-0.1,0.2-0.1c0.6,0,1.2,0,1.7,0c0.1-0.3-0.2-0.6-0.5-0.7s-0.6-0.2-0.8-0.5c-0.3-0.6,0.5-1.4,0.2-1.9
            S486.6,115.1,486.1,115.4z"/>
          <path opacity="0.5" fill="#FE9236" d="M470.4,96.7c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.3-0.2
            c-0.3-0.6-0.1-1.4-0.5-1.9c-0.4,0.5-0.8,1.1-1,1.7l-1.6-0.5c-0.2,0.6,0,1.3,0.3,1.9c-0.3,0.1-0.5,0.3-0.8,0.5
            c-0.2,0.2-0.3,0.6-0.2,0.9c0.4,0.1,0.8,0.1,1.2,0.1c-0.1,0.7,0,1.4,0.1,2.1c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.3,0,0.4-0.1
            c0.6-0.3,1-0.8,1.3-1.4l0.6,1.4c0,0.1,0.1,0.3,0.3,0.4c0.2,0.1,0.4,0,0.5-0.2c0.1-0.2,0.2-0.4,0.2-0.6l0.2-1.4c0-0.1,0-0.1,0.1-0.2
            c0.1-0.1,0.2-0.1,0.2-0.1c0.6,0,1.2,0,1.7,0c0.1-0.3-0.2-0.6-0.5-0.7s-0.6-0.2-0.8-0.5c-0.3-0.6,0.5-1.4,0.2-1.9
            S470.9,96.5,470.4,96.7z"/>
          <path opacity="0.5" fill="#FE9236" d="M537.9,101.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.3-0.2
            c-0.3-0.6-0.1-1.4-0.5-1.9c-0.4,0.5-0.8,1.1-1,1.7l-1.6-0.5c-0.2,0.6,0,1.3,0.3,1.9c-0.3,0.1-0.5,0.3-0.8,0.5
            c-0.2,0.2-0.3,0.6-0.2,0.9c0.4,0.1,0.8,0.1,1.2,0.1c-0.1,0.7,0,1.4,0.1,2.1c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.3,0,0.4-0.1
            c0.6-0.3,1-0.8,1.3-1.4l0.6,1.4c0,0.1,0.1,0.3,0.3,0.4c0.2,0.1,0.4,0,0.5-0.2c0.1-0.2,0.2-0.4,0.2-0.6l0.2-1.4
            c0-0.1,0.1-0.3,0.3-0.3c0,0,0,0,0,0c0.6,0,1.1,0,1.7,0c0.1-0.3-0.2-0.6-0.5-0.7s-0.6-0.2-0.8-0.5c-0.3-0.6,0.5-1.4,0.2-1.9
            S538.4,100.9,537.9,101.1z"/>
          <path opacity="0.2" fill="#FE9236" d="M505.1,97.5c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.3-0.2
            c-0.3-0.6-0.1-1.4-0.5-1.9c-0.4,0.5-0.8,1.1-1,1.7l-1.6-0.5c-0.2,0.6,0,1.3,0.3,1.9c-0.3,0.1-0.5,0.3-0.8,0.5
            c-0.2,0.2-0.3,0.6-0.2,0.9c0.4,0.1,0.8,0.1,1.1,0.1c-0.1,0.7,0,1.4,0.1,2.1c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.3,0,0.4-0.1
            c0.6-0.3,1-0.8,1.3-1.4l0.6,1.4c0,0.1,0.1,0.3,0.3,0.4c0.2,0.1,0.4,0,0.5-0.2c0.1-0.2,0.2-0.3,0.2-0.5l0.2-1.4
            c0-0.1,0.1-0.3,0.3-0.3c0,0,0,0,0,0c0.6,0,1.1,0,1.7,0c0.1-0.3-0.2-0.6-0.5-0.7s-0.7-0.2-0.8-0.5c-0.3-0.6,0.5-1.4,0.1-1.9
            S505.6,97.2,505.1,97.5z"/>
          <path opacity="0.3" fill="#FE9236" d="M494.9,101.9c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.3-0.2
            c-0.3-0.6-0.1-1.4-0.5-1.9c-0.4,0.5-0.8,1.1-1,1.7l-1.6-0.5c-0.2,0.6,0,1.3,0.3,1.9c-0.3,0.1-0.5,0.3-0.8,0.5
            c-0.2,0.2-0.3,0.6-0.2,0.9c0.4,0.1,0.8,0.1,1.1,0.1c-0.1,0.7,0,1.4,0.1,2.1c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.3,0,0.4-0.1
            c0.6-0.3,1-0.8,1.3-1.4l0.6,1.4c0,0.2,0.1,0.3,0.3,0.4c0.2,0.1,0.4,0,0.5-0.2c0.1-0.2,0.2-0.4,0.2-0.6l0.2-1.4
            c0-0.1,0.1-0.3,0.3-0.3c0,0,0,0,0,0c0.6,0,1.1,0,1.7,0c0.1-0.3-0.2-0.6-0.5-0.7s-0.6-0.2-0.8-0.5c-0.3-0.6,0.5-1.4,0.1-1.9
            S495.4,101.7,494.9,101.9z"/>
          <path opacity="0.2" fill="#FE9236" d="M485.3,74.9c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.3-0.2
            c-0.3-0.6-0.1-1.4-0.5-1.9c-0.4,0.5-0.8,1.1-1,1.7l-1.6-0.5c-0.2,0.6,0,1.3,0.3,1.9c-0.3,0.1-0.5,0.3-0.8,0.5
            c-0.2,0.2-0.3,0.6-0.2,0.9c0.4,0.1,0.8,0.1,1.1,0.1c-0.1,0.7,0,1.4,0.1,2.1c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.3,0,0.4-0.1
            c0.6-0.3,1-0.8,1.3-1.4l0.6,1.4c0,0.1,0.1,0.3,0.3,0.4c0.2,0.1,0.4,0,0.5-0.2c0.1-0.2,0.2-0.4,0.2-0.6l0.2-1.4c0-0.1,0-0.1,0.1-0.2
            c0.1,0,0.2-0.1,0.2-0.1c0.6,0,1.1,0,1.7,0c0.1-0.3-0.2-0.6-0.5-0.7s-0.7-0.2-0.8-0.5c-0.3-0.6,0.5-1.4,0.1-1.9
            S485.8,74.7,485.3,74.9z"/>
          <path opacity="0.3" fill="#FE9236" d="M504.1,110.2c-0.1,0.1-0.3,0.1-0.4-0.1c0,0,0-0.1,0-0.1
            c-0.3-0.6-0.1-1.4-0.5-1.9c-0.4,0.5-0.8,1.1-1,1.7l-1.6-0.5c-0.2,0.6,0,1.3,0.3,1.9c-0.3,0.1-0.5,0.3-0.8,0.5
            c-0.2,0.2-0.3,0.6-0.2,0.9c0.4,0.1,0.8,0.1,1.2,0.1c-0.1,0.7,0,1.4,0.1,2.1c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.3,0,0.4-0.1
            c0.6-0.3,1-0.8,1.3-1.4l0.6,1.4c0,0.1,0.1,0.3,0.3,0.4c0.2,0.1,0.4,0,0.5-0.2c0.1-0.2,0.1-0.4,0.1-0.6l0.2-1.4c0-0.1,0-0.1,0.1-0.2
            c0.1-0.1,0.2-0.1,0.2-0.1c0.6,0,1.2,0,1.7,0c0.1-0.3-0.2-0.6-0.5-0.7s-0.6-0.2-0.8-0.5c-0.3-0.6,0.5-1.4,0.2-1.9
            S504.6,109.9,504.1,110.2z"/>
        </g>
        <g id="Mentor2020Trophy_FireworksThree">
          <path opacity="0.5" fill="#FE9236" d="M249.1,36.9c-2.4-3.3-7.4,0.3-5.2,3.7c0.6,0.9,1.2,1.8,1.9,2.6
            c7.5,10,11.3,22.3,14.6,34.3c0.8,3,1.6,6.1,0.7,9c1.3-7.8,0.5-15.9-1.5-23.6s-5.1-15-8.4-22.3C250.6,39.3,249.9,38,249.1,36.9z"/>
          <path fill="#FE9236" d="M200.8,58c-5-0.2-5.7,7.5-0.7,7.9c1.3,0.1,2.6,0.1,3.9,0.2c15.4,0.4,29.9,6,43.9,12c3.4,1.5,7,3.1,9,6.3
            c-6.3-7.3-14.6-12.7-23.4-16.6s-18.2-6.5-27.6-8.9C204.2,58.4,202.5,58.1,200.8,58z"/>
          <path opacity="0.2" fill="#FE9236" d="M202.1,76.7c-3.2,0.1-2.5,4.6,0.7,4.7c0.8,0,1.7,0,2.5-0.1
            c9.7-0.4,19.7,2.3,29.3,5.2c2.4,0.7,4.9,1.5,6.6,3.3c-5.1-4-10.9-6.9-17.1-8.8c-6.1-1.8-12.3-3.2-18.6-4.1
            C204.4,76.8,203.3,76.7,202.1,76.7z"/>
          <path opacity="0.5" fill="#FE9236" d="M267.1,160.7c0.9,3.1,5.1,1.2,4.3-1.9c-0.2-0.8-0.5-1.6-0.7-2.4
            c-2.8-9.3-2.7-19.6-2.2-29.7c0.1-2.5,0.3-5.1,1.6-7.2c-2.6,5.9-4,12.3-4.3,18.8c-0.2,6.4,0,12.7,0.6,19.1
            C266.6,158.5,266.8,159.6,267.1,160.7z"/>
          <path opacity="0.2" fill="#FE9236" d="M207.9,32.7c-1.9-0.3-2.1,2.4-0.1,2.9c0.5,0.1,1,0.2,1.5,0.3
            c5.9,1,11.4,3.9,16.8,6.8c1.4,0.6,2.6,1.6,3.5,2.8c-2.6-3-5.6-5.5-9.1-7.4c-3.4-1.9-7-3.5-10.6-4.8C209.3,33,208.6,32.8,207.9,32.7z
            "/>
          <path fill="#FE9236" d="M178.4,106.4c-4.9,2.5-0.8,9.4,4.2,7.1c1.3-0.6,2.6-1.3,3.8-1.9c14.8-7.7,32-10.5,49-12.7
            c4.1-0.5,8.6-1,12.4,0.6c-10.5-2.9-21.6-3.2-32.4-1.9s-21.3,3.9-31.6,6.8C182,104.9,180.2,105.6,178.4,106.4z"/>
          <path opacity="0.5" fill="#FE9236" d="M192.3,133.1c-3.5,3.2,2,7.1,5.6,4c1-0.8,1.9-1.7,2.7-2.5
            c10.6-10,24.6-17.1,38.6-23.6c3.4-1.6,17.5-3.2,21.3-3.1c-9.8,0.9-29.9,4-38.9,8c-8.8,4.2-17.2,8.9-25.4,14.2
            C194.9,131,193.5,132,192.3,133.1z"/>
          <path fill="#FE9236" d="M217.1,175.7c-1,4.6,5.6,4.7,6.9,0.1c0.3-1.2,0.6-2.4,0.8-3.6c3.1-14.2,10.6-28.1,18.4-41.4
            c1.9-3.3,12.6-12.6,15.7-14.7c-7.6,6.3-22.4,20.3-27.4,28.7c-4.8,8.4-9.1,17.1-12.8,26.1C218.1,172.4,217.5,174,217.1,175.7z"/>
          <path fill="#FE9236" d="M267.2,12.3c-2.4-4.8-9.2-0.9-7,4.1c0.6,1.3,1.2,2.6,1.8,3.8c7.3,14.8,9.8,31.9,11.7,48.7
            c0.5,4.1,0.9,8.5-0.8,12.3c3.1-10.3,3.5-21.4,2.4-32.1s-3.5-21.1-6.2-31.5C268.6,15.7,268,14,267.2,12.3z"/>
          <path fill="#FE9236" d="M229.4,20.5c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.5-0.3,0.6-0.5c0.6-1.3,0.2-3,1.2-4c0.9,1.1,1.7,2.3,2.2,3.6
            l3.5-1c0.3,1.4,0.1,2.8-0.7,4c0.6,0.3,1.2,0.6,1.6,1.1c0.5,0.5,0.6,1.2,0.4,1.8c-0.8,0.2-1.6,0.3-2.5,0.2c0.1,1.5,0,3.1-0.3,4.6
            c0,0.2-0.1,0.4-0.3,0.6c-0.2,0.2-0.6,0-0.9-0.1c-1.2-0.7-2.2-1.8-2.9-3l-1.3,3.1c-0.1,0.3-0.3,0.6-0.6,0.8c-0.4,0.1-0.9,0-1.1-0.4
            c-0.2-0.4-0.3-0.8-0.4-1.2l-0.5-2.9c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.5-0.2c-1.2,0-2.5,0-3.7,0c-0.2-0.7,0.4-1.3,1.1-1.5
            s1.4-0.5,1.7-1.1c0.6-1.2-1-3-0.3-4C226.9,18.7,228.3,20,229.4,20.5z"/>
          <path opacity="0.5" fill="#FE9236" d="M296.6,36.9c2.4-3.3,7.4,0.3,5.2,3.7c-0.6,0.9-1.2,1.8-1.9,2.6
            c-7.5,10-11.3,22.3-14.6,34.3c-0.8,3-1.6,6.1-0.7,9c-1.3-7.8-0.5-15.9,1.5-23.6s5.1-15.1,8.4-22.3C295.1,39.2,295.8,38,296.6,36.9z"
            />
          <path fill="#FE9236" d="M344.9,58c5-0.2,5.7,7.5,0.7,7.9c-1.3,0.1-2.6,0.1-3.9,0.2c-15.4,0.4-30,6-43.9,12c-3.4,1.5-7,3.1-9,6.3
            c6.3-7.3,14.6-12.7,23.4-16.6s18.2-6.5,27.6-8.9C341.5,58.4,343.2,58.1,344.9,58z"/>
          <path opacity="0.5" fill="#FE9236" d="M343.5,76.7c3.2,0.1,2.5,4.6-0.7,4.7c-0.8,0-1.7,0-2.5-0.1
            c-9.7-0.4-19.6,2.3-29.3,5.2c-2.4,0.7-4.9,1.5-6.6,3.3c5.1-4,10.9-6.9,17.1-8.8c6.1-1.8,12.3-3.2,18.6-4.1
            C341.3,76.8,342.4,76.7,343.5,76.7z"/>
          <path opacity="0.2" fill="#FE9236" d="M337.7,32.7c1.9-0.3,2.1,2.4,0.2,2.9c-0.5,0.1-1,0.2-1.5,0.3
            c-5.9,1-11.4,3.9-16.8,6.8c-1.4,0.6-2.6,1.6-3.5,2.8c2.6-3,5.6-5.5,9.1-7.4c3.4-1.9,7-3.5,10.6-4.8C336.4,33,337.1,32.8,337.7,32.7z
            "/>
          <path opacity="0.2" fill="#FE9236" d="M288,166.4c-0.4,1.9-3.1,1-2.7-1c0.1-0.5,0.2-1,0.3-1.5
            c1.3-5.8,0.9-12.1,0.2-18.2c-0.1-1.5-0.5-3-1.2-4.3c1.8,3.5,2.9,7.4,3.3,11.3c0.4,3.9,0.5,7.8,0.4,11.6
            C288.2,165.1,288.1,165.8,288,166.4z"/>
          <path opacity="0.5" fill="#FE9236" d="M336.5,153.9c1.5,2.5-2.2,2.4-3.8-0.2c-0.4-0.7-0.8-1.3-1.2-2
            c-4.5-7.7-11.5-15.4-18.4-22.8c-1.7-1.9-3.6-3.6-5.7-4.9c5.5,3.7,10.5,8,15,12.8c4.5,4.7,8.4,9.6,12.3,14.4
            C335.4,152,336,152.9,336.5,153.9z"/>
          <path opacity="0.2" fill="#FE9236" d="M203.7,153.9c-1.5,2.5,2.2,2.4,3.8-0.2c0.4-0.7,0.8-1.3,1.2-2
            c4.5-7.7,11.5-15.4,18.4-22.8c1.6-1.9,3.6-3.6,5.7-4.9c-5.4,3.7-10.5,8-15,12.8c-4.5,4.7-8.4,9.6-12.3,14.4
            C204.9,152,204.3,152.9,203.7,153.9z"/>
          <path opacity="0.2" fill="#FE9236" d="M252.3,166.4c0.4,1.9,3,1,2.7-1c-0.1-0.5-0.2-1-0.3-1.5
            c-1.3-5.8-0.9-12.1-0.2-18.2c0.1-1.5,0.5-3,1.2-4.3c-1.8,3.5-2.9,7.4-3.3,11.3c-0.4,3.9-0.5,7.8-0.4,11.6
            C252.1,165.1,252.2,165.8,252.3,166.4z"/>
          <path opacity="0.2" fill="#FE9236" d="M322.4,51.7c1.9-0.3,2,2.4,0.1,2.9c-0.5,0.1-1,0.2-1.5,0.3
            c-5.9,1-11.5,3.9-16.8,6.8c-1.4,0.6-2.6,1.6-3.5,2.8c2.6-3,5.6-5.5,9.1-7.4c3.4-1.9,7-3.5,10.6-4.8C321.1,52,321.8,51.8,322.4,51.7z
            "/>
          <path opacity="0.2" fill="#FE9236" d="M304.6,14.4c1.6-1,2.8,1.5,1.2,2.6c-0.4,0.3-0.9,0.6-1.3,0.8
            c-5,3.1-9.2,7.9-13,12.6c-1,1.1-1.8,2.5-2.2,3.9c1.2-3.7,3.2-7.2,5.7-10.3c2.5-3,5.1-5.8,8-8.4C303.5,15.1,304,14.7,304.6,14.4z"/>
          <path fill="#FE9236" d="M367.3,106.4c4.9,2.5,0.8,9.4-4.2,7.1c-1.3-0.6-2.6-1.3-3.8-1.9c-14.8-7.7-32-10.5-49-12.7
            c-4.1-0.5-8.6-1-12.4,0.6c10.5-2.9,21.6-3.2,32.4-1.9s21.3,3.9,31.6,6.8C363.7,104.9,365.5,105.6,367.3,106.4z"/>
          <path opacity="0.5" fill="#FE9236" d="M353.4,133.1c3.5,3.2-2,7.1-5.6,4c-1-0.8-1.9-1.7-2.7-2.5
            c-10.7-10-24.7-17.1-38.7-23.6c-3.4-1.6-17.5-3.2-21.3-3.1c9.9,0.9,30,4,38.9,8c8.8,4.2,17.2,8.9,25.4,14.2
            C350.8,130.9,352.2,131.9,353.4,133.1z"/>
          <path fill="#FE9236" d="M312.3,181c0.4,4.7-6.2,4-6.8-0.8c-0.2-1.2-0.3-2.5-0.4-3.7c-1.3-14.5-7-29.2-13-43.3
            c-1.5-3.5-10.9-14.1-13.8-16.6c6.8,7.2,19.7,22.9,23.6,31.9c3.8,9,6.9,18.1,9.4,27.5C311.9,177.6,312.2,179.3,312.3,181z"/>
          <path fill="#FE9236" d="M316.3,20.5c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.4-0.3-0.5-0.5c-0.6-1.3-0.3-3-1.2-4
            c-0.9,1.1-1.7,2.3-2.2,3.6l-3.5-1c-0.3,1.4-0.1,2.8,0.7,4c-0.6,0.3-1.2,0.6-1.6,1.1c-0.5,0.5-0.6,1.2-0.4,1.8
            c0.8,0.2,1.6,0.3,2.5,0.2c-0.1,1.5,0,3.1,0.3,4.6c0,0.2,0.1,0.4,0.3,0.6c0.2,0.2,0.6,0,0.9-0.1c1.2-0.7,2.2-1.8,2.9-3l1.3,3.1
            c0.1,0.3,0.3,0.6,0.6,0.8c0.4,0.1,0.9,0,1.1-0.4c0.2-0.4,0.3-0.8,0.4-1.2l0.5-2.9c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.5-0.2
            c1.2,0,2.5,0,3.7,0c0.2-0.7-0.4-1.3-1.1-1.5s-1.4-0.5-1.7-1.1c-0.6-1.2,1-3,0.3-4C318.7,18.7,317.4,20,316.3,20.5z"/>
          <path opacity="0.2" fill="#FE9236" d="M326.8,163.9c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.5-0.3-0.6-0.5
            c-0.6-1.3-0.2-2.9-1.2-4c-0.9,1.1-1.7,2.4-2.2,3.7l-3.5-1.1c-0.3,1.4-0.1,2.8,0.7,4c-0.6,0.3-1.2,0.6-1.6,1.1
            c-0.5,0.5-0.6,1.2-0.4,1.8c0.8,0.2,1.6,0.3,2.5,0.2c-0.1,1.5,0,3.1,0.3,4.6c0,0.2,0.1,0.4,0.3,0.6c0.3,0.2,0.6,0,0.9-0.1
            c1.2-0.7,2.2-1.8,2.9-3l1.3,3.1c0.1,0.3,0.3,0.6,0.6,0.8c0.4,0.1,0.9,0,1.1-0.4c0.2-0.4,0.3-0.8,0.4-1.2l0.5-2.9
            c0-0.2,0.1-0.3,0.2-0.4c0.2-0.1,0.3-0.2,0.5-0.1c1.2,0,2.5-0.1,3.7,0c0.2-0.6-0.4-1.3-1.1-1.5s-1.4-0.5-1.7-1.1c-0.6-1.2,1-3,0.3-4
            C329.2,162.1,327.9,163.4,326.8,163.9z"/>
          <path fill="#FE9236" d="M356.3,67.7c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.5-0.3-0.6-0.5c-0.6-1.3-0.2-3-1.2-4
            c-0.9,1.1-1.7,2.4-2.2,3.7l-3.5-1.1c-0.3,1.4-0.1,2.8,0.7,4c-0.6,0.3-1.2,0.6-1.6,1.1c-0.5,0.5-0.6,1.2-0.4,1.8
            c0.8,0.2,1.6,0.3,2.5,0.2c-0.1,1.5,0,3.1,0.3,4.6c0,0.2,0.1,0.4,0.3,0.6c0.2,0.2,0.6,0,0.9-0.1c1.2-0.7,2.2-1.8,2.9-3l1.3,3.1
            c0.1,0.3,0.3,0.6,0.6,0.8c0.4,0.1,0.9,0,1.1-0.4c0.2-0.4,0.3-0.8,0.4-1.2l0.5-3c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.5-0.2
            c1.2,0,2.5,0,3.7,0c0.2-0.7-0.4-1.3-1.1-1.5s-1.4-0.5-1.7-1.1c-0.6-1.2,1-3,0.3-4C358.7,66,357.4,67.2,356.3,67.7z"/>
          <path fill="#FE9236" d="M238.4,178.3c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0-0.6-0.4-0.8-0.7c-0.8-1.8-0.4-4.1-1.6-5.6
            c-1.3,1.6-2.3,3.3-3.1,5.2l-4.9-1.5c-0.5,1.9-0.1,3.9,0.9,5.6c-0.9,0.4-1.6,0.9-2.3,1.6c-0.7,0.7-0.9,1.7-0.6,2.6
            c1.1,0.3,2.3,0.4,3.4,0.4c-0.2,2.1-0.1,4.3,0.4,6.4c0,0.3,0.2,0.6,0.4,0.8c0.4,0.2,0.8,0,1.2-0.2c1.7-1,3.1-2.5,4-4.2l1.8,4.3
            c0.1,0.4,0.4,0.8,0.8,1.1c0.6,0.2,1.2,0,1.5-0.5c0.3-0.5,0.5-1.1,0.5-1.6l0.7-4.1c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.5-0.2,0.7-0.2
            c1.7,0,3.4-0.1,5.1-0.1c0.3-0.9-0.6-1.8-1.5-2.1s-1.9-0.7-2.4-1.5c-0.9-1.7,1.4-4.1,0.4-5.6C241.7,175.8,239.9,177.5,238.4,178.3z"
            />
          <path fill="#FE9236" d="M189.3,70.6c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0-0.6-0.4-0.8-0.7c-0.8-1.8-0.4-4.1-1.6-5.6
            c-1.3,1.6-2.3,3.3-3.1,5.2l-4.9-1.5c-0.5,1.9-0.1,3.9,0.9,5.6c-0.9,0.4-1.6,0.9-2.3,1.6c-0.7,0.7-0.9,1.7-0.6,2.6
            c1.1,0.3,2.3,0.4,3.4,0.3c-0.2,2.1-0.1,4.3,0.4,6.4c0,0.5,0.3,0.9,0.8,1c0.3,0,0.6-0.1,0.8-0.3c1.7-1,3.1-2.5,4-4.2l1.8,4.3
            c0.1,0.4,0.4,0.8,0.8,1.1c0.6,0.2,1.2,0,1.5-0.5c0.3-0.5,0.5-1.1,0.5-1.6l0.7-4.1c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.5-0.2,0.7-0.2
            c1.7,0,3.4-0.1,5.1-0.1c0.3-0.9-0.6-1.8-1.5-2.1s-1.9-0.7-2.4-1.5c-0.9-1.7,1.4-4.1,0.4-5.6C192.6,68.2,190.8,69.9,189.3,70.6z"/>
          <path opacity="0.2" fill="#FE9236" d="M338.2,137.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.2-0.4-0.4
            c-0.4-1-0.2-2.2-0.9-3c-0.7,0.8-1.2,1.8-1.6,2.8l-2.6-0.8c-0.2,1-0.1,2.1,0.5,3c-0.5,0.2-0.9,0.5-1.2,0.8c-0.4,0.4-0.5,0.9-0.3,1.4
            c0.6,0.1,1.2,0.2,1.8,0.2c-0.1,1.1,0,2.3,0.2,3.4c0,0.2,0.1,0.3,0.2,0.4c0.2,0.1,0.5,0,0.6-0.1c0.9-0.6,1.6-1.3,2.1-2.2l1,2.3
            c0.1,0.2,0.2,0.4,0.4,0.6c0.3,0.1,0.6,0,0.8-0.3c0.2-0.3,0.3-0.6,0.3-0.9l0.4-2.2c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.4-0.1h2.7
            c0.1-0.5-0.3-0.9-0.8-1.1s-1-0.4-1.2-0.8c-0.5-0.9,0.8-2.2,0.2-3S339,136.9,338.2,137.3z"/>
          <path opacity="0.2" fill="#FE9236" d="M271.5,175.8c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.4-0.4
            c-0.4-0.9-0.2-2.2-0.9-3c-0.7,0.8-1.2,1.8-1.6,2.8l-2.6-0.8c-0.2,1-0.1,2.1,0.5,2.9c-0.4,0.2-0.9,0.5-1.2,0.8
            c-0.4,0.4-0.5,0.9-0.3,1.4c0.6,0.1,1.2,0.2,1.8,0.2c-0.1,1.1,0,2.3,0.2,3.4c0,0.2,0.1,0.3,0.2,0.4c0.2,0.1,0.4,0,0.6-0.1
            c0.9-0.6,1.6-1.3,2.1-2.2l1,2.3c0.1,0.2,0.2,0.4,0.4,0.6c0.3,0.1,0.6,0,0.8-0.3c0.2-0.3,0.3-0.6,0.3-0.9l0.4-2.2
            c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.4-0.1c0.9,0,1.8,0,2.7,0c0.1-0.5-0.3-0.9-0.8-1.1s-1-0.4-1.2-0.8c-0.5-0.9,0.8-2.2,0.2-3
            S272.3,175.4,271.5,175.8z"/>
          <path opacity="0.5" fill="#FE9236" d="M207,41.1c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.4-0.4
            c-0.4-1-0.2-2.2-0.9-3c-0.7,0.8-1.2,1.7-1.6,2.8l-2.6-0.8c-0.3,1-0.1,2.1,0.5,3c-0.5,0.2-0.9,0.5-1.2,0.8c-0.4,0.4-0.5,0.9-0.3,1.4
            c0.6,0.2,1.2,0.2,1.8,0.2c-0.1,1.1,0,2.3,0.2,3.4c0,0.2,0.1,0.3,0.2,0.4c0.2,0.1,0.4,0,0.6-0.1c0.9-0.6,1.6-1.3,2.1-2.2l1,2.3
            c0.1,0.2,0.2,0.4,0.4,0.6c0.3,0.1,0.6,0,0.8-0.3c0.2-0.3,0.3-0.6,0.3-0.9l0.4-2.2c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.4-0.1
            c0.9,0,1.8,0,2.7,0c0.2-0.5-0.3-0.9-0.8-1.1s-1-0.3-1.2-0.8c-0.5-0.9,0.8-2.2,0.2-3S207.8,40.7,207,41.1z"/>
          <path opacity="0.5" fill="#FE9236" d="M241.4,117c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.2-0.4-0.4
            c-0.4-0.9-0.2-2.2-0.9-3c-0.7,0.8-1.2,1.8-1.6,2.8l-2.6-0.8c-0.3,1-0.1,2.1,0.5,3c-0.5,0.2-0.9,0.5-1.2,0.8
            c-0.4,0.4-0.5,0.9-0.3,1.4c0.6,0.1,1.2,0.2,1.8,0.2c-0.1,1.1,0,2.3,0.2,3.4c0,0.2,0.1,0.3,0.2,0.4c0.2,0.1,0.4,0,0.6-0.1
            c0.9-0.5,1.6-1.3,2.1-2.2l1,2.3c0.1,0.2,0.2,0.4,0.4,0.6c0.3,0.1,0.6,0,0.8-0.3c0.2-0.3,0.3-0.6,0.3-0.9l0.4-2.2
            c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.4-0.1c0.9,0,1.8,0,2.7,0c0.2-0.5-0.3-0.9-0.8-1.1s-1-0.4-1.2-0.8c-0.5-0.9,0.8-2.2,0.2-3
            S242.1,116.6,241.4,117z"/>
          <path opacity="0.5" fill="#FE9236" d="M216.4,87.4c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.2-0.4-0.4
            c-0.4-0.9-0.2-2.2-0.9-3c-0.7,0.8-1.2,1.8-1.6,2.8l-2.6-0.8c-0.3,1-0.1,2.1,0.5,3c-0.4,0.2-0.9,0.5-1.2,0.8
            c-0.4,0.4-0.5,0.9-0.3,1.4c0.6,0.1,1.2,0.2,1.8,0.2c-0.1,1.1,0,2.3,0.2,3.4c0,0.2,0.1,0.3,0.2,0.4c0.2,0.1,0.4,0,0.6-0.1
            c0.9-0.5,1.6-1.3,2.1-2.2l1,2.3c0.1,0.2,0.2,0.4,0.4,0.6c0.3,0.1,0.6,0,0.8-0.3c0.2-0.3,0.3-0.6,0.3-0.9l0.4-2.2
            c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.4-0.1c0.9,0,1.8,0,2.7,0c0.1-0.5-0.3-0.9-0.8-1.1s-1-0.4-1.2-0.8c-0.5-0.9,0.8-2.2,0.2-3
            S217.2,87,216.4,87.4z"/>
          <path opacity="0.5" fill="#FE9236" d="M323.5,94.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.2-0.4-0.4
            c-0.4-0.9-0.2-2.2-0.9-3c-0.7,0.8-1.2,1.8-1.6,2.8l-2.6-0.8c-0.2,1-0.1,2.1,0.5,2.9c-0.5,0.2-0.9,0.5-1.2,0.8
            c-0.4,0.4-0.5,0.9-0.3,1.4c0.6,0.1,1.2,0.2,1.8,0.2c-0.1,1.1,0,2.3,0.2,3.4c0,0.2,0.1,0.3,0.2,0.4c0.2,0.1,0.4,0,0.6-0.1
            c0.9-0.6,1.6-1.3,2.1-2.2l1,2.3c0.1,0.2,0.2,0.4,0.4,0.6c0.3,0.1,0.6,0,0.8-0.3c0.2-0.3,0.3-0.6,0.3-0.9l0.4-2.2
            c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.4-0.1c0.9,0,1.8,0,2.7,0c0.1-0.5-0.3-0.9-0.8-1.1s-1-0.4-1.2-0.8c-0.5-0.9,0.8-2.2,0.2-3
            S324.3,94,323.5,94.3z"/>
          <path opacity="0.2" fill="#FE9236" d="M271.5,88.5c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.2-0.4-0.4
            c-0.4-1-0.2-2.2-0.9-3c-0.7,0.8-1.2,1.8-1.6,2.8l-2.6-0.8c-0.3,1-0.1,2.1,0.5,3c-0.5,0.2-0.9,0.5-1.2,0.8c-0.4,0.4-0.5,0.9-0.3,1.4
            c0.6,0.1,1.2,0.2,1.8,0.2c-0.1,1.1,0,2.3,0.2,3.4c0,0.2,0.1,0.3,0.2,0.4c0.2,0.1,0.4,0,0.6-0.1c0.9-0.6,1.6-1.3,2.1-2.2l1,2.3
            c0.1,0.2,0.2,0.4,0.4,0.6c0.3,0.1,0.6,0,0.8-0.3c0.2-0.3,0.3-0.6,0.3-0.9l0.4-2.2c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.4-0.1
            c0.9,0,1.8,0,2.7,0c0.1-0.5-0.3-0.9-0.8-1.1s-1-0.4-1.2-0.8c-0.5-0.9,0.8-2.2,0.2-3S272.3,88.1,271.5,88.5z"/>
          <path opacity="0.3" fill="#FE9236" d="M255.2,95.6c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.2-0.4-0.4
            c-0.4-1-0.2-2.2-0.9-3c-0.7,0.8-1.2,1.8-1.6,2.8l-2.6-0.8c-0.2,1-0.1,2.1,0.5,2.9c-0.5,0.2-0.9,0.5-1.2,0.8
            c-0.4,0.4-0.5,0.9-0.3,1.4c0.6,0.1,1.2,0.2,1.8,0.2c-0.1,1.1,0,2.3,0.2,3.4c0,0.2,0.1,0.3,0.2,0.4c0.2,0.1,0.4,0,0.6-0.1
            c0.9-0.6,1.6-1.3,2.1-2.2l1,2.3c0.1,0.2,0.2,0.4,0.4,0.6c0.3,0.1,0.6,0,0.8-0.3c0.2-0.3,0.3-0.6,0.3-0.9l0.4-2.2
            c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.4-0.1c0.9,0,1.8,0,2.7,0c0.1-0.5-0.3-0.9-0.8-1.1s-1-0.4-1.2-0.8c-0.5-0.9,0.8-2.2,0.2-3
            S256,95.2,255.2,95.6z"/>
          <path opacity="0.2" fill="#FE9236" d="M240,52.7c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.2-0.4-0.4
            c-0.4-1-0.2-2.2-0.9-3c-0.7,0.8-1.2,1.7-1.6,2.7l-2.6-0.8c-0.3,1-0.1,2.1,0.5,3c-0.5,0.2-0.9,0.5-1.2,0.8c-0.3,0.4-0.5,0.9-0.3,1.4
            c0.6,0.1,1.2,0.2,1.8,0.2c-0.1,1.1,0,2.3,0.2,3.4c0,0.2,0.1,0.3,0.2,0.4c0.2,0.1,0.4,0,0.6-0.1c0.9-0.5,1.6-1.3,2.1-2.2l1,2.3
            c0.1,0.2,0.2,0.4,0.4,0.6c0.3,0.1,0.7-0.1,0.8-0.4c0.2-0.3,0.3-0.6,0.3-0.9l0.4-2.2c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.4-0.1
            c0.9,0,1.8,0,2.7,0c0.2-0.5-0.3-0.9-0.8-1.1s-1-0.4-1.2-0.8c-0.5-0.9,0.8-2.2,0.2-3S240.8,52.3,240,52.7z"/>
          <path opacity="0.3" fill="#FE9236" d="M270,108.7c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.2-0.4-0.4
            c-0.4-0.9-0.2-2.2-0.9-3c-0.7,0.8-1.2,1.8-1.6,2.8l-2.6-0.8c-0.3,1-0.1,2.1,0.5,3c-0.5,0.2-0.9,0.5-1.2,0.8
            c-0.3,0.4-0.5,0.9-0.3,1.4c0.6,0.1,1.2,0.2,1.8,0.2c-0.1,1.1,0,2.3,0.2,3.4c0,0.2,0.1,0.3,0.2,0.4c0.2,0.1,0.5,0,0.6-0.1
            c0.9-0.6,1.6-1.3,2.1-2.2l1,2.3c0.1,0.2,0.2,0.4,0.4,0.6c0.3,0.1,0.6,0,0.8-0.3c0.2-0.3,0.3-0.6,0.3-0.9l0.4-2.2
            c0-0.2,0.2-0.4,0.4-0.4c0,0,0.1,0,0.1,0c0.9,0,1.8,0,2.7,0c0.2-0.5-0.3-0.9-0.8-1.1s-1-0.4-1.2-0.8c-0.5-0.9,0.8-2.2,0.2-3
            S270.8,108.3,270,108.7z"/>
        </g>
        <g id="Mentor2020Trophy_Text">
          <path fill="#FFFFFF" d="M473.2,680.4c0.7-1.7,2.4-3.5,5.1-5.4c2.3-1.7,3.8-2.8,4.5-3.6c1-1.1,1.6-2.3,1.6-3.7c0-1.1-0.3-2-0.9-2.7
            s-1.5-1.1-2.6-1.1c-1.5,0-2.6,0.6-3.1,1.7c-0.3,0.7-0.5,1.7-0.6,3.1h-4.9c0.1-2.2,0.5-3.9,1.2-5.3c1.3-2.5,3.7-3.8,7.1-3.8
            c2.7,0,4.8,0.7,6.4,2.2c1.6,1.5,2.4,3.5,2.4,5.9c0,1.9-0.6,3.6-1.7,5c-0.7,1-2,2.1-3.6,3.3l-2,1.4c-1.3,0.9-2.1,1.5-2.6,1.9
            s-0.9,0.9-1.2,1.4h11.1v4.4H472C472.1,683.6,472.5,681.9,473.2,680.4z"/>
          <path fill="#FFFFFF" d="M507.7,663.2c1.3,2.3,2,5.5,2,9.7s-0.7,7.4-2,9.7s-3.6,3.4-6.8,3.4s-5.5-1.1-6.8-3.4s-2-5.5-2-9.7
            s0.7-7.5,2-9.7s3.6-3.4,6.8-3.4S506.4,660.9,507.7,663.2z M497.9,679.7c0.5,1.6,1.5,2.3,3,2.3s2.5-0.8,2.9-2.3s0.7-3.8,0.7-6.7
            c0-3.1-0.2-5.3-0.7-6.8s-1.4-2.2-2.9-2.2s-2.5,0.7-3,2.2s-0.7,3.8-0.7,6.8C497.2,675.9,497.4,678.1,497.9,679.7z"/>
          <path fill="#FFFFFF" d="M513.2,680.4c0.7-1.7,2.4-3.5,5.1-5.4c2.3-1.7,3.8-2.8,4.5-3.6c1-1.1,1.6-2.3,1.6-3.7c0-1.1-0.3-2-0.9-2.7
            s-1.5-1.1-2.6-1.1c-1.5,0-2.6,0.6-3.1,1.7c-0.3,0.7-0.5,1.7-0.6,3.1h-4.9c0.1-2.2,0.5-3.9,1.2-5.3c1.3-2.5,3.7-3.8,7.1-3.8
            c2.7,0,4.8,0.7,6.4,2.2s2.4,3.5,2.4,5.9c0,1.9-0.6,3.6-1.7,5c-0.7,1-2,2.1-3.6,3.3l-2,1.4c-1.3,0.9-2.1,1.5-2.6,1.9
            s-0.9,0.9-1.2,1.4h11.1v4.4h-17.5C512.1,683.6,512.5,681.9,513.2,680.4z"/>
          <path fill="#FFFFFF" d="M547.7,663.2c1.3,2.3,2,5.5,2,9.7s-0.7,7.4-2,9.7s-3.6,3.4-6.8,3.4s-5.5-1.1-6.8-3.4s-2-5.5-2-9.7
            s0.7-7.5,2-9.7s3.6-3.4,6.8-3.4S546.4,660.9,547.7,663.2z M537.9,679.7c0.5,1.6,1.5,2.3,3,2.3s2.5-0.8,2.9-2.3s0.7-3.8,0.7-6.7
            c0-3.1-0.2-5.3-0.7-6.8s-1.4-2.2-2.9-2.2s-2.5,0.7-3,2.2s-0.7,3.8-0.7,6.8C537.2,675.9,537.4,678.1,537.9,679.7z"/>
        </g>
      </svg>
    );
  }

}

export default Mentor2020Trophy;