import React from 'react';

import { WithTrans } from '../i18n/i18nWrapper';

const Help = (props) => {
  return (
    <div className="Info">
      <div className="Info__top">
        <h3 className="Info__title">{props.t('help.got_feedback')}</h3>
        <p className="Info__subtitle">{props.t('help.write_us_on')} <a href="mailto:kontakt@careermentor.dk">kontakt@careermentor.dk</a> <span role="img" aria-label="Mail box">📮</span></p>
        <p className="Info__text">{props.t('help.we_appreciate_feedback')}</p>
      </div>
    </div>
  )
}

export default WithTrans(Help);