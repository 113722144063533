import React from 'react';
import Loader from './Loader';

import { useTranslation } from 'react-i18next';

const Achievement = (props) => {
  const { i18n } = useTranslation();
  const achievement = props.achievement;
  const loading = props.loading;
  const unlocked = props.userAchievements.includes(achievement.id);
  return (
    <li className={unlocked ? 'Achievement Achievement--unlocked' : 'Achievement'}>
      <div className="Achievement__wrapper border-gray">
        <div className={!unlocked ? 'Achievement__illustration Achievement__illustration--locked' : 'Achievement__illustration'}>
          {!loading ? achievement.illustration : <Loader />}
        </div>
        <div className="Achievement__inner">
          <h3 className="Achievement__title">{i18n.language === 'da' ? achievement.title : achievement.title_en}</h3>
          <p className="Achievement__text">{unlocked ? (i18n.language === 'da' ? achievement.text : achievement.text_en) : (i18n.language === 'da' ? achievement.lockedText : achievement.lockedText_en)}</p>
        </div>
        {/* <div className="Achievement__footer">
          <button className="Button">Del på LinkedIn</button>
          <button className="Button Button--green">Download badge</button>
        </div> */}
      </div>
    </li>
  )
}

export default Achievement;