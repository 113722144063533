import React from 'react'
import { Link } from 'react-router-dom';

import WithUser from './HOC/WithUser';
import Tooltip from './Tooltip';
import avatarPlaceholder from '../assets/avatar-placeholder.jpg'

import { WithTrans } from  '../i18n/i18nWrapper';

const ProfileShortcut = (props) => {
  const user = props.user;
  const image = user.profileImageURL ? user.profileImageURL : avatarPlaceholder;
  return (
    <div className="ConversationShortcut">
      <Link to='/profil/'>
        <Tooltip text={user ? props.t('profile.your_profile') : props.t('profile.profile_not_found')} placement="bottom">
          <figure>
            <img src={image} alt={user ? user.name : props.t('profile.profile_not_found')}/>
          </figure>
        </Tooltip>
      </Link>
    </div>
  )
}

export default WithTrans(WithUser(ProfileShortcut));