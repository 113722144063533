import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import classNames from 'classnames';

import { firestore, storage } from '../firebase';

import WithUser from './HOC/WithUser';
import { WithTrans } from '../i18n/i18nWrapper';

import Loader from './Loader';

import icons from '../assets/icons.svg';
const imageIcon = icons + '#image';

class ImageUpload extends PureComponent {
  
  state = {
    src: null,
    crop: {
      aspect: 1,
      width: 300,
      height: 300,
    },
    loading: false,
    success: false,
    error: false,
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  onImageLoaded = image => {
    this.imageRef = image;
  }

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  }

  onCropChange = crop => {
    this.setState({ crop });
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  uploadImage = async () => {
    if(this.state.loading) {
      // loading previous request
      return;
    }
    if(!this.state.croppedImageUrl) {
      // no image uploaded
      return;
    }

    this.setState({ loading: true });

    if(this.props.inSignup) {
      const image = this.state.croppedImageUrl;
      this.props.setImage(image);
      setTimeout(() => {
        this.setState({ success: true, error: false, loading: false });
      }, 500);
    } else {
      const userId = this.props.user.uid;
      const userName = this.props.user.name;
      const image = this.state.croppedImageUrl;
      const userBucket = this.props.user.userBucketName;
  
      if(userId && userBucket && userName && image) {
        try {
          let imageBlob = await fetch(image).then(r => r.blob());
    
          // Generate file name
          const d = new Date();
          const fileName = userName + '-' + d.getTime();
    
          await storage
          .ref()
          .child('user-profiles')
          .child(userBucket)
          .child(fileName)
          .put(imageBlob)
          .then(response => response.ref.getDownloadURL())
          .then((photoURL) => {
            // Save new photo url back to user
            const currentUserDocumentRef = firestore.collection('users').doc(userId);
            currentUserDocumentRef.get().then((docSnapshot) => {
              if (docSnapshot.exists) {
                currentUserDocumentRef.update({
                  profileImageURL: photoURL
                }).then(() => {
                  // Updated photo
                  setTimeout(() => {
                    this.setState({ success: true, error: false, loading: false });
                  }, 500);
                }).catch(function(error) {
                  // Something went wrong
                  console.error("Image upload error: ", error);
                  this.setState({ error: true, success: false, loading: false });
                });
              } else {
                // Something went wrong
                console.error("Image upload error, user not found");
                this.setState({ error: true, success: false, loading: false });
              }
            }).catch((error) => {
              // Something went wrong
              console.error("Image upload error: ", error);
              this.setState({ error: true, success: false, loading: false });
            });
    
          });
        }
        catch(error) {
          console.error("Image upload error: ", error);
          this.setState({ error: true, success: false, loading: false });
        }
  
      } else {
        alert('User or image not found')
        console.error('Upload of user image failed (not found)');
        this.setState({ loading: false });
      }
    }

  }
  
  cancel() {
    if(!this.state.loading) {
      this.setState({
        src: null,
        croppedImageUrl: null,
        success: false,
        error: false,
        crop: {
          aspect: 1,
          width: 300,
          height: 300,
        },
      });
      this.props.closeImageUploadModal();
    }
  }

  render() {
    const { crop, src, loading, success, error } = this.state;
    const saveBtnClass = classNames('Button', {
      'Button--gray': !this.state.src,
    });
    const optionsClass = classNames('ImageUpload__options', {
      'ImageUpload__options--success': success,
      'ImageUpload__options--error': error,
    });
    return (
      <div className="ImageUpload">
        {success ? (
          <img src={this.props.inSignup ? this.state.croppedImageUrl : this.props.user.profileImageURL} alt={this.props.t('upload_image.new_image_preview')} className="ImageUpload__preview"/>
        ) : (
          <>
            {src ? (
              <ReactCrop
                src={src}
                crop={crop}
                minWidth={200}
                maxWidth={500}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            ) : (
              <label className="ImageUpload__input" htmlFor="profilepicture">
                <div>
                  <svg>
                    <use xlinkHref={imageIcon}></use>
                  </svg>
                  <span>{this.props.t('upload_image.upload_image')}</span>
                </div>
                <input type="file" name="profilepicture" id="profilepicture" onChange={this.onSelectFile} accept="image/x-png,image/jpeg,image/jpg"/>
              </label>
            )}
          </>
        )}
        <div className={optionsClass}>
          {success ? (
            <>
              <div className="Form__successMessage Form__successMessage--showMessage">
                <p>{this.props.t('upload_image.success_your_image_is')} {this.props.inSignup ? this.props.t('upload_image.uploaded') : this.props.t('upload_image.updated')}.</p>
              </div>
              <button className="Button" onClick={() => this.cancel()}>{this.props.t('upload_image.cancel_and_close')}</button>
            </>
          ) : (
            <>
              {error ? <div className="Form__errorMessage Form__errorMessage--showMessage"><p>{this.props.t('upload_image.error_inline')}</p></div> : null}
              <button className="Button Button--green--outline Request__ignore" style={{ cursor: loading ? 'wait' : 'pointer' }} onClick={() => this.cancel()}>{this.props.t('upload_image.cancel_capitalized')}</button>
              <button className={saveBtnClass} onClick={() => this.uploadImage()}>
                {loading ? <Loader/> : <span>{this.props.t('upload_image.save_image')}</span> }
              </button>
            </>
          )}
        </div>
      </div>
    ) 
  }

}

export default WithUser(WithTrans(ImageUpload));