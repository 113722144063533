import React from 'react';

import ConversationShortcuts from './ConversationShortcuts';
import Notifications from './Notifications';
import Burger from './Burger';
import ProfileShortCut from './ProfileShortcut';
import LangSwitcher from './LangSwitcher';

const Topbar = () => {
  return (
    <div className="Topbar" id="Topbar">
      <div>
        <Burger />
      </div>
      <div>
        <ConversationShortcuts />
      </div>
      <div>
        <LangSwitcher />
        <ProfileShortCut />
        <Notifications />
      </div>
    </div>  
  )
}

export default Topbar;