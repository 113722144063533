import React, { useRef } from 'react'
import classNames from 'classnames';

import icons from '../../assets/icons.svg';
const CheckMark = icons + '#checkmark';

const Competence = (props) => {
  const listElement = useRef(null);
  const selected = props.competencies ? props.competencies.includes(props.id) : false;
  const competenceClass = classNames('Competence', {
    'Competence--selected': selected,
  });
  const buttonClass = classNames('Competence__btn Button Button--green--outline', {
    'Button--icon': selected,
  });
  function handleClick(e) {
    e.preventDefault();
    props.handleClick(props.id, props.profile, selected);

    // Stupid non-reactive logic here to highlight parent
    if(listElement.current) {
      const categoryElement = listElement.current.parentElement.parentElement.parentElement;
      if(!selected) {
        // Element has been selected, check if parent category needs highlighting
        if(!categoryElement.classList.contains('CompetenceSelector__category--selected')) {
          categoryElement.classList.add('CompetenceSelector__category--selected');
        }
      } else {
        // Element has been de-selected, check if parent category needs to have highlight removed
        const allSelectedCategoryElements = [...categoryElement.querySelectorAll('.Competence--selected')];
        if(!allSelectedCategoryElements || allSelectedCategoryElements.length < 2) {
          categoryElement.classList.remove('CompetenceSelector__category--selected');
        }
      }
    }
  }
  return (
    <li className={competenceClass} ref={listElement}>
      <button className={buttonClass} onClick={(e) => handleClick(e)}>
        <span>{props.title}</span>
        {selected ? <svg><use xlinkHref={CheckMark}></use></svg> : null}
      </button>
    </li>
  )
}

export default Competence;