import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { validateString, getBirthYears } from '../../util';

import { WithTrans } from '../../i18n/i18nWrapper';

import Avatar from './Illustrations/Avatar';
import TempUserContext from "../../providers/TempUserProvider";
import CompetenceSelector from './CompetenceSelector';
import Modal from '../Modal';
import ImageUpload from '../ImageUpload';

class StepThree extends Component {

  constructor(props) {
    super(props);
    this.handleCompetenceSelect = this.handleCompetenceSelect.bind(this);
    this.setImage = this.setImage.bind(this);
  }

  state = {
    image: '',
    name: '',
    gender: '',
    status: '',
    title: '',
    company: '',
    location: 1,
    birthday: 0,
    birthYears: [],
    linkedinurl: '',
    linkedInUrlInvalid: false,
    mentorExperience: 1,
    mentorCompetencies: [],
    mentorText: '',
    mentorStartup: false,
    menteeText: '',
    menteePreferenceExperience: "1",
    menteePreferenceMentorYounger: true,
    menteePreferenceStartup: false,
    menteePreferenceGender: 'both',
    menteePreferenceCompetencies: [],
    menteePreferenceLocations: [],
    menteePreferenceCopenhagen: false,
    menteePreferenceZealand: false,
    menteePreferenceFunen: false,
    menteePreferenceJutlandNorth: false,
    menteePreferenceJutlandMiddle: false,
    menteePreferenceJutlandSouth: false,
    contextInSync: false,
    profilepictureModalOpen: false,
    dontcheck: false,
  };

  linkedInInput = React.createRef();

  componentDidMount() {
    // Send initial data fields to parent
    this.props.getStepThreeInputs(this.state);

    // Calculate possible birth years (min 15 years max 100 years)
    const years = getBirthYears();
    this.setState({
      birthday: years.defaultYear,
      birthYears: years.years,
    });
  }

  componentDidUpdate(prevProps, prevState) {

    // Check if we're ready to navigate by validating inputs
    let generalValidated = false;
    let restValidated = false;

    // Check general inputs
    let { image, name, gender, status, title, company, location, birthday, birthYears, linkedinurl } = this.state;
    if(name && gender && status && title && company && location && birthday && birthYears.includes(parseInt(birthday)) && linkedinurl) {
      generalValidated = true;
    } else {
      generalValidated = false;
    }

    if(generalValidated) {
      if(this.props.tempUser.mentor && this.props.tempUser.mentee) {
        // Need to check both mentor and mentee inputs
        let menteeValid = this.checkMenteeInputs();
        let mentorValid = this.checkMentorInputs();
        if (menteeValid && mentorValid) {
          restValidated = true;
        }
      } else if (this.props.tempUser.mentor) {
        // Check mentor inputs
        let mentorValid = this.checkMentorInputs();
        if (mentorValid) {
          restValidated = true;
        }
      } else if (this.props.tempUser.mentee) {
        // Check mentee inputs
        let menteeValid = this.checkMenteeInputs();
        if (menteeValid) {
          restValidated = true;
        }
      }
    }

    if(generalValidated && restValidated) {
      // console.log("Ready to navigate from step 3");

      let contextInSync = this.state.contextInSync;

      if(this.props.tempUser.mentor) {
        if(this.state.mentorText !== prevState.mentorText) {
          contextInSync = false;
        }
      }
      if(this.props.tempUser.mentee) {
        if(this.state.menteeText !== prevState.menteeText) {
          contextInSync = false;
        }
      }

      if(!contextInSync) {
        const context = this.context;
        const { updateTempUser } = context;
        // console.log("Update temp user object");
        updateTempUser({
          profile: {
            ...image && { image: this.state.image },
            name: this.state.name,
            gender: this.state.gender,
            status: this.state.status,
            title: this.state.title,
            company: this.state.company,
            location: parseInt(this.state.location),
            birthday:  parseInt(this.state.birthday),
            linkedInUrl: linkedinurl
          }
        })
        if(this.props.tempUser.mentor) {
          updateTempUser({
            mentorProfile: {
              experience: parseInt(this.state.mentorExperience),
              competencies: this.state.mentorCompetencies,
              profileText: this.state.mentorText,
              startupExperience: this.state.mentorStartup,
            }
          })
        }
        if(this.props.tempUser.mentee) {
          updateTempUser({
            menteeProfile: {
              profileText: this.state.menteeText,
              preferenceExperience: parseInt(this.state.menteePreferenceExperience),
              preferenceMentorCanBeYounger: this.state.menteePreferenceMentorYounger,
              preferenceStartupExperience: this.state.menteePreferenceStartup,
              preferenceGender: this.state.menteePreferenceGender,
              preferenceCompetencies: this.state.menteePreferenceCompetencies,
              preferenceAreas: this.state.menteePreferenceLocations,
            }
          })
        }
        // The temp user context is updated and in sync with this data
        this.setState({ contextInSync: true });
      }
      // Ready to navigate
      this.props.navReady();
    } else {
      // We're not ready to navigate
      this.props.navNotReady();
      if(this.state.contextInSync) {
        this.setState({ contextInSync: false });
      }
    }

    this.props.getStepThreeInputs(this.state);
  };

  checkMentorInputs() {
    let { mentorExperience, mentorText } = this.state;
    if(mentorExperience && mentorText ) {
      return true;
    } else {
      return false;
    }
  }

  checkMenteeInputs() {
    let { menteeText, menteePreferenceExperience, menteePreferenceGender, menteePreferenceCopenhagen, menteePreferenceZealand, menteePreferenceFunen, menteePreferenceJutlandNorth, menteePreferenceJutlandMiddle, menteePreferenceJutlandSouth } = this.state;
    if(menteeText && menteePreferenceExperience && menteePreferenceGender && (menteePreferenceCopenhagen || menteePreferenceZealand || menteePreferenceFunen || menteePreferenceJutlandNorth || menteePreferenceJutlandMiddle || menteePreferenceJutlandSouth)) {
      return true;
    } else {
      return false;
    }
  }

  handleChange = event => {
    const { name } = event.target;
    let { value } = event.target;
    if (value === 'true' || value === 'false') {
      value = (value === "true");  
    }
    // console.log(value);
    this.setState({ [name]: value });
  };

  handleCheckbox = event => {
    const { name } = event.target;
    this.setState({ [name]: !this.state[name] });
  };

  handleCheckboxMulti = event => {
    const { name } = event.target;
    const id = parseInt(event.target.getAttribute('data-location-id'));

    let selectedAreas = this.state.menteePreferenceLocations;
    if (selectedAreas.includes(id)) {
      var index = selectedAreas.indexOf(id);
      selectedAreas.splice(index, 1);
    } else {
      selectedAreas.push(id);
    }
    this.setState({ 
      [name]: !this.state[name],
      menteePreferenceLocations: selectedAreas,
      contextInSync: false,
    });

  };

  setImage(image) {
    this.setState({ image });
  }

  handleSubmit = event => {
    event.preventDefault();
  };

  validateStringInput = event => {
    const { value } = event.target;
    const valid = validateString(value);
    if(!valid) {
      event.target.nextSibling.classList.add('Form__errorMessage--showMessage');
    } else {
      event.target.nextSibling.classList.remove('Form__errorMessage--showMessage');
    }
  }

  validateLinkedInUrl = event => {
    const { value } = event.target;
    const string = 'https://www.linkedin.com';
    const startsWithLinkedin = value.startsWith(string);
    if (!startsWithLinkedin) {
      if (value === '#') {
        // allowed as no profile url
        this.setState({ linkedInUrlInvalid: false });
      } else {
        this.setState({ linkedInUrlInvalid: true });
        this.linkedInInput.current.focus();
      }
    } else {
      this.setState({ linkedInUrlInvalid: false });
    }
  }

  handleCompetenceSelect(id, profile, selected) {
    if(profile === 'mentor') {
      // Mentor competencies
      if(selected) {
        // Competence already selected (remove it)
        this.setState(prevState => ({ 
          mentorCompetencies: prevState.mentorCompetencies.filter(competence => competence !== id) 
        }));
      } else {
        // console.log(id);
        // Add competence to list of selected competencies
        this.setState(prevState => ({
          mentorCompetencies: [...prevState.mentorCompetencies, id]
        }));
      }
    }
    if(profile === 'mentee') {
      // Mentee competencies preference
      if(selected) {
        // Competence already selected (remove it)
        this.setState(prevState => ({ 
          menteePreferenceCompetencies: prevState.menteePreferenceCompetencies.filter(competence => competence !== id) 
        }));
      } else {
        // console.log(id);
        // Add competence to list of selected competencies
        this.setState(prevState => ({
          menteePreferenceCompetencies: [...prevState.menteePreferenceCompetencies, id]
        }));
      }
    }
  }

  toggleProfilepictureModal() {
    this.setState(prevState => ({
      profilepictureModalOpen: !prevState.profilepictureModalOpen,
    }));
  }

  componentWillUnmount = () => {
    this.props.navNotReady();
  }

  // populate(e) {
  //   e.preventDefault();

  //   const categoryID = '1CeXlCG3uplDp22umyb3';

  //   console.log('Start populating category with id: ', categoryID)

  //   const competencies = [
  //     'Byggeri',
  //     'Projektering',
  //     'Entreprenørvirksomhed',
  //     'Byplanlægning',
  //     'Byggeledelse',
  //     'Konstruktionsteknik',
  //     'Bygningsinformatik',
  //     'Arkitektur',
  //     'Byggeteknik',
  //     'Arkitekturtegning',
  //     'Urban design',
  //     'Grønne områder',
  //     'Naturvejledning',
  //     'Skov- og naturteknik',
  //     'Naturpleje',
  //     'Økologi, miljø og biologi',
  //     'Jordbrugsøkonomi',
  //   ];

  //   competencies.forEach((competency) => {
  //     firestore.collection('competencies').doc(categoryID).collection('competencies').add({
  //       title: competency,
  //     }).then(() => {
  //       console.log('Populate: ', competency);
  //     }).catch((error) => {
  //       console.error('Error writing new document to database', error);
  //     });
  //   });

  // }

  render() {
    const avatarClass = classNames('SignUpStep__avatar', {
      'SignUpStep__avatar--imageUploaded': this.state.image,
    });
    return (
      <div className="SignUpStep">
        <div className="SignUpStep__content">
          <h2 className="SignUpStep__title">{this.props.t('signup.your_information')}</h2>
          {/* <button className="Button Button--outline" onClick={(e) => this.populate(e)}>Populate</button> */}
          <form className="Form SignUpStep__form" onSubmit={this.handleSubmit} onBlur={(e) => {e.preventDefault()}}>

            <h3 className="SignUpStep__subtitle">{this.props.t('signup.general')}</h3>
            <label htmlFor="avatar">{this.props.t('signup.profile_image')}</label>
            <span className="tooltip">{this.props.t('signup.profile_image_info')}</span>
            <button className={avatarClass} onClick={() => this.toggleProfilepictureModal()}>
              {this.state.image ? (
                <img src={this.state.image} alt="Temporary user avatar"/>
              ) : (
                <Avatar />
              )}
              <p>{this.props.t('signup.select_image')}</p>
            </button>

            <label htmlFor="name">{this.props.t('signup.full_name')}</label>
            <input type="text" placeholder="John Doe" name="name" id="name" onChange={this.handleChange} onBlur={this.validateStringInput}/>
            <div className="Form__errorMessage">
              <p>{this.props.t('profile.error_no_name')}</p>
            </div>

            <label>{this.props.t('profile.gender')}</label>
            <div className="Form__radioWrapper" id="gender">
              <label>
                <input type="radio" name="gender" value="male" checked={this.state.gender === 'male'} onChange={this.handleChange}/>
                <span>{this.props.t('profile.gender_male')}</span>
              </label>
              <label>
                <input type="radio" name="gender" value="female" checked={this.state.gender === 'female'} onChange={this.handleChange} />
                <span>{this.props.t('profile.gender_female')}</span>
              </label>
              <label>
                <input type="radio" name="gender" value="other" checked={this.state.gender === 'other'} onChange={this.handleChange} />
                <span>{this.props.t('profile.gender_other')}</span>
              </label>
            </div>

            <label>{this.props.t('profile.employment')}</label>
            <div className="Form__radioWrapper" id="status">
              <label>
                <input type="radio" name="status" value="employed" checked={this.state.status === 'employed'} onChange={this.handleChange} />
                <span>{this.props.t('profile.employment_employed')}</span>
              </label>
              <label>
                <input type="radio" name="status" value="self-employed" checked={this.state.status === 'self-employed'} onChange={this.handleChange} />
                <span>{this.props.t('profile.employment_entrepreneur')}</span>
              </label>
              <label>
                <input type="radio" name="status" value="student" checked={this.state.status === 'student'} onChange={this.handleChange} />
                <span>{this.props.t('profile.employment_student')}</span>
              </label>
              <label>
                <input type="radio" name="status" value="other" checked={this.state.status === 'other'} onChange={this.handleChange} />
                <span>{this.props.t('profile.employment_other')}</span>
              </label>
            </div>
            <span className="tooltip">{this.props.t('signup.select_what_you_identify_with')}</span>

            <label htmlFor="title">{this.props.t('signup.title')}</label>
            <input type="text" placeholder={this.props.t('profile.title_placeholder')} name="title" id="title" onChange={this.handleChange} onBlur={this.validateStringInput}/>
            <div className="Form__errorMessage">
              <p>{this.props.t('signup.title_error')}</p>
            </div>
            <span className="tooltip">{this.props.t('signup.title_tooltip')}</span>
            
            <label htmlFor="mentor-title">{this.props.t('profile.company')}</label>
            <input type="text" placeholder={this.props.t('profile.company_placeholder')} name="company" id="company" onChange={this.handleChange} onBlur={this.validateStringInput}/>
            <div className="Form__errorMessage">
              <p>{this.props.t('profile.error_no_company')}</p>
            </div>

            <label htmlFor="location">{this.props.t('profile.area')}</label>
            <div className="Form__selectWrapper">
              <select id="location" name="location" onChange={this.handleChange} required>
                <option value="1">{this.props.t('profile.area_copenhagen')}</option>
                <option value="2">{this.props.t('profile.area_zealand')}</option>
                <option value="3">{this.props.t('profile.area_funen')}</option>
                <option value="4">{this.props.t('profile.area_northern_jutland')}</option>
                <option value="5">{this.props.t('profile.area_central_jutland')}</option>
                <option value="6">{this.props.t('profile.area_southern_jutland')}</option>
              </select>
            </div>

            <label htmlFor="birthday">{this.props.t('profile.birth_year')}</label>
            <div className="Form__selectWrapper">
              <select id="birthday" name="birthday" onChange={this.handleChange} required value={this.state.birthday}>
                {this.state.birthYears.map(year =>
                  <option value={year} key={year}>{year}</option>
                )}
              </select>
            </div>

            <label htmlFor="linkedinurl">{this.props.t('profile.linkedin_profile')}</label>
            <input type="text" placeholder={this.props.t('profile.linkedin_profile_placeholder')} name="linkedinurl" id="linkedinurl" onChange={this.handleChange} onBlur={this.validateLinkedInUrl} ref={this.linkedInInput}/>
            <span className="tooltip black">{this.props.t('signup.linkedin_tooltip')} <a href="https://www.linkedin.com/in/" target="_blank" rel="noopener noreferrer">{this.props.t('signup.linkedin_tooltip_go_to_profile')}</a>. {this.props.t('signup.linkedin_tooltip_no_profile')}</span>
            {this.state.linkedInUrlInvalid ? (
              <div className="Form__errorMessage Form__errorMessage--showMessage">
                <p>{this.props.t('profile.error_no_linkedin')}</p>
              </div>
            ) : null }
            {this.props.tempUser.mentor ? (
              <>
                <h4 className="SignUpStep__subtitle">{this.props.t('profile.mentor_profile')}</h4>
                <p>{this.props.t('signup.mentor_introduction_one')} <span role="img" aria-label="Trophy">🏆</span> <br/> {this.props.t('signup.mentor_introduction_two')}</p>
                <label htmlFor="mentor-experience" id="mentorexperience">{this.props.t('profile.years_of_experience')}</label>
                <div className="Form__selectWrapper">
                  <select id="mentor-experience" name="mentorExperience" onChange={this.handleChange}>
                    <option value="1">1+</option>
                    <option value="2">3+</option>
                    <option value="3">5+</option>
                    <option value="4">10+</option>
                    <option value="5">15+</option>
                    <option value="6">20+</option>
                    <option value="7">30+</option>
                  </select>
                </div>
                <label htmlFor="competencies">{this.props.t('signup.competency_areas')}</label>
                <span className="tooltip">{this.props.t('signup.competency_areas_tooltip')}</span>
                <CompetenceSelector profile="mentor" handleClick={this.handleCompetenceSelect} competencies={this.state.mentorCompetencies}/>
                {/* <label htmlFor="industry">Primær branche</label>
                <div className="Form__selectWrapper">
                  <select id="industry" name="mentorIndustry" required onChange={this.handleChange}>
                    <option value="">Vælg brancheområde</option>
                    {this.state.allIndustries.map((industry, index) =>
                      <option value={industry.id} key={index}>{industry.name}</option>
                    )}
                  </select>
                </div> */}
                <label htmlFor="mentor-startup">{this.props.t('profile.experience_with_startups')}</label>
                <div className="Form__radioWrapper">
                  <label>
                    <input type="radio" name="mentorStartup" value={true} checked={this.state.mentorStartup === true} onChange={this.handleChange}/>
                    <span>{this.props.t('profile.yes')}</span>
                  </label>
                  <label>
                    <input type="radio" name="mentorStartup" value={false} checked={this.state.mentorStartup === false} onChange={this.handleChange}/>
                    <span>{this.props.t('profile.no')}</span>
                  </label>
                </div>
                <label htmlFor="mentor-text">{this.props.t('profile.profile_text')}</label>
                <textarea id="mentor-text" name="mentorText" onChange={this.handleChange} value={this.state.mentorText} placeholder={this.props.t('profile.profile_text_mentor_placeholder')}></textarea>
                <span className="tooltip">{this.props.t('signup.mentor_profile_text_tooltip')}</span>
              </>
            ) : (
              null
            )}

            {this.props.tempUser.mentee ? (
              <>
                <h3 className="SignUpStep__subtitle">{this.props.t('profile.mentee_profile')}</h3>
                <p>{this.props.t('signup.mentee_introduction_one')}<span role="img" aria-label="Strong arm">💪</span> <br/> {this.props.t('signup.mentee_introduction_two')}</p>
                <label htmlFor="menteeText">{this.props.t('profile.profile_text')}</label>
                <textarea id="menteeText" name="menteeText" onChange={this.handleChange} value={this.state.menteeText} placeholder={this.props.t('profile.profile_text_mentee_placeholder')}></textarea>
                <span className="tooltip">{this.props.t('signup.metee_profile_text_tooltip')}</span>
                <h4 className="SignUpStep__subsubtitle">{this.props.t('profile.mentor_preferences')}</h4>
                <p>{this.props.t('signup.mentee_mentor_preferences_tooltip')}</p>
                <label htmlFor="mentee-preference-experience">{this.props.t('profile.years_of_experience_minimum')}</label>
                <div className="Form__selectWrapper">
                  <select id="mentee-preference-experience" name="menteePreferenceExperience" onChange={this.handleChange}>
                    <option value="1">1+</option>
                    <option value="2">3+</option>
                    <option value="3">5+</option>
                    <option value="4">10+</option>
                    <option value="5">15+</option>
                    <option value="6">20+</option>
                    <option value="7">30+</option>
                  </select>
                </div>
                <label htmlFor="mentor-startup">{this.props.t('profile.mentor_can_be_younger')}</label>
                <div className="Form__radioWrapper">
                  <label>
                    <input type="radio" name="menteePreferenceMentorYounger" value={true} checked={this.state.menteePreferenceMentorYounger === true} onChange={this.handleChange}/>
                    <span>{this.props.t('profile.yes')}</span>
                  </label>
                  <label>
                    <input type="radio" name="menteePreferenceMentorYounger" value={false} checked={this.state.menteePreferenceMentorYounger === false} onChange={this.handleChange}/>
                    <span>{this.props.t('profile.no')}</span>
                  </label>
                </div>
                <label htmlFor="mentor-startup">{this.props.t('profile.mentor_experience_entrepreneur')}</label>
                <div className="Form__radioWrapper"> 
                  <label>
                    <input type="radio" name="menteePreferenceStartup" value={true} checked={this.state.menteePreferenceStartup === true} onChange={this.handleChange}/>
                    <span>{this.props.t('profile.yes')}</span>
                  </label>
                  <label>
                    <input type="radio" name="menteePreferenceStartup" value={false} checked={this.state.menteePreferenceStartup === false} onChange={this.handleChange}/>
                    <span>{this.props.t('profile.no')}</span>
                  </label>
                </div>
                {/* <label>{this.props.t('profile.mentor_gender')}</label>
                <div className="Form__radioWrapper" id="mentorgender">
                  <label>
                    <input type="radio" name="menteePreferenceGender" value="both" checked={this.state.menteePreferenceGender === 'both'} onChange={this.handleChange}/>
                    <span>{this.props.t('profile.gender_both')}</span>
                  </label>
                  <label>
                    <input type="radio" name="menteePreferenceGender" value="male" checked={this.state.menteePreferenceGender === 'male'} onChange={this.handleChange}/>
                    <span>{this.props.t('profile.gender_male')}</span>
                  </label>
                  <label>
                    <input type="radio" name="menteePreferenceGender" value="female" checked={this.state.menteePreferenceGender === 'female'} onChange={this.handleChange}/>
                    <span>{this.props.t('profile.gender_female')}</span>
                  </label>
                </div> */}
                <label htmlFor="competencies">{this.props.t('profile.mentor_comptencies')}</label>
                <span className="tooltip">{this.props.t('signup.mentor_competency_areas_tooltip')}</span>
                <CompetenceSelector profile="mentee" handleClick={this.handleCompetenceSelect} competencies={this.state.menteePreferenceCompetencies} />
                <label htmlFor="mentee-preference-industry-1">{this.props.t('profile.area')}</label>
                <div className="Form__multicheckWrapper Form__multicheckWrapper--width33" id="mentee-preference-location-wrapper">
                  <label htmlFor="Location__copenhagen" className="Form__checkbox">
                    <input type="checkbox" id="Location__copenhagen" name="menteePreferenceCopenhagen" onChange={this.handleCheckboxMulti} data-location-id={1} defaultChecked={this.state.menteePreferenceCopenhagen}/>
                    <span>{this.props.t('profile.area_copenhagen')}</span>
                  </label>
                  <label htmlFor="Location__zealand" className="Form__checkbox">
                    <input type="checkbox" id="Location__zealand" name="menteePreferenceZealand" onChange={this.handleCheckboxMulti} data-location-id={2} defaultChecked={this.state.menteePreferenceZealand}/>
                    <span>{this.props.t('profile.area_zealand')}</span>
                  </label>
                  <label htmlFor="Location__funen" className="Form__checkbox">
                    <input type="checkbox" id="Location__funen" name="menteePreferenceFunen" onChange={this.handleCheckboxMulti} data-location-id={3} defaultChecked={this.state.menteePreferenceFunen}/>
                    <span>{this.props.t('profile.area_funen')}</span>
                  </label>
                  <label htmlFor="Location__jutlandNorth" className="Form__checkbox">
                    <input type="checkbox" id="Location__jutlandNorth" name="menteePreferenceJutlandNorth" onChange={this.handleCheckboxMulti} data-location-id={4} defaultChecked={this.state.menteePreferenceJutlandNorth}/>
                    <span>{this.props.t('profile.area_northern_jutland')}</span>
                  </label>
                  <label htmlFor="Location__jutlandMiddle" className="Form__checkbox">
                    <input type="checkbox" id="Location__jutlandMiddle" name="menteePreferenceJutlandMiddle" onChange={this.handleCheckboxMulti} data-location-id={5} defaultChecked={this.state.menteePreferenceJutlandMiddle}/>
                    <span>{this.props.t('profile.area_central_jutland')}</span>
                  </label>
                  <label htmlFor="Location__jutlandSouth" className="Form__checkbox">
                    <input type="checkbox" id="Location__jutlandSouth" name="menteePreferenceJutlandSouth" onChange={this.handleCheckboxMulti} data-location-id={6} defaultChecked={this.state.menteePreferenceJutlandSouth}/>
                    <span>{this.props.t('profile.area_southern_jutland')}</span>
                  </label>
                </div>
              </>
            ) : (
              null
            )}
          </form>
        </div>
        <Modal toggleModal={() => this.toggleProfilepictureModal()} open={this.state.profilepictureModalOpen} title={this.props.t('upload_image.upload_image')}>
          <ImageUpload closeImageUploadModal={() => this.toggleProfilepictureModal()} inSignup={true} setImage={this.setImage} />
        </Modal>
      </div>
    );
  }

}

StepThree.propTypes = {
  tempUser: PropTypes.object.isRequired,
};

StepThree.contextType = TempUserContext; 

export default WithTrans(StepThree);