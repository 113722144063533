import React from 'react';
import classNames from 'classnames';

const Tooltip = (props) => {
  const tooltipClass = classNames('Tooltip', {
    'Tooltip--top': props.placement === 'top',
    'Tooltip--bottom': props.placement === 'bottom',
    'Tooltip--right': props.placement === 'right',
    'Tooltip--left': props.placement === 'left',
  });
  return (
    <div className={tooltipClass}>
      {props.children}
      <span className="Tooltip__text">{props.text}</span>
    </div>
  )
}

export default Tooltip;