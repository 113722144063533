import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';

import Dashboard from './Dashboard';

const PrivateRoute = ({ component: Component, authenticated, ...rest}) => (
  <Route
    {...rest} 
    render={props => (authenticated === true ? <Dashboard><Component {...props} /></Dashboard> : <Redirect to="/login" />)}
  />
);

export default withRouter(PrivateRoute);