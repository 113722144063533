import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { getLocationName, getCompetenceNames, getMeetingFrequency } from '../util';
import { getUserDocument } from '../firebase';

import WithUser from './HOC/WithUser';
import { WithTrans } from '../i18n/i18nWrapper';

import Loader from './Loader';
import Tooltip from './Tooltip';

import avatarPlaceholder from '../assets/avatar-placeholder.jpg'
import icons from '../assets/icons.svg';
const owlIcon = icons + '#owl';
const sproutIcon = icons + '#sprout';
const LinkedInIcon = icons + '#linkedin';
const rocketIcon = icons + '#rocket-two';

class PublicProfile extends Component {

  state = {
    loading: true,
    redirect: false,
    user: {},
  }

  componentDidMount = async () => {
    // Get profile
    const user = await getUserDocument(this.props.match.params.id);
    if(user) {
      this.setState({ user, loading: false });
    } else {
      // error
      console.error('No such user!');
      this.setState({ redirect: true });
    }
  }
  
  render() {
    const { name, title, company, location, mentee, mentor, linkedInUrl, profileImageURL } = this.state.user;
    const loggedInUser = this.props.user;
    const loading = this.state.loading;
    let locationName = '';
    let entrepreneur = false;
    let mentorCompetencies = [];
    let menteePreferenceCompetencies = [];
    let viewAsMentor = false;
    let viewAsMentee = false;
    const profileImage = profileImageURL ? profileImageURL : avatarPlaceholder;
    if(this.props.location.search === '?viewAs=mentor' && mentee) { viewAsMentor = true; }
    if(this.props.location.search === '?viewAs=mentee' && mentor) { viewAsMentee = true; }
    if(location) {
      locationName = getLocationName(location);
    }
    if(mentor) {
      if(this.state.user.mentorProfile.startupExperience ) {
        entrepreneur = true;
      }
      if(this.state.user.mentorProfile.competencies && this.state.user.mentorProfile.competencies.length > 0) {
        mentorCompetencies = getCompetenceNames(this.state.user.mentorProfile.competencies);
      }
    }
    if(mentee) {
      if(this.state.user.menteeProfile.preferenceCompetencies && this.state.user.menteeProfile.preferenceCompetencies.length > 0) {
        menteePreferenceCompetencies = getCompetenceNames(this.state.user.menteeProfile.preferenceCompetencies);
      }
    }
    return this.state.redirect
    ? <Redirect to="/" />
    : (
      <article className="Profile">
        <div className="Profile__top">
          <figure className="Profile__image">
            { loading ? null : <img src={profileImage} alt={name}/> }
          </figure>
          <div className="Profile__badge">
            { loading ? null : (
              <>
                {mentor ? (
                  <Tooltip text="Mentor" placement="bottom">
                    <svg className="RequestPreview__type">
                      <use xlinkHref={owlIcon}></use>
                    </svg>
                  </Tooltip>
                ) : null}
                {mentee ? (
                  <Tooltip text="Mentee" placement="bottom">
                    <svg className="RequestPreview__type">
                      <use xlinkHref={sproutIcon}></use>
                    </svg>
                  </Tooltip>
                ) : null}
                {entrepreneur ? (
                  <Tooltip text="Iværksætter" placement="bottom">
                    <svg className="RequestPreview__type">
                      <use xlinkHref={rocketIcon}></use>
                    </svg>
                  </Tooltip>
                ) : null}
              </>
            ) }
          </div>
          <div>
            { loading ? <Loader /> : (
              <>
                <h1 className="Profile__name">{name}</h1>
                <h3 className="Profile__title"><span>{title}</span> | <span>{company}</span></h3>
                <p className="Profile__location">{locationName}</p>
                {linkedInUrl && linkedInUrl !== '#' ? (
                  <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
                    <svg>
                      <use xlinkHref={LinkedInIcon}></use>
                    </svg>
                  </a>
                ) : null}
              </>
            ) }
          </div>
        </div>
        <div className="Profile__inner bg-white">
          <div className="Profile__page">
            { loading ? <Loader color="green" /> : (
              <>
                { viewAsMentor || (loggedInUser.mentor && mentee && !viewAsMentee) ? (
                  <>
                    <h3 className="Profile__headline">{this.props.t('profile.mentee_profile')}</h3>
                    <p className="Profile__subheadline">{this.props.t('profile.mentee_text')}</p>
                    <div className={!this.state.user.menteeProfile.profileText ? 'Profile__text Profile__text--none': 'Profile__text'}>{this.state.user.menteeProfile.profileText ? this.state.user.menteeProfile.profileText : `${name} ${this.props.t('profile.not_written_profile_text')}`}</div>
                    {this.state.user.menteeProfile.meetingFrequency ? (
                      <div style={{marginBottom: 20}}>
                        <p className="Profile__subheadline">{this.props.t('profile.prefers_to_meet')}:</p>
                        {getMeetingFrequency(this.state.user.menteeProfile.meetingFrequency )}
                      </div>
                    ) : null}
                    {this.state.user.menteeProfile.subjects ? (
                      <div style={{marginBottom: 20}}>
                        <p className="Profile__subheadline">{this.props.t('profile.mentee_current_subjects')}:</p>
                        <div className="Profile__text">{this.state.user.menteeProfile.subjects}</div>
                      </div>
                    ) : null}
                    {this.state.user.menteeProfile.dreams ? (
                      <div style={{marginBottom: 20}}>
                        <p className="Profile__subheadline">{this.props.t('profile.goals_and_dreams')}:</p>
                        <div className="Profile__text">{this.state.user.menteeProfile.dreams}</div>
                      </div>
                    ) : null}
                    {this.state.user.menteeProfile.funFact ? (
                      <div style={{marginBottom: 20}}>
                        <p className="Profile__subheadline">{this.props.t('profile.fun_fact_about_mentee')}:</p>
                        <div className="Profile__text">{this.state.user.menteeProfile.funFact}</div>
                      </div>
                    ) : null}
                    {this.state.user.menteeProfile.hobbies ? (
                      <div style={{marginBottom: 20}}>
                        <p className="Profile__subheadline">{this.props.t('profile.hobbies')}:</p>
                        <div className="Profile__text">{this.state.user.menteeProfile.hobbies}</div>
                      </div>
                    ) : null}
                    {menteePreferenceCompetencies && menteePreferenceCompetencies.length > 0 ? (
                      <div className="Match__criterias">
                        <span>{this.props.t('profile.seeking_advice_on')}:</span>
                        <ul className="list-unstyled">
                          {menteePreferenceCompetencies.map((competence) =>
                            <li key={competence.id}>{this.props.i18n.language === 'en' ? competence.title_en : competence.title}</li>
                          )}
                        </ul>
                      </div>
                    ) : null}
                  </>
                ) : null }
                { loggedInUser.mentor && mentee && loggedInUser.mentee && mentor && !viewAsMentor && !viewAsMentee ? <hr/> : null }
                { viewAsMentee || (loggedInUser.mentee && mentor && !viewAsMentor) ? (
                  <>
                    <h3 className="Profile__headline">{this.props.t('profile.mentor_profile')}</h3>
                    <p className="Profile__subheadline">{this.props.t('profile.mentor_text')}:</p>
                    <div className={!this.state.user.mentorProfile.profileText ? 'Profile__text Profile__text--none': 'Profile__text'}>{this.state.user.mentorProfile.profileText ? this.state.user.mentorProfile.profileText : `${name} ${this.props.t('profile.not_written_profile_text')}`}</div>
                    {mentorCompetencies && mentorCompetencies.length > 0 ? (
                      <div className="Match__criterias">
                        <span>{this.props.t('profile.mentor_competencies')}:</span>
                        <ul className="list-unstyled">
                          {mentorCompetencies.map((competence) =>
                            <li key={competence.id}>{this.props.i18n.language === 'en' ? competence.title_en : competence.title}</li>
                          )}
                        </ul>
                      </div>
                    ) : null}
                  </>
                ) : null }
              </>
            ) }
          </div>
        </div>
      </article>
    )
  }
}

export default WithUser(WithTrans(PublicProfile));