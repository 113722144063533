import industries from './components/SignUp/industries.json';
import allCompetencies from './components/SignUp/competencies.json';

import i18next from './i18n/config';

export const getLocationName = (id) => {
  switch(id) {
    case 1:
      return i18next.t('profile.area_copenhagen');
    case 2:
      return i18next.t('profile.area_zealand');
    case 3:
      return i18next.t('profile.area_funen');
    case 4:
      return i18next.t('profile.area_northern_jutland');
    case 5:
      return i18next.t('profile.area_central_jutland');
    case 6:
      return i18next.t('profile.area_southern_jutland');
    default:
      return 'Missing location';
  }
};

export const getGenderName = (gender) => {
  switch(gender) {
    case 'male':
      return i18next.t('profile.gender_male');
    case 'female':
      return i18next.t('profile.gender_female');
    case 'other':
      return i18next.t('profile.gender_other');
    default:
      return i18next.t('profile.gender_other');
  }
};

export const getStatusName = (status) => {
  switch(status) {
    case 'employed':
      return i18next.t('profile.employment_employed');
    case 'self-employed':
      return i18next.t('profile.employment_entrepreneur');
    case 'student':
      return i18next.t('profile.employment_student');
    case 'other':
      return i18next.t('profile.employment_other');
    default:
      return 'Missing status';
  }
};

export const getIndustryName = (id) => {
  if(id) {
    const industryName = industries.find(x => x.id === id).name;
    return industryName;
  } else {
    return false;
  }
};

export const getDateString = (timestamp, lang) => {
  let d;
  if(timestamp) {
    if(typeof timestamp.toDate === "function") {
      let date = timestamp.toDate();
      d = new Date(date);
    } else {
      d = new Date(timestamp);
    }
    let month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    const dateString = lang === 'da' ? [day, month, year].join('/') : [month, day, year].join('/');
    return dateString;
  } else {
    return '...';
  }
};

export const getTime = (timestamp) => {
  let d;
  if(timestamp) {
    if(typeof timestamp.toDate === "function") {
      let date = timestamp.toDate();
      d = new Date(date);
    } else {
      d = new Date(timestamp);
    }
    var h = addZero(d.getHours());
    var m = addZero(d.getMinutes());
    // var s = addZero(d.getSeconds());
    const time = h + ":" + m;
    return time;
  } else {
    return '...';
  }
}

const addZero = (i) => {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

export const getYearsExperience = (experience) => {
  switch(experience) {
    case 1:
      return '1+';
    case 2:
      return '3+';
    case 3:
      return '5+';
    case 4:
      return '10+';
    case 5:
      return '15+';
    case 6:
      return '20+';
    case 7:
      return '30+';
    default:
      return 'Missing experience';
  }
};

export const getMeetingFrequency = (id) => {
  switch(id) {
    case 0:
      return i18next.t('profile.not_specified');
    case 1:
      return i18next.t('profile.freq_once_month');
    case 2:
      return i18next.t('profile.freq_two_month');
    case 3:
      return i18next.t('profile.freq_every_quarter');
    case 4:
      return i18next.t('profile.freq_ad_hoc');
    default:
      return 'Missing frequency';
  }
};

export const getBirthYears = () => {
  const minAge = 15 - 1; // -1 if you had your birthday that year
  const maxAge = 100;
  const predefinedAge = 45;
  const dateToday = new Date();
  const latestYearToBeBorn = dateToday.getFullYear() - maxAge;
  const earlistYearToBeBorn = dateToday.getFullYear() - minAge;
  const predefinedYear = earlistYearToBeBorn - predefinedAge + minAge;
  // Generate years to choose from
  let years = [];
  for (let i = latestYearToBeBorn; i < earlistYearToBeBorn + 1; i++) {
    years.push(i);
  }
  return {
    years: years,
    defaultYear: predefinedYear
  }
};

export const getBirthdayFromYear = (birthYear) => {
  const dateObject = new Date(birthYear, 0);
  return dateObject;
};

export const getYearFromTimestamp = (timestamp) => {
  if(timestamp && timestamp.seconds) {
    const dateObject = new Date(timestamp.seconds * 1000);
    const year = dateObject.getFullYear();
    return year;
  } else {
    return 'Invalid date format'
  }  
};

export const getYearFromMillis = (millis) => {
  if(millis) {
    const dateObject = new Date(millis);
    const year = dateObject.getFullYear();
    return year;
  } else {
    return 'Invalid date format'
  }  
};

export const validateString = (str) => {
  // strip numbers and special chars
  let string = str.replace(/[^a-zA-Z ]/g, "")
  // string is null or contains nothing
  if(!string) {
    return false;
  }
  // string only contains white space
  if(string.replace(/\s/g,"") === "") {
    return false;
  }
  // string is less than 2 characters
  if(string.length < 2) {
    return false;
  }
  // string passed validation
  return true;
};

export const debounce = (fn, ms = 0) => {
  let timeoutId;
  return function(...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const getCompetenceNames = (competencies) => {
  let competenceNames = [];
  if(allCompetencies && allCompetencies.length > 0 && competencies && competencies.length > 0) {
    competencies.forEach(competenceId => {
      allCompetencies.forEach(competence => {
        if(competenceId === competence.id) {
          competenceNames.push(competence);
        }
      });
    });
  }
  return competenceNames;
};

export const getCompetenceName = (competenceId) => {
  let name = '';
  if(allCompetencies && allCompetencies.length > 0 && competenceId) {
    allCompetencies.forEach(competence => {
      if(competenceId === competence.id) {
        name = i18next.language === 'en' ? competence.title_en : competence.title
      }
    });
  }
  return name;
};

export const getRandomHexColor = () => {
  const number = Math.floor(Math.random()*16777215).toString(16);
  const randomColor = "#" + number;
  return randomColor;
};