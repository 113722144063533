import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import WithUser from './HOC/WithUser';
import PrivateRoute from './PrivateRoute';

import Login from './Login';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import EmailValidation from './EmailValidation';
import SignUpFlow from './SignUp/SignUpFlow';
import PageHome from './Pages/PageHome';
import PageConversations from './Pages/PageConversations';
import PageProfile from './Pages/PageProfile';
import PageInformation from './Pages/PageInformation';
import PageAchievements from './Pages/PageAchievements';
import PageAdmin from './Pages/PageAdmin';
import PageCollabs from './Pages/PageCollabs';
import PageHelp from './Pages/PageHelp';
import Conversation from './Conversation';
import PublicProfile from './PublicProfile';
import ScrollToTop from './ScrollToTop';

const AllRoutes = (props) => {
  const authenticated = props.user ? true : false;
  // console.log("User logged in: ", authenticated);
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          {/* Public routes */}
          <Route exact path='/login' render={(props) => <Login {...props} inline={false} loggedIn={authenticated}/>} />
          <Route exact path='/signup' component={SignUp}/>
          <Route exact path='/glemt-password' component={ForgotPassword}/>
          <Route exact path='/signups/:id' component={SignUpFlow}/>
          <Route exact path='/validate/:id' component={EmailValidation} />
          {/* Private routes */}
          <PrivateRoute exact path='/' component={PageHome} authenticated={authenticated}/>
          <PrivateRoute exact path='/conversations' component={PageConversations} authenticated={authenticated}/>
          <PrivateRoute exact path='/conversations/:id' component={Conversation} authenticated={authenticated}/>
          <PrivateRoute exact path='/profil' component={PageProfile} authenticated={authenticated}/>
          <PrivateRoute exact path='/profil/:subpage' component={PageProfile} authenticated={authenticated}/>
          <PrivateRoute exact path='/information' component={PageInformation} authenticated={authenticated}/>
          <PrivateRoute exact path='/achievements' component={PageAchievements} authenticated={authenticated}/>
          <PrivateRoute exact path='/cmadmin' component={PageAdmin} authenticated={authenticated}/>
          <PrivateRoute exact path='/collabs' component={PageCollabs} authenticated={authenticated}/>
          <PrivateRoute exact path='/profile/:id' component={PublicProfile} authenticated={authenticated}/>
          <PrivateRoute exact path='/help' component={PageHelp} authenticated={authenticated}/>
          {/* 404 */ }
          <Route render={() => <Redirect to={'/'}/> } />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default WithUser(AllRoutes);