import React, { Component } from 'react';
import classNames from 'classnames';

import { firestore, storage } from '../firebase';

import { getLocationName } from '../util';
import { WithTrans } from '../i18n/i18nWrapper';

import WithUser from './HOC/WithUser';

import Tooltip from './Tooltip';
import ProfileGeneral from './ProfileGeneral';
import ProfileMentee from './ProfileMentee';
import ProfileMentor from './ProfileMentor';
import ProfilePreferences from './ProfileOther';
import Modal from './Modal';
import ImageUpload from './ImageUpload';
import avatarPlaceholder from '../assets/avatar-placeholder.jpg';

import icons from '../assets/icons.svg';
const owlIcon = icons + '#owl';
const sproutIcon = icons + '#sprout';
const LinkedInIcon = icons + '#linkedin';
const rocketIcon = icons + '#rocket-two';

class Profile extends Component {

  state = {
    subpage: 1,
    profilepictureModalOpen: false,
    newUserPhoto: false,
    loading: false,
    uploadError: false,
    uploadSuccess: false,
  }

  navigate(id)  {
    this.setState({ subpage: id });
  }

  componentDidMount() {
    if(this.props.subpage) {
      if(this.props.subpage === 'mentor') {
        this.setState({ subpage: 3 });
      }
      if(this.props.subpage === 'mentee') {
        this.setState({ subpage: 2 });
      }
    }
  }

  toggleProfilepictureModal() {
    this.setState(prevState => ({
      profilepictureModalOpen: !prevState.profilepictureModalOpen
    }));
  }

  handleFileChange = async (file) => {
    if(!file) {
      // no file was chosen
      return;
    } 
    // Check if actually an image
    if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type ===  'image/x-png' || file.type ===  'image/png') {
      // Is a image file in jpeg, jpg or png format
    } else {
      // Not a valid image file!
      alert(this.props.t('upload_image.not_image_feedback'));
      return;
    }
    // Check for size
    if(file.size > 5000000) {
      // File is bigger than 5mb (5000000 bytes)
      alert(this.props.t('upload_image.image_too_large_feedback'));
      return;
    }
    // Front end validation passed so put in file
    this.setState({
      newUserPhoto: URL.createObjectURL(file)
    })
  }

  cancelPhoto() {
    this.setState({ newUserPhoto: false })
  }

  saveNewPhoto = async () => {
    if(this.state.loading) {
      return;
    }
    if(!this.state.newUserPhoto) {
      return;
    }
    this.setState({ loading: true });
    try {
      let imageBlob = await fetch(this.state.newUserPhoto).then(r => r.blob());

      // Generate file name
      const d = new Date();
      const fileName = this.props.user.name + '-' + d.getTime();

      await storage
      .ref()
      .child(this.props.user.userBucketName)
      .child(fileName)
      .put(imageBlob)
      .then(response => response.ref.getDownloadURL())
      .then((photoURL) => {
        // Save new photo url back to user
        const currentUserDocumentRef = firestore.collection('users').doc(this.props.user.uid);
        currentUserDocumentRef.get().then((docSnapshot) => {
          if (docSnapshot.exists) {
            currentUserDocumentRef.update({
              profileImageURL: photoURL
            }).then(() => {
              // Updated photo
              this.setState({ uploadSuccess: true, uploadError: false, loading: false  });
            }).catch(function(error) {
              // Something went wrong
              console.error("Error: ", error);
              this.setState({ uploadError: true, uploadSuccess: false, loading: false });
            });
          }
        }).catch(function(error) {
          // Something went wrong
          console.error("Error: ", error);
          this.setState({ uploadError: true, uploadSuccess: false, loading: false });
        });

      });
    }
    catch(err) {
      console.error(err);
      this.setState({ uploadError: true, uploadSuccess: false, loading: false });
    }
  }

  renderSubPage() {
    switch(this.state.subpage) {
      case 1:
        return <ProfileGeneral user={this.props.user} />;
      case 2:
        return <ProfileMentee user={this.props.user} />;
      case 3:
        return <ProfileMentor user={this.props.user} />;
      case 4:
        return <ProfilePreferences user={this.props.user} />;
      default:
        return <ProfileGeneral user={this.props.user} />;
    }
  }
  
  render() {
    const { name, title, company, location, mentee, mentor, linkedInUrl, profileImageURL } = this.props.user;
    const locationName = getLocationName(location);
    let image = profileImageURL ? profileImageURL : avatarPlaceholder;
    let entrepreneur = false;
    if(mentor) {
      if(this.props.user.mentorProfile.startupExperience ) {
        entrepreneur = true;
      }
    }
    if(this.state.newUserPhoto) {
      image = this.state.newUserPhoto;
    }
    const newProfileImageClass = classNames('Profile__newimage', {
      'Profile__newimage--loading': this.state.loading,
    });
    return (
      <article className="Profile">
        <div className="Profile__top">
          <figure className="Profile__image">
            <button onClick={() => this.toggleProfilepictureModal()}>
              <img src={image} alt={name}/>
              <p>{this.props.t('upload_image.change_image')}</p>
              {/* <input type="file" name="avatar" id="avatar" onChange={ (e) => this.handleFileChange(e.target.files[0]) } accept="image/x-png,image/jpeg,image/jpg"/> */}
            </button>
          </figure>
          {this.state.newUserPhoto ? (
            <div className={newProfileImageClass}>
              {!this.state.uploadError && !this.state.uploadSuccess ? (
                <>
                  <button className="Button" onClick={() => this.saveNewPhoto()}>{this.props.t('upload_image.save_new_image')}</button>
                  <button className="Button Button--green--outline" onClick={() => this.cancelPhoto()}>{this.props.t('upload_image.cancel')}</button>
                </>
              ) : null }
              {this.state.uploadError ? (
                <div className="Form__errorMessage Form__errorMessage--showMessage">
                  <p>{this.props.t('upload_image.error')}</p>
                </div>
              ) : null }
              {this.state.uploadSuccess ? (
                <div className="Form__successMessage Form__successMessage--showMessage">
                  <p>{this.props.t('upload_image.success')}</p>
                </div>
              ) : null }
            </div>
          ) : null}
          <div className="Profile__badge">
            {mentor ? (
              <Tooltip text={this.props.t('mentor')} placement="bottom">
                <svg className="RequestPreview__type">
                  <use xlinkHref={owlIcon}></use>
                </svg>
              </Tooltip>
            ) : null}
            {mentee ? (
              <Tooltip text={this.props.t('mentee')} placement="bottom">
                <svg className="RequestPreview__type">
                  <use xlinkHref={sproutIcon}></use>
                </svg>
              </Tooltip>
            ) : null}
            {entrepreneur ? (
              <Tooltip text={this.props.t('entrepreneur')} placement="bottom">
                <svg className="RequestPreview__type">
                  <use xlinkHref={rocketIcon}></use>
                </svg>
              </Tooltip>
            ) : null}
          </div>
          <div>
            <h1 className="Profile__name">{name}</h1>
            <h3 className="Profile__title"><span>{title}</span> | <span>{company}</span></h3>
            <p className="Profile__location">{locationName}</p>
            {linkedInUrl && linkedInUrl !== '#' ? (
              <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
                <svg>
                  <use xlinkHref={LinkedInIcon}></use>
                </svg>
              </a>
            ) : null}
          </div>
        </div>
        <div className="Profile__inner bg-white">
          <nav className="Profile__nav">
            <ul className="list-unstyled">
              <li>
                <button data-active={this.state.subpage === 1} onClick={() => this.navigate(1)}>
                  <span>{this.props.t('profile.general')}</span>
                </button>
              </li>
              {mentee ? (
                <li>
                  <button data-active={this.state.subpage === 2} onClick={() => this.navigate(2)}>
                    <span>{this.props.t('profile.mentee_settings')}</span>
                  </button>
                </li>
              ) : null}
              {mentor ? (
                <li>
                  <button data-active={this.state.subpage === 3} onClick={() => this.navigate(3)}>
                    <span>{this.props.t('profile.mentor_settings')}</span>
                  </button>
                </li>
              ) : null}
              <li>
                <button data-active={this.state.subpage === 4} onClick={() => this.navigate(4)}>
                  <span>{this.props.t('profile.other_settings')}</span>
                </button>
              </li>
            </ul>
          </nav>
          <div className="Profile__page">
            {this.renderSubPage()}
          </div>
        </div>
        <Modal toggleModal={() => this.toggleProfilepictureModal()} open={this.state.profilepictureModalOpen} title={this.props.t('upload_image.upload_image')}>
          <ImageUpload closeImageUploadModal={() => this.toggleProfilepictureModal()} inSignup={false}/>
        </Modal>
      </article>
    )
  }

}

export default WithUser(WithTrans(Profile));