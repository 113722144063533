export const checkForMissingStepThreeInputs = (inputs, isMentor, isMentee, translation) => {

  let errors = [];

  // Validate general fields (always required)
  // if(!inputs.image) {
  //   errors.push({
  //     message: 'Du mangler at uploade et profil billede',
  //     id: 'avatar-label'
  //   });
  // }
  if(!inputs.name) {
    errors.push({
      message: translation('signup.error_no_name'),
      id: 'name'
    });
  }
  if(!inputs.gender) {
    errors.push({
      message: translation('signup.error_no_gender'),
      id: 'gender'
    });
  }
  if(!inputs.status) {
    errors.push({
      message: translation('signup.error_no_employment'),
      id: 'status'
    });
  }
  if(!inputs.title) {
    errors.push({
      message: translation('signup.error_no_title'),
      id: 'title'
    });
  }
  if(!inputs.company) {
    errors.push({
      message: translation('signup.error_no_company'),
      id: 'company'
    });
  }
  if(!inputs.location) {
    errors.push({
      message: translation('signup.error_no_location'),
      id: 'location'
    });
  }
  if(!inputs.birthday) {
    errors.push({
      message: translation('signup.error_no_birthyear'),
      id: 'birthday'
    });
  }
  if(!inputs.linkedinurl) {
    errors.push({
      message: translation('signup.error_no_linkedin'),
      id: 'linkedinurl'
    });
  }
  
  if(isMentor) {
    if(!inputs.mentorExperience) {
      errors.push({
        message: translation('signup.error_no_mentor_experience'),
        id: 'mentorexperience'
      });
    }
    if(!inputs.mentorText) {
      errors.push({
        message: translation('signup.error_no_mentor_profile_text'),
        id: 'mentor-text'
      });
    }
  }

  if(isMentee) {
    if(!inputs.menteeText) {
      errors.push({
        message: translation('signup.error_no_mentee_profile_text'),
        id: 'menteeText'
      });
    }
    // if(!inputs.menteePreferenceGender) {
    //   errors.push({
    //     message: 'Du mangler at vælge din præference for mentor køn',
    //     id: 'mentorgender'
    //   });
    // }
    if(!inputs.menteePreferenceCopenhagen && !inputs.menteePreferenceZealand && !inputs.menteePreferenceFunen && !inputs.menteePreferenceJutlandNorth && !inputs.menteePreferenceJutlandMiddle && !inputs.menteePreferenceJutlandSouth) {
      errors.push({
        message: translation('signup.error_no_mentee_mentor_location_preference'),
        id: 'mentee-preference-location-wrapper'
      });
    }
  }

  return errors;
};

