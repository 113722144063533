import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import WithUser from './HOC/WithUser';
import { WithTrans } from '../i18n/i18nWrapper';

import { getMenteeUsers, getMentorUsers, analytics } from '../firebase';

import Matches from './Matches';
import Modal from './Modal';
import LogoIcon from './LogoIcon';

// person one
import person from '../assets/person-x32.jpg'
import personx2 from '../assets/person-x64.jpg'
import personx3 from '../assets/person-x96.jpg'
// person two
import persontwo from '../assets/persontwo-x32.jpg'
import persontwox2 from '../assets/persontwo-x64.jpg'
import persontwox3 from '../assets/persontwo-x96.jpg'
// person three
import personthree from '../assets/personthree-x32.jpg'
import personthreex2 from '../assets/personthree-x64.jpg'
import personthreex3 from '../assets/personthree-x96.jpg'
// person four
import personfour from '../assets/personfour-x32.jpg'
import personfourx2 from '../assets/personfour-x64.jpg'
import personfourx3 from '../assets/personfour-x96.jpg'
// person five
import personfive from '../assets/personfive-x32.jpg'
import personfivex2 from '../assets/personfive-x64.jpg'
import personfivex3 from '../assets/personfive-x96.jpg'
// person five
import personsix from '../assets/personsix-x32.jpg'
import personsixx2 from '../assets/personsix-x64.jpg'
import personsixx3 from '../assets/personsix-x96.jpg'
// person five
import personseven from '../assets/personseven-x32.jpg'
import personsevenx2 from '../assets/personseven-x64.jpg'
import personsevenx3 from '../assets/personseven-x96.jpg'
// person five
import personeight from '../assets/personeight-x32.jpg'
import personeightx2 from '../assets/personeight-x64.jpg'
import personeightx3 from '../assets/personeight-x96.jpg'
// person five
import personnine from '../assets/personnine-x32.jpg'
import personninex2 from '../assets/personnine-x64.jpg'
import personninex3 from '../assets/personnine-x96.jpg'
// person five
import personten from '../assets/personten-x32.jpg'
import persontenx2 from '../assets/personten-x64.jpg'
import persontenx3 from '../assets/personten-x96.jpg'
// person five
import personelleven from '../assets/personelleven-x32.jpg'
import personellevenx2 from '../assets/personelleven-x64.jpg'
import personellevenx3 from '../assets/personelleven-x96.jpg'


class Matchmaker extends Component {

  state = {
    searching: false,
    modalOpen: false,
    matches: [],
    minimumLoadTime: Math.floor((Math.random()*2000) + 2000)
  };

  startSearch(type) {
    this.setState({
      searching: true
    });
    if(type === 'mentor') {
      this.startMentorSearch();
    }
    if(type === 'mentee') {
      this.startMenteeSearch();
    }
  }

  toggleMatchesModal() {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }));
  }

  startMentorSearch = async () => {
    // Track mentor search event
    if(process.env.NODE_ENV === 'production') {
      analytics.logEvent('Start mentor search')
    }
    // Check if we already fetched and matched mentors before
    if (this.state.matches && this.state.matches.length > 1) {
      // We already got mentors
      setTimeout( () => {
        // Stop matching animation and open modal
        this.setState({
          searching: false,
          modalOpen: true,
        });
      }, this.state.minimumLoadTime);
    } else {
      // Get all user documents marked as mentor
      let mentors = await getMentorUsers();
      // Get current users mentor preferences
      const menteeProfile = this.props.user.menteeProfile;
      const menteeBirthyear = this.props.user.birthday.toMillis ? this.props.user.birthday.toMillis() : this.props.user.birthday;
      const preferenceAreas = menteeProfile.preferenceAreas;
      const preferenceExperience = menteeProfile.preferenceExperience;
      const preferenceGender = menteeProfile.preferenceGender;
      const preferenceCanBeYounger = menteeProfile.preferenceMentorCanBeYounger;
      const preferenceStartupExperience = menteeProfile.preferenceStartupExperience;
      const preferenceCompetencies = menteeProfile.preferenceCompetencies;
      // const preferenceIndustries = menteeProfile.preferenceIndustries;
      // Make ready array for matched mentor
      let matchedMentors = [];
      // Loop over mentors, match preferences with profile and give a MentorMatch score
      mentors.forEach(mentor => {
        // Exclude yourself and test users
        if (mentor.uid !== this.props.user.uid && mentor.environment === process.env.NODE_ENV) {
          // Exclude previously requested mentors
          if (this.props.user.requestedMentors.includes(mentor.uid) || mentor.requestedMentees.includes(this.props.user.uid)) {
            // Mentor already requested, or mentor has already requested mentee as mentee
          } else {
            if (this.props.user.ignoredMentors && this.props.user.ignoredMentors.includes(mentor.uid)) {
              // Mentor has previously been removed from search entries by mentee
            } else {
              // Start calculating match score
              let score = 0;
              let rating = 0;
              let matchingCriterias = [];
              let matchingCompetencies = [];
              // Calculate matching score my comparing values
              // Same area + 4
              // Gender + 2
              // Experience + 2
              // Startup exp + 4
              // Younger + 2
              // Competence + 2
              const mentorArea = mentor.location;
              const mentorGender = mentor.gender;
              const mentorBirthyear = mentor.birthday.toMillis ? mentor.birthday.toMillis() : mentor.birthday;
              const mentorExperience = mentor.mentorProfile.experience;
              const mentorStartupExperience = mentor.mentorProfile.startupExperience;
              const mentorCompetencies = mentor.mentorProfile.competencies;
              // const mentorIndustry = mentor.mentorProfile.industry;
              if(preferenceAreas.includes(mentorArea)) { score = score + 4; matchingCriterias.push('Lokation'); }
              if(mentorExperience >= preferenceExperience) { score = score + 2; matchingCriterias.push('Erhvervserfaring'); };
              if(preferenceStartupExperience === true && mentorStartupExperience === true) { score = score + 4; matchingCriterias.push('Startup erfaring'); }
              if(!preferenceCanBeYounger) {
                if(mentorBirthyear < menteeBirthyear) { score = score + 2; }
              }
              if(preferenceGender !== 'both' && (mentorGender === preferenceGender || 'other')) { score = score + 2}
              // if(preferenceIndustries.includes(mentorIndustry)) { score = score + 2}
              if(preferenceCompetencies && preferenceCompetencies.length > 0 && mentorCompetencies && mentorCompetencies.length > 0) {
                preferenceCompetencies.forEach(preferenceCompetenceId => {
                  if(mentorCompetencies.includes(preferenceCompetenceId)) {
                    score = score + 2;
                    matchingCompetencies.push(preferenceCompetenceId);
                  }
                });
              }
              // Match rating is the following based on score; 0-11: OK[0], 12-18: Good[1], 19+: TopMatch[2]
              if (score < 11) {rating = 0}
              if (score > 11 && score < 19) {rating = 1}
              if (score > 18) {rating = 2}
              // Store score/rating/competencies on mentor
              mentor.MentorMatchScore = score;
              mentor.MentorMatchRating = rating;
              mentor.MentorMatchCriterias = {
                criterias: matchingCriterias,
                competencies: matchingCompetencies
              }
              // Push matched mentor with score to matched mentors array
              matchedMentors.push(mentor);
            }
          }
        }
      });
      // Sort array of matched mentors by matching score
      matchedMentors.sort((a, b) => b.MentorMatchScore - a.MentorMatchScore);
      // Limit result to 20
      if(matchedMentors.length > 20) {
        matchedMentors.length = 20;
      }
      // Show matched mentors in modal
      this.setState({
        matches: matchedMentors,
      });
      // Ensure we at least see animation for 1.5 seconds
      setTimeout( () => {
        // Stop matching animation and open modal
        this.setState({
          searching: false,
          modalOpen: true,
        });
      }, this.state.minimumLoadTime);
    }
  }

  startMenteeSearch = async () => {
    // Track mentee search event
    if(process.env.NODE_ENV === 'production') {
      analytics.logEvent('Start mentee search')
    }
    // Check if we already fetched and matched mentees before
    if (this.state.matches && this.state.matches.length > 1) {
      // We already got mentees
      setTimeout( () => {
        // Stop matching animation and open modal
        this.setState({
          searching: false,
          modalOpen: true,
        });
      }, this.state.minimumLoadTime);
    } else {
      // Get all user documents marked as mentee
      let mentees = await getMenteeUsers();
      // console.log(mentees);
      // Get current mentor user info
      const mentorProfile = this.props.user.mentorProfile;
      const mentorGender = this.props.user.gender;
      const mentorBirthyear = this.props.user.birthday.toMillis ? this.props.user.birthday.toMillis() : this.props.user.birthday;
      const mentorLocation = this.props.user.location;
      const mentorExperience = mentorProfile.experience;
      const mentorCompetencies = mentorProfile.competencies;
      // const mentorIndustry = mentorProfile.industry;
      const mentorStartupExperience = mentorProfile.startupExperience;
      // Make ready array for matched mentor
      let matchedMentees = [];
      // Loop over mentors, match preferences with profile and give a MentorMatch score
      mentees.forEach(mentee => {
        // Exclude yourself and test users
        if (mentee.uid !== this.props.user.uid && mentee.environment === process.env.NODE_ENV) {
          // Exclude mentees who this mentor already has requested, or mentees who already requested this mentor
          if ( this.props.user.requestedMentees.includes(mentee.uid) || mentee.requestedMentors.includes(this.props.user.uid) ) {
            // Mentee already requested, or mentee has already send a request to mentor
          } else {
            if(this.props.user.ignoredMentees && this.props.user.ignoredMentees.includes(mentee.uid)) {
              // Mentee has previously been removed from search entries by mentor
            } else {
              // Start calculating match score
              let score = 0;
              let rating = 0;
              let matchingCriterias = [];
              let matchingCompetencies = [];
              // Calculate matching score my comparing values
              // Same area + 4
              // Gender + 2
              // Experience + 2
              // Startup exp + 4
              // Younger + 2
              // Competence + 2
              const menteeBirthyear = mentee.birthday.toMillis ? mentee.birthday.toMillis() : mentee.birthday;
              const menteeProfile = mentee.menteeProfile;
              const menteepreferenceAreas = menteeProfile.preferenceAreas;
              const menteepreferenceExperience = menteeProfile.preferenceExperience;
              const menteepreferenceGender = menteeProfile.preferenceGender;
              const menteepreferenceCanBeYounger = menteeProfile.preferenceMentorCanBeYounger;
              const menteepreferenceStartupExperience = menteeProfile.preferenceStartupExperience;
              const menteepreferenceCompetencies = menteeProfile.preferenceCompetencies;
              // const menteepreferenceIndustries = menteeProfile.preferenceIndustries;
              if(menteepreferenceAreas.includes(mentorLocation)) { score = score + 4; matchingCriterias.push('Lokation'); }
              if(mentorExperience >= menteepreferenceExperience) { score = score + 2; matchingCriterias.push('Erhvervserfaring'); };
              if(menteepreferenceStartupExperience === mentorStartupExperience) { score = score + 4; matchingCriterias.push('Startup erfaring'); }
              if(!menteepreferenceCanBeYounger) {
                if(mentorBirthyear < menteeBirthyear) { score = score + 2; }
              }
              if(menteepreferenceGender !== 'both' && (mentorGender === menteepreferenceGender || 'other')) { score = score + 2}
              // if(menteepreferenceIndustries.includes(mentorIndustry)) { score = score + 2}
              if(mentorCompetencies && mentorCompetencies.length > 0 && menteepreferenceCompetencies && menteepreferenceCompetencies.length > 0) {
                mentorCompetencies.forEach(mentorCompetenceId => {
                  menteepreferenceCompetencies.forEach(menteeCompetenceId => {
                    if(mentorCompetenceId === menteeCompetenceId) {
                      score = score + 2;
                      matchingCompetencies.push(mentorCompetenceId);
                    }
                  });
                });
              }
              // Match rating is the following based on score; 0-11: OK[0], 12-18: Godt[1], 19+: Top[2]
              if (score < 11) {rating = 0}
              if (score > 11 && score < 19) {rating = 1}
              if (score > 18) {rating = 2}
              // Store score/rating/competencies on mentor
              mentee.MentorMatchScore = score;
              mentee.MentorMatchRating = rating;
              mentee.MentorMatchCriterias = {
                criterias: matchingCriterias,
                competencies: matchingCompetencies
              }
              // Push matched mentor with score to matched mentees array
              matchedMentees.push(mentee);
            }
          }
        }
      });
      // Sort array of matched mentees by matching score
      matchedMentees.sort((a, b) => b.MentorMatchScore - a.MentorMatchScore);
      // Limit result to 20
      if(matchedMentees.length > 20) {
        matchedMentees.length = 20;
      }
      // Show matched mentors in modal
      this.setState({
        matches: matchedMentees,
      });
      // Ensure we at least see animation for 1.5 seconds
      setTimeout( () => {
        // Stop matching animation and open modal
        this.setState({
          searching: false,
          modalOpen: true,
        });
      }, this.state.minimumLoadTime);
    }

  }

  render() {
    const matchAnimationClass = classNames('MatchAnimation', {
      'MatchAnimation--searching': this.state.searching,
    });
    const buttonClass = classNames('Button', {
      'Button--searching': this.state.searching,
    });
    const buttonText = this.state.searching ? this.props.t('matchMaker.matching') : this.props.t('matchMaker.start');
    const arrayOfAvatars = [
      [person, personx2, personx3],
      [persontwo, persontwox2, persontwox3],
      [personthree, personthreex2, personthreex3],
      [personfour, personfourx2, personfourx3],
      [personfive, personfivex2, personfivex3],
      [personsix, personsixx2, personsixx3],
      [personseven, personsevenx2, personsevenx3],
      [personeight, personeightx2, personeightx3],
      [personnine, personninex2, personninex3],
      [personten, persontenx2, persontenx3],
      [personelleven, personellevenx2, personellevenx3],
    ]
    const personOne = arrayOfAvatars[Math.floor(Math.random() * arrayOfAvatars.length)];
    const personTwo = arrayOfAvatars[Math.floor(Math.random() * arrayOfAvatars.length)];
    const personThree = arrayOfAvatars[Math.floor(Math.random() * arrayOfAvatars.length)];
    const personFour = arrayOfAvatars[Math.floor(Math.random() * arrayOfAvatars.length)];
    const personFive = arrayOfAvatars[Math.floor(Math.random() * arrayOfAvatars.length)];
    return (
      <>
        <div className="Matchmaker">
          <div className={matchAnimationClass}>
            <div className="MatchAnimation__inner">
              <img src={personOne[0]} srcSet={`${personOne[0]} 1x, ${personOne[1]} 2x, ${personOne[2]} 3x`} alt="Avatar"/>
              <img src={personTwo[0]} srcSet={`${personTwo[0]} 1x, ${personTwo[1]} 2x, ${personTwo[2]} 3x`} alt="Avatar"/>
              <img src={personThree[0]} srcSet={`${personThree[0]} 1x, ${personThree[1]} 2x, ${personThree[2]} 3x`} alt="Avatar"/>
              <img src={personFour[0]} srcSet={`${personFour[0]} 1x, ${personFour[1]} 2x, ${personFour[2]} 3x`} alt="Avatar"/>
              <img src={personFive[0]} srcSet={`${personFive[0]} 1x, ${personFive[1]} 2x, ${personFive[2]} 3x`} alt="Avatar"/>
              <div className="MatchAnimation__center">
                <LogoIcon/>
                <div className="MatchAnimation__pulse"></div>
              </div>
              <div className="MatchAnimation__circles">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          <div className="Matchmaker__start">
            <button className={buttonClass} onClick={() => this.startSearch(this.props.type)}>{buttonText}</button>
          </div>
          {
            this.props.type === 'mentor' ? (
              <p>{this.props.t('matchMaker.or')} <Link to="/profil/mentee">{this.props.t('matchMaker.configure_preferences')}</Link></p>
            ) : null
          }
          {
            this.props.type === 'mentee' ? (
              <p>{this.props.t('matchMaker.or')} <Link to="/profil/mentor">{this.props.t('matchMaker.configure_mentor_profile')}</Link></p>
            ) : null
          }
        </div>
        <Modal toggleModal={() => this.toggleMatchesModal()} open={this.state.modalOpen} title={this.props.t('matchMaker.matches')}>
          <Matches matches={this.state.matches} closeRequestModal={() => this.toggleMatchesModal()} requestType={this.props.type} />
        </Modal>
      </>
    )
  }

}

export default WithUser(WithTrans(Matchmaker));