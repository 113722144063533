import React, { useState } from 'react';

import { WithTrans } from '../i18n/i18nWrapper';

import PopupMatch from './PopupMatch';
import Modal from './Modal';

const Popup = (props) => {
  const [open, setOpen] = useState(true);
  const popup = props.popup;
  let modalTitle = '';
  if(popup.type === 'newMatch') { modalTitle = props.t('popups.new_mentormatch') }
  return (
    <Modal toggleModal={() => setOpen(false)} open={open} title={modalTitle}>
      { popup.type === 'newMatch' ? (
        <PopupMatch popup={popup} toggleModal={() => setOpen(false)} />
      ) : null }
    </Modal>
  )
}

export default WithTrans(Popup);