import React from 'react'
import classNames from 'classnames';

const Module = (props) => {
  const moduleClass = classNames('Module', {
    'Module--transparent': props.transparent,
    'Module--wide': props.wide
  });
  return (
    <div className={moduleClass}>
      <div className="Module__title border-gray">
        <h3>{props.title}</h3>
      </div>
      <div className="Module__content border-gray bg-white">
        {props.children}
      </div>
    </div>
  )
}

export default Module;