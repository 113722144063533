import React from 'react'

import Collaborations from '../Collaborations';
import Module from '../Module';

import { WithTrans } from  '../../i18n/i18nWrapper';

const PageCollabs = (props) => {
  return (
    <div className="Page Collaborations__wrapper">
      <Module title={props.t('collabs.collabs_title')} transparent>
        <p style={{maxWidth: 1000}}>{props.t('collabs.collabs_intro')}</p>
        <Collaborations />
      </Module>
    </div>
  )
}

export default WithTrans(PageCollabs);