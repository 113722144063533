import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { WithTrans } from  '../../i18n/i18nWrapper';

class StepFive extends Component {  
  render() {
    return this.props.userDocumentReady
    ? <Redirect to="/" />
    : (
      <div className="SignUpStep">
        <div className="SignUpStep__content">
          <h2 className="SignUpStep__title">{this.props.t('signup.thank_you_welcome')}</h2>
          <p>{this.props.t('signup.your_profile_is_created')} <span role="img" aria-label="Thumbs up">👍</span><br></br> {this.props.t('signup.please_wait_a_moment')}</p>
        </div>
      </div>
    )
  };

};

export default WithTrans(StepFive);


