import React from 'react'

import Module from '../Module';
import Help from '../Help';
import Faqs from '../Faqs';

const PageHelp = () => {
  return (
    <div className="Page">
      <Module title="Feedback">
        <Help />
      </Module>
      <Module title="FAQ">
        <Faqs />
      </Module>
    </div>
  )
}

export default PageHelp;