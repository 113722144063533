import React, { Component } from 'react';
import { forgotPassword } from '../firebase';
import { Link } from 'react-router-dom';

import { WithTrans } from '../i18n/i18nWrapper';

import Logo from './Logo';
import image from '../assets/login.jpg'
import imagex2 from '../assets/login-x2.jpg';
import imagex3 from '../assets/login-x3.jpg';
import imagex4 from '../assets/login-x4.jpg';

class ForgotPassword extends Component {

  state = {
    loading: false,
    submitBtnText: this.props.t('forgot_password.reset_your_password'),
    email: '',
    errorCode: false,
    passwordResetMailSend: false,
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({
      loading: true,
      submitBtnText: this.props.t('wait'),
    });

    const { email } = this.state; 
    const forgotPasswordSuccess = await forgotPassword(email);

    if (forgotPasswordSuccess.error) {
      const errorCode = forgotPasswordSuccess.error;
      if (errorCode === 'auth/user-not-found') {
        this.setState({ errorCode: 1 });
      } else if(errorCode === 'auth/invalid-email') {
        this.setState({ errorCode: 2 });
      } else {
        this.setState({ errorCode: 3 });
      }
      this.setState({ loading: false, submitBtnText: this.props.t('forgot_password.reset_your_password') });
    }

    if (forgotPasswordSuccess === true) {
      this.setState({ 
        loading: false,
        passwordResetMailSend: true,
        submitBtnText: this.props.t('forgot_password.reset_your_password'),
        email: '', 
      });
    }

  };

  render() {
    const { email, passwordResetMailSend } = this.state;
    return (
      <div className="Login">
        <div className="Login__wrapper">
          <div className="Login__inner">
            <Logo />
            <div className="Login__form">
              <h1 className="Login__title">{this.props.t('forgot_password.forgot_password')}</h1>
              <form className="Form" onSubmit={this.handleSubmit}>
                <label htmlFor="email">{this.props.t('forgot_password.write_your_user_email')}</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="navn@domæne.dk"
                  value={email}
                  onChange={this.handleChange}
                  required
                />
                {passwordResetMailSend ? (
                  <div className="Form__successMessage Form__successMessage--showMessage">
                    <p>{this.props.t('forgot_password.we_have_send_you_a_link')}</p>
                  </div>
                ) : (
                  <>
                    <input type="submit" className="Button" value={this.state.submitBtnText} disabled={this.state.loading} style={{ cursor: this.state.loading ? 'wait' : 'auto'}} />
                    {this.state.errorCode === 1 ? <div className="Form__errorMessage Form__errorMessage--showMessage"><p>{this.props.t('forgot_password.error_no_user_found')}</p></div> : null }
                    {this.state.errorCode === 2 ? <div className="Form__errorMessage Form__errorMessage--showMessage"><p>{this.props.t('forgot_password.error_invalid_email')}</p></div> : null }
                    {this.state.errorCode === 3 ? <div className="Form__errorMessage Form__errorMessage--showMessage"><p>{this.props.t('forgot_password.error_unknown')}</p></div> : null }
                  </>
                ) }

              </form>
              <div>
                <p className="SignUp__alternate"><Link to="/login">{this.props.t('forgot_password.go_to_login')}</Link></p>
              </div>
            </div>
          </div>
        </div>
        <div className="Login__media">
          <img 
            src={imagex2}
            alt="Mentor and mentee talking"
            srcSet={`
              ${image} 480w,
              ${imagex2} 900w,
              ${imagex3} 1200w,
              ${imagex4} 1600w
            `}
            sizes="(min-width: 1600px) 80vw, (min-width: 1200px) 50vw, 100vw"
          />
        </div>
      </div>
    );
  }

}

export default WithTrans(ForgotPassword);

