import React from 'react'
import classNames from 'classnames';

import LogoIcon from './LogoIcon';
import avatarPlaceholder from '../assets/avatar-placeholder.jpg'

import { getDateString, getTime } from '../util';
import { useTranslation } from 'react-i18next';

const Message = (props) => {
  const { i18n } = useTranslation();
  const message = props.message;
  const messageClass = classNames('Message', {
    'Message--own': message.user === props.currentUserId,
    'Message--cm': message.user === 'careermentor',
  });
  const date = getDateString(message.timestamp, i18n.language);
  const time = getTime(message.timestamp);
  return (
    <div className={messageClass}>
      <figure className="Message__image">
        {message.user === 'careermentor' ? (
          <LogoIcon/>
        ) : (
          <img src={props.photo ? props.photo : avatarPlaceholder} alt={props.userName}/>
        )}
      </figure>
      <div className="Message__message">
        <p>{message.message}</p>
        <span className="Message__timestamp">{date} - {time}</span>
      </div>
    </div>
  )
}

export default Message;