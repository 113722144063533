import React, { Component, createContext } from 'react'
import { auth, getUserReference, firebasecore } from '../firebase';

import Loading from '../components/Loading';

export const UserContext = createContext({ user: null });

class UserProvider extends Component {

  state = {
    user: null,
    loading: true,
  };

  unsubscribeFromAuth = null;
  ubsubscribeFromUser = null;

  componentDidMount = async () => {
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
      if (userAuth) {
        const userRef = await getUserReference(userAuth.uid);
        // Subsribe to user
        this.ubsubscribeFromUser = userRef.onSnapshot(snapshot => {
          this.setState({ user: { uid: snapshot.id, email: userAuth.email, ...snapshot.data() }, loading: false })
        });
        // Update last login time
        userRef.get().then((docSnapshot) => {
          if (docSnapshot.exists) {
            userRef.update({ lastSignIn: firebasecore.firestore.FieldValue.serverTimestamp() }).then(() => {
              // Updated last login time
            });
          }
        }).catch((error) => {
          console.error("Error: ", error);
        });
      } else {
        if(this.ubsubscribeFromUser) {
          this.ubsubscribeFromUser();
        }
        this.setState({ loading: false, user: null });
      }
    });
  };

  componentWillUnmount = () => {
    if(this.unsubscribeFromAuth) {
      this.unsubscribeFromAuth();
    }
  };

  render() {
    const { user } = this.state;
    const { children } = this.props;
    return (
      <>
        {this.state.loading ? (
          <Loading />
        ) : (
          <UserContext.Provider value={user}>{children}</UserContext.Provider>
        )}
      </>
    )
  }
}

export default UserProvider;