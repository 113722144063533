import React from 'react';

const Sprout = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path d="M47.7,0.3C47.7,0.3,47.7,0.3,47.7,0.3C47.4,0,47.1,0,46.8,0c-4.8,1.1-16.2,4.1-19,7.8c-2.6,3.4-2.4,8.3,0.6,11.5
        c-2.4,4.2-4.1,8.9-4.9,13.7c-0.9-2.4-2.1-4.7-3.6-6.9c2.9-3.4,2.8-8.7-0.5-12C16.3,10.9,2.8,7.6,1.2,7.2C0.6,7-0.2,7.6,0,8.4
        c0.3,1.2,2.4,9.7,4.8,14.9c0.7,1.4,1.4,2.6,2,3.3c3.3,3.3,8.2,3.3,11.6,0.8c1.8,2.8,3.2,5.9,4.2,9.1c-6.1,0.7-11.5,4.4-14.2,10
        c-0.2,0.3-0.1,0.7,0.1,1C8.6,47.8,8.9,48,9.3,48h30.4c0.3,0,0.7-0.2,0.9-0.5c0.2-0.3,0.2-0.7,0.1-1c-2.9-6.1-8.9-9.9-15.6-10.1
        c0.5-5.6,2.2-11,4.9-15.9c3,2,6.9,1.9,9.8-0.1c0.5-0.3,0.9-0.7,1.3-1.1c3.2-3.2,6.5-16.7,6.9-18.2c0-0.1,0-0.2,0-0.3
        C48,0.7,47.9,0.5,47.7,0.3z M16.5,24.4C16.5,24.4,16.5,24.4,16.5,24.4l-0.9-0.9h-2.7c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5	h1.7l-0.8-0.8c0,0,0,0,0,0l-0.9-0.9h-2.7c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h1.7L11.2,19c0,0,0,0,0,0l-0.9-0.9H7.5 c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h1.7l-1.7-1.7c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l2.6,2.6l0,0l0.2,0.2	v-1.7c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v2.7l1.7,1.7v-1.7c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v2.7l1.7,1.7	v-1.7c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v2.7l1,1c-0.3,0.3-0.4,0.4-0.7,0.7L16.5,24.4z M40.4,11h-2.7l-0.9,0.9 c0,0,0,0,0,0l-0.8,0.8h1.7c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5h-2.7l-1.7,1.7h1.7c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5h-2.7 c-0.1,0.1-2.2,2.2-2.1,2.1C30,18,29.9,18,29.6,17.6l1-1v-2.7c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v1.7l0.2-0.2c0,0,0,0,0,0 l1.5-1.5v-2.7c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5V13l0.2-0.2c0,0,0,0,0,0l1.5-1.5V8.6c0-0.3,0.2-0.5,0.5-0.5	c0.3,0,0.5,0.2,0.5,0.5v1.7l0.2-0.2c0,0,0,0,0,0l2.6-2.6c0.2-0.2,0.5-0.2,0.7,0s0.2,0.5,0,0.7L38.7,10h1.7c0.3,0,0.5,0.2,0.5,0.5	C40.9,10.7,40.7,11,40.4,11z"/>
    </svg>
  )
}

export default Sprout;
