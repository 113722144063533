import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { signOut } from '../firebase';
import { WithTrans } from '../i18n/i18nWrapper';

import WithConversations from '../components/HOC/WithConversations';
import WithUser from '../components/HOC/WithUser';

import LogoIcon from './LogoIcon';

import icons from '../assets/icons.svg';
const HomeIcon = icons + '#home';
const MessagesIcon = icons + '#messages';
const ProfileIcon = icons + '#profile';
const TrophyIcon = icons + '#trophy';
const LogoutIcon = icons + '#logout';
const LightbulbIcon = icons + '#lightbulb';
const CompassIcon = icons + '#compass';
const InfoIcon = icons + '#infooutline';
const StatsIcon = icons + '#stats';

class Sidebar extends Component {

  sidebar = null;
  topbar = null;

  componentDidMount() {
    this.sidebar = document.getElementById("Sidebar");
    this.topbar = document.getElementById("Topbar");
  }

  closeSidebar() {
    if(this.sidebar.classList.contains('Sidebar--open')) {
      this.sidebar.classList.remove('Sidebar--open')
    }
    if(this.topbar.classList.contains('Sidebar--open')) {
      this.topbar.classList.remove('Sidebar--open')
    }
  }

  render() {
    const conversations = this.props.conversations;
    let aConversationIsUnread = false;
    if(conversations && conversations.length > 0) {
      conversations.forEach(conversation => {
        // User read key
        const userReadKey = 'lastRead-' + this.props.user.uid;
        // Check if user has a last-read-time on the conversation
        if(conversation[userReadKey]) {
          // The user has at some point read the conversation
          if (conversation.lastMessageTimestamp > conversation[userReadKey]) {
            // The last message is newer than last check time (most be unread)
            aConversationIsUnread = true;
          } else {
            // The last check time is newer than the last message (already read last message)
          }
        }
      })
    }
    return (
      <aside className="Sidebar" id="Sidebar">
        <nav onClick={() => this.closeSidebar()}>
          <Link to="/">
            <LogoIcon/>
          </Link>
          <ul className="Sidebar__nav list-unstyled">
            <li>
              <NavLink to="/" exact={true} activeClassName='current-page'>
                <svg>
                  <use xlinkHref={HomeIcon}></use>
                </svg>
                <span>{this.props.t('sidebar.home')}</span>
              </NavLink>
            </li>
            <li className={aConversationIsUnread ? 'UnreadConversation' : null}>
              <NavLink to="/conversations/" activeClassName='current-page'>
                <svg>
                  <use xlinkHref={MessagesIcon}></use>
                </svg>
                <span>{this.props.t('sidebar.conversations')}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/profil/" activeClassName='current-page'>
                <svg>
                  <use xlinkHref={ProfileIcon}></use>
                </svg>
                <span>{this.props.t('sidebar.profile')}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/collabs/" activeClassName='current-page'>
                <svg>
                  <use xlinkHref={CompassIcon}></use>
                </svg>
                <span>{this.props.t('sidebar.explore')}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/information/" activeClassName='current-page'>
                <svg>
                  <use xlinkHref={LightbulbIcon}></use>
                </svg>
                <span>{this.props.t('sidebar.tips')}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/achievements/" activeClassName='current-page'>
                <svg>
                  <use xlinkHref={TrophyIcon}></use>
                </svg>
                <span>{this.props.t('sidebar.trophies')}</span>
              </NavLink>
            </li>
            {this.props.user.cmadmin ? (
              <li>
                <NavLink to="/cmadmin/" activeClassName='current-page'>
                  <svg>
                    <use xlinkHref={StatsIcon}></use>
                  </svg>
                  <span>{this.props.t('sidebar.admin')}</span>
                </NavLink>
              </li>
            ) : null}
          </ul>
        </nav>
        <div className="Sidebar__bottom">
          <NavLink to="/help/" activeClassName='current-page'>
            <svg>
              <use xlinkHref={InfoIcon}></use>
            </svg>
            <span>{this.props.t('sidebar.info')}</span>
          </NavLink>
          <button onClick={signOut}>
            <svg>
              <use xlinkHref={LogoutIcon}></use>
            </svg>
            <span>{this.props.t('sidebar.logout')}</span>
          </button>
        </div>
      </aside>
    )
  }
}

export default WithUser(WithConversations(WithTrans(Sidebar)));