import React from 'react';

import Routes from './Routes';
import UserProvider from '../providers/UserProvider';
import ConversationsProvider from '../providers/ConversationsProvider';
import NewsProvider from '../providers/NewsProvider';
import CollaborationsProvider from '../providers/CollaborationsProvider';
import FaqsProvider from '../providers/FaqsProvider';

const Application = () => {
  return (
    <main className="Application">
      <UserProvider>
        <ConversationsProvider>
          <NewsProvider>
            <CollaborationsProvider>
              <FaqsProvider>
                <Routes />
              </FaqsProvider>
            </CollaborationsProvider>
          </NewsProvider>
        </ConversationsProvider>
      </UserProvider>
    </main>
  )
}

export default Application;