import React from 'react';

import Sidebar from './Sidebar';
import Topbar from './Topbar';

const Dashboard = (props) => {
  return (
    <div className="Dashboard">
      <Topbar />
      <Sidebar />
      <main className="Dashboard__inner">
        {props.children}
      </main>
    </div>
  );
}

export default Dashboard;