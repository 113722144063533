import React from 'react'

const Accordion = (props) => {
  return (
		<div className="Accordion">
			<input type="checkbox" id={ `accordion-${ props.id }` } className="Accordion__checkbox"/>
			<label htmlFor={ `accordion-${ props.id }` } aria-label="Toggle accordion" className="Accordion__label">
				<span>{ props.title }</span>
				<div className="Accordion__icon">
					<div></div>
					<div></div>
				</div>
			</label>
			<div className="Accordion__content">
        { props.content }
			</div>
		</div>
  )
}

export default Accordion;