import React, { Component } from 'react';

import { auth } from '../firebase';
import { Redirect, Link } from 'react-router-dom';
import { firebasefunctions } from '../firebase';
import { WithTrans } from '../i18n/i18nWrapper';

import Logo from './Logo';
import LangSwitcher from './LangSwitcher';

import image from '../assets/signup.jpg'
import imagex2 from '../assets/signup-x2.jpg';
import imagex3 from '../assets/signup-x3.jpg';
import imagex4 from '../assets/signup-x4.jpg';

class SignUp extends Component {

  state = { 
    email: '', 
    emailValid: false, 
    checked: false,
    loading: false,
    submitBtnText: '',
    submitBtnTextUpdate: false,
    redirect: false,
    signUpID: 0,
  };

  handleChange = event => {
    const { value } = event.target;
    this.setState({ email: value });
  };

  validateEmail = email => {
    let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    this.setState({ emailValid: emailValid ? true : false });
    return emailValid;
  }

  handleClick = event => {
    this.setState({ checked: !this.state.checked });
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={`/signups/${this.state.signUpID}`} /> 
    }
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ loading: true, submitBtnTextUpdate: true, submitBtnText: this.props.t('signup.one_moment') + '.' });
    setTimeout( () => { this.setState({ submitBtnText: this.props.t('signup.one_moment') + '..' }) }, 300);
    setTimeout( () => { this.setState({ submitBtnText: this.props.t('signup.one_moment') + '...' }) }, 600);

    const { email } = this.state;
    let validEmail = this.validateEmail(email);

    // If valid email
    if(validEmail) {
      // Check if user with e-mail already exist      
      await auth.fetchSignInMethodsForEmail(email).then(providers => {
        let newUser = true;
        if (providers.length !== 0) { newUser = false; }
        if(!newUser) {
          // User already exist
          newUser = false;
          setTimeout( () => {
            this.setState({ loading: false, submitBtnText: this.props.t('signup.create_free_account') });
          }, 600);
          alert(this.props.t('signup.error_email_already_exist'));
          return;
        }
        // We have a new user
        if(newUser) {
          const language = this.props.i18n.language
          const createNewSignup = firebasefunctions.httpsCallable('createNewSignup');
          createNewSignup({email: email, env: process.env.NODE_ENV, language}).then(dataObject => {
            const signupID = dataObject.data.signupID;
            this.setState({ signUpID: signupID });
            setTimeout( () => {
              this.setState({ 
                submitBtnText: this.props.t('signup.thank_you'), 
                email: '', 
                emailValid: true, 
                loading: false, 
                checked: false 
              }) 
            }, 600);
            setTimeout( () => { this.setState({ redirect: true }) }, 1000);
          }).catch((error) => {
            alert(this.props.t('signup.error_something_went_wrong') + ' Signup 2');
            console.log(error)
          });
        }
      }).catch((error) => {
        alert(this.props.t('signup.error_something_went_wrong') + ' Signup');
        console.log(error)
      });
    } else {
      // Email invalid
      setTimeout( () => {
        this.setState({ loading: false, submitBtnText: this.props.t('signup.create_free_account') });
      }, 600);
      alert(this.props.t('signup.error_invalid_email'));
    }
  };

  render() {
    const { email } = this.state;
    return (
      <>
        {this.renderRedirect()}
        <div className="SignUp">
          <div className="SignUp__wrapper">
            <div className="SignUp__inner">
              <Logo />
              <div className="SignUp__form">
                <h1 className="SignUp__title">{this.props.t('signup.signup_title')}</h1>
                <form className="Form" onSubmit={this.handleSubmit}>
                  <label htmlFor="email">{this.props.t('signup.your_email')}</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder={this.props.t('signin.email_placeholder')}
                    value={email}
                    onChange={this.handleChange}
                    required
                  />
                  <div className="checkbox-wrapper Form__checkbox">
                    <input
                      type="checkbox"
                      id="consent"
                      name="consent"
                      defaultChecked={this.state.checked}
                      onClick={this.handleClick}
                      required
                    />
                    <label htmlFor="consent">{this.props.t('signup.i_accept')} <a href="https://careermentor.dk/privatlivspolitik-og-betingelser/" target="_blank" rel="noopener noreferrer">{this.props.t('signup.conditions_and_terms')}</a></label>
                  </div>
                  <input type="submit" className="Button" value={this.state.submitBtnTextUpdate ? this.state.submitBtnText : this.props.t('signup.create_free_account') } disabled={this.state.loading} style={{ cursor: this.state.loading ? 'wait' : 'auto'}} />
                </form>
                <div>
                  <p className="SignUp__alternate">{this.props.t('signup.already_got_profile')} <Link to="/login">{this.props.t('signup.login')}</Link></p>
                </div>
                <LangSwitcher loginStyle />
              </div>
            </div>
          </div>
          <div className="SignUp__media">
            <img 
              src={imagex2}
              alt="Mentor and mentee talking"
              srcSet={`
                ${image} 480w,
                ${imagex2} 900w,
                ${imagex3} 1200w,
                ${imagex4} 1600w
              `}
              sizes="(min-width: 1600px) 80vw, (min-width: 1200px) 50vw, 100vw"
            />
          </div>
        </div>
      </>
    );
  }

}

export default WithTrans(SignUp);