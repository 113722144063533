import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import avatarPlaceholder from '../assets/avatar-placeholder.jpg'

import { firestore, firebasefunctions, analytics } from '../firebase';
import { getLocationName, getCompetenceNames } from '../util';

import Loader from './Loader';

import WithUser from './HOC/WithUser';
import { WithTrans } from  '../i18n/i18nWrapper';

import icons from '../assets/icons.svg';
const linkedInIcon = icons + '#linkedin';

class Request extends Component {

  state = {
    loading: false,
    loadingAccept: false,
    loadingIgnore: false,
    prepareIgnore: false,
    ignoreMessage: '',
    loadingArchive: false,
    actionTaken: false,
    requestAccepted: false,
    conversationId: 0,
  }

  acceptRequest = async () => {
    if(this.state.loading) {
      // Request already in progress
      return;
    }
    // Track accepted matches
    if(process.env.NODE_ENV === 'production') {
      analytics.logEvent('MentorMatch');
    }
    // Start loading
    this.setState({ loading: true, loadingAccept: true });
    // Accept request via cloud function
    const acceptRequest = firebasefunctions.httpsCallable('acceptRequest');
    acceptRequest({
      sender: this.props.fromUserId,
      recipient: this.props.user.uid, 
      requestId: this.props.request.id,
      requestType: this.props.request.type,
      requestDate: this.props.request.date,
      message: this.props.request.message,
      env: process.env.NODE_ENV,
    }).then((response) => {
      const conversationId = response.data ? response.data.conversationId : 0;
      // Show success message
      setTimeout(() => {
        this.setState({ 
          loading: false,
          actionTaken: true,
          requestAccepted: true,
          conversationId: conversationId,
        });
      }, 1000);
    }).catch((error) => {
      // Indicate error
      console.error(error);
      alert('Something went wrong... Refresh the page and try again');
    });
  }

  prepareIgnore() {
    this.setState({ 
      prepareIgnore: true,
    });
  }

  ignoreRequest() {
    if(this.state.loading) {
      // Request already in progress
      return;
    }
    // Start loading
    this.setState({ loading: true, loadingIgnore: true });
    // Update request
    const updateRequest = firebasefunctions.httpsCallable('updateRequest');
    updateRequest({
      sender: this.props.fromUserId,
      recipient: this.props.user.uid,
      status: 'ignored',
      requestId: this.props.request.id,
      requestType: this.props.request.type,
      requestDate: this.props.request.date,
      message: this.state.ignoreMessage,
    }).then(() => {
      // Allow 1 second load
      setTimeout(() => {
        this.setState({ 
          loading: false,
          actionTaken: true,
          requestAccepted: false
        });
      }, 1000);
    }).catch((error) => {
      // Indicate error
      console.error(error);
      alert('Something went wrong... Refresh the page and try again');
    });
  }

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  archiveRequest = async () => {
    if(this.state.loading) {
      // Some action already in progress
      return;
    }
    // Start loading
    this.setState({ loading: true, loadingArchive: true });
    // Update own outgoing request with status ignore
    // (We can keep this one here client side since user is only editing own profile)
    const currentUserDocumentRef = firestore.collection('users').doc(this.props.user.uid);
    await currentUserDocumentRef.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        // Create new array of outgoing requests
        let outgoingRequestsArray = [];
        // Check if any existing requests sent
        if(docSnapshot.data().outgoingRequests) {
          outgoingRequestsArray = docSnapshot.data().outgoingRequests;
        }
        // Update array
        outgoingRequestsArray.find(request => request.id === this.props.request.id).archived = true;
        // Save updated array back to db
        currentUserDocumentRef.update({ outgoingRequests: outgoingRequestsArray }).then(() => {
          setTimeout(() => {
            this.setState({
              actionTaken: true,
              loading: false,
              loadingArchive: false,
            });
          }, 1000);
          return true;
        });
      } else { console.error("Error: User for pending request not found"); alert('Something went wrong... Refresh the page and try again'); }
    }).catch(function(error) {
      console.error("Error: ", error);
      alert('Something went wrong... Refresh the page and try again');
    });
  }

  render() {
    const user = this.props.fromUser;
    const footerClass = classNames('Request__footer', {
      'Request__footer--actionTaken': this.state.actionTaken,
      'Request__footer--loading': this.state.loading,
    });
    const linkedInBtnClass = classNames('Button Button--green Button--green--outline Button--icon Match__linkedinbtn', {
      'Match__linkedinbtn--none': user.linkedInUrl === '#' ? true : false,
    });
    const requestIgnoreButtonClass = classNames('Button Button--green--outline Request__ignore', {
      'Request__ignore--english': this.props.i18n.language === 'en'
    });
    const linkedInBtnText = user.linkedInUrl === '#' ? this.props.t('match.no_linkedin') : this.props.t('match.linkedin');
    let matchingCompetencies = [];
    if(this.props.request.type === 'mentor') {
      if(user.menteeProfile.preferenceCompetencies && user.menteeProfile.preferenceCompetencies.length > 0 && this.props.user.mentorProfile.competencies && this.props.user.mentorProfile.competencies.length > 0) {
        user.menteeProfile.preferenceCompetencies.forEach(menteePreferenceCompetenceId => {
          this.props.user.mentorProfile.competencies.forEach(mentorCompetenceId => {
            if(menteePreferenceCompetenceId === mentorCompetenceId) {
              matchingCompetencies.push(mentorCompetenceId);
            }
          });
        });
      }
    }
    if(this.props.request.type === 'mentee') {
      if(user.mentorProfile.competencies && user.mentorProfile.competencies.length > 0 && this.props.user.menteeProfile.preferenceCompetencies && this.props.user.menteeProfile.preferenceCompetencies.length > 0) {
        user.mentorProfile.competencies.forEach(mentorCompetenceId => {
          this.props.user.menteeProfile.preferenceCompetencies.forEach(menteePreferenceCompetenceId => {
            if(menteePreferenceCompetenceId === mentorCompetenceId) {
              matchingCompetencies.push(menteePreferenceCompetenceId);
            }
          });
        });
      }
    }
    let viewAs = '';
    if((this.props.request.type === 'mentor' && this.props.direction === 'ingoing') || (this.props.request.type === 'mentee' && this.props.direction === 'outgoing')) {
      viewAs = '?viewAs=mentor';
    }
    if((this.props.request.type === 'mentee' && this.props.direction === 'ingoing') || (this.props.request.type === 'mentor' && this.props.direction === 'outgoing')) {
      viewAs = '?viewAs=mentee';
    }
    let matchingCompetenciesWithNames = [];
    if(matchingCompetencies.length > 0) {
      matchingCompetenciesWithNames = getCompetenceNames(matchingCompetencies);
    }
    const sevenDaysAgo = new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
    return (
      <article className="Request">
        <div className="Request__top">
          <div>
            <Link to={`/profile/${this.props.fromUserId + viewAs}`}>
              <figure className="Request__image">
                <img src={user.profileImageURL ? user.profileImageURL : avatarPlaceholder} alt={user.name}/>
              </figure>
            </Link>
            <div className="Request__credentials">
              <Link to={`/profile/${this.props.fromUserId + viewAs}`}>
                <h1 className="Request__name">{user.name}</h1>
              </Link>
              <h3 className="Request__title"><span>{user.title}</span> | <span>{user.company}</span></h3>
              <p className="Request__location">{getLocationName(user.location)}</p>
              {user.linkedInUrl ? (
                <a href={user.linkedInUrl} className={linkedInBtnClass} target="_blank" rel="noopener noreferrer">
                  <span>{linkedInBtnText}</span>
                  <svg>
                    <use xlinkHref={linkedInIcon}></use>
                  </svg>
                </a>
              ) : null }
            </div>
          </div>
          <div>
            {matchingCompetenciesWithNames && matchingCompetenciesWithNames.length > 0 ? (
              <div className="Match__criterias">
                <span>{this.props.t('match.competency_areas')}:</span>
                <ul className="list-unstyled">
                  {matchingCompetenciesWithNames.map((competence) =>
                    <li key={competence.id}>{this.props.i18n.language === 'en' ? competence.title_en : competence.title}</li>
                  )}
                </ul>
              </div>
              ) : null
              }
          </div>
        </div>
        <div className="Request__inner">
          <p className="Request__message__title">{this.props.direction === 'outgoing' ? this.props.t('requests.your_message') + ':' : this.props.t('requests.message') + ':'}</p>
          <div className="Request__message">
            <p>{this.props.request.message ? this.props.request.message : <span>{this.props.t('requests.no_message')}</span>}</p>
          </div>
          {this.props.direction === 'outgoing' ? (
            <div className="Request__status">
              <div>
                <p>{this.props.t('requests.status')}:</p>
                <p>
                  {this.props.request.status === 'pending' ? this.props.t('requests.pending_answer') : null}
                  {this.props.request.status === 'accepted' ? this.props.t('requests.accepted') : null}
                  {this.props.request.status === 'ignored' ? this.props.t('requests.dismissed') : null}
                </p>
              </div>
              {this.props.request.status === 'ignored' ? (
                <div>
                  <p>{this.props.t('requests.message')}:</p>
                  <p>{this.props.request.ignoreMessage ? this.props.request.ignoreMessage : <span className="nomessage">{this.props.t('requests.no_message')}</span>}</p>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className={footerClass}>
        {this.props.direction !== 'outgoing' ? (
          <>
            {this.state.actionTaken ? (
              <>
                {this.state.requestAccepted ? (
                  <>
                    <div className="Form__successMessage Form__successMessage--showMessage">
                      <p>{this.props.t('requests.request_accepted')}</p>
                    </div>
                    <button className="Button"><Link to={`/conversations/${this.state.conversationId}`}>{this.props.t('requests.go_to_conversation')}</Link></button>
                  </>
                ) : (
                  <>
                    <div className="Form__successMessage Form__successMessage--showMessage">
                      <p>{this.props.t('requests.request_dismissed')}</p>
                    </div>
                    <button className="Button Button--green--outline" onClick={() => this.props.closeRequestModal()}>{this.props.t('requests.close')}</button>
                  </>
                )
                }
              </>
            ) : (
              <>
                {this.state.prepareIgnore ? (
                  <div className="Request__ignore__wrapper">
                    <p className="Request__message__title">{this.props.t('requests.your_message')}</p>
                    <div className="Form">
                      <textarea name="ignoreMessage" placeholder={this.props.t('requests.add_message')} onChange={this.onChange} value={this.state.ignoreMessage}></textarea>
                    </div>
                    <button className="Button Button--green" onClick={() => this.ignoreRequest()}>
                      {this.state.loading && this.state.loadingIgnore ? <Loader color='white'/> : <span>{this.props.t('requests.dismiss')}</span> }
                    </button>
                  </div>
                ) : (
                  <>
                    <button className={requestIgnoreButtonClass} onClick={() => this.prepareIgnore()}>
                      {this.state.loading && this.state.loadingIgnore ? <Loader color='green'/> : <span>{this.props.t('requests.dismiss')}</span> }
                    </button>
                    <button className="Button" onClick={() => this.acceptRequest()}>
                      {this.state.loading && this.state.loadingAccept ? <Loader/> : <span>{this.props.t('requests.accept')}</span> }
                    </button>
                  </>
                )}
              </>
            )
            }
          </>
        ) : (
          <>
            {this.props.request.status === 'ignored' || this.props.request.date < sevenDaysAgo ? (
              <>
                {this.state.actionTaken ? (
                  <>
                    <div className="Form__successMessage Form__successMessage--showMessage">
                      <p>{this.props.request.status === 'ignored' ? this.props.t('requests.request_archived_recipient') : this.props.t('requests.request_archived_sender')}</p>
                    </div>
                    <button className="Button Button--green--outline" onClick={() => this.props.closeRequestModal()}>{this.props.t('requests.close')}</button>
                  </>
                ) : (
                  <div className="Request__archive">
                    <button className="Button" onClick={() => this.archiveRequest()}>
                      {this.state.loading && this.state.loadingArchive ? <Loader color='white'/> : <span>{this.props.t('requests.archive')}</span> }
                    </button>
                  </div>
                )}
              </>
            ) : null}
          </>
        )}
        </div>
      </article>
    )
  }

}

export default WithUser(WithTrans(Request));