import React, { Component } from 'react';
import { TweenLite, TimelineLite, Elastic } from "gsap/TweenMax";

class Mentor2020NomineeTrophy extends Component {

  componentDidMount() {
    // Objects
    const illustration = document.getElementById('Mentor2020NomineeTrophy');
    const trophy = document.getElementById('Mentor2020NomineeTrophy_Trophy');
    const text = document.getElementById('Mentor2020NomineeTrophy_Text');

    // Check if used in a achievement context and if its locked
    const parentElement = illustration.parentElement;
    if(parentElement.classList.contains('Achievement__illustration--locked')) {
      // Dont play yet
    } else {
      // Set initial state
      TweenLite.set(trophy, {
        opacity: 0,
        y: 50,
        scale: 0,
        transformOrigin: '50% 50%'
      });
      TweenLite.set(text, {
        opacity: 0,
      });

      // Timeline
      const timeline = new TimelineLite({ paused: true });

      timeline.to(trophy, 0.6, {
        opacity: 1,
        y: 0,
        scale: 1,
        ease: Elastic.easeOut.config(1,0.8),
      }).to(text, 0.3, {
        opacity: 1,
      });

      // Play
      setTimeout(() => {
        timeline.play();
      }, 500);
    }

  }

  render() {
    return (
      <svg viewBox="0 0 1009.5 766.6" id="Mentor2020NomineeTrophy">
        <path opacity="0.1" fill="#fe9236" d="M586.8,156.4c-64.7-1.9-126.4-20-185.2-39.8S284.4,75,221.2,64.5
        c-40.6-6.7-87.1-7.7-119.8,11.2c-31.5,18.1-41.7,49.4-47.2,78.5C50.1,176,47.7,199,59,219.4c7.8,14.2,21.7,26.1,31.4,39.8
        c33.5,47.4,9.8,105.7-26.4,152c-17,21.7-36.8,42.4-49.9,65.5s-19.2,49.5-7.7,73.1c11.4,23.3,38.5,40.9,67.9,53.2
        c59.7,25,130,32.2,198.6,36.3c151.8,9,304.5,5.1,456.7,1.2c56.3-1.4,112.9-2.9,168.3-10.4c30.8-4.2,62.6-10.8,84.9-26.9
        c28.4-20.4,35.4-54.8,16.4-80.4c-31.9-42.8-120-53.5-142.3-99.4c-12.3-25.3,0.3-53.5,18.2-76.9c38.2-50.3,102.3-94.5,105.7-152
        c2.3-39.5-28.5-79.1-76.1-97.8c-49.9-19.6-119.2-17.1-156,15.3C710.6,145.3,643.9,158.2,586.8,156.4z"/>
        <g id="Mentor2020NomineeTrophy_Trophy">
          <path fill="#fe9236" d="M740.4,218.3l-0.8,11.4c-0.1,1.5-3.1,38.3-24.8,81.3c-18.7,36.8-55,84.8-123.7,111.8
          c-5.4,2.1-10.9,4.1-16.7,5.9l-6.4-20.2c16.1-5.1,31.7-11.9,46.4-20.3c35-20.2,62.2-49,81.1-86.1c10.1-19.8,17.2-40.8,21.3-62.6
          h-78.1v-21.2H740.4z"/>
          <path fill="#fe9236" d="M445.3,408.5l-6.4,20.2c-5.8-1.8-11.3-3.8-16.7-5.9c-68.6-27-105-75-123.6-111.8
            c-21.8-43-24.7-79.7-24.8-81.3l-0.8-11.4h101.7v21.2h-78.1c4.1,21.8,11.3,42.9,21.4,62.6c19,37.1,46.2,65.9,81.1,86.1
            C413.6,396.7,429.2,403.5,445.3,408.5z"/>
          <path opacity="0.1" fill="#FFFFFF" d="M430.6,422.8c-68.6-27-105-75-123.6-111.8
            c-21.8-43-24.7-79.7-24.8-81.3l-0.8-11.4h-8.7l0.8,11.4c0.1,1.5,3.1,38.3,24.8,81.3c18.7,36.8,55,84.8,123.6,111.8
            c5.4,2.1,10.9,4.1,16.7,5.9l0.8-2.7C436.4,425,433.5,423.9,430.6,422.8z"/>
          <path fill="#fe9236" d="M624.1,603.6v114.2h-235V603.6l7.5-10c76.1-27,91.6-97.5,94.6-125.4c0.5-3.9,0.7-7.7,0.8-11.6h29.1
            c0.1,3.9,0.3,7.8,0.8,11.6c3.1,27.9,18.6,98.4,94.6,125.4L624.1,603.6z"/>
          <rect x="389.2" y="603.6" opacity="0.1" width="235" height="14.3"/>
          <path opacity="0.1" fill="#FFFFFF" d="M409.1,593.6c76.1-27,91.6-97.5,94.6-125.4
            c0.5-3.9,0.7-7.7,0.8-11.6h-12.5c-0.1,3.9-0.3,7.8-0.8,11.6c-3.1,27.9-18.6,98.4-94.6,125.4l-7.5,10v114.2h12.5V603.6L409.1,593.6z"
            />
          <path opacity="0.1" d="M353.5,218.3h21.1v21.2h-19.2C354.6,232.5,354,225.5,353.5,218.3z"/>
          <path opacity="0.1" d="M445.3,408.5l-6.4,20.2c-5.8-1.8-11.3-3.8-16.7-5.9
            c-8.8-10.8-16.6-22.3-23.3-34.5C413.6,396.7,429.2,403.5,445.3,408.5z"/>
          <path opacity="0.1" d="M568,408.5c16.1-5.1,31.7-11.9,46.4-20.3c-6.7,12.2-14.4,23.8-23.3,34.5
            c-5.4,2.1-10.9,4.1-16.7,5.9L568,408.5z"/>
          <path opacity="0.1" d="M659.8,218.3c-0.5,7.1-1.1,14.2-1.9,21.2h-19.2v-21.2H659.8z"/>
          <path opacity="0.1" d="M521.9,468.2c-10.1,2-20.5,2-30.6,0c0.5-3.9,0.7-7.7,0.8-11.6h29.1
            C521.2,460.5,521.5,464.4,521.9,468.2z"/>
          <path fill="#fe9236" d="M356,183.3c0.5,157,67.8,283.9,150.7,283.9s150.1-126.9,150.7-283.9L356,183.3z"/>
          <path opacity="0.1" fill="#FFFFFF" d="M382.7,183.3H356c0.5,157,67.8,283.9,150.7,283.9
            c4.5,0,9-0.4,13.4-1.1C443.4,453.3,383.2,331.7,382.7,183.3z"/>
          <polygon opacity="0.1" fill="#FFFFFF" points="434.2,652.9 586.5,652.9 586.5,647.3 426.7,647.3 
            426.7,688.3 434.2,688.3 "/>
          <circle fill="#F7F7F7" cx="504.5" cy="299.9" r="59.7"/>
          <path fill="#4EA39A" d="M523.1,300.4c-1,0.6-1.9,1.5-2.7,2.2c-2.7,3-4.5,6.8-4.5,11.2c0,4.2,1.6,8.2,4.5,11.1
              c0.9,0.9,1.7,1.6,2.7,2.4c0.2,0.2,0.7,0,0.7-0.4v-26.2C523.7,300.4,523.3,300.1,523.1,300.4z"/>
          <path fill="#4EA39A" d="M523.7,287.6c0-9.7-7.8-17.6-17.4-17.6h-5.1c-9.7,0-17.6,7.8-17.6,17.6v12.1c0,16.6,13.6,30.1,30.1,30.1h6.1
            c0.2,0,0.4-0.2,0.1-0.5c-1.1-0.9-2.1-1.9-3-3c-2.9-3.5-4.5-7.8-4.5-12.6c0-6.4,3-12.1,7.8-15.7c0.9-0.6,1.7-1.2,2.7-1.7
            c0.5-0.2,0.7-0.6,0.7-1.1L523.7,287.6L523.7,287.6z M513.5,296.9c-0.1,0-0.2,0-0.2,0.1h-4.9c-1.2,0-2.6,0.5-3.5,1.5l-0.7,0.7
            c-0.2,0.2-0.6,0.2-0.9,0l-0.7-0.7c-0.9-0.9-2.1-1.5-3.5-1.5h-4.4h-0.1c-4.1-0.2-7.2-3.7-7.1-7.8c0.2-3.7,3.2-6.7,7-7
            c4-0.2,7.5,2.6,8,6.6c0.1,0.7,0.6,1.2,1.4,1.2c0.7,0,1.2-0.5,1.4-1.2c0.5-4,4-6.8,8-6.6c3.6,0.2,6.6,3.2,7,6.8
            C520.2,292.9,517.3,296.4,513.5,296.9z"/>
          <circle fill="#4EA39A" cx="495" cy="289.5" r="3.1"/>
          <circle fill="#4EA39A" cx="512.2" cy="289.5" r="3.1"/>
        </g>
        <g id="Mentor2020NomineeTrophy_Text">
          <path fill="#FFFFFF" d="M473.2,680.4c0.7-1.7,2.4-3.5,5.1-5.4c2.3-1.7,3.8-2.8,4.5-3.6c1-1.1,1.6-2.3,1.6-3.7c0-1.1-0.3-2-0.9-2.7
            s-1.5-1.1-2.6-1.1c-1.5,0-2.6,0.6-3.1,1.7c-0.3,0.7-0.5,1.7-0.6,3.1h-4.9c0.1-2.2,0.5-3.9,1.2-5.3c1.3-2.5,3.7-3.8,7.1-3.8
            c2.7,0,4.8,0.7,6.4,2.2c1.6,1.5,2.4,3.5,2.4,5.9c0,1.9-0.6,3.6-1.7,5c-0.7,1-2,2.1-3.6,3.3l-2,1.4c-1.3,0.9-2.1,1.5-2.6,1.9
            s-0.9,0.9-1.2,1.4h11.1v4.4H472C472.1,683.6,472.5,681.9,473.2,680.4z"/>
          <path fill="#FFFFFF" d="M507.7,663.2c1.3,2.3,2,5.5,2,9.7s-0.7,7.4-2,9.7s-3.6,3.4-6.8,3.4s-5.5-1.1-6.8-3.4s-2-5.5-2-9.7
            s0.7-7.5,2-9.7s3.6-3.4,6.8-3.4S506.4,660.9,507.7,663.2z M497.9,679.7c0.5,1.6,1.5,2.3,3,2.3s2.5-0.8,2.9-2.3s0.7-3.8,0.7-6.7
            c0-3.1-0.2-5.3-0.7-6.8s-1.4-2.2-2.9-2.2s-2.5,0.7-3,2.2s-0.7,3.8-0.7,6.8C497.2,675.9,497.4,678.1,497.9,679.7z"/>
          <path fill="#FFFFFF" d="M513.2,680.4c0.7-1.7,2.4-3.5,5.1-5.4c2.3-1.7,3.8-2.8,4.5-3.6c1-1.1,1.6-2.3,1.6-3.7c0-1.1-0.3-2-0.9-2.7
            s-1.5-1.1-2.6-1.1c-1.5,0-2.6,0.6-3.1,1.7c-0.3,0.7-0.5,1.7-0.6,3.1h-4.9c0.1-2.2,0.5-3.9,1.2-5.3c1.3-2.5,3.7-3.8,7.1-3.8
            c2.7,0,4.8,0.7,6.4,2.2s2.4,3.5,2.4,5.9c0,1.9-0.6,3.6-1.7,5c-0.7,1-2,2.1-3.6,3.3l-2,1.4c-1.3,0.9-2.1,1.5-2.6,1.9
            s-0.9,0.9-1.2,1.4h11.1v4.4h-17.5C512.1,683.6,512.5,681.9,513.2,680.4z"/>
          <path fill="#FFFFFF" d="M547.7,663.2c1.3,2.3,2,5.5,2,9.7s-0.7,7.4-2,9.7s-3.6,3.4-6.8,3.4s-5.5-1.1-6.8-3.4s-2-5.5-2-9.7
            s0.7-7.5,2-9.7s3.6-3.4,6.8-3.4S546.4,660.9,547.7,663.2z M537.9,679.7c0.5,1.6,1.5,2.3,3,2.3s2.5-0.8,2.9-2.3s0.7-3.8,0.7-6.7
            c0-3.1-0.2-5.3-0.7-6.8s-1.4-2.2-2.9-2.2s-2.5,0.7-3,2.2s-0.7,3.8-0.7,6.8C537.2,675.9,537.4,678.1,537.9,679.7z"/>
        </g>
      </svg>
    );
  }

}

export default Mentor2020NomineeTrophy;