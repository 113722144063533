import React from 'react'

import Module from '../Module';
import Stats from '../Stats';

const PageAdmin = () => {
  return (
    <div className="Page">
      <Module title="CareerMentor admin" wide>
        <Stats />
      </Module>
    </div>
  )
}

export default PageAdmin;