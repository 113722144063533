import React, { Component } from 'react';
import { TweenLite, TimelineLite, Elastic, Power2 } from "gsap/TweenMax";

class Info extends Component {

  componentDidMount() {
    // Objects
    const thecase = document.getElementById('Info_Case');
    const paper = document.getElementById('Info_Paper');
    const leafOne = document.getElementById('Info_LeafOne');
    const leafTwo = document.getElementById('Info_LeafTwo');
    const textOne = document.getElementById('Info_TextOne');
    const textTwo = document.getElementById('Info_TextTwo');
    const textThree = document.getElementById('Info_TextThree');
    const avatar = document.getElementById('Info_Avatar');
    const man = document.getElementById('Info_Man');

    // Set initial state
    TweenLite.set(thecase, {
      opacity: 0,
      y: 50,
    });
    TweenLite.set(paper, {
      opacity: 0,
      y: -20,
    });
    TweenLite.set(leafOne, {
      opacity: 0,
      scale: 0.7,
      transformOrigin: '50% 100%'
    });
    TweenLite.set(leafTwo, {
      opacity: 0,
      scale: 0.7,
      transformOrigin: '50% 100%'
    });
    TweenLite.set(textOne, {
      opacity: 0,
      y: 10,
    });
    TweenLite.set(textTwo, {
      opacity: 0,
      y: 10,
    });
    TweenLite.set(textThree, {
      opacity: 0,
      y: 10,
    });
    TweenLite.set(avatar, {
      opacity: 0,
    });
    TweenLite.set(paper, {
      opacity: 0,
      y: -50,
    });
    TweenLite.set(man, {
      opacity: 0,
      x: 50,
    });

    // Timeline
    const timeline = new TimelineLite({ paused: true });

    timeline.to(thecase, 0.6, {
      opacity: 1,
      y: 0,
      ease: Power2.easeOut
    }).to(leafOne, 0.5, {
      opacity: 1,
      scale: 1,
      ease: Elastic.easeOut.config(0.3,0.3),
    }).to(leafTwo, 0.5, {
      opacity: 1,
      scale: 1,
      ease: Elastic.easeOut.config(0.3,0.3),
    },'-=0.4').to(avatar, 0.5, {
      opacity: 1,
      ease: Power2.easeOut
    },'-=0.4').to(textOne, 0.3, {
      opacity: 1,
      y: 0,
      ease: Power2.easeOut
    },'-=0.3').to(textTwo, 0.3, {
      opacity: 1,
      y: 0,
      ease: Power2.easeOut
    },'-=0.2').to(textThree, 0.3, {
      opacity: 1,
      y: 0,
      ease: Power2.easeOut
    },'-=0.2').to(paper, 0.5, {
      opacity: 1,
      y: 0,
      ease: Power2.easeOut
    },'-=0.2').to(man, 0.5, {
      opacity: 1,
      x: 0,
      ease: Power2.easeOut
    });
    
    // Play
    setTimeout(() => {
      timeline.play();
    }, 500);

  }

  render() {
    return (
      <svg id="Info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 982 676.5">
        <g id="Info_Case">
          <rect x="87.8" y="52.4" fill="#3F3D56" width="772.4" height="494.2"/>
          <g id="Info_Paper">
            <rect x="490.5" y="141.6" fill="#F2F2F2" width="347.8" height="404.5"/>
            <rect x="608.1" y="179.3" fill="#FFFFFF" width="210.9" height="8.5"/>
            <rect x="608.1" y="198" fill="#FFFFFF" width="210.9" height="8.5"/>
            <rect x="608.1" y="216.7" fill="#FFFFFF" width="71.4" height="8.5"/>
            <rect x="558.7" y="283.6" fill="#FFFFFF" width="210.9" height="8.5"/>
            <rect x="558.7" y="302.3" fill="#FFFFFF" width="210.9" height="8.5"/>
            <rect x="558.7" y="320.6" fill="#FFFFFF" width="210.9" height="8.5"/>
            <rect x="558.7" y="338.9" fill="#FFFFFF" width="210.9" height="8.5"/>
            <rect x="558.7" y="357.2" fill="#FFFFFF" width="210.9" height="8.5"/>
            <rect x="558.7" y="375.5" fill="#FFFFFF" width="210.9" height="8.5"/>
            <rect x="558.7" y="393.8" fill="#FFFFFF" width="210.9" height="8.5"/>
            <rect x="558.7" y="412.1" fill="#FFFFFF" width="210.9" height="8.5"/>
            <rect x="558.7" y="430.4" fill="#FFFFFF" width="210.9" height="8.5"/>
            <rect x="558.7" y="448.7" fill="#FFFFFF" width="210.9" height="8.5"/>
            <rect x="558.7" y="467" fill="#FFFFFF" width="210.9" height="8.5"/>
            <path fill="#ECC198" d="M586,213.6c0.1,24.6-14.5,33.3-32.5,33.3c-0.4,0-0.8,0-1.3,0c-0.8,0-1.7,0-2.5-0.1
            c-16.3-1.1-29-10.1-29.1-32.9c-0.1-23.7,30-53.7,32.3-55.9l0,0c0.1-0.1,0.1-0.1,0.1-0.1S585.8,189,586,213.6z"/>
          </g>
          <rect x="465.3" y="52.4" fill="#2F2E41" width="16.5" height="494.2"/>
          <path fill="#4EA39A" d="M313.8,45.1V32.3c0-14.7-11.9-26.5-26.6-26.5c-14.6,0-26.5,11.9-26.5,26.5v12.8h-50.3v28.4h153.8V45.1H313.8
            z M287.3,13c8.6,0,15.6,7,15.6,15.6s-7,15.6-15.6,15.6s-15.6-7-15.6-15.6v0C271.7,20,278.7,13,287.3,13L287.3,13L287.3,13z"/>
          <path fill="#3F3D56" d="M859.8,268.8c-70.8,0-128.1,57.4-128.1,128.1v0c0,20.7-16.8,37.5-37.5,37.5c0,0,0,0,0,0h-62.2 c-61.7,0-111.7,50-111.7,111.7l0,0v0h339.5L859.8,268.8L859.8,268.8z"/>
        </g>
        <g id="Info_LeafOne">
          <path fill="#4EA39A" d="M56.6,518.9c10.6,19.6,33,28,33,28s5.3-23.4-5.3-43s-33-28-33-28S46.1,499.3,56.6,518.9z"/>
        </g>
        <g id="Info_LeafTwo">
          <path fill="#ECC198" d="M63.9,512.5C83,524,90.3,546.8,90.3,546.8s-23.6,4.2-42.7-7.3c-19.1-11.5-26.4-34.3-26.4-34.3
          S44.8,501,63.9,512.5z"/>
        </g>
        <g id="Info_TextOne">
          <rect x="175" y="434.6" fill="#E29866" width="210.9" height="8.5"/>
        </g>
        <g id="Info_TextTwo">
          <rect x="175" y="453.3" fill="#E29866" width="210.9" height="8.5"/>
        </g>
        <g id="Info_TextThree">
          <rect x="175" y="472" fill="#E29866" width="71.4" height="8.5"/>
        </g>

        <g id="Info_Avatar">
          <path fill="#4EA39A" d="M357.3,299.4c0,23.2-10.5,45.2-28.5,59.8c-3.3,2.7-6.8,5.1-10.5,7.2c-2.2,1.3-4.5,2.4-6.9,3.5 c-7.8,3.4-16.1,5.5-24.6,6.2c-2.1,0.2-4.2,0.3-6.3,0.3c-4.2 0-8.4-0.3-12.6-1c-1.5-0.2-2.9-0.5-4.3-0.8c-2.5-0.6-5-1.2-7.4-2.1 c-10-3.3-19.2-8.7-27-15.7c-2-1.8-3.9-3.7-5.7-5.7c-28.5-31.5-26-80.1,5.4-108.5s80.1-26,108.5,5.4 C350.2,261.9,357.3,280.3,357.3,299.4z"/>
          <path fill="#FFB8B8" d="M294.1,294.3c0,0-7.6,21.8-2.9,25.1c4.7,3.3-15.9,30.7-15.9,30.7l-24.7-6.7l13.5-22.9c0,0,1.8-22.9,1.8-26.2
          S294.1,294.3,294.1,294.3z"/>
          <path opacity="0.1" d="M294.1,294.3c0,0-7.6,21.8-2.9,25.1c4.7,3.3-15.9,30.7-15.9,30.7l-24.7-6.7
            l13.5-22.9c0,0,1.8-22.9,1.8-26.2S294.1,294.3,294.1,294.3z"/>
          <circle fill="#FFB8B8" cx="278.5" cy="290.4" r="20.1"/>
          <path fill="#D0CDE1" d="M263.5,374.3c-2.5-0.6-5-1.2-7.4-2.1l3.8-26.4l1.2-8.1l6.1,2.3c0.8,0.3,1.8,0.7,2.8,1.1
            c1.7,0.6,3.5,0.6,5.1-0.2c5.9-2.7,9.4-13.2,9.4-13.2s0.2,2.9,0.5,7.9c0.6,8.8,1.4,23.8,1.6,40.3c-2.1,0.2-4.2,0.3-6.3,0.3
            c-4.2,0-8.4-0.3-12.6-1C266.4,375,265,374.7,263.5,374.3z"/>
          <path fill="#D0CDE1" d="M269,324.4l-1.7,15.7l-0.8,7.6l-2.9,26.7c-2.5-0.6-5-1.2-7.4-2.1c-10-3.3-19.2-8.7-27-15.7l-3.7-13.2
            c0-0.8,0.1-1.6,0.2-2.4c0,0,0,0,0,0c0.1-0.7,0.3-1.4,0.5-2.1c0,0,0,0,0,0c0.7-2.5,1.9-4.8,3.8-6.7c4.5-4.5,34-18.4,34-18.4l0.6,1.2
            L269,324.4z"/>
          <path fill="#2F2E41" d="M278,262c4.5-0.2,9.2-0.2,13,2.2c1.7,1.2,3.2,2.7,4.5,4.4c3.7,4.6,6.8,10.4,5.5,16.2l-1.6-0.6
            c0,1.5,0.3,3,1,4.4c-0.2-0.6-1,0.4-0.8,1.1l1.7,5.7c-2.8-0.9-5.8,0.7-6.7,3.5c-0.1,0.2-0.1,0.4-0.1,0.5c-0.2-6.6-0.9-14.2-6.3-18.1
            c-2.7-2-6.1-2.6-9.4-3.1c-3-0.5-6.2-1-9.1,0s-5.4,4-4.7,7c-1.4-0.3-2.9,0.4-3.5,1.7c-0.6,1.3-0.8,2.7-0.7,4.2c-1.4,1-3.1-1-3.6-2.7
            c-1.4-3.9-2.6-8-1.9-12.1c0.7-4.1,4-7.9,8.2-7.9c-0.5-0.5-0.8-1.3-0.7-2l3.4-0.3c-0.9-0.1-1.7-0.5-2.2-1.2
            C265.1,266.5,275.8,262.1,278,262z"/>
          <path fill="#D0CDE1" d="M324.8,338.9c-0.5,4.2-3.5,16-6.5,27.4c-2.2,1.3-4.5,2.4-6.9,3.5c-7.8,3.4-16.1,5.5-24.6,6.2
            c-2.1,0.2-4.2,0.3-6.3,0.3c-4.2,0-8.4-0.3-12.6-1c1.6-6,3.7-15.9,5.6-25.6c0.6-2.9,1.2-5.8,1.7-8.6c2.2-11,3.8-19.9,3.8-19.9
            s6.7-6.6,10.6-8.1c0.5-0.2,1.1-0.3,1.7-0.3c3.3,0.5,7.7,8.4,7.7,8.4s20,6.7,24.7,14.3C324.6,336.4,324.9,337.7,324.8,338.9z"/>
          <path fill="#D0CDE1" d="M240,338.9l-1.2,25c-3.4-2.2-6.6-4.7-9.6-7.4c-2-1.8-3.9-3.7-5.7-5.7c1-4.2,1.8-7.8,2.3-9.9c0,0,0,0,0,0
            c0.3-1.2,0.4-1.9,0.5-2.1c0,0,0,0,0,0H240z"/>
          <path fill="#D0CDE1" d="M328.8,359.1c-3.3,2.7-6.8,5.1-10.5,7.2c-2.2,1.3-4.5,2.4-6.9,3.5l-2.2-13l2.2-24.5l12.5,3.1l0.9,0.2 L328.8,359.1z"/>
        </g>
        <g id="Info_Man">
          <path fill="#A0616A" d="M856.8,402.2c0,0-8.7,42.5,5.8,38.6s8.7-40.6,8.7-40.6L856.8,402.2z"/>
          <path fill="#D0CDE1" d="M897.4,226.4h-9.7c0,0-10.6,7.7-9.7,16.4s-17.4,84-17.4,93.7s-3.9,12.6-3.9,18.3s1.9,9.7,0,12.6 s-5.8,20.3-4.8,27c0.6,4.5,0.6,9,0,13.5h24.1c0,0,17.4-58.9,21.2-61.8s5.8-7.7,5.8-10.6s-1.9-2.9,2.9-6.8s1.9-21.2,1.9-21.2 l6.8-17.4L897.4,226.4z"/>
          <path opacity="0.1" d="M897.4,226.4h-9.7c0,0-10.6,7.7-9.7,16.4s-17.4,84-17.4,93.7s-3.9,12.6-3.9,18.3
            s1.9,9.7,0,12.6s-5.8,20.3-4.8,27c0.6,4.5,0.6,9,0,13.5h24.1c0,0,17.4-58.9,21.2-61.8s5.8-7.7,5.8-10.6s-1.9-2.9,2.9-6.8
            s1.9-21.2,1.9-21.2l6.8-17.4L897.4,226.4z"/>
          <path fill="#A0616A" d="M762.2,378.5c0,0-8.7,42.5,5.8,38.6s8.7-40.6,8.7-40.6L762.2,378.5z"/>
          <polygon fill="#A0616A" points="765.1,576.5 759.3,600.6 780.5,614.1 786.3,585.2 "/>
          <polygon fill="#A0616A" points="865.5,606.4 854.9,620.9 867.5,635.4 884.8,635.4 884.8,606.4 "/>
          <path fill="#2F2E41" d="M887.7,622.8h-5.8c-2.9-0.6-5.8-0.6-8.7,0c-5.8,1-13.7-8.3-13.7-8.3l-7.6,6.4c0,0-15.5,3.9-7.7,22.2
            l17.4,10.6c0,0,8.7,18.3,26.1,15.5s4.8-22.2,4.8-22.2L887.7,622.8z"/>
          <path fill="#2F2E41" d="M765.1,589l-1.5-6.4l-13,3.5c0,0-27-17.4-27,5.8s21.2,30.9,21.2,30.9s7.7-1,7.7,5.8s20.3,17.4,28,13.5
            s7.7-13.5,7.7-13.5l-1.9-27l-8.7,1.9c0,0-16.4-1-13.5-4.8S765.1,589,765.1,589z"/>
          <path fill="#2F2E41" d="M793.1,366.9c0,0-11.6,15.5-11.6,24.1s-3.9,86-3.9,86s-21.2,98.5-15.5,104.3s23.2,14.5,26.1,11.6
            s23.2-108.2,23.2-108.2l24.1-64.7l22.2,92.7c0,0-1,80.2,2.9,81.1s5.8,1.9,3.9,5.8s-4.8,11.6-4.8,11.6s24.1,5.8,29,1.9
            s5.8-97.5,0-130.4s1-108.2,1-108.2L793.1,366.9z"/>
          <circle fill="#A0616A" cx="828.3" cy="151.1" r="29"/>
          <path fill="#A0616A" d="M819.7,169.4c0,0,3.9,20.3,0,26.1c-3.9,5.8,52.2,1.9,52.2,1.9s-27-30.9-24.1-36.7S819.7,169.4,819.7,169.4z"
            />
          <path fill="#D0CDE1" d="M795,202.7c0,0,19.3-25.1,43.5-20.3c6.5,1.3,12.5,2.6,17.7,3.8c18.4,4.3,33.7,17.4,40,35.2
            c1.6,4.6,2.4,9.1,1.7,12.7c-2.9,14.5-1.4,128.9-1.4,128.9s-7.7,2.9-6.8,6.8s7.7,8.7,1,8.7s-109.1-3.9-107.2-6.8S795,202.7,795,202.7
            z"/>
          <path fill="#D0CDE1" d="M804.7,202.7H795c0,0-10.6,7.7-9.7,16.4s-17.4,84-17.4,93.7s-3.9,12.6-3.9,18.3s1.9,9.7,0,12.6
            s-5.8,20.3-4.8,27c0.6,4.5,0.6,9,0,13.5h24.1c0,0,17.4-58.9,21.2-61.8s5.8-7.7,5.8-10.6s-1.9-2.9,2.9-6.8s1.9-21.2,1.9-21.2
            l6.8-17.4L804.7,202.7z"/>
          <polygon opacity="0.1" points="790.6,370.5 819.7,306.5 805.1,374.5 "/>
          <path fill="#2F2E41" d="M818.2,107.2l0-2.4c2-0.3,4-0.2,6,0.2c0-1.4-0.3-2.9-0.9-4.2c2,0.3,3.9,0.8,5.7,1.7l0.7-4.4l17.3,6.1
            c6.2,2.2,12.8,4.6,16.8,9.8c4.5,5.8,4.8,13.9,3.1,21c-1.7,7.1-5.2,13.7-7.9,20.5c-1,2.9-2.2,5.8-3.7,8.4c-5.2,8.4-17,11.4-26.3,7.9
            c-3.4-1.3-6.6-3.5-8.4-6.8c-0.9-2-1.6-4.1-2.3-6.2c-1.6-4.3-4-13.2-7.9-15.5c-1.7-1-4.3,3.4-5.8,2c-3.8-3.5-3.8-9.5-2.3-14.4
            c1.4-4.9,3.2-9.8,5.3-14.4C808.8,114.8,818.2,107,818.2,107.2z"/>
        </g>
      </svg>
    );
  }

}

export default Info;