import React, { Component } from 'react';
import { TweenLite, TimelineLite, Elastic } from "gsap/TweenMax";

class Mailbox extends Component {

  componentDidMount() {
    // Objects
    const mailbox = document.getElementById('Mailbox_Box');
    const flag = document.getElementById('Mailbox_Flag');
    const mail = document.getElementById('Mailbox_Mail');

    // Set initial state
    TweenLite.set(mail, {
      opacity: 0,
      y: -30,
      x: -150,
    });
    TweenLite.set(flag, {
      rotation: 90,
      transformOrigin: '50% 88%'
    });
    TweenLite.set(mailbox, {
      transformOrigin: '50% 100%'
    });

    // Timeline
    const timeline = new TimelineLite({ paused: true });

    timeline.to(mail, 1, {
      opacity: 1,
      x: 0,
      y: 0,
      ease: Elastic.easeOut.config(0.5,0.3),
    }).to(mailbox, 1.1, {
      rotation: 8,
      ease: Elastic.easeOut.config(1,0.3),
    },'-=0.9').to(mailbox, 1, {
      rotation: 0,
      ease: Elastic.easeOut.config(0.5,0.3),
    },'-=0.2').to(flag, 1, {
      rotation: '0',
      ease: Elastic.easeOut.config(0.5,0.3),
    },'-=0.9');

    // Play
    setTimeout(() => {
      timeline.play();
    }, 1000);    

  }

  render() {
    return (
      <svg id="Mailbox" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 733.51 728.97">
        <defs>
          <linearGradient id="1e50e1f7-79f5-452b-a40c-a2d98084d9bb" x1="383.47" y1="728.97" x2="383.47" y2="400.92" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="gray" stopOpacity="0.25"/><stop offset="0.54" stopColor="gray" stopOpacity="0.12"/><stop offset="1" stopColor="gray" stopOpacity="0.1"/></linearGradient><linearGradient id="065ea879-0bf6-43b6-9672-9e36e849f5ca" x1="238.26" y1="361.82" x2="838.21" y2="361.82" xlinkHref="#1e50e1f7-79f5-452b-a40c-a2d98084d9bb"/>
          <linearGradient id="459818a5-69c2-4f7f-8bb2-5507c3d29640" x1="465.73" y1="249.13" x2="465.73" y2="125.75" xlinkHref="#1e50e1f7-79f5-452b-a40c-a2d98084d9bb"/>
          <linearGradient id="2f211934-bfe1-48dc-bf69-4a6889a77622" x1="264.33" y1="382.43" x2="264.33" y2="305.75" gradientTransform="matrix(0, -1, -1, 0, 760.81, 597.66)" xlinkHref="#1e50e1f7-79f5-452b-a40c-a2d98084d9bb"/>
          <linearGradient id="fdaec826-1474-477a-aab7-79681abb65ed" x1="212.21" y1="392.96" x2="212.21" y2="316.28" gradientTransform="matrix(0, -1, -1, 0, 821.81, 562.29)" xlinkHref="#1e50e1f7-79f5-452b-a40c-a2d98084d9bb"/>
          <linearGradient id="230aae4a-4ba3-43a0-a33b-500e3dc2a25d" x1="295.6" y1="440.27" x2="295.6" y2="336.33" gradientTransform="matrix(0, -1, -1, 0, 768.63, 669.04)" xlinkHref="#1e50e1f7-79f5-452b-a40c-a2d98084d9bb"/>
          <linearGradient id="598da11b-697d-4f9c-9fd7-d6849a884081" x1="224.95" y1="454.54" x2="224.95" y2="350.6" gradientTransform="matrix(0, -1, -1, 0, 851.31, 621.1)" xlinkHref="#1e50e1f7-79f5-452b-a40c-a2d98084d9bb"/>
          <linearGradient id="1b1d52ac-2d33-4d8b-a499-27df2fc326e7" x1="627.39" y1="372.38" x2="627.39" y2="313.03" gradientTransform="matrix(-0.65, 0.76, 0.76, 0.65, 837.43, -649.66)" xlinkHref="#1e50e1f7-79f5-452b-a40c-a2d98084d9bb"/>
          <linearGradient id="72adf1b2-6031-4d64-80db-157af34e8b1b" x1="303.02" y1="618.34" x2="303.02" y2="531.38" gradientTransform="translate(117.27 -574.84) rotate(32.36)" xlinkHref="#1e50e1f7-79f5-452b-a40c-a2d98084d9bb"/>
        </defs>

        <g id="Mailbox_Box">
          <rect x="346.26" y="400.92" width="74.42" height="328.05" fill="url(#1e50e1f7-79f5-452b-a40c-a2d98084d9bb)"/>
          <rect x="353.11" y="400.92" width="62.67" height="322.18" fill="#eceff1"/>
          <path d="M779.59,228.64H444.43A88.94,88.94,0,0,0,411,236.09c-34.07,15.1-53.9,38.8-59.5,91.36V448.88l-88.81-.94c-17.86,0-24.39,28.08-24.39,47.06H838.21V290.93C838.21,256.53,812,228.64,779.59,228.64Z" transform="translate(-185.78 -83.31)" fill="url(#065ea879-0bf6-43b6-9672-9e36e849f5ca)"/>
          <path d="M270.85,158.06H602.93a40.68,40.68,0,0,1,40.68,40.68V400.92a0,0,0,0,1,0,0H270.85a0,0,0,0,1,0,0V158.06A0,0,0,0,1,270.85,158.06Z" fill="#4EA39A"/>
          <path d="M263,158.06h0a90.09,90.09,0,0,1,90.09,90.09V356.83A44.09,44.09,0,0,1,309,400.92H217a44.09,44.09,0,0,1-44.09-44.09V248.15A90.09,90.09,0,0,1,263,158.06Z" fill="#4ea39a"/>
          <path d="M263,158.06h0a90.09,90.09,0,0,1,90.09,90.09V356.83A44.09,44.09,0,0,1,309,400.92H217a44.09,44.09,0,0,1-44.09-44.09V248.15A90.09,90.09,0,0,1,263,158.06Z" opacity="0.2"/>

          <g id="Mailbox_Mail">
            <rect x="396.77" y="311.59" width="103.94" height="169.12" transform="translate(-184.52 710.41) rotate(-83.06)" fill="url(#598da11b-697d-4f9c-9fd7-d6849a884081)"/>
            <rect x="399.56" y="313.48" width="97.01" height="163.39" transform="translate(-184.15 708.89) rotate(-83.06)" fill="#fff"/>
            <g opacity="0.6">
              <rect x="377.95" y="346.55" width="20.79" height="15.38" transform="translate(545.24 669.52) rotate(-173.06)" fill="#fe9236"/>
              <rect x="390.41" y="392.25" width="79.83" height="4.99" transform="translate(623.99 755.3) rotate(-173.06)" fill="#fe9236"/>
              <rect x="410.54" y="405.94" width="58.21" height="3.33" transform="translate(641.02 782.05) rotate(-173.06)" fill="#fe9236"/>
            </g>
          </g>

          <rect x="352.2" y="263.1" fill="#4EA39A" width="229.1" height="137.9"/>

          <g id="Mailbox_Flag">
            <polygon points="502.94 125.75 456.43 125.75 428.52 125.75 428.52 181.06 428.52 249.13 462.3 249.13 462.3 181.06 502.94 181.06 502.94 125.75" fill="url(#459818a5-69c2-4f7f-8bb2-5507c3d29640)"/>
            <polygon points="497.06 125.75 457.89 125.75 434.39 125.75 434.39 176.67 434.39 239.34 457.89 239.34 457.89 176.67 497.06 176.67 497.06 125.75" fill="#fe9236"/>
          </g>

          <path d="M447.82,242.35h0a89.8,89.8,0,0,0-34.35,6.8,90.77,90.77,0,0,1,12.81-.92h0a90.09,90.09,0,0,1,90.09,90.09v143h21.54V332.44A90.09,90.09,0,0,0,447.82,242.35Z" transform="translate(-185.78 -83.31)" opacity="0.2"/>
          <path d="M94.59,369.58H353.11a0,0,0,0,1,0,0v31.34a0,0,0,0,1,0,0H63.25a0,0,0,0,1,0,0v0A31.34,31.34,0,0,1,94.59,369.58Z" fill="#4EA39A"/>
          <path d="M94.59,369.58H353.11a0,0,0,0,1,0,0v31.34a0,0,0,0,1,0,0H63.25a0,0,0,0,1,0,0v0A31.34,31.34,0,0,1,94.59,369.58Z" fill="#fff" opacity="0.2"/>
        </g>
      </svg>
    );
  }

}

export default Mailbox;