import React from 'react';

const CollabSkeleton = () => (
  <li className="CollabSkeleton">
    <div></div>
    <div></div>
    <div></div>
  </li>
)

export default CollabSkeleton;