import React, { useState } from 'react';

import Modal from './Modal';
import Collab from './Collab';

const CollabPreview = (props) => {
  const [open, setOpen] = useState(false);
  const collab = props.collab;
  const data = collab.cmb2.collab_metabox;
  const company = collab.title.rendered
  const title = data._cm_collab_title
  const image = data._cm_collab_image;
  const excerpt = data._cm_collab_excerpt;
  const description = data._cm_collab_description;
  const url = data._cm_collab_url;
  return (
    <li className="CollabPreview" onClick={() => setOpen(!open)}>
      <div className="CollabPreview__inner">
        <figure className="CollabPreview__image">
          <img src={image} alt={title}/>
        </figure>
        <div className="CollabPreview__content border-gray">
          <p className="CollabPreview__title">{title}</p>
          <p className="CollabPreview__text">{excerpt}</p>
        </div>
        <div className="CollabPreview__footer">
          <p className="tooltip">Læs mere</p>
        </div>
      </div>
      <Modal toggleModal={() => setOpen(!open)} open={open} title={title}>
        <Collab 
          title={title} 
          company={company}
          image={image}
          description={description}
          url={url}
        />
      </Modal>
    </li>
  )
}

export default CollabPreview;