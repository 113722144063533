import React, { Component } from 'react';
import { signInWithEmailAndPassword } from '../firebase';
import { Redirect, Link } from 'react-router-dom';

import { WithTrans } from '../i18n/i18nWrapper';

import LangSwitcher from './LangSwitcher';
import Logo from './Logo';

import image from '../assets/login.jpg'
import imagex2 from '../assets/login-x2.jpg';
import imagex3 from '../assets/login-x3.jpg';
import imagex4 from '../assets/login-x4.jpg';

class Login extends Component {

  state = {
    loading: false,
    submitBtnText: this.props.t('signin.login'),
    email: '',
    password: '',
    errorCode: false,
  };

  renderRedirect = () => {
    if (this.props.loggedIn) {
      return <Redirect to={'/'}/> 
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async event => {
    event.preventDefault();
    // console.log("Signing in...");
    this.setState({
      loading: true,
      submitBtnText: this.props.t('wait'),
    });
    const { email, password } = this.state; 
    const signIn = await signInWithEmailAndPassword(email, password);

    if (signIn.error) {
      const errorCode = signIn.error;
      console.log("Signin error: ", errorCode);
      if (errorCode === 'auth/wrong-password') {
        this.setState({ errorCode: 1, loading: false, submitBtnText: this.props.t('signin.login') });
      } else if(errorCode === 'auth/too-many-requests') {
        this.setState({ errorCode: 2, loading: false, submitBtnText: this.props.t('signin.login') });
      } else if(errorCode === 'auth/user-not-found') {
        this.setState({ errorCode: 3, loading: false, submitBtnText: this.props.t('signin.login') });
      } else {
        this.setState({ errorCode: 4, loading: false, submitBtnText: this.props.t('signin.login') });
      }
    }

    if (signIn.user) {
      // success
      // console.log("Login succes: ", signIn);
    }

  };

  render() {
    const { email, password } = this.state;
    return (
      <>
        {this.renderRedirect()}
        <div className="Login">
          <div className="Login__wrapper">
            <div className="Login__inner">
              {this.props.inline ? null : <Logo /> }
              <div className="Login__form">
                <h1 className="Login__title">{this.props.t('signin.login_headline')}</h1>
                <form className="Form" onSubmit={this.handleSubmit}>
                  <label htmlFor="email">{this.props.t('signin.email')}</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder={this.props.t('signin.email_placeholder')}
                    value={email}
                    onChange={this.handleChange}
                    required
                  />
                  <label htmlFor="password">{this.props.t('signin.password')}</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder={this.props.t('signin.password_placeholder')}
                    value={password}
                    onChange={this.handleChange}
                    required
                  />
                  <input type="submit" className="Button" value={this.state.submitBtnText} disabled={this.state.loading} style={{ cursor: this.state.loading ? 'wait' : 'auto'}} />
                  {this.state.errorCode === 1 ? <div className="Form__errorMessage Form__errorMessage--showMessage"><p>{this.props.t('signin.error_wrong_password')}</p></div> : null }
                  {this.state.errorCode === 2 ? <div className="Form__errorMessage Form__errorMessage--showMessage"><p>{this.props.t('signin.error_too_many_tries_please_wait')}</p></div> : null }
                  {this.state.errorCode === 3 ? <div className="Form__errorMessage Form__errorMessage--showMessage"><p>{this.props.t('signin.error_email_or_password_incorrect')}</p></div> : null }
                  {this.state.errorCode === 4 ? <div className="Form__errorMessage Form__errorMessage--showMessage"><p>{this.props.t('signin.error_unknown')}</p></div> : null }
                </form>
                {this.props.inline ? null : (
                  <div>
                    <p className="SignUp__alternate">{this.props.t('signin.forgot_password')} <Link to="/glemt-password">{this.props.t('signin.get_a_new_one')}</Link></p>
                    <p className="SignUp__alternate">{this.props.t('signin.dont_got_a_profile')} <Link to="/signup">{this.props.t('signin.create_new_profile')}</Link></p>
                  </div>
                ) }
                <LangSwitcher loginStyle />
              </div>
            </div>
          </div>
          {this.props.inline ? null : (
            <div className="Login__media">
              <img 
                src={imagex2}
                alt="Mentor and mentee talking"
                srcSet={`
                  ${image} 480w,
                  ${imagex2} 900w,
                  ${imagex3} 1200w,
                  ${imagex4} 1600w
                `}
                sizes="(min-width: 1600px) 80vw, (min-width: 1200px) 50vw, 100vw"
              />
            </div>
          ) }
        </div>
      </>
    );
  }

}

export default WithTrans(Login);

