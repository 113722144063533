import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { firestore } from '../firebase';

import { getYearsExperience, getCompetenceNames, validateString } from '../util';

import { WithTrans } from '../i18n/i18nWrapper';

import CompetenceSelector from './SignUp/CompetenceSelector';
import icons from '../assets/icons.svg';
const PencilIcon = icons + '#pencil';
const SproutIcon = icons + '#sprout';

class ProfileMentor extends Component {


  constructor(props) {
    super(props);
    this.handleCompetenceSelect = this.handleCompetenceSelect.bind(this);
  }
  
  state = {
    // New profile fields
    newAvailability: this.props.user.mentorProfile.available,
    newProfileText: this.props.user.mentorProfile.profileText,
    newExperience: this.props.user.mentorProfile.experience,
    newStartupExperience: this.props.user.mentorProfile.startupExperience,
    newCompetencies: this.props.user.mentorProfile.competencies,
    // newIndustry: this.props.user.mentorProfile.industry,
    // State logic
    editMode: false,
    showErrorMessage: false,
    showSuccessMessage: false,
    loading: false,
    validationFailed: false,
    validationErrors: [],
  }

  handleChange = event => {
    const { name } = event.target;
    let { value } = event.target;
    if (name === 'newExperience') {
      // convert select string values to integers
      value = parseInt(value);
    }
    this.setState({ [name]: value });
  };

  handleToggle = event => {
    const { name, value } = event.target;
    const bool = value === 'true' ? true : false;
    this.setState({ [name]: bool });
  }

  handleCompetenceSelect(id, profile, selected) {
    if(profile === 'mentor') {
      // Mentor competencies preference
      if(selected) {
        // Competence already selected (remove it)
        this.setState(prevState => ({ 
          newCompetencies: prevState.newCompetencies.filter(competence => competence !== id) 
        }));
      } else {
        // Add competence to list of selected competencies
        this.setState(prevState => ({
          newCompetencies: [...prevState.newCompetencies, id]
        }));
      }
    }
  }

  handleSubmit = event => {
    if(this.state.loading) {
      return;
    }
    // Prevent form submit from refreshing page
    event.preventDefault();
    // Reset validation errors if any and indicate loading
    this.setState({ validationErrors: [], validationFailed: false, loading: true, showSuccessMessage: false, showErrorMessage: false });
    // Validate various fields
    this.validateProfileText();
    this.validateExperience();
    this.validateCompetencies();
    this.validateTrueFalse();

    setTimeout(() => {
      // Show errors if failed
      if(this.state.validationFailed) {
        // console.log('validation failed');
        this.setState({ showErrorMessage: true, loading: false });
      }

       // All validation passed, check if anything has changed
       if(!this.state.validationFailed) {
        // console.log("Validation passed");
        const availabilityHasChanged = this.state.newAvailability !== this.props.user.mentorProfile.available ? true : false;
        // Availability has changed
        const profileTextFieldHasChanged = this.state.newProfileText !== this.props.user.mentorProfile.profileText ? true : false;
        // console.log('profile text field has changed: ', profileTextFieldHasChanged);
        const experienceFieldHasChanged = this.state.newExperience !== this.props.user.mentorProfile.experience ? true : false;
        // console.log('experience field has changed: ', experienceFieldHasChanged);
        const startupExperienceFieldHasChanged = this.state.newStartupExperience !== this.props.user.mentorProfile.startupExperience ? true : false;
        // console.log('startup experience field has changed: ', startupExperienceFieldHasChanged);
        const competenciesHasChanged = this.state.newCompetencies !== this.props.user.mentorProfile.competencies ? true : false;
        // console.log('mentor competencies has changed: ', competenciesHasChanged);

        // Check if anything has changed
        if(availabilityHasChanged || profileTextFieldHasChanged  || experienceFieldHasChanged || startupExperienceFieldHasChanged || competenciesHasChanged) {
          // console.log("Something has changed!");
          // Something has changed, try saving to database
          const currentUserDocumentRef = firestore.collection('users').doc(this.props.user.uid);
          // Get user document
          currentUserDocumentRef.get().then((docSnapshot) => {
            if (docSnapshot.exists) {
              // console.log("Retrieved user");
              // User exists and is retrieved
              // Update user with changed fields
              currentUserDocumentRef.update({
                ...availabilityHasChanged && { "mentorProfile.available": (this.state.newAvailability) },
                ...profileTextFieldHasChanged && { "mentorProfile.profileText": this.state.newProfileText },
                ...experienceFieldHasChanged && { "mentorProfile.experience": parseInt(this.state.newExperience) },
                ...startupExperienceFieldHasChanged && { "mentorProfile.startupExperience": (this.state.newStartupExperience) },
                ...competenciesHasChanged && { "mentorProfile.competencies": this.state.newCompetencies },
              }).then(() => {
                // User updated
                // console.log("User profile updated");
                // Changes were successfully saved, show success message
                this.setState({ showSuccessMessage: true, loading: false, editMode: false });
              }).catch(function(error) {
                // Something went wrong with update of user (probably timed out or not allowed)
                console.error("Error: ", error);
                this.setState(prevState => ({
                  showErrorMessage: true,
                  loading: false,
                  validationErrors: [...prevState.validationErrors, this.props.t('profile.error_unknown')]
                }))
              });
            }
          }).catch(function(error) {
            // User probably doesn't exist
            console.error("Error: ", error);
            this.setState(prevState => ({
              showErrorMessage: true,
              loading: false,
              validationErrors: [...prevState.validationErrors, this.props.t('profile.error_unknown')]
            }))
          });
        } else {
          // Nothing has changed!
          // console.log("Nothing has changed!");
          this.setState({ loading: false, editMode: false });
        }

      }

    }, 500);
  }

  startEditMode() {
    this.setState({ editMode: true });
    // Check if any competency categories need to be highlighted in a dumb non-reactive way
    setTimeout( () => {
      const CategoryElements = [...document.querySelectorAll('.CompetenceSelector__category')];
      CategoryElements.forEach((category) => {
        const competencies = [...category.querySelectorAll('.Competence')];
        competencies.forEach((competency) => {
          if(competency.classList.contains('Competence--selected')) {
            category.classList.add('CompetenceSelector__category--selected');
          }
        });
      });
    }, 200);
  }

  stopEditMode() {
    this.setState({ editMode: false });
  }

  validateProfileText() {
    const valid = validateString(this.state.newProfileText);
    if(!valid) {
      console.error('not a valid profile text');
      this.setState(prevState => ({
        validationFailed: true,
        validationErrors: [...prevState.validationErrors, this.props.t('profile.error_no_profile_text')]
      }))
    }
  }

  validateExperience() {
    const validExperience = [1,2,3,4,5,6,7];
    if(!validExperience.includes(parseInt(this.state.newExperience))) {
      console.error('Not a valid preference');
      this.setState(prevState => ({
        validationFailed: true,
        validationErrors: [...prevState.validationErrors, this.props.t('profile.error_no_experience')]
      }))
    }
  }

  // validateIndustry() {
  //   if(this.state.newIndustry === 0 || !Number.isInteger(this.state.newIndustry )) {
  //     console.error('Industry not valid');
  //     this.setState(prevState => ({
  //       validationFailed: true,
  //       validationErrors: [...prevState.validationErrors, 'Du skal vælge et primært brancheområde']
  //     }))
  //   }
  // }

  validateCompetencies() {
    if(!Array.isArray(this.state.newCompetencies)) {
      console.error('Competencies not valid');
      this.setState(prevState => ({
        validationFailed: true,
        validationErrors: [...prevState.validationErrors, this.props.t('profile.error_invalid_competencies')]
      }))
    }
  }

  validateTrueFalse() {
    if( typeof this.state.newStartupExperience !== 'boolean' ) {
      this.setState(prevState => ({
        validationFailed: true,
        validationErrors: [...prevState.validationErrors, this.props.t('profile.error_invalid_younger_preference')]
      }))
    }
  }

  render() {
    const editMode = this.state.editMode;

    // Old state
    const { mentorProfile } = this.props.user;
    const { profileText, experience, startupExperience, competencies, available } = mentorProfile;
    const experienceString = getYearsExperience(experience);

    // New state
    const { newAvailability, newProfileText, newExperience, newStartupExperience, newCompetencies } = this.state;

    // Current competencies and their names
    let competenciesNames = getCompetenceNames(competencies);

    return (
      <div className="Profile__mentor">
        <form className="Form" onSubmit={(e) => this.handleSubmit(e)}>
          {this.state.showSuccessMessage ? (
            <div className="Form__successMessage Form__successMessage--showMessage">
              <p>{this.props.t('profile.success_updated')}</p>
            </div>
          ) : null }
          {editMode ? (
            <>
              <div className="Profile__save">
                <button className="Button Button--green--outline" onClick={() => this.stopEditMode()}>{this.props.t('profile.cancel')}</button>
                <input type="submit" className="Button" value={this.state.loading ? this.props.t('profile.wait') : this.props.t('profile.save_changes')} disabled={this.state.loading} style={{ cursor: this.state.loading ? 'wait' : 'pointer' }} />
              </div>
              {this.state.showErrorMessage ? (
                <div className="Form__errorMessage Form__errorMessage--showMessage">
                  <ul>
                    {this.state.validationErrors.map((error, i) =>
                      <li key={i}>{error}</li>
                    )}
                  </ul>
                </div>
              ) : null }
            </>
          ) : (
            <button className="Button Button--icon Profile__editbtn" onClick={() => this.startEditMode()}>
              <span>{this.props.t('profile.edit')}</span>
              <svg>
                <use xlinkHref={PencilIcon}></use>
              </svg>
            </button>
          )
          }
          <h3 className="Profile__headline">{this.props.t('profile.mentor_settings')}</h3>
          <hr/>
          <div>
            <p className="Profile__subheadline">{this.props.t('profile.available')}</p>
            {editMode ? (
              <div className="Form__radioWrapper">
                <label>
                  <input type="radio" name="newAvailability" value={true} onChange={this.handleToggle} defaultChecked={newAvailability === true ? true : false}/>
                  <span>{this.props.t('profile.yes')}</span>
                </label>
                <label>
                  <input type="radio" name="newAvailability" value={false} onChange={this.handleToggle} defaultChecked={newAvailability === false ? true : false}/>
                  <span>{this.props.t('profile.no')}</span>
                </label>
              </div>
            ) : (
              <p className="Profile__info">{available === true ? this.props.t('profile.yes') : this.props.t('profile.no')}</p>
            )
            }
            <span className="tooltip">{this.props.t('profile.available_tooltip')}</span>
          </div>
          <hr/>
          <div>
            <p className="Profile__subheadline">{this.props.t('profile.profile_text')}</p>
            <span className="tooltip">{this.props.t('profile.profile_text_mentor_tip')}</span>
            {editMode ? (
              <textarea id="profileText" name="newProfileText" placeholder={this.props.t('profile.profile_text_mentor_placeholder')} required onChange={this.handleChange} defaultValue={newProfileText}></textarea>
            ) : (
              <div className="Profile__text">{profileText ? profileText : this.props.t('profile.profile_text_none')}</div>
            )}
          </div>
          <hr/>
          <div>
            <p className="Profile__subheadline">{this.props.t('profile.years_of_experience_minimum')}</p>
            {editMode ? (
              <div className="Form__selectWrapper">
                <select id="mentorExperience" name="newExperience" onChange={this.handleChange} defaultValue={newExperience}>
                  <option value="1">{getYearsExperience(1)}</option>
                  <option value="2">{getYearsExperience(2)}</option>
                  <option value="3">{getYearsExperience(3)}</option>
                  <option value="4">{getYearsExperience(4)}</option>
                  <option value="5">{getYearsExperience(5)}</option>
                  <option value="6">{getYearsExperience(6)}</option>
                  <option value="7">{getYearsExperience(7)}</option>
                </select>
              </div>
            ) : (
              <p className="Profile__info">{experienceString}</p>
            )
            }
          </div>
          <hr/>
          <div>
            <p className="Profile__subheadline">{this.props.t('profile.competencies')}</p>
              {editMode ? (
                <CompetenceSelector profile="mentor" handleClick={this.handleCompetenceSelect} competencies={newCompetencies}/>
              ) : (
                <ul className="Profile__inlinelist list-unstyled">
                  {competenciesNames && competenciesNames.length > 0 ? (
                    competenciesNames.map((competence, i) =>
                      <li key={competence.id}>{this.props.i18n.language === 'en' ? competence.title_en : competence.title}{i !== competenciesNames.length - 1 ? ', ' : null}</li>
                    )
                  ) : <li>{this.props.t('profile.no_competencies_selected')}</li> }
                </ul>
              )
              }
          </div>
          <hr/>
          <div>
            <p className="Profile__subheadline">{this.props.t('profile.experience_with_startups')}</p>
            {editMode ? (
              <div className="Form__radioWrapper">
                <label>
                  <input type="radio" name="newStartupExperience" value={true} onChange={this.handleToggle} defaultChecked={newStartupExperience === true ? true : false}/>
                  <span>{this.props.t('profile.yes')}</span>
                </label>
                <label>
                  <input type="radio" name="newStartupExperience" value={false} onChange={this.handleToggle} defaultChecked={newStartupExperience === false ? true : false}/>
                  <span>{this.props.t('profile.no')}</span>
                </label>
              </div>
            ) : (
              <p className="Profile__info">{startupExperience === true ? this.props.t('profile.yes') : this.props.t('profile.no')}</p>
            )
            }
          </div>
        </form>
        {!editMode ? (
          <>
            <hr/>
            <Link to={`/profile/${this.props.user.uid}?viewAs=mentee`} className="Button Button--green--outline Button--icon Profile__viewbtn">
              <span>{this.props.t('profile.see_your_profile_as_mentee')}</span>
              <svg>
                <use xlinkHref={SproutIcon}></use>
              </svg>
            </Link>
          </>
        ) : null }
      </div>
    )
  }

}

export default WithTrans(ProfileMentor);