import React, { Component } from 'react';
import classNames from 'classnames';
import noScroll from 'no-scroll';

class Modal extends Component {
  componentWillUnmount = () => {
    noScroll.off();
  };
  render() {
    const modalClass = classNames('Modal', {
      'Modal--open': this.props.open,
    });
    if(this.props.open) {
      noScroll.on();
    }
    if(!this.props.open) {
      noScroll.off();
    }
    return (
      <div className={modalClass} onClick={e => e.stopPropagation()}>
        <div className="Modal__inner">
          <div className="Modal__surface">
            <button className="Modal__close" onClick={this.props.toggleModal}><span>×</span></button>
            <div className="Modal__top">
              <p>{this.props.title}</p>
            </div>
            <div className="Modal__content">
              {this.props.children}
            </div>
          </div>
        </div>
        <div className="Modal__scrim" onClick={this.props.toggleModal}></div>
      </div>
    );
  }
}

export default Modal;