import React, { Component } from 'react';
import { TweenLite, TimelineLite, Elastic } from "gsap/TweenMax";

class MenteeTrophy extends Component {

  componentDidMount() {
    // Objects
    const illustration = document.getElementById('MenteeTrophy');
    const trophy = document.getElementById('MenteeTrophy_Trophy');

    // Check if used in a achievement context and if its locked
    const parentElement = illustration.parentElement;
    if(parentElement.classList.contains('Achievement__illustration--locked')) {
      // Dont play yet
    } else {
      // Set initial state
      TweenLite.set(trophy, {
        opacity: 0,
        y: 50,
        scale: 0,
        transformOrigin: '50% 50%'
      });

      // Timeline
      const timeline = new TimelineLite({ paused: true });

      timeline.to(trophy, 0.6, {
        opacity: 1,
        y: 0,
        scale: 1,
        ease: Elastic.easeOut.config(1,0.8),
      });

      // Play
      setTimeout(() => {
        timeline.play();
      }, 500);
    }

  }

  render() {
    return (
      <svg viewBox="0 0 1009.5 766.6" id="MenteeTrophy">
        <path opacity="0.1" fill="#4EA39A" d="M586.8,156.4c-64.7-1.9-126.4-20-185.2-39.8S284.4,75,221.2,64.5
        c-40.6-6.7-87.1-7.7-119.8,11.2c-31.5,18.1-41.7,49.4-47.2,78.5C50.1,176,47.7,199,59,219.4c7.8,14.2,21.7,26.1,31.4,39.8
        c33.5,47.4,9.8,105.7-26.4,152c-17,21.7-36.8,42.4-49.9,65.5s-19.2,49.5-7.7,73.1c11.4,23.3,38.5,40.9,67.9,53.2
        c59.7,25,130,32.2,198.6,36.3c151.8,9,304.5,5.1,456.7,1.2c56.3-1.4,112.9-2.9,168.3-10.4c30.8-4.2,62.6-10.8,84.9-26.9
        c28.4-20.4,35.4-54.8,16.4-80.4c-31.9-42.8-120-53.5-142.3-99.4c-12.3-25.3,0.3-53.5,18.2-76.9c38.2-50.3,102.3-94.5,105.7-152
        c2.3-39.5-28.5-79.1-76.1-97.8c-49.9-19.6-119.2-17.1-156,15.3C710.6,145.3,643.9,158.2,586.8,156.4z"/>
        <g id="MenteeTrophy_Trophy">
          <path fill="#4EA39A" d="M740.4,218.3l-0.8,11.4c-0.1,1.5-3.1,38.3-24.8,81.3c-18.7,36.8-55,84.8-123.7,111.8
          c-5.4,2.1-10.9,4.1-16.7,5.9l-6.4-20.2c16.1-5.1,31.7-11.9,46.4-20.3c35-20.2,62.2-49,81.1-86.1c10.1-19.8,17.2-40.8,21.3-62.6
          h-78.1v-21.2H740.4z"/>
        <path fill="#4EA39A" d="M445.3,408.5l-6.4,20.2c-5.8-1.8-11.3-3.8-16.7-5.9c-68.6-27-105-75-123.6-111.8
          c-21.8-43-24.7-79.7-24.8-81.3l-0.8-11.4h101.7v21.2h-78.1c4.1,21.8,11.3,42.9,21.4,62.6c19,37.1,46.2,65.9,81.1,86.1
          C413.6,396.7,429.2,403.5,445.3,408.5z"/>
        <path opacity="0.1" fill="#FFFFFF" d="M430.6,422.8c-68.6-27-105-75-123.6-111.8
          c-21.8-43-24.7-79.7-24.8-81.3l-0.8-11.4h-8.7l0.8,11.4c0.1,1.5,3.1,38.3,24.8,81.3c18.7,36.8,55,84.8,123.6,111.8
          c5.4,2.1,10.9,4.1,16.7,5.9l0.8-2.7C436.4,425,433.5,423.9,430.6,422.8z"/>
        <path fill="#4EA39A" d="M624.1,603.6v114.2h-235V603.6l7.5-10c76.1-27,91.6-97.5,94.6-125.4c0.5-3.9,0.7-7.7,0.8-11.6h29.1
          c0.1,3.9,0.3,7.8,0.8,11.6c3.1,27.9,18.6,98.4,94.6,125.4L624.1,603.6z"/>
        <rect x="389.2" y="603.6" opacity="0.1" width="235" height="14.3"/>
        <path opacity="0.1" fill="#FFFFFF" d="M409.1,593.6c76.1-27,91.6-97.5,94.6-125.4
          c0.5-3.9,0.7-7.7,0.8-11.6h-12.5c-0.1,3.9-0.3,7.8-0.8,11.6c-3.1,27.9-18.6,98.4-94.6,125.4l-7.5,10v114.2h12.5V603.6L409.1,593.6z"
          />
        <path opacity="0.1" d="M353.5,218.3h21.1v21.2h-19.2C354.6,232.5,354,225.5,353.5,218.3z"/>
        <path opacity="0.1" d="M445.3,408.5l-6.4,20.2c-5.8-1.8-11.3-3.8-16.7-5.9
          c-8.8-10.8-16.6-22.3-23.3-34.5C413.6,396.7,429.2,403.5,445.3,408.5z"/>
        <path opacity="0.1" d="M568,408.5c16.1-5.1,31.7-11.9,46.4-20.3c-6.7,12.2-14.4,23.8-23.3,34.5
          c-5.4,2.1-10.9,4.1-16.7,5.9L568,408.5z"/>
        <path opacity="0.1" d="M659.8,218.3c-0.5,7.1-1.1,14.2-1.9,21.2h-19.2v-21.2H659.8z"/>
        <path opacity="0.1" d="M521.9,468.2c-10.1,2-20.5,2-30.6,0c0.5-3.9,0.7-7.7,0.8-11.6h29.1
          C521.2,460.5,521.5,464.4,521.9,468.2z"/>
        <path fill="#4EA39A" d="M356,183.3c0.5,157,67.8,283.9,150.7,283.9s150.1-126.9,150.7-283.9L356,183.3z"/>
        <path opacity="0.1" fill="#FFFFFF" d="M382.7,183.3H356c0.5,157,67.8,283.9,150.7,283.9
          c4.5,0,9-0.4,13.4-1.1C443.4,453.3,383.2,331.7,382.7,183.3z"/>
        <polygon opacity="0.1" fill="#FFFFFF" points="434.2,652.9 586.5,652.9 586.5,647.3 426.7,647.3 
          426.7,688.3 434.2,688.3 "/>
        <circle fill="#F7F7F7" cx="504.5" cy="299.9" r="59.7"/>
        <path fill="#4EA39A" d="M535.2,268.1L535.2,268.1c-0.4-0.4-0.8-0.4-1.2-0.4c-6.2,1.4-20.9,5.3-24.6,10.1c-3.4,4.4-3.1,10.7,0.8,14.9
          c-3.1,5.4-5.3,11.5-6.3,17.7c-1.2-3.1-2.7-6.1-4.7-8.9c3.8-4.4,3.6-11.3-0.6-15.5c-4-4.1-21.5-8.4-23.5-8.9
          c-0.8-0.3-1.8,0.5-1.6,1.6c0.4,1.6,3.1,12.5,6.2,19.3c0.9,1.8,1.8,3.4,2.6,4.3c4.3,4.3,10.6,4.3,15,1c2.3,3.6,4.1,7.6,5.4,11.8
          c-7.9,0.9-14.9,5.7-18.4,12.9c-0.3,0.4-0.1,0.9,0.1,1.3c0.1,0.4,0.5,0.6,1,0.6h39.3c0.4,0,0.9-0.3,1.2-0.6c0.3-0.4,0.3-0.9,0.1-1.3
          c-3.8-7.9-11.5-12.8-20.2-13.1c0.6-7.2,2.8-14.2,6.3-20.6c3.9,2.6,8.9,2.5,12.7-0.1c0.6-0.4,1.2-0.9,1.7-1.4
          c4.1-4.1,8.4-21.6,8.9-23.5c0-0.1,0-0.3,0-0.4C535.6,268.6,535.4,268.3,535.2,268.1z M494.8,299.2L494.8,299.2l-1.2-1.2h-3.5
          c-0.4,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h2.2l-1-1l0,0l-1.2-1.2h-3.5c-0.4,0-0.6-0.3-0.6-0.6c0-0.4,0.3-0.6,0.6-0.6h2.2l-0.9-1l0,0
          l-1.2-1.2h-3.6c-0.4,0-0.6-0.3-0.6-0.6c0-0.4,0.3-0.6,0.6-0.6h2.2l-2.2-2.2c-0.3-0.3-0.3-0.6,0-0.9c0.3-0.3,0.6-0.3,0.9,0l3.4,3.4
          l0,0l0.3,0.3v-2.2c0-0.4,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6v3.5l2.2,2.2v-2.2c0-0.4,0.3-0.6,0.6-0.6c0.4,0,0.6,0.3,0.6,0.6v3.5
          l2.2,2.2v-2.2c0-0.4,0.3-0.6,0.6-0.6c0.4,0,0.6,0.3,0.6,0.6v3.5l1.3,1.3c-0.4,0.4-0.5,0.5-0.9,0.9L494.8,299.2z M525.7,281.9h-3.5
          l-1.2,1.2l0,0l-1,1h2.2c0.4,0,0.6,0.3,0.6,0.6c0,0.4-0.3,0.6-0.6,0.6h-3.5l-2.2,2.2h2.2c0.4,0,0.6,0.3,0.6,0.6
          c0,0.4-0.3,0.6-0.6,0.6h-3.5c-0.1,0.1-2.8,2.8-2.7,2.7c-0.3-0.6-0.4-0.6-0.8-1.2l1.3-1.3v-3.5c0-0.4,0.3-0.6,0.6-0.6
          c0.4,0,0.6,0.3,0.6,0.6v2.2l0.3-0.3l0,0l1.9-1.9v-3.5c0-0.4,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6v2.3l0.3-0.3l0,0l1.9-1.9v-3.5
          c0-0.4,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6v2.2l0.3-0.3l0,0l3.4-3.4c0.3-0.3,0.6-0.3,0.9,0c0.3,0.3,0.3,0.6,0,0.9l-2.3,2.3h2.2
          c0.4,0,0.6,0.3,0.6,0.6C526.4,281.5,526.1,281.9,525.7,281.9z"/>
        </g>
      </svg>
    );
  }

}

export default MenteeTrophy;