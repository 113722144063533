import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { getUserDocument } from '../firebase';

import { WithTrans } from '../i18n/i18nWrapper';

import Loader from './Loader';
import HighFiveIllustration from './SignUp/Illustrations/HighFive'

class PopupMatch extends Component {

  state = {
    name: '',
  }

  componentDidMount = async () => {
    const userId = this.props.popup.userId;
    let name = '';
    if(userId !== 'careermentor') {
      // Get user image
      const user = await getUserDocument(userId);
      if(user) {
        name = user.name;
      }
    }
    this.setState({ name });
  }

  render() {
    return (
      <div className="Popup Popup--match">
        <h3 className="Popup__title">{this.props.t('popups.you_have_a_new_match_with')} {this.state.name ? this.state.name + '!' : <Loader color='green' />}</h3>
        <div className="Popup__illustration">
          <HighFiveIllustration/>
        </div>
        <div className="Popup__action">
          <Link to={this.props.popup.link} onClick={ () => this.props.toggleModal()} className="Button">{this.props.t('popups.go_to_conversation')}</Link>
        </div>
      </div>
    );

  }

}

export default WithTrans(PopupMatch);