import React, { useContext } from 'react';
import CollabPreview from './CollabPreview';
import CollabSkeleton from './CollabSkeleton';

import { CollabsContext } from '../providers/CollaborationsProvider';

import { WithTrans } from  '../i18n/i18nWrapper';

const Collaborations = (props) => {
  const collaborations = useContext(CollabsContext);
  const skeletons =[1,2,3,4];
  return (
    <div className="Collaborations">
      {collaborations && collaborations.length > 0 ? (
        <ul className="list-unstyled">
          {collaborations.map((collab, index) =>
            <CollabPreview collab={collab} key={index} />
          )}
        </ul>
      ) : (
        <ul className="list-unstyled">
          {skeletons.map((skeleton) =>
            <CollabSkeleton key={skeleton} />
          )}
        </ul>
      )}
      <div>
        <p style={{maxWidth: 1200}}>{props.t('collabs.we_update_write_to')} <a href="mailto:kontakt@careermentor.dk">kontakt@careermentor.dk</a> {props.t('collabs.if_you_know_any')}</p>
      </div>
    </div>
  )
}

export default WithTrans(Collaborations);