import React, { Component } from 'react';
import { TweenLite, TimelineLite, Elastic, Power0, Power2 } from "gsap/TweenMax";

class Celebration extends Component {

  componentDidMount() {
    // Objects
    const illustration = document.getElementById('Celebration');
    const logo = document.getElementById('Celebration_Logo');
    const confetti = document.getElementById('Celebration_Confetti');
    const baloonOne = document.getElementById('Celebration_BaloonOrange');
    const baloonTwo = document.getElementById('Celebration_BaloonGreen');
    const baloonThree = document.getElementById('Celebration_BaloonDarkGreen');
    const man = document.getElementById('Celebration_Man');
    const woman = document.getElementById('Celebration_Woman');
    const plusOne = document.getElementById('Plus_1');
    const plusTwo = document.getElementById('Plus_2');
    const plusThree = document.getElementById('Plus_3');
    const plusFour = document.getElementById('Plus_4');
    const plusFive = document.getElementById('Plus_6');
    const starOne = document.getElementById('Star_1');
    const starTwo = document.getElementById('Star_2');
    const starThree = document.getElementById('Star_3');
    const starFour = document.getElementById('Star_4');
    const starFive = document.getElementById('Star_5');
    const starSix = document.getElementById('Star_6');
    const starSeven = document.getElementById('Star_7');
    const circleOne = document.getElementById('Circle_one');
    const circleTwo = document.getElementById('Circle_two');
    const circleThree = document.getElementById('Circle_three');
    const circleFour = document.getElementById('Circle_four');
    const circleFive = document.getElementById('Circle_five');

    // Check if used in a achievement context and if its locked
    const parentElement = illustration.parentElement;
    if(parentElement.classList.contains('Achievement__illustration--locked')) {
      // Dont play yet
    } else {
      // Set initial state
      TweenLite.set([logo, confetti, baloonOne, baloonTwo, baloonThree, man, woman], {
        opacity: 0
      });
      TweenLite.set([plusOne, plusTwo, plusThree, plusFour, plusFive, starOne, starTwo, starThree, starFour, starFive, starSix, starSeven], {
        scale: 3.1,
        transformOrigin: '50% 50%'
      });
      TweenLite.set([circleOne, circleTwo, circleThree, circleFour, circleFive], {
        scale: 1.6,
        transformOrigin: '50% 50%'
      });
      TweenLite.set(logo, {
        y: 30,
        scale: 0.5,
        transformOrigin: '50% 50%'
      });
      TweenLite.set(confetti, {
        y: 30,
        scale: 0.5,
        transformOrigin: '50% 50%'
      });
      TweenLite.set(man, {
        x: 50,
      });
      TweenLite.set(woman, {
        x: -50,
      });

      // Timeline
      const timeline = new TimelineLite({ paused: true });

      timeline.to(logo, 1, {
        opacity: 1,
        y: 0,
        scale: 1,
        ease: Elastic.easeOut.config(1,0.3),
      }).to(confetti, 0.8, {
        opacity: 1,
        y: 0,
        scale: 1,
        ease: Elastic.easeOut.config(0.5,0.3),
      },'-=0.5').to([baloonOne,baloonTwo,baloonThree], 0.2, {
        opacity: 1,
      },'-=0.5').to(baloonOne, 4, {
        y: -2000,
        ease: Power0.easeNone
      },'-=0.5').to(baloonTwo, 3.5, {
        y: -2000,
        ease: Power0.easeNone
      },'-=4').to(baloonThree, 3, {
        y: -2000,
        ease: Power0.easeNone
      },'-=4').to(woman, 1, {
        opacity: 1,
        x: 0,
        ease: Power2.easeOut
      },'-=3.8').to(man, 1, {
        opacity: 1,
        x: 0,
        ease: Power2.easeOut
      },'-=3.4').to([starOne, starThree, starFour, starFive], 2, {
        rotation: 360,
        yoyo: true, 
        repeat: -1,
        ease: Power0.easeNone
      },'-=3.4').to([starTwo, starSix, starSeven], 2, {
        rotation: 360,
        yoyo: true,
        delay: 1,
        repeat: -1,
        ease: Power0.easeNone
      },'-=2').to([plusOne, plusFour], 1.5, {
        opacity: 0,
        yoyo: true,
        repeat: -1,
        ease: Power0.easeNone
      },'-=2');

      // Play
      setTimeout(() => {
        timeline.play();
      }, 500);
    }

  }

  render() {
    return (
      <svg id="Celebration" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1144 652.5" enableBackground="new 0 0 1144 652.5" xmlSpace="preserve">
        <g id="Celebration_Background">
          <path opacity="0.2" fill="#4EA39A" enableBackground="new" d="M1141.7,419.3c0.2-58.6-28.2-113.6-76-147.4 c0.7-6.2,1-12.5,1-18.8c0-99.1-79.3-179.5-177-179.5c-30.1,0-59.7,7.7-85.9,22.5c-69.2-45.5-159.5-73.1-258.3-73.1 C384.7,23.1,246.3,96,185,200.6c-3.4-0.2-6.8-0.3-10.3-0.3c-97.7,0-177,80.4-177,179.5c-0.1,35,10,69.3,29.1,98.7l0,0 c0,89.2,84.2,161.5,188,161.5h753.1c81.5,0,147.6-56.8,147.6-126.8l0,0C1132.7,484.9,1141.8,452.4,1141.7,419.3z"/>
        </g>
        <g id="Celebration_BaloonOrange">
          <polygon fill="#FE9236" points="454.7,616.3 445.9,616.3 447.5,611 452.7,611 		"/>
          <path fill="#FE9236" d="M486.3,561.1c0,20-16.2,49.3-36.2,49.3c-20,0-36.2-29.3-36.2-49.3c0-20,16.2-36.2,36.2-36.2
            C470.1,524.9,486.3,541.1,486.3,561.1L486.3,561.1z"/>
          <polygon fill="#FE841D" points="452.7,611.3 447.5,611.3 444.4,609.5 455.8,609.5 		"/>
          <path opacity="0.1" fill="#FFFFFF" d="M437,533c0,0-15.3,8.3-17.4,24.8"/>
        </g>
        <g id="Celebration_BaloonGreen">
          <path fill="#3AD29F" d="M780,538.3c0,20-16.2,49.3-36.2,49.3c-20,0-36.2-29.3-36.2-49.3c0-20,16.2-36.2,36.2-36.2
            S780,518.3,780,538.3z"/>
          <polygon fill="#3AD29F" points="748.4,593.4 739.7,593.4 741.2,588.2 746.5,588.2 		"/>
          <polygon fill="#32B78B" points="746.5,588.4 741.2,588.4 738.2,586.7 749.5,586.7 		"/>
          <path opacity="0.1" fill="#FFFFFF" d="M730.8,510.1c0,0-15.3,8.3-17.4,24.8"/>
        </g>
        <g id="Celebration_BaloonDarkGreen">
          <polygon fill="#4EA39A" points="562.3,555.8 553.5,555.8 555.1,550.6 560.3,550.6 	"/>
          <path fill="#4EA39A" d="M593.9,500.7c0,20-16.2,49.3-36.2,49.3c-20,0-36.2-29.3-36.2-49.3c0-20,16.2-36.2,36.2-36.2
            C577.7,464.5,593.9,480.7,593.9,500.7L593.9,500.7z"/>
          <polygon fill="#428880" points="560.3,550.8 555.1,550.8 552,549.1 563.3,549.1 	"/>
          <path opacity="0.1" fill="#FFFFFF" d="M544.6,472.6c0,0-15.3,8.3-17.4,24.8"/>
        </g>
        <g id="Celebration_Logo">
          <path id="Logo_Background" opacity="0.9" fill="#FFFFFF" d="M681.9,449.2H462.1c-6.6,0-12-5.4-12-12V217.4c0-6.6,5.4-12,12-12	h219.8c6.6,0,12,5.4,12,12v219.8C693.9,443.8,688.5,449.2,681.9,449.2z"/>
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="491.8215" y1="384.5003" x2="545.1548" y2="329.5528" gradientTransform="matrix(1 1.998856e-04 -1.998856e-04 1 6.2144 -2.164)">
            <stop offset="1.364087e-03" style={{ stopColor: '#FF7F45' }} />
            <stop offset="1" style={{ stopColor: '#FF7F45' }} />
          </linearGradient>
          <path fill="url(#SVGID_2_)" d="M500.3,304.4c-1,0-1.8,0.9-1.8,2l0.1,75.7c0,1.1,0.8,2,1.8,2l69.5-0.1c1,0,1.8-0.9,1.8-2
            C571.7,339.2,539.6,304.3,500.3,304.4z"/>
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="535.4688" y1="383.8528" x2="611.4688" y2="305.5525" gradientTransform="matrix(1 1.998856e-04 -1.998856e-04 1 6.2144 -2.164)">
            <stop  offset="0.1452" style={{ stopColor: '#4FA39A' }} />
            <stop  offset="1" style={{ stopColor: '#4FA39A' }}/>
          </linearGradient>
          <path fill="url(#SVGID_3_)" d="M545,270.5c-1.4,0-2.6,1.3-2.6,2.8l0,107.9c0,1.6,1.2,2.8,2.6,2.8l99.1,0c1.4,0,2.6-1.3,2.6-2.8	C646.6,320.2,601,270.5,545,270.5z"/>
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="491.8215" y1="384.5003" x2="545.1548" y2="329.5528" gradientTransform="matrix(1 1.998856e-04 -1.998856e-04 1 6.2144 -2.164)">
            <stop  offset="1.364087e-03" style={{ stopColor: '#FF7F45' }} />
            <stop  offset="1" style={{ stopColor: '#FFAA45' }} />
          </linearGradient>
          <path opacity="0.7" fill="url(#SVGID_4_)" d="M500.3,304.4c-1,0-1.8,0.9-1.8,2l0.1,75.7c0,1.1,0.8,2,1.8,2l69.5-0.1
            c1,0,1.8-0.9,1.8-2C571.7,339.2,539.6,304.3,500.3,304.4z"/>
        </g>
        <g id="Celebration_Confetti">
          <g id="Plus_5" opacity="0.5">
            <rect x="417.3" y="229.1" fill="#3AD29F" width="1.6" height="8.9"/>
            <rect x="413.7" y="232.8" fill="#3AD29F" width="8.9" height="1.6"/>
          </g>
          <g id="Plus_4" opacity="0.5">
            <rect x="496.2" y="467.1" fill="#3AD29F" width="1.6" height="8.9"/>
            <rect x="492.6" y="470.7" fill="#3AD29F" width="8.9" height="1.6"/>
          </g>
          <g id="Plus_3" opacity="0.5">
            <rect x="395" y="319.5" fill="#3AD29F" width="1.6" height="8.9"/>
            <rect x="391.3" y="323.2" fill="#3AD29F" width="8.9" height="1.6"/>
          </g>
          <g id="Plus_2" opacity="0.5">
            <rect x="670.3" y="167.9" fill="#3AD29F" width="1.6" height="8.9"/>
            <rect x="666.7" y="171.6" fill="#3AD29F" width="8.9" height="1.6"/>
          </g>
          <g id="Plus_1" opacity="0.5">
            <rect x="421" y="174.1" fill="#3AD29F" width="1.6" height="8.9"/>
            <rect x="417.3" y="177.8" fill="#3AD29F" width="8.9" height="1.6"/>
          </g>
          <path id="Star_7" opacity="0.5" fill="#4D8AF0" d="M547.5,158c-0.9-0.4-1.4-1.4-1.1-2.3
            c0-0.1,0-0.1,0-0.2l0,0c0.1-0.5-0.3-1-0.8-1.1c-0.4-0.1-0.7,0.1-0.9,0.4l0,0c0,0.1-0.1,0.1-0.1,0.2c-0.4,0.9-1.4,1.4-2.3,1.1
            c-0.1,0-0.1,0-0.2,0l0,0c-0.5-0.1-1,0.3-1.1,0.8c-0.1,0.4,0.1,0.7,0.4,0.9l0,0c0.1,0,0.1,0.1,0.2,0.1c0.9,0.4,1.4,1.4,1.1,2.3
            c0,0.1,0,0.1,0,0.2l0,0c-0.1,0.5,0.3,1,0.8,1.1c0.4,0.1,0.7-0.1,0.9-0.4l0,0c0-0.1,0.1-0.1,0.1-0.2c0.4-0.9,1.4-1.4,2.3-1.1
            c0.1,0,0.1,0,0.2,0l0,0c0.5,0.1,1-0.3,1.1-0.8c0.1-0.4-0.1-0.7-0.4-0.9l0,0C547.6,158.1,547.5,158,547.5,158z"/>
          <path id="Star_6" opacity="0.5" fill="#4D8AF0" d="M436.7,391.2c-0.9-0.4-1.4-1.4-1.1-2.3
            c0-0.1,0-0.1,0-0.2l0,0c0.1-0.5-0.3-1-0.8-1.1c-0.4-0.1-0.7,0.1-0.9,0.4l0,0c0,0.1-0.1,0.1-0.1,0.2c-0.4,0.9-1.4,1.4-2.3,1.1
            c-0.1,0-0.1,0-0.2,0l0,0c-0.5-0.1-1,0.3-1.1,0.8c-0.1,0.4,0.1,0.7,0.4,0.9l0,0c0.1,0,0.1,0.1,0.2,0.1c0.9,0.4,1.4,1.4,1.1,2.3
            c0,0.1,0,0.1,0,0.2l0,0c-0.1,0.5,0.3,1,0.8,1.1c0.4,0.1,0.7-0.1,0.9-0.4l0,0c0-0.1,0.1-0.1,0.1-0.2c0.4-0.9,1.4-1.4,2.3-1.1
            c0.1,0,0.1,0,0.2,0l0,0c0.5,0.1,1-0.3,1.1-0.8c0.1-0.4-0.1-0.7-0.4-0.9l0,0C436.8,391.3,436.7,391.3,436.7,391.2z"/>
          <path id="Star_5" opacity="0.5" fill="#4D8AF0" d="M428.9,476c-0.9-0.4-1.4-1.4-1.1-2.3
            c0-0.1,0-0.1,0-0.2l0,0c0.1-0.5-0.3-1-0.8-1.1c-0.4-0.1-0.7,0.1-0.9,0.4l0,0c0,0.1-0.1,0.1-0.1,0.2c-0.4,0.9-1.4,1.4-2.3,1.1
            c-0.1,0-0.1,0-0.2,0l0,0c-0.5-0.1-1,0.3-1.1,0.8c-0.1,0.4,0.1,0.7,0.4,0.9l0,0c0.1,0,0.1,0.1,0.2,0.1c0.9,0.4,1.4,1.4,1.1,2.3
            c0,0.1,0,0.1,0,0.2l0,0c-0.1,0.5,0.3,1,0.8,1.1c0.4,0.1,0.7-0.1,0.9-0.4l0,0c0-0.1,0.1-0.1,0.1-0.2c0.4-0.9,1.4-1.4,2.3-1.1
            c0.1,0,0.1,0,0.2,0l0,0c0.5,0.1,1-0.3,1.1-0.8c0.1-0.4-0.1-0.7-0.4-0.9l0,0C429,476,428.9,476,428.9,476z"/>
          <path id="Star_4" opacity="0.5" fill="#4D8AF0" d="M715.6,288.5c-0.9-0.4-1.4-1.4-1.1-2.3
            c0-0.1,0-0.1,0-0.2l0,0c0.1-0.5-0.3-1-0.8-1.1c-0.4-0.1-0.7,0.1-0.9,0.4l0,0c0,0.1-0.1,0.1-0.1,0.2c-0.4,0.9-1.4,1.4-2.3,1.1
            c-0.1,0-0.1,0-0.2,0l0,0c-0.5-0.1-1,0.3-1.1,0.8c-0.1,0.4,0.1,0.7,0.4,0.9l0,0c0.1,0,0.1,0.1,0.2,0.1c0.9,0.4,1.4,1.4,1.1,2.3
            c0,0.1,0,0.1,0,0.2l0,0c-0.1,0.5,0.3,1,0.8,1.1c0.4,0.1,0.7-0.1,0.9-0.4l0,0c0-0.1,0.1-0.1,0.1-0.2c0.4-0.9,1.4-1.4,2.3-1.1
            c0.1,0,0.1,0,0.2,0l0,0c0.5,0.1,1-0.3,1.1-0.8c0.1-0.4-0.1-0.7-0.4-0.9l0,0C715.7,288.5,715.6,288.5,715.6,288.5z"/>
          <path id="Star_3" opacity="0.5" fill="#3AD29F" d="M721.6,378.9c-0.9-0.4-1.4-1.4-1.1-2.3
            c0-0.1,0-0.1,0-0.2l0,0c0.1-0.5-0.3-1-0.8-1.1c-0.4-0.1-0.7,0.1-0.9,0.4l0,0c0,0.1-0.1,0.1-0.1,0.2c-0.4,0.9-1.4,1.4-2.3,1.1
            c-0.1,0-0.1,0-0.2,0l0,0c-0.5-0.1-1,0.3-1.1,0.8c-0.1,0.4,0.1,0.7,0.4,0.9l0,0c0.1,0,0.1,0.1,0.2,0.1c0.9,0.4,1.4,1.4,1.1,2.3
            c0,0.1,0,0.1,0,0.2l0,0c-0.1,0.5,0.3,1,0.8,1.1c0.4,0.1,0.7-0.1,0.9-0.4l0,0c0-0.1,0.1-0.1,0.1-0.2c0.4-0.9,1.4-1.4,2.3-1.1
            c0.1,0,0.1,0,0.2,0l0,0c0.5,0.1,1-0.3,1.1-0.8c0.1-0.4-0.1-0.7-0.4-0.9l0,0C721.7,378.9,721.7,378.9,721.6,378.9z"/>
          <path id="Star_2" opacity="0.5" fill="#4D8AF0" d="M712.3,199.7c-0.9-0.4-1.4-1.4-1.1-2.3
            c0-0.1,0-0.1,0-0.2l0,0c0.1-0.5-0.3-1-0.8-1.1c-0.4-0.1-0.7,0.1-0.9,0.4l0,0c0,0.1-0.1,0.1-0.1,0.2c-0.4,0.9-1.4,1.4-2.3,1.1
            c-0.1,0-0.1,0-0.2,0l0,0c-0.5-0.1-1,0.3-1.1,0.8c-0.1,0.4,0.1,0.7,0.4,0.9l0,0c0.1,0,0.1,0.1,0.2,0.1c0.9,0.4,1.4,1.4,1.1,2.3
            c0,0.1,0,0.1,0,0.2l0,0c-0.1,0.5,0.3,1,0.8,1.1c0.4,0.1,0.7-0.1,0.9-0.4l0,0c0-0.1,0.1-0.1,0.1-0.2c0.4-0.9,1.4-1.4,2.3-1.1
            c0.1,0,0.1,0,0.2,0l0,0c0.5,0.1,1-0.3,1.1-0.8c0.1-0.4-0.1-0.7-0.4-0.9l0,0C712.4,199.7,712.3,199.7,712.3,199.7z"/>
          <path id="Star_1" opacity="0.5" fill="#4D8AF0" d="M742.6,432.1c-0.9-0.4-1.4-1.4-1.1-2.3
            c0-0.1,0-0.1,0-0.2l0,0c0.1-0.5-0.3-1-0.8-1.1c-0.4-0.1-0.7,0.1-0.9,0.4l0,0c0,0.1-0.1,0.1-0.1,0.2c-0.4,0.9-1.4,1.4-2.3,1.1
            c-0.1,0-0.1,0-0.2,0l0,0c-0.5-0.1-1,0.3-1.1,0.8c-0.1,0.4,0.1,0.7,0.4,0.9l0,0c0.1,0,0.1,0.1,0.2,0.1c0.9,0.4,1.4,1.4,1.1,2.3
            c0,0.1,0,0.1,0,0.2l0,0c-0.1,0.5,0.3,1,0.8,1.1c0.4,0.1,0.7-0.1,0.9-0.4l0,0c0-0.1,0.1-0.1,0.1-0.2c0.4-0.9,1.4-1.4,2.3-1.1
            c0.1,0,0.1,0,0.2,0l0,0c0.5,0.1,1-0.3,1.1-0.8c0.1-0.4-0.1-0.7-0.4-0.9l0,0C742.7,432.1,742.7,432.1,742.6,432.1z"/>
          <circle id="Circle_five" opacity="0.5" fill="#FE9236" cx="392.8" cy="270.7" r="3.1"/>
          <circle id="Circle_four" opacity="0.5" fill="#FE9236" cx="731.8" cy="249.9" r="3.1"/>
          <circle id="Circle_three" opacity="0.5" fill="#FE9236" cx="751.9" cy="331.5" r="3.1"/>
          <circle id="Circle_two" opacity="0.5" fill="#FE9236" cx="497.6" cy="190.2" r="3.5"/>
          <circle id="Circle_one" opacity="0.5" fill="#FE9236" cx="699.5" cy="461.4" r="3.1"/>
        </g>
        <g id="People">
          <g id="Celebration_Man">
              <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="954.0669" y1="13.4213" x2="954.0669" y2="415.783" gradientTransform="matrix(1 0 0 -1 -28 528.26)">
              <stop  offset="0" style={{ stopColor: '#808080', stopOpacity: '0.25' }}/>
              <stop  offset="0.54" style={{ stopColor: '#808080', stopOpacity: '0.12' }}/>
              <stop  offset="1" style={{ stopColor: '#808080', stopOpacity: '0.1' }}/>
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M1051.8,329.9c-1.4-1.1-3.1-1.8-4.9-2.1c-1.9-0.4-3.8-0.7-5.7-1l-24.6-3.5c-2.5-0.5-5-0.6-7.5-0.4
              c-2.6,0.2-4.9,1.4-6.6,3.4c-0.7,0.9-1.1,2-1.1,3.2c0.1,1.2,0.5,2.3,1.1,3.3c0.2,0.5,0.4,1,0.6,1.5c0.2,1.1,0.5,2.2,0.9,3.4
              c0.1,0.2,0.3,0.5,0.4,0.7c0,0.1,0,0.1,0,0.2c-0.3,1.4,0.6,2.8,0.2,4.1c-0.3,1.1-1.5,1.8-2.2,2.8c-1,1.4-1,3.2-0.1,4.6
              c0.8,1.2,2.1,2,2.5,3.3c0.5,1.8-0.9,3.4-2.1,4.8c-2.3,2.5-4.4,5.1-6.3,7.9c-0.5,0.7-0.9,1.5-1,2.4c-0.1,1.7,1.6,3.3,1.3,5
              c-0.2,1.4-1.6,2.2-2.7,3c-4.2,2.8-7.7,7.3-8,12.4c-1.1-8.1-4.4-15.7-9.7-22c-1.4-1.7-3-3.5-3.6-5.6c-0.2-0.9-0.3-1.8-0.6-2.6
              c-1-2.6-4.2-3.8-5.6-6.2c-1-1.7-1-3.9-2.2-5.4s-3.2-1.9-4.3-3.3c-0.5-1-1-2-1.4-3.1c-0.4-0.9-1.1-1.6-1.8-2.2
              c-0.1-0.1-0.3-0.2-0.5-0.3c0.3-0.3,0.5-0.7,0.5-1.2c0-1.2-1.2-1.9-2.2-2.5c-1.3-0.7-2.6-1.5-3.8-2.4c1.4-0.6,2.5-1.6,3.4-2.8
              c1.1-1.8,1.6-3.9,1.3-6c-0.1-1.7-0.7-3.3-1.8-4.6c-0.6-0.7-1.4-1.1-2.1-1.8c-1.7-1.7-2.3-4.1-3-6.4c-0.9-3-2.4-6.1-5.2-7.7
              c-1.3-0.8-2.9-1.1-4.2-1.9s-2.4-2.3-1.9-3.8c0.3-1,1.2-1.7,1.9-2.5c1.6-2.3,1-5.4-1.3-7c-0.7-0.5-1.7-0.9-1.8-1.7
              c-1.2-0.3-2.1-1.3-2.3-2.5c-0.2-1.2-0.2-2.4,0-3.6c0.3-1.9,0.5-3.7,0.8-5.6c0.9,0.4,1.9,0.4,2.8,0c0.9,1.4,1.9,2.7,3,3.9
              c3,3.6,5.4,7.7,7,12.1c0.6,1.6,1.2,3.4,2.6,4.2c0.5,0.3,1.1,0.4,1.6,0.8c0.6,0.4,1.1,1,1.6,1.6c1.2,1.6,2.4,3.2,3.6,4.8l0.1,0.2
              c0,0.1,0.1,0.3,0.2,0.4c0.2,0.2,0.5,0.4,0.9,0.4c3.7,0.9,7.8,1.6,11-0.4c2.9-1.8,4.1-5.4,6.6-7.7c1.7-1.7,4.2-3,4.7-5.4
              c-0.6-0.2-1.2-0.4-1.7-0.6c0.5-0.5,1-1.1,1.7-1.7c1-1,1.9-2,2.7-3.2c0.8-1.4,1.5-2.9,2-4.5c1.9-5.5,3.9-11,5.8-16.5
              c0.9-2.6,1.8-5.2,3-7.7c1.2-2.7,2.6-5.4,3.9-8.1c3.4-7,6-14.3,7.8-21.9c0.3-1,0.4-2.1,0.3-3.2c-0.3-1.7-1.6-3.1-2.8-4.3
              c-1.3-1.4-2.7-2.5-4.3-3.5c-1.6-1-3.5-1.3-5.3-0.9c-2.6,0.8-4.2,3.4-4.8,6s-0.4,5.3-0.6,8c-0.4,3.9-1.7,7.6-2,11.4
              c-0.2,2.2,0,4.5-0.3,6.7c-0.5,2.9-1.5,5.7-3,8.3c-3.2,6.1-7.6,11.5-12.9,15.9c-0.6,0.5-1.2,1-1.8,1.5c0-0.4,0.1-0.9,0.1-1.3
              c-0.4,0.1-0.9,0.3-1.3,0.5c0.1-0.5,0-1-0.2-1.5c-0.8-2.4-4.4-3-5-5.4c-0.1-0.6-0.2-1.2-0.2-1.8c-0.2-2.1-1.7-3.9-2.2-6
              c-0.5-1.9-0.1-4-1-5.8c-0.8-1.1-1.7-2.1-2.7-3c-1.2-1.4-2.1-3-2.5-4.8c-0.1-0.5-0.2-1-0.4-1.5c0.2-1.1,0-2.2-0.4-3.3
              c-0.9-2.3-3.3-3.7-4.1-6c-0.7-1.8-0.4-4-1.6-5.4c-0.7-0.7-1.5-1.1-2.4-1.4c-4.6-1.8-9.4-2.9-14.3-3.4c-2.7-0.2-5.8-0.4-7.7-2.3
              c-2.3-2.2-2.4-6.3-5.1-8c-2.4-1.5-5.7-0.4-7.5,1.8c-0.3,0.3-0.5,0.7-0.8,1c-0.3-1.3-0.4-2.7-0.2-4.1c0-0.3,0.1-0.7,0.1-1
              c4.5-1.5,8.4-4.5,10.8-8.6c0,0,0,0,0,0.1l0.1,0.3c0.3,0.7,0.8,1.4,1.4,1.5l0,0c0.5,0,0.9-0.2,1.2-0.5c0.6-0.4,1.2-0.9,1.7-1.4
              c0.3-0.3,0.5-0.6,0.7-0.9c0.5-0.6,0.9-1.3,1.2-2c0.5-1.1,0.8-2.2,1-3.4c0.2-1.2,0.2-2.4,0.4-3.5c0.2-0.9,0.4-1.8,0.7-2.6
              c0.1-0.3,0.2-0.6,0.3-0.9s0.2-0.7,0.3-1c0.6-2.1,1-4.2,1-6.3c0-0.4,0-0.7,0-1.1c-0.1-2.8-1.3-5.5-3.4-7.5c-1.7-1.4-4-2-5.3-3.9
              c-0.8-1.3-1-2.9-1.5-4.4c-1.1-3.7-4.1-6.4-7.9-7.1c-2.2-0.2-4.4,0.4-6.2,1.7l0,0l-0.1,0.1l0,0c-1,0.8-1.8,1.7-2.5,2.8
              c-1.3-0.4-2.5-1.1-3.5-2c-1-0.9-1.8-2.1-2.8-3c-1.9-1.8-4.7-2.3-7.1-1.5c-2.5,1-4.4,3.2-5.1,5.8c-0.5,2.1-0.5,4.4-1.6,6.1
              c-0.9,1.3-2.3,2-3.4,3.2c-0.6,0.8-1.1,1.7-1.3,2.7l0,0c-0.1,0.5-0.2,1.1-0.2,1.7l0,0c0,0.2,0,0.5,0.1,0.7c0,0,0,0.1,0,0.1
              c0,0.3,0.1,0.5,0.2,0.8l0,0c0.3,1,0.8,2,1.5,2.7c0.7,0.8,1.5,1.4,2.4,1.7c0.4,0.1,0.8,0.3,1.2,0.4l1.2,0.3l0.4,0.1
              c-2.9,5.5-3.1,12-0.6,17.6c0,0.3-0.1,0.6-0.1,0.8c-0.2,3.9,0,7.9,0.5,11.8c0.1,1.5,0.2,3.3-0.7,4.5c-0.4,0.5-0.9,0.9-1.5,1.3
              c0.5-0.7,1.1-1.4,1.7-2.1c-1.4-1.4-3.6-1.6-5.6-1.5c-3.9,0.3-7.6,1.4-11,3.4c-2.2,1.3-4.5,3-7.1,2.7c-1.5-0.2-3-1-4.5-0.6
              c-0.8,0.3-1.6,0.8-2.3,1.4c-0.5,0.4-1.2,0.6-1.9,0.6c-1.6-1.9-4-3.2-5.9-4.8c-2.1-1.9-4.1-4-5.8-6.3c-3.2-3.9-6.5-7.8-9.7-11.8
              c-2.7-3.3-5.4-6.9-5.9-11.2c0-0.4-0.1-0.8-0.1-1.3c0.1-2,0.3-4,0.8-6c0.1-0.5,0.1-1,0-1.5c-0.3-1-1.5-1.4-2.4-1.6l0,0
              c0.1-0.8,0.2-1.6,0.3-2.3c0.6-8.6,0-17.3,1.8-25.8c0.7-3.2,1.8-6.5,4.2-8.9c3.7-3.7,9.8-4.6,12.6-8.9c2.3-3.6,1.4-8.8-1.5-12
              s-7.5-4.5-11.8-4.4c-1.2,0-2.4,0.3-3.4,0.9c-1,0.9-1.7,1.9-2.1,3.2l-4.3,10.4c-1.2,2.7-2.3,5.5-3.1,8.4c-1.2,4.4-1.5,9-2.4,13.4
              c0,3.4-2.2,7.6-3.4,10.8c-1.3,3.5-2.8,7-4,10.6c-0.3,0.9-1.3,3.6-2,6c-0.2,0.1-0.5,0.3-0.7,0.5c-0.5,0.7-1,1.4-1.4,2.1
              c-0.9,1.2-2.4,1.8-3.4,3s-1.2,3.3,0.2,3.9c0.3,0.1,0.6,0.2,0.9,0.2c0.2,0,0.4,0,0.5,0.1c1.5,0.4,2.1,2.3,1.9,3.9s-1,3-1.1,4.6
              c-0.3,2.8,1.3,5.3,2.9,7.6c1.6,2.5,3.4,4.9,5.3,7.2c1.4,1.6,3,3,4.4,4.5c5.4,5.6,9.7,12.1,14.6,18.1c1.7,2.3,3.7,4.4,5.9,6.2
              c1.4,1.1,2.9,2.2,4.5,3.1l8.4,5.3c0.9,0.7,2,1.2,3.2,1.5c0.4,0,0.7,0,1.1,0c0,0.2,0.1,0.5,0.2,0.7c0.7,2.6,2.1,5,2.2,7.7
              c0.2,2.7-0.1,5.4,0.1,8.1c0.2,2.5,0.7,5,0.9,7.5c0.3,4.7-0.9,9.4-2,14c-1.7,6.8-2.7,13.7-6,19.8c-0.9,1.7-2,3.4-2,5.3
              c0,0.2,0,0.5,0.1,0.7l0,0l-1.5,0.9c-0.4,0.2-0.9,0.5-1.3,0.8c-1.9,1.2-3.9,2.6-4.7,4.8c-0.6,1.7-0.3,3.7-1.1,5.4
              c0,0.1-0.1,0.1-0.1,0.2c-0.8,1.6-2.4,2.7-2.8,4.4l0,0c-0.3,1.3,0.2,2.7,0,4c0,0,0,0,0,0c-0.2,0.7-0.4,1.3-0.5,2
              c0,0.2,0,0.4,0.1,0.7c0.2,0.8,0.6,1.4,1.2,2c0.4,0.3,0.8,0.7,1.2,0.9c0.4,0.3,0.9,0.5,1.3,0.8l0.3,0.1c0.4,0.2,0.8,0.4,1.2,0.6
              l0.3,0.1c0.5,0.2,0.9,0.4,1.4,0.5l0.3,0.1c0.4,0.1,0.7,0.2,1.1,0.3l0.2,0.1l0.2,0l1,0.2l0.4,0.1c0.4,0.1,0.9,0.1,1.3,0.2h0.4
              c0.3,0,0.7,0,1,0.1h0.5c0.3,0,0.7,0,1,0h0.4c0.5,0,0.9-0.1,1.4-0.1h0.2c0.4-0.1,0.8-0.1,1.3-0.2l0.3-0.1c0.4-0.1,0.8-0.2,1.2-0.3
              l0.2-0.1c0.5-0.1,0.9-0.3,1.4-0.5c1.6-0.7,3.1-1.4,4.6-2.3c1-0.5,2-1.1,3-1.6c0.7-0.4,1.5-0.7,2.3-1c1.9-0.8,3.9-1.2,6-1
              c1,0.1,2,0.3,3,0.6c0,0.1,0,0.2,0,0.3c0.8,4.8,1.6,9.6,2.4,14.4c1.3-0.9,2.7-1.8,4.1-2.5c-0.3,0.7-0.4,1.5-0.5,2.2
              c-0.1,0.6-0.1,1.2,0,1.8c0.1,1,0.3,2-0.3,2.8c-0.3,0.5-1,0.7-1.2,1.2c-0.1,0.5-0.2,1.1-0.1,1.6c-0.1,1.3-1.1,2.3-2.4,2.4
              c0.6,1.7,0,3.5-1.5,4.5c-0.4,0.2-0.8,0.5-1.2,0.8c-0.3,0.4-0.6,1-0.7,1.5c-1.3,5-4,9.4-7.8,12.9c-1.4,1.1-2.7,2.4-3.9,3.8
              c-0.7,1.1-1.3,2.2-1.8,3.5c-2.7,6.5-5.4,13-8.1,19.6c-1.5,3.7-3,7.4-3.5,11.3c-1.2,10.8-10.8,19.9-9,30.6c0.4,2.7,1.4,5.2,3.1,7.3
              c2,2.4,5,3.8,8,4.9c7.7,3,15.8,4.6,23.9,6.3l12.7,2.6c2.5,0.6,5,1,7.6,1.2c3.1,0.2,6.2-0.1,9.2,0.7c1.8,0.5,3.6,1.4,5.5,1.5
              c1.6,0.1,3.7-0.3,4.6,1.1s0.2,3.1,1.1,4.3c1.5,1.9,5.1,0,6.7,1.8c0.4,0.7,0.8,1.4,1,2.2c0.2,0.5,0.6,0.9,1,1.3
              c0.1,0.6,0.1,1.1,0.1,1.7c-0.2,2.3-0.7,4.6-1.4,6.8c-1.2,4.1-2.4,8.1-3.6,12.2c-0.5,1.4-0.8,2.9-1,4.4c-0.1,0.7-0.1,1.5,0,2.2
              c0.2,2.2,1.5,4.5,3.6,5.2c1.6,0.4,3.4,0.2,4.9-0.6c1.4-0.7,2.8-1.7,4-2.7c2.2-1.7,4.2-3.7,5.8-5.9c5-7.5,3.4-17.6,6.9-25.9
              c2.3-5.5,6.9-10,8.4-15.8c0.4-1.1,0.4-2.3,0.1-3.4c-0.6-2-2.8-3.1-4.8-3.9h-0.1c-1.6-0.6-3.3-1.1-5-1.4c-0.4-0.1-0.8-0.2-1.3-0.2
              c-1-0.8-1.7-1.8-2.2-2.9c-0.1-0.6-0.2-1.2-0.5-1.7c-0.3-0.5-0.9-0.8-1.4-0.8c-1-0.1-2.3,0.3-3.4,0.2c-2.2-0.2-3.7-2.3-5.7-3.4
              s-4.2-0.8-6.3-0.6c-0.8,0.1-1.5,0.1-2.3-0.1c-0.9-0.4-1.7-1-2.4-1.7c-2.5-1.9-5.8-2.6-8.8-1.8c-1,0.4-2,0.6-3,0.7
              c-2-0.1-3.3-1.8-4.8-3.2c-3.9-3.6-9.5-4.6-14.3-6.9c-0.7-0.3-1.3-0.8-1.7-1.5c-0.5-1.4,0.7-2.7,1.8-3.7l8.2-6.8
              c9-7.5,20.2-13.1,24.4-24.1c0.4-1.5,1.1-2.9,2-4.1c0.3-0.4,0.7-0.7,1.1-1c1.8-1.2,4-1.6,6.2-1.2c2.6,0.6,5.1,1.7,7.2,3.2
              c3.6,2.2,7.1,4.5,10.5,7c3.4,2.4,6.5,5,9.6,7.8c5.2,5,9.6,10.9,14.5,16.3s10.5,10.4,17.3,13s15,2.5,20.8-1.9
              c6.7-5,8.9-13.9,10.6-22.1c3.8-3.6,4.9-9.1,6.7-14c1.2-3.1,2.6-6,3.9-9.1c1.5-3.6,2.6-7.4,3.8-11.1c1.3-3.7,2.2-7.6,2.7-11.6
              c0.5-6.2-1.2-12.9,0.6-18.7c2.3-0.5,4.4-1.8,6.7-2.6c3.4-1.1,7-0.9,10.6-0.9h0.2c1.2,0.1,2.5-0.2,3.5-0.8c1.2-1,1.4-2.7,1.5-4.3
              C1054.9,336.7,1054.7,332.4,1051.8,329.9z M906.9,159.3c-0.8,0.2-1.7,0.3-2.5,0.2L906.9,159.3z"/>
            <path fill="#BE6F72" d="M910.9,222.6c-1.2,1-2.6,1.8-4.2,2.2l-7.1,2.5c-3.8,1.4-7.9,2.8-11.9,2.3c-2.3-0.3-12-1.7-13.5-3.5
              c-2.2-2.7,5.6-6.8,8.3-9.1c0.6-0.4,1.2-0.9,1.7-1.5c1-1.2,0.9-2.9,0.7-4.5c-0.5-3.9-0.7-7.8-0.5-11.8c0.1-0.9,0.2-1.9,0.4-2.8
              c1-4.6,5.3-2.9,8.7-1.7c4.1,1.3,8,3,11.8,5.1c1.1,0.6,2.1,1.4,2.9,2.5c1.5,2.2,0.9,5.2,0.7,8c-0.2,2.2-0.2,4.3,1.2,5.9
              c0.7,0.6,1.3,1.3,1.9,2C912.5,219.8,912.1,221.5,910.9,222.6z"/>
            <path fill="#BE6F72" d="M812.9,150.5c1-4.4,1.2-9,2.4-13.4c0.8-2.9,1.9-5.6,3.1-8.4l4.4-10.3c0.4-1.2,1.1-2.3,2.1-3.1
              c1-0.6,2.2-1,3.4-0.9c4.3-0.2,8.8,1.2,11.8,4.4s3.9,8.3,1.5,11.9c-2.8,4.4-8.9,5.2-12.6,8.9c-2.3,2.4-3.5,5.6-4.2,8.9
              c-1.8,8.4-1.2,17.1-1.8,25.7c-0.2,2.7-0.7,5.7-3,7.1c-1.4,0.7-2.9,1-4.5,0.9l-6.6,0.1c-1.1,0-5.1,0.7-5.8-0.1
              c-1.3-1.5,1.6-8.7,2.2-10.3c1.2-3.6,2.6-7,4-10.6C810.7,158.1,812.9,153.9,812.9,150.5z"/>
            <path fill="#3F3D56" d="M948.4,464.7c-1.5,5.8-6,10.3-8.4,15.8c-3.5,8.2-1.9,18.3-6.9,25.8c-1.6,2.3-3.6,4.3-5.8,6
              c-1.2,1-2.6,1.9-4,2.7c-1.5,0.8-3.2,1-4.9,0.6c-2.1-0.7-3.4-3-3.6-5.2c-0.1-0.7-0.1-1.4,0-2.2c0.2-1.5,0.5-3,1-4.4
              c1.2-4,2.4-8.1,3.6-12.2c0.7-2.2,1.2-4.5,1.4-6.8c0.1-2.5-1.5-5.8-0.8-8.2c0.3-1.1,1.5-2,2-2.9c0.8-1.3,1.3-2.7,1.7-4.1
              c0.8-2.8,1.4-5.8,3.1-8.1c1.1-1.4,2.4-2.6,3.9-3.7c1.1-0.9,2.3-1.7,3.7-2.1c1.3-0.2,2.7-0.2,4,0.2c1.7,0.4,3.4,0.8,5,1.4h0.1
              c2,0.7,4.1,1.8,4.8,3.8C948.7,462.4,948.7,463.6,948.4,464.7z"/>
            <path fill="#3F3D56" d="M1054.8,341.7c-0.1,1.5-0.2,3.3-1.4,4.2c-1,0.7-2.3,1-3.5,0.8h-0.3c-3.6,0-7.2-0.2-10.6,0.9
              c-2.6,0.9-4.9,2.4-7.6,2.7c-3.7,0.4-7.2-1.6-10.4-3.4c-3.1-1.7-6.2-3.2-9.5-4.5c-2.1-0.9-6.5-1.4-7.5-3.8
              c-0.3-1.1-0.6-2.2-0.9-3.3c-0.2-0.5-0.4-1-0.6-1.5c-0.6-1-1-2.1-1.1-3.3c0-1.2,0.4-2.3,1.1-3.2c1.6-2,4-3.2,6.5-3.4
              c2.5-0.2,5.1-0.1,7.5,0.4l24.6,3.5c1.9,0.3,3.8,0.5,5.7,1c1.8,0.3,3.5,1,4.9,2.1C1054.7,333.7,1054.9,338,1054.8,341.7z"/>
            <path fill="#65617D" d="M1033.1,348.1c-3,6.3-0.8,13.8-1.4,20.8c-0.5,3.9-1.3,7.8-2.7,11.5c-1.2,3.7-2.3,7.5-3.8,11.1
              c-1.2,3-2.7,6-3.9,9.1c-1.8,4.9-3,10.4-6.7,14c-1.7,8.2-3.9,17.1-10.6,22.1c-5.8,4.3-14,4.5-20.8,1.9s-12.4-7.6-17.3-13
              s-9.3-11.3-14.5-16.3c-3-2.8-6.2-5.4-9.6-7.8c-3.4-2.5-6.9-4.8-10.5-6.9c-2.2-1.5-4.6-2.6-7.2-3.1c-2.1-0.4-4.3,0-6.2,1.2
              c-0.4,0.3-0.8,0.6-1.1,1c-0.9,1.2-1.6,2.6-2,4.1c-4.2,10.9-15.5,16.6-24.5,24l-8.2,6.8c-1.1,0.9-2.4,2.3-1.8,3.6
              c0.4,0.7,1,1.2,1.7,1.5c4.8,2.4,10.4,3.3,14.3,6.9c1.4,1.3,2.8,3.1,4.8,3.2c1-0.1,2-0.3,3-0.7c3.1-0.8,6.3-0.2,8.8,1.8
              c0.7,0.7,1.5,1.2,2.4,1.7c0.7,0.2,1.5,0.2,2.3,0.1c2.1-0.2,4.4-0.3,6.3,0.6s3.5,3.2,5.7,3.3c1.1,0.1,2.4-0.3,3.4-0.2
              c0.6,0,1.1,0.3,1.4,0.8c0.2,0.5,0.4,1.1,0.5,1.7c0.4,1.4,1.8,2.3,2.7,3.4c1.4,1.8,1.6,4.2,1.4,6.5c-0.5,7-3.6,13.6-8.8,18.4
              c-1.5,1.5-3.3,2.5-5.4,3c-2.1,0.4-4.3-0.5-5.3-2.4c-0.2-0.8-0.6-1.5-1-2.2c-1.6-1.8-5.2,0.1-6.7-1.8c-0.9-1.2-0.3-3.1-1.1-4.3
              s-3-1-4.6-1.1c-1.9-0.1-3.7-1-5.5-1.5c-3-0.8-6.1-0.5-9.2-0.7c-2.6-0.2-5.1-0.6-7.6-1.2l-12.7-2.6c-8.1-1.7-16.2-3.3-23.9-6.3
              c-3-1.1-5.9-2.5-8-4.9c-1.7-2.1-2.8-4.6-3.1-7.3c-1.8-10.7,7.8-19.8,9-30.5c0.4-3.9,2-7.6,3.5-11.3c2.7-6.5,5.4-13,8.1-19.5
              c0.4-1.2,1-2.4,1.7-3.5c1.2-1.4,2.4-2.7,3.9-3.8c3.8-3.4,6.5-7.9,7.8-12.8c0.1-0.6,0.3-1.1,0.7-1.5c0.4-0.3,0.7-0.5,1.2-0.8
              c1.5-1,2.1-2.8,1.5-4.5c1.3-0.1,2.3-1.1,2.4-2.4c-0.1-0.5,0-1.1,0.1-1.6c0.2-0.5,0.8-0.8,1.2-1.2c0.6-0.8,0.4-1.8,0.3-2.7
              c-0.1-0.6-0.1-1.2,0-1.8c0.2-2.1,1.2-4.1,2.7-5.6c2.4-2.4,5.8-1.7,9.1-2.3c3.3-0.7,6.5-1.7,9.6-3.1c8.7-3.4,17.8-5.5,27.1-6.3
              c9.3-0.7,18.7,0.8,27.4,4.4c1.3,0.5,2.4,1.2,3.5,2c0.8,0.6,1.4,1.3,1.8,2.2c0.4,1.1,0.8,2.1,1.4,3.1c1.1,1.5,3.1,1.9,4.3,3.3
              s1.3,3.7,2.2,5.4c1.4,2.4,4.6,3.6,5.6,6.2c0.3,0.8,0.3,1.8,0.6,2.6c0.5,2.2,2.2,3.9,3.6,5.6c5.2,6.3,8.6,13.9,9.7,22
              c0.3-5.1,3.7-9.6,8-12.4c1.2-0.8,2.5-1.6,2.7-2.9c0.3-1.7-1.4-3.2-1.3-5c0.1-0.9,0.5-1.7,1-2.3c1.9-2.8,4-5.4,6.3-7.9
              c1.2-1.4,2.6-3,2.1-4.8c-0.4-1.3-1.8-2.1-2.5-3.3c-0.9-1.4-0.9-3.2,0.1-4.6c0.7-1,1.8-1.6,2.2-2.8c0.4-1.3-0.5-2.8-0.2-4.1
              c0.4-1.1,1.1-2,2.1-2.7c1.3-1.2,2.8-2.2,4.5-2.9c2.6-1,5.6-0.6,8.4-0.2c4.7,0.6,9.9,1.6,12.6,5.6
              C1033.5,341.8,1034.4,345.5,1033.1,348.1z"/>
            <path opacity="0.1" d="M1054.8,341.7c-0.1,1.5-0.2,3.3-1.4,4.2c-1,0.7-2.3,1-3.5,0.8h-0.3
              c0.4-2.1,2.9-3.3,3.4-5.4c0.2-1.3-0.2-2.6-1.1-3.6c-2.1-2.9-5.6-4.5-9-5.7c-9.7-3.3-20-4.1-30.1-2.2c-2.7,0.4-5.4,1.3-7.9,2.4
              c-0.8,0.5-1.6,1.1-2.4,1.7c-0.6-1-1-2.1-1.1-3.3c0-1.2,0.4-2.3,1.1-3.2c1.6-2,4-3.2,6.5-3.4c2.5-0.2,5.1-0.1,7.5,0.4l24.6,3.5
              c1.9,0.3,3.8,0.5,5.7,1c1.8,0.3,3.5,1,4.9,2.1C1054.7,333.7,1054.9,338,1054.8,341.7z"/>
            <path opacity="0.1" d="M948.4,464.7c-1.5,5.8-6,10.3-8.4,15.8c-3.5,8.2-1.9,18.3-6.9,25.8
              c-1.6,2.3-3.6,4.3-5.8,6c-1.2,1-2.6,1.9-4,2.7c-1.5,0.8-3.2,1-4.9,0.6c-2.1-0.7-3.4-3-3.6-5.2c-0.1-0.7-0.1-1.4,0-2.2l0.1,0.2
              c0.9,2.1,2.3,4.3,4.5,4.7c1.7,0.1,3.4-0.5,4.6-1.6c3.3-2.7,5.2-6.6,7-10.4c1.4-2.7,2.6-5.6,3.4-8.6c0.4-1.7,0.6-3.4,1-5.1
              c1.9-8.3,8.4-15,9.8-23.4c0.1-0.6,0.1-1.2,0.1-1.8c-0.2-0.8-0.5-1.5-0.9-2.2c-0.3-0.8-0.6-1.6-0.8-2.4c2,0.7,4.1,1.8,4.8,3.8
              C948.8,462.4,948.8,463.6,948.4,464.7z"/>
            <path opacity="0.1" d="M933.3,450.3c-0.9,2.4-2.5,4.5-4.5,6.2c-2.8,2.2-6.2,3.6-9.7,4.2
              c-1.6,0.4-3.4,0.4-5-0.1c-2.2-0.8-3.8-2.8-6.1-3.5c-1.6-0.4-3.4,0-5-0.3c-3-0.4-5.2-2.7-7.6-4.5c-7.3-5.5-16.9-7.2-24.2-12.7
              c-0.3-0.2-0.6-0.5-0.8-0.8c-0.1-0.4-0.2-0.7-0.2-1.1c0-3.3,0.8-6.6,2.2-9.6c1.2-2.1,2.5-4.1,4.1-5.9c6.5-8,13.1-16.1,20.9-22.8
              c3.3-2.8,6.9-5.5,11-6.8c2.3-0.7,6.8-1.4,9.6-0.2c-0.4,0.3-0.8,0.6-1.1,1c-0.9,1.2-1.6,2.6-2,4.1c-4.2,10.9-15.5,16.6-24.5,24
              l-8.2,6.8c-1.1,0.9-2.4,2.3-1.8,3.6c0.4,0.7,1,1.2,1.7,1.5c4.8,2.4,10.4,3.3,14.3,6.9c1.4,1.3,2.8,3.1,4.8,3.2c1-0.1,2-0.3,3-0.7
              c3.1-0.8,6.3-0.2,8.8,1.8c0.7,0.7,1.5,1.2,2.4,1.7c0.7,0.2,1.5,0.2,2.3,0.1c2.1-0.2,4.4-0.3,6.3,0.6s3.5,3.2,5.7,3.3
              C930.9,450.6,932.2,450.2,933.3,450.3z"/>
            <path fill="#3F3D56" d="M841.3,314.6c-1.9,1.2-3.9,2.6-4.7,4.7c-0.6,1.7-0.3,3.7-1.1,5.4s-2.5,2.9-2.9,4.6c-0.3,1.3,0.3,2.7,0,4
              c-0.2,0.7-0.4,1.4-0.5,2.1c-0.1,1.5,1.2,2.7,2.4,3.5c5.4,3.5,12.1,4.3,18.2,2c2.7-1,5.1-2.6,7.6-3.9c2.5-1.4,5.4-2.1,8.2-2
              c1.7,0.2,3.2,0.8,4.9,1c1.7,0.3,3.4-0.2,4.6-1.4c0.5-0.7,0.9-1.5,1-2.4c1.4-5.5,0.8-11.3-0.6-16.8s-3.3-10.8-4.7-16.3
              c-1-4.1-1.8-8.2-3.4-12.1s-4.5-7.4-8.4-8.9c-0.3-0.2-0.7-0.2-1-0.1c-0.3,0.1-0.5,0.3-0.7,0.5c-3.1,3.3-5.5,7.2-6.9,11.5
              c-1.6,4.8-2,9.9-3.4,14.7c-0.6,2-1.4,4.7-3,6.2S843.1,313.5,841.3,314.6z"/>
            <path opacity="0.1" d="M879,332.3c-0.2,0.9-0.5,1.7-1,2.4c-1.2,1.2-2.9,1.7-4.6,1.4
              c-1.6-0.2-3.2-0.8-4.9-1c-2.9-0.1-5.7,0.6-8.2,2c-2.6,1.3-5,2.9-7.7,3.9c-3.9,1.5-8.1,1.7-12.1,0.7c-2.1-0.5-4.2-1.4-6-2.6
              c-1.3-0.8-2.5-2-2.4-3.5c0.1-0.7,0.3-1.4,0.5-2.1c0.3-1.3-0.3-2.7,0-4c0.4-1.8,2.1-3,2.9-4.7s0.5-3.6,1.1-5.4
              c0.8-2.1,2.8-3.5,4.7-4.7s4.2-2.2,5.7-3.7c0.2-0.2,0.4-0.5,0.5-0.7c1.1-1.7,1.9-3.6,2.4-5.5c1.5-4.8,1.9-9.9,3.4-14.7
              c1.4-4.3,3.8-8.2,6.9-11.5c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.1,0.7,0,1,0.1c3.9,1.5,6.7,5.1,8.4,8.9s2.4,8,3.4,12.1
              c1.4,5.5,3.4,10.8,4.7,16.3S880.4,326.8,879,332.3z"/>
            <path opacity="0.1" d="M908.8,210.4c-9.7,3.1-20.2-1.6-24.4-11c0.1-0.9,0.2-1.9,0.4-2.8
              c1-4.6,5.3-2.9,8.7-1.7c4.1,1.3,8,3,11.8,5.1c1.1,0.6,2.1,1.4,2.9,2.5C909.6,204.6,909,207.6,908.8,210.4z"/>
            <circle fill="#BE6F72" cx="902.7" cy="190.3" r="20"/>
            <path opacity="0.1" d="M957,339.8c0,1.7-2.2,2.2-3.9,2.1c-6-0.4-11.9-2-17.8-1.5
              c-7.5,0.7-14.4,4.8-21.9,5.2c-2.9,0.1-5.8-0.3-8.7,0.2c-3.7,0.7-7,2.9-10.8,3.3c-3.3,0.3-6.5-1-9.8-0.8c-2.3,0.1-4.5,0.8-6.5,1.9
              c0.2-2.1,1.2-4.1,2.7-5.6c2.4-2.4,5.8-1.7,9.1-2.3c3.3-0.7,6.5-1.7,9.6-3.1c8.7-3.4,17.8-5.5,27.1-6.3c9.3-0.7,18.7,0.7,27.4,4.3
              c1.3,0.5,2.4,1.2,3.5,2C957,339.4,957,339.6,957,339.8z"/>
            <path fill="#E3E8F4" d="M893.5,226.8l-8.1-3l-3.4-1.3c-1.4-0.6-2.9-1-4.4-1.2c-3.9-0.3-7.6,2.3-9.7,5.6s-2.9,7.3-3.5,11.2
              c-0.9,5.3-1.7,10.6-2.3,15.9c-0.7,4.8-1,9.7-1,14.5c0.2,12.4,2.8,24.6,5.7,36.6c1.4,5.3,2.5,10.8,3.2,16.2
              c0.2,2.3,0.3,4.5,0.6,6.8c0.3,2.5,0.7,5.1,1.1,7.6l2.4,14.4c3-2.1,6.4-4,10.1-4.1c3.3-0.1,6.5,1.1,9.8,0.8
              c3.8-0.3,7.1-2.6,10.8-3.2c2.9-0.5,5.8-0.1,8.7-0.2c7.5-0.4,14.4-4.5,21.9-5.2c6-0.5,11.9,1.1,17.8,1.5c1.7,0.1,3.9-0.4,3.9-2.1
              c0-1.2-1.2-1.9-2.2-2.5c-4.8-2.9-9.3-6.1-13.5-9.7c-3.9-3.1-7.9-6.3-10.7-10.5c-6.5-10.1-4.3-23.9-9.9-34.6
              c-4.5-8.7,0-20.1,0-29.9c0-4.9,0-9.8-1.2-14.6c-0.7-2.7-1.6-5.3-2.7-7.8l-3.9-9.9c-0.1-0.3-0.2-0.5-0.5-0.8
              c-0.3-0.2-0.6-0.3-0.9-0.3c-2.3-0.1-4.6,0.6-6.5,2.1c-1.8,1.5-3,3.6-4.8,5.2C898.5,226.2,895.9,227.7,893.5,226.8z"/>
            <path fill="#3F3D56" d="M906.5,221.1c0.6-2.7,1.3-5.6,3.2-7.7s5.2-3.3,7.5-1.7c2.7,1.7,2.8,5.8,5.1,8c2,1.9,5,2.1,7.7,2.3
              c4.9,0.4,9.7,1.6,14.3,3.4c0.9,0.3,1.7,0.8,2.4,1.4c1.2,1.4,1,3.6,1.6,5.4c0.8,2.3,3.2,3.7,4.1,6c1,2.5,0,5.3-0.8,7.9
              c-1.5,4.6-2.5,9.5-3.9,14.1c-1.5,4.7-3.6,9.3-7,12.8c-1.5,1.6-3.8,3-5.8,2.1l-0.8,5.5c-0.2,1.2-0.2,2.4,0,3.5
              c0.2,1.2,1.1,2.2,2.3,2.5c0.2,0.9,1.1,1.3,1.8,1.7c2.3,1.6,2.9,4.7,1.3,6.9c0,0,0,0,0,0.1c-0.6,0.9-1.6,1.5-1.9,2.5
              c-0.5,1.5,0.6,3,1.9,3.8s2.8,1.2,4.2,1.9c2.7,1.6,4.2,4.7,5.2,7.7c0.7,2.3,1.3,4.8,3,6.4c0.7,0.6,1.5,1.1,2.1,1.8
              c1.1,1.3,1.7,2.9,1.8,4.6c0.3,2.1-0.2,4.2-1.3,6c-2,2.9-5.7,3.7-9,5c-5.3,2.2-9.7,6.1-14.4,9.4c-1.9,1.5-4.2,2.6-6.5,3.1
              c-3.2,0.4-6.4-1-9.7-1.1c-2,0-3.9,0.3-5.8,0.8l-6,1.3c0.3-4.6-2.2-9.3-3.7-13.7c-2.7-8.3-3.2-17.3-2.6-26s2.4-17.4,4.2-25.9
              c1.2-6,1.1-12.3,3-18.1c1.8-5.6,4.3-11,5.2-16.8C910.7,238.5,904.4,230.5,906.5,221.1z"/>
            <path fill="#3F3D56" d="M952,239.6c0.6,1,0.9,2.1,1.2,3.2c0.5,1.8,1.3,3.4,2.5,4.8c1,0.9,1.9,1.9,2.7,3c0.9,1.7,0.6,3.9,1,5.8
              c0.5,2.1,2,3.9,2.2,6c0,0.6,0.1,1.2,0.2,1.8c0.7,2.4,4.2,3,5,5.4c0.5,1.6-0.4,3.3-0.2,4.9c0.1,0.5,0.2,1,0.1,1.6
              c-0.2,0.6-0.6,1.1-1.1,1.5c-3.3,3.2-5.6,7.2-6.6,11.7c-1,4.4-1.5,9-1.5,13.5l-3.9-5.2c-0.4-0.6-0.9-1.2-1.6-1.6
              c-0.5-0.3-1.1-0.5-1.6-0.8c-1.5-0.8-2.1-2.6-2.6-4.2c-1.6-4.4-4-8.5-7-12.1c-1.6-1.9-3.4-3.8-3.8-6.2c-0.1-1.1,0-2.2,0.2-3.3
              c1-6.5,2.1-13.3,5.3-19.1c1.5-2.7,3.4-5.2,4.8-7.9C948.1,240.6,949.5,235.9,952,239.6z"/>
            <path fill="#3F3D56" d="M853.4,245.9c0,0.8-0.2,1.7-0.6,2.4c-0.8,0.9-2,1.3-3.2,1c-1.1-0.3-2.2-0.8-3.2-1.5
              c-2.8-1.8-5.6-3.5-8.4-5.3c-1.6-0.9-3.1-2-4.5-3.1c-2.2-1.9-4.2-3.9-5.9-6.2c-4.9-6-9.2-12.5-14.6-18c-1.5-1.5-3-2.9-4.4-4.5
              c-1.9-2.3-3.7-4.7-5.3-7.2c-1.6-2.3-3.2-4.9-2.9-7.6c0.2-1.6,0.9-3,1.1-4.5s-0.4-3.4-1.9-3.9c-0.2,0-0.4-0.1-0.5-0.1
              c-0.3,0-0.6-0.1-0.9-0.2c-1.4-0.6-1.2-2.7-0.2-3.9s2.5-1.8,3.4-3c0.4-0.7,0.9-1.4,1.4-2.1c0.7-0.6,1.5-1,2.3-1.2
              c5.8-1.7,11.9-1.9,17.8-0.7c1,0.2,2.1,0.6,2.4,1.6c0.1,0.5,0.1,1,0,1.4c-0.4,2-0.7,4-0.8,6c0,0.4,0,0.8,0.1,1.3
              c0.5,4.2,3.2,7.8,5.9,11.1c3.2,3.9,6.4,7.9,9.7,11.8c1.8,2.3,3.7,4.4,5.8,6.2c2,1.7,4.6,3,6.2,5.1c1.3,1.7,0.9,4.1,1,6.1
              C853.8,233.4,853.8,239.7,853.4,245.9z"/>
            <path fill="#BE6F72" d="M980.4,287.6c1-1,1.9-2,2.7-3.1c0.8-1.4,1.5-2.9,2-4.5l5.8-16.5c0.9-2.6,1.8-5.2,3-7.7
              c1.2-2.7,2.6-5.4,3.9-8c3.4-6.9,6-14.2,7.8-21.8c0.3-1,0.4-2.1,0.3-3.2c-0.3-1.7-1.6-3.1-2.8-4.3c-1.3-1.4-2.7-2.5-4.3-3.5
              c-1.6-1-3.5-1.3-5.3-0.9c-2.6,0.8-4.2,3.4-4.8,6s-0.4,5.3-0.6,8c-0.4,3.8-1.7,7.5-2,11.4c-0.2,2.2,0,4.5-0.3,6.7
              c-0.5,2.9-1.5,5.7-3,8.3c-3.2,6.1-7.6,11.5-12.9,15.9c-1.4,1.1-2.8,2.3-3.9,3.7c-0.9,1.4-1.6,3-1.9,4.7c-0.7,2.5-0.4,5.2,0.8,7.6
              c1.6,2.8,4.8,4,7.8,4.6C976.7,291.9,977.6,290.5,980.4,287.6z"/>
            <path fill="#3F3D56" d="M902.8,161.3c1.8-3.3,5.3-5.2,9.1-4.9c3.8,0.7,6.8,3.4,7.9,7.1c0.5,1.5,0.7,3.1,1.5,4.4
              c1.2,1.9,3.5,2.5,5.3,3.9c2.1,1.9,3.3,4.6,3.4,7.4c0.1,2.9-0.4,5.7-1.3,8.4c-0.4,1.1-0.7,2.3-1,3.5c-0.2,1.2-0.2,2.3-0.4,3.5
              c-0.4,3-2.1,5.8-4.6,7.6c-0.4,0.3-0.8,0.4-1.2,0.5c-0.8-0.1-1.3-1-1.5-1.8c-1.6-4.6-1.2-10.2-4.1-13.9c-1.3-1.5-2.9-2.7-4.7-3.5
              c-0.7-0.5-1.6-0.8-2.4-0.9c-1.3,0.1-2.5,0.5-3.5,1.3c-2.1,1.4-4.5,2.5-6.9,3.1c-2.5,0.6-5.1,0-7.2-1.5c-1.4-1.1-2.4-2.9-3.9-3.7
              s-3.1-0.8-4.6-1.5c-3.5-1.5-5.1-5.5-3.6-9c0.2-0.5,0.5-1,0.9-1.5c1-1.2,2.5-1.9,3.4-3.2c1.2-1.7,1-4.1,1.6-6.1
              c0.7-2.6,2.6-4.8,5.1-5.8c2.5-0.8,5.2-0.3,7.1,1.5c1,0.9,1.8,2.1,2.8,3c2,1.9,4.8,2.6,7.4,2"/>
            <path opacity="0.1" d="M919.3,308.5c-1.2,1.1-1.6,2.7-1.1,4.2c0.2,0.5,10.5-1.5,11.6-1.7
              c2.7-0.4,7.5-0.2,9.3-2.7c3.3-4.4-6.8-3.4-8.7-3.3C927,305.4,922,305.9,919.3,308.5z"/>
            <path fill="#3F3D56" d="M825.4,179.5c-0.4,2-0.7,4-0.8,6c-3,0.6-6.1-0.4-9-1.4l-8.3-2.8c-1-0.7-2.4-0.4-3.2,0.6
              c-1.3,1.4-3.2,4-5,5.5c-0.3,0-0.6-0.1-0.9-0.2c-1.4-0.6-1.2-2.7-0.2-3.9s2.5-1.8,3.4-3c0.4-0.7,0.9-1.4,1.4-2.1
              c0.7-0.6,1.5-1,2.3-1.2c5.8-1.7,11.9-1.9,17.8-0.7c1,0.2,2.1,0.6,2.4,1.6C825.6,178.5,825.6,179,825.4,179.5z"/>
            <path opacity="0.1" d="M825.4,179.5c-0.4,2-0.7,4-0.8,6c-3,0.6-6.1-0.4-9-1.4l-8.3-2.8
              c-1-0.7-2.4-0.4-3.2,0.6c-1.3,1.4-3.2,4-5,5.5c-0.3,0-0.6-0.1-0.9-0.2c-1.4-0.6-1.2-2.7-0.2-3.9s2.5-1.8,3.4-3
              c0.4-0.7,0.9-1.4,1.4-2.1c0.7-0.6,1.5-1,2.3-1.2c5.8-1.7,11.9-1.9,17.8-0.7c1,0.2,2.1,0.6,2.4,1.6
              C825.6,178.5,825.6,179,825.4,179.5z"/>
            <path opacity="0.1" d="M901.1,369.4c1.4,0.5,3,1.2,3.5,2.6L893,369l-6-1.5c-1.1-0.3-4.9-0.6-5.6-1.4
              c-1-1.2,2.5-0.4,3.2-0.3c1.9,0.3,3.9,0.6,5.8,0.9C894,367.4,897.5,368.5,901.1,369.4z"/>
            <path opacity="0.1" d="M905.5,379.6c-1-0.7-3.9-0.6-5.1-0.9c-1.8-0.4-3.6-1-5.3-1.5
              c-3.6-1.2-7.2-2.5-10.8-3.4c-0.7-0.1-3-0.2-2.9-1s2.3-1.2,2.9-1.2c3.3-0.2,7.3,1.3,10.4,2.4C898.5,375.3,902.2,377.2,905.5,379.6z
              "/>
            <path opacity="0.1" d="M898.2,360.2c-3.7-1-7-3.3-10.8-3.7c-1,0-2-0.3-2.8-0.8
              c-0.4-0.4-0.8-1-0.9-1.6c2.5-0.1,5.5,0.9,7.8,2c1.3,0.5,2.5,1.2,3.6,1.9C896,358.5,897.3,359.9,898.2,360.2z"/>
            <path fill="#3F3D56" d="M968.2,270.7c-1.3,8.6,3.9,16.8,12.3,19.3c-0.5,2.4-2.9,3.7-4.7,5.4c-2.4,2.4-3.7,5.9-6.6,7.7
              c-3.2,2-7.3,1.2-11,0.4c-0.3,0-0.6-0.2-0.9-0.5c-0.1-0.1-0.1-0.3-0.2-0.4c-1.2-4.4-2-9.2-0.8-13.6c1-3.8,3.4-7,5.3-10.3
              S963.8,271.8,968.2,270.7z"/>
            <path fill="#3F3D56" d="M878.8,213.3c-3.9,0.3-7.6,1.4-11,3.4c-2.2,1.3-4.5,3-7.1,2.7c-1.5-0.2-3-1-4.5-0.6
              c-0.8,0.3-1.6,0.8-2.3,1.4c-0.7,0.6-1.7,0.7-2.5,0.4l-1,13.8c-0.4,5.3-0.8,10.6,0.5,15.8c0.7,2.6,2.1,5,2.2,7.7
              c0.2,2.7-0.1,5.4,0.1,8.1c0.2,2.5,0.7,4.9,0.9,7.4c0.3,4.7-0.9,9.4-2,13.9c-1.7,6.7-2.7,13.6-6,19.7c-0.9,1.7-2,3.4-2,5.3
              c0,0.2,0,0.5,0.1,0.7c-1,0.5-1.9,1.1-2.8,1.6c-1.9,1.2-3.9,2.6-4.7,4.7c-0.6,1.7-0.3,3.7-1.1,5.4s-2.5,2.9-2.9,4.7
              c-0.3,1.3,0.2,2.7,0,4c-0.2,0.7-0.4,1.4-0.5,2.1c-0.1,1.5,1.2,2.7,2.4,3.5c1.8,1.2,3.9,2.1,6,2.6l6-23.7l0,0
              c5.4-10.1,10-20.6,13.5-31.5c1-3.6,2.3-7,3.9-10.4c1.1-1.9,2.1-3.9,3-5.9c0.9-2.5,1-5.2,1.4-7.9c0.5-3.1,1.3-6.2,2.3-9.1
              c1.1-3.2,2.3-6.4,2.5-9.8c0.3-4.8-1.4-9.9,0.4-14.4c0.8-1.7,1.8-3.2,3-4.6c2.6-3.2,4.9-6.5,7.5-9.6
              C883,213.4,880.8,213.2,878.8,213.3z"/>
            <g opacity="0.1">
              <path d="M906.9,158l-1.2,0.1l-0.1,0.1C906,158.2,906.5,158.1,906.9,158z"/>
              <path d="M929.8,178.8c-0.2,2-0.6,3.9-1.3,5.8c-0.4,1.1-0.7,2.3-1,3.5c-0.2,1.2-0.2,2.3-0.4,3.5c-0.4,3-2.1,5.8-4.6,7.6
                c-0.4,0.3-0.8,0.4-1.2,0.5c-0.8-0.1-1.3-1-1.5-1.8c-1.6-4.6-1.2-10.2-4.1-13.9c-1.3-1.5-2.9-2.7-4.7-3.5
                c-0.7-0.5-1.6-0.8-2.4-0.9c-1.3,0.1-2.5,0.5-3.5,1.3c-2.1,1.4-4.5,2.5-6.9,3.1c-2.5,0.6-5.1,0-7.2-1.5c-1.4-1.1-2.4-2.9-3.9-3.7
                s-3.1-0.8-4.6-1.5c-1.9-0.8-3.3-2.5-3.9-4.5c0-0.1-0.1-0.3-0.1-0.4c-0.2,1.1-0.2,2.3,0.1,3.4c0.6,2,2,3.6,3.9,4.5
                c1.5,0.6,3.2,0.6,4.6,1.5s2.5,2.6,3.9,3.7c2,1.6,4.7,2.1,7.2,1.5c2.5-0.6,4.8-1.6,6.9-3.1c1-0.8,2.2-1.2,3.5-1.3
                c0.9,0.1,1.7,0.4,2.4,0.9c1.8,0.9,3.4,2.1,4.7,3.6c2.9,3.7,2.5,9.3,4.1,13.9c0.3,0.8,0.8,1.7,1.5,1.8c0.5,0,0.9-0.2,1.3-0.5
                c2.5-1.8,4.2-4.5,4.6-7.6c0.2-1.2,0.2-2.3,0.4-3.5c0.3-1.2,0.6-2.4,1-3.5c1-2.7,1.4-5.5,1.3-8.4
                C929.9,179.1,929.8,178.9,929.8,178.8z"/>
            </g>
          </g>
          <g id="Celebration_Woman">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="178.5825" y1="111.6557" x2="400.8467" y2="111.6557" gradientTransform="matrix(1 0 0 -1 -28 528.26)">
              <stop offset="0" style={{ stopColor: '#808080', stopOpacity: '0.25' }} />
              <stop offset="0.54" style={{ stopColor: '#808080', stopOpacity: '0.12' }} />
              <stop offset="1" style={{ stopColor: '#808080', stopOpacity: '0.1' }} />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M371.8,347.6c-2.3-0.8-15.9-11.5-16.9-16.4s7.2-50.2,7.2-50.2s-0.8,0-2-0.1v-0.7l1.7-59.6
              c0,0,6.9-40-6.2-37.7s-4.9,37.7-4.9,37.7s-1.5,17.9-2.5,23.3c-1,5.2-5.6,26.3-2.5,32.9c0.1,0.2,0.2,0.5,0.4,0.7l0,0
              c0,0,0.5,22.5-5.1,25.6c-5.4,2.9-7.6,17.7-7.8,19l-0.3-0.1l0,0c-1.6-1-3-2.3-4.2-3.8c-0.1-0.1-0.2-0.3-0.3-0.5
              c7.6-8.6,7.4-21.6-0.6-29.9c-1.8-1.9-3.9-3.4-6.2-4.5c-0.1-1.9-0.5-3.8-1.3-5.5c-2.6-5.6-17-12.8-27.2-13c-4.7-0.1-8.6,1.2-10,4.9
              c-0.7,1.9-0.5,4-0.8,5.9c-0.6,4-3.2,7.1-6.5,9.6c-1.3-1.5-2.8-2.9-4.3-4.1c-3.3-2.6-10-11-10-11s-20.8-21.5-22.3-27.4
              s-22.3-41.3-26.9-35.2s15.9,33.1,15.9,33.1s3.9,9.8,6.9,13.1s26,36.7,26,36.7l1.1,2.3c-4.9,2.6-9.3,5.8-10.9,10.9
              c-2.3,7.5,2.7,15,6.2,22c6.8,13.7,7.8,31.1-1,43.6c-7.8,11-21.4,16.3-34.2,20.8c6.1,4.8,13.8,7.4,21.6,7.2
              c-2.9,8.5-13.4,11.3-22.2,12.9c4.7,4.5,11.3,6.1,17.7,7.1c3.1,0.5,6.3,0.8,9.5,0.8h0.8l0.8-0.1c1.1-0.1,2.2-0.2,3.2-0.4
              c4.6-0.9,9.1-3.5,11.5-7.5c2-3.5,2.7-8.2,6.3-10c0.9-0.4,2-0.7,3-0.7h-0.1c0.7,0,1.4,0,2.1,0l15.1,0.2c0.6,0,1.3,0,1.9,0
              c4.3-0.1,8.8-0.8,11-4.4c0.9-1.7,1.4-3.6,1.4-5.5c0.3-4.8,0-9.7,0.1-14.5c1.3,8.8,2.6,22.5-0.6,27.5c-2.6,3.8-5.8,7.3-9.4,10.2
              c-0.9,0.8-1.7,1.6-2.5,2.5c-0.5,0.3-0.8,0.5-0.8,0.5l-0.3,0.9c-1.1,1.7-2,3.6-2.4,5.7c-0.2,0.5-0.2,1.1-0.2,1.6
              c0.4,0.7-2.5,3.6-1.7,4.9c-7.1,8.3-8.5,21-8.5,21l-13,19.7c-6.7,17.5-16.7,19.3-16.7,19.3s-23.5-15.8-24.1-16.5s-9.6-4.6-9.6-4.6
              c-1.1-1.9-2.7-3.3-4.6-4.2c-3.1-1.3-3.3-6-3.3-6c-0.4-11.2-6.9-12-6.9-12c-5.3-0.5-9.3,0.5-12.4,2.2l-0.9,0.1
              c-4.5,0.4-10,0.8-16,1.2c-16.7,1.1-20.7,11.9-20.7,11.9s-2.1,2.3,0.6,4.8s19.9,0,20.6-0.1c-1.3,0.1-2.6,0.1-3.9-0.3
              c2.7-0.1,6.4-0.2,9.6-0.2h0.8c-0.2,1.7-0.3,3.4-0.3,5.1c0,3.2,8.9,5.2,8.9,5.2l26.9,24c0,0,16.9,7.6,27.7,18.5s20.3,1.5,20.3,1.5
              c11.4-2.8,44.2-42.5,44.3-42.5l0,0c0,1.2,0.7,39.8,2,47.5l0,0c0.1,0.4,0.1,0.8,0.3,1.2c1.5,3.3-0.5,12.4-1.4,16.3
              c-0.6,3.2-0.8,6.5-0.6,9.7c0,0-12.3,47-11.3,51.1s-6.7,23.3-6.7,23.3v16l1.3-0.1c-0.2,0.3-0.3,0.5-0.5,0.8l-0.1,0.2
              c0,0,8.2,1.8,13.7,1.3s15,4.2,15,4.2s3.2,1.5,13.1,2.2s9.9-4.4,9.9-4.4c-1.1-6.8-16.7-9.1-17.2-9.2c-1.3-0.8-2.4-1.7-3.4-2.4
              l-0.2-0.2c0.1-0.1,0.1-0.3,0.2-0.5c1.7-6.8-7.6-9.8-7.6-9.8v-1.7c6-3.1,7.2-24.2,7.2-24.2s9-26.1,10.3-30.5s0-10.9,0-10.9l0.9-5.6
              c0,0,1.9-3.8,1.9-4.6c0.2-2,1-3.8,2.2-5.4c1.4-1.7-1.5-5.6-1.5-5.6l2.6-6.7v-7.3l6-26.5c0,0,6.3-30.9,4.1-41s2.6-15.2,2.6-15.2
              c-0.4,0.2-0.9,0.4-1.3,0.6c0.6-1.4,1.1-2.8,1.5-4.3l13.5,1.7v0.2h4.9c0,0-1.5-19-1.5-26.7s5.2-35.2,5.2-35.2
              S374.1,348.4,371.8,347.6z M311.9,325.4c0,0.8-0.1,1.5-0.1,2.3l-0.3,0.4l-0.5-0.4l-0.6,1l-0.2,0.1c-1.3-0.7-2-4.6-3-3.7l-0.1,0.1
              c-0.2-0.2-0.4-0.3-0.6-0.1l-0.2,0.2c0-0.3,0-0.6,0-0.9c1.8,0.5,3.7,0.7,5.6,0.7C311.9,325.2,311.9,325.3,311.9,325.4z
              M356.8,413.9h0.3l-0.5,1.3C356.7,414.8,356.7,414.4,356.8,413.9z M307.5,640.7l-1.8-0.5l1-0.2L307.5,640.7l0.2,0.1L307.5,640.7z
              M326.3,638c-0.6-0.3-1.2-0.7-1.8-1.1C324.7,637,325.5,637.5,326.3,638z"/>
            <polygon fill="#524F68" points="363.4,395 351.3,426.6 354.3,426.6 342.9,435.7 363.4,438.2 		"/>
            <polygon fill="#524F68" points="344.4,415.2 363.4,415.2 370.4,357.9 344.4,357.9 		"/>
            <path fill="#FBBEBE" d="M307.4,371.5l4.1-0.7l9.4-1.6c0,0,28.3-6.4,34.1-6.9c3.5-0.5,7-1.5,10.2-2.9c1.5-0.7,2.5-1.2,2.5-1.2
              l-10.2-18.6l-15.8-11.7c0,0-10-2.1-14.2-8c-1.9-2.5-2.4-5.9-1.3-8.9c2.6-8-15.4,7.6-15.4,7.6s0.2,3.8,0,8.5c0,0.9-0.1,1.9-0.2,2.9
              c0,0.7-0.1,1.3-0.2,2c-0.4,4.8-1.3,9.6-3.1,12.6C303,351.8,307.4,371.5,307.4,371.5z"/>
            <path opacity="0.1" d="M310.8,327.6h0.4c0.7,0,1.3-1.5,2-1.6c5.6-0.5,10.8-1.7,14.3-5.7
              c-1.9-2.5-2.4-5.9-1.3-8.9c2.6-8-15.4,7.6-15.4,7.6S311,322.8,310.8,327.6z"/>
            <path fill="#FBBEBE" d="M288.9,304.9c0,12.1,9.8,21.9,21.9,21.9c0,0,0,0,0,0c0.7,0,1.4,0,2.1-0.1c12-1.2,20.9-11.9,19.7-24
              s-11.9-20.9-24-19.7C297.4,284.2,288.9,293.6,288.9,304.9L288.9,304.9z"/>
            <path fill="#3F3D56" d="M282.2,271.7c-0.7,1.9-0.5,3.9-0.8,5.9c-1.1,7.1-8.3,11.4-14.8,14.4s-13.9,6.5-16,13.4
              c-2.3,7.4,2.7,14.9,6.2,21.9c6.7,13.6,7.7,30.9-1,43.3c-7.7,11-21.2,16.2-33.9,20.8c6.1,4.8,13.7,7.3,21.5,7.1
              c-2.9,8.5-13.3,11.3-22.1,12.8c4.6,4.5,11.3,6.1,17.6,7.1c4.6,0.7,9.4,1.2,14,0.3s9-3.4,11.4-7.5c2-3.5,2.7-8.2,6.3-10
              c1.6-0.6,3.4-0.9,5.1-0.8l15,0.2c4.7,0,10.2-0.4,12.6-4.3c0.9-1.7,1.4-3.6,1.4-5.5c0.6-9.8-1.2-20.2,2.8-29.2
              c1.2-2.7-6.6-7-5.8-9.9c2.8-9.9,3.5-17.6,3.5-27.9c0-8,5.2-14.8,9.4-21.5s7.8-15.2,4.5-22.4C315.2,271.7,286.6,260.1,282.2,271.7z
              "/>
            <path fill="#FBBEBE" d="M302.1,637.8l3.8,2.5l0.6,0.4l0.1,0.1l4.5,3l10.6,1.8l9.3-0.6c0,0,2.7-5.6-1.5-5.5
              c-1.3-0.1-2.7-0.4-3.8-1.1l-0.6-0.3l0,0c-0.6-0.3-1.2-0.7-1.8-1.1l0,0c-1.2-0.8-2.4-1.7-3.4-2.4c-1.4-1.1-2.3-1.9-2.3-1.9
              L302.1,637.8z"/>
            <path opacity="0.1" d="M306,640.3l0.6,0.4l0.1,0.1l4.5,3l10.6,1.8l9.3-0.6c0,0,2.7-5.6-1.5-5.5
              c-1.3-0.1-2.7-0.4-3.8-1.1c0.7,0.6,1.4,1.2,1.4,1.7c0.2,1.4-6.9,4-6.9,4l-9.9-2.6c0,0-1.7-0.5-4-1.1l-0.2-0.1L306,640.3z"/>
            <path fill="#3F3D56" d="M289,642.8c0,0,8.1,1.8,13.6,1.3s14.9,4.2,14.9,4.2s3.2,1.5,13,2.1s9.8-4.3,9.8-4.3
              c-1.1-6.8-16.6-9-17.1-9.1l0,0l0,0l0,0c0.2,0.1,1,0.5,1.8,1.1l0,0c1.1,0.7,2.3,1.7,2.4,2.4c0.2,1.4-6.9,4-6.9,4l-9.9-2.6
              c0,0-1.7-0.5-4-1.1h-0.2c-3.4-0.9-6.9-1.7-10.4-2.1c-4.3-0.5-6.5,3.2-6.9,4C289,642.6,289,642.7,289,642.8z"/>
            <path fill="#FBBEBE" d="M160.5,463l4.3,3.3c0,0,1.3-0.1,3.1-0.1h0.6c2.7-0.1,6.4-0.2,9.6-0.2c2-0.1,4.1,0,6.1,0.3
              c2.2,0.8,6-12.4,6-12.4l-4.3-1l-1.4-0.3l-1-0.2l-3.5-0.8l-10.7,1.2l-7.5,4.8L160.5,463z"/>
            <path opacity="0.1" d="M160.5,463l4.3,3.3c0,0,1.3-0.1,3.1-0.1c-0.9-0.3-1.6-1-2-1.8
              c-2.8-7.8,11.1-11.1,11.1-11.1l6.5-0.3l1.3-0.1h1l-1.4-0.3l-1-0.2l-3.5-0.8l-10.7,1.2l-7.5,4.8L160.5,463z"/>
            <path fill="#3F3D56" d="M152,466.5c2.6,2.4,19.8,0,20.5-0.1c-1.3,0.1-2.6,0.1-3.9-0.3c-1.3-0.2-2.4-1-3-2.2
              c-2.8-7.8,11.1-11.1,11.1-11.1l6.5-0.3l1.2-0.1l15.7-0.7l0.8-4.3c0,0-5.1,0.5-13.1,1.1c-4.5,0.4-10,0.8-15.9,1.2
              c-16.6,1.1-20.5,11.8-20.5,11.8S149.3,464,152,466.5z"/>
            <path fill="#FBBEBE" d="M344.5,278.8c0.1,0.2,0.2,0.5,0.4,0.7l3.8,5.6l9.9,1l0.1-3.9l1.7-59.3c0,0,6.9-39.8-6.1-37.5
              s-4.8,37.5-4.8,37.5s-1.5,17.8-2.5,23.2C345.9,251.2,341.4,272.3,344.5,278.8z"/>
            <path fill="#FBBEBE" d="M322.1,426l2.8,4.8l6.3,10.8c0,0,11.5,4.2,14.4,1c1.6-1.8,3.4-7.9,4.6-13c1-4.2,1.7-7.7,1.7-7.7L322.1,426
              z"/>
            <path opacity="0.1" d="M322.1,426l2.8,4.8c0.2-0.2,0.3-0.3,0.5-0.5c3.2-4.1,17.3-0.9,24.8-0.6
              c1-4.2,1.7-7.7,1.7-7.7L322.1,426z"/>
            <path opacity="0.1" d="M178.2,465.9c2-0.1,4.1,0,6.1,0.3c2.2,0.8,6-12.4,6-12.4l-5.8-1.4l15.7-0.7
              l0.8-4.3c0,0-5.1,0.5-13.1,1.1c-1.7,1-3.1,2.2-4.4,3.7l-0.2,0.3C179.9,456.7,178.6,462,178.2,465.9z"/>
            <path opacity="0.1" d="M302.1,637.8l4.4,2.9c6.6-1.2,12.6-3.1,13.3-6c0,0,0-0.1,0-0.1
              c-1.4-1.1-2.3-1.9-2.3-1.9L302.1,637.8z"/>
            <path opacity="0.1" d="M289.1,642.6c5.8-0.3,11.6-0.9,17.3-1.9c-3.4-0.9-6.9-1.7-10.4-2.2
              C291.7,638.1,289.5,641.8,289.1,642.6z"/>
            <path fill="#3F3D56" d="M178.6,471c0,3.2,8.8,5.2,8.8,5.2l26.8,23.8c0,0,16.8,7.5,27.5,18.3s20.1,1.5,20.1,1.5
              c11.3-2.8,44-42.3,44-42.3s0.8,45.1,2.3,48.4s-0.5,12.4-1.4,16.2c-0.6,3.2-0.8,6.4-0.6,9.7c0,0-12.2,46.8-11.2,50.8
              s-6.6,23.2-6.6,23.2v15.9c0,0,30-1.2,31.6-7.9s-7.5-9.7-7.5-9.7v-1.7c6-3.1,7.1-24.1,7.1-24.1s8.9-26,10.2-30.3s0-10.8,0-10.8
              l0.9-5.6c0,0,1.9-3.8,1.9-4.6c0.2-1.9,1-3.8,2.2-5.3c1.4-1.7-1.5-5.6-1.5-5.6l2.5-6.6v-7.3l6-26.4c0,0,6.2-30.7,4.1-40.8
              s2.5-15.2,2.5-15.2c-10.2,5.4-15.4-6.6-17.1-11.7c-0.4-1.3-0.6-2.2-0.6-2.2s-14-5.2-27.1-3.9c-2.7,0.2-5.3,0.8-7.8,1.7
              c-14.5,5.2-16.8,26.9-16.8,26.9L266,470.3c-6.6,17.5-16.6,19.2-16.6,19.2s-23.3-15.7-24-16.4s-9.6-4.6-9.6-4.6
              c-1-1.8-2.7-3.3-4.6-4.2c-3.1-1.3-3.3-6-3.3-6c-0.4-11.1-6.9-12-6.9-12C178.5,444.5,178.6,467.8,178.6,471z"/>
            <rect x="308.4" y="366" fill="#524F68" width="13.8" height="42.7"/>
            <path opacity="0.1" d="M344.4,278.7C344.4,278.7,344.4,278.7,344.4,278.7c0.1,0.3,0.2,0.6,0.4,0.8
              l3.8,5.6l9.9,1l0.1-3.9C355.6,281.9,348.5,281,344.4,278.7z"/>
            <path fill="#65617D" d="M344.8,279.4c0,0,0.5,22.4-5.1,25.5s-7.8,19-7.8,19s17,11.2,20.8,16.1s12.5,16.6,10.7,19.4
              s4.3,15.5,4.3,15.5s-11.2,22.9-8.7,31.6s2.8,31.6,2.8,31.6h4.9c0,0-1.5-18.9-1.5-26.5s5.1-34.9,5.1-34.9s2.3-26.8,0-27.5
              s-15.8-11.5-16.8-16.3s7.1-50,7.1-50S350.2,282.5,344.8,279.4z"/>
            <path opacity="0.1" d="M308.1,362c0.5,3.1,1.6,6.1,3.3,8.7l9.4-1.6c0,0,28.3-6.4,34.1-6.9
              c3.5-0.5,7-1.5,10.2-2.9c-2.2-7.9-6.6-15.1-12.6-20.8l-3.6-3.3c-5.3-4.9-11.4-8.8-18.1-11.6c0.4,0.4,30.1,25.7,23.6,30.7
              s-24-7.1-24-7.1l-19.9-17.1l-0.7-0.6l-1.1,1.8c0.6,0.2,1.2,0.5,1.7,0.8c6.8,3.7,11.8,10,14,17.4C328.5,363.3,308.1,362,308.1,362z
              "/>
            <path fill="#444053" d="M302,425.7c0,0,18.9,10.5,23.4,4.6c1-1.3,3.1-1.8,5.7-2c-0.4-1.3-0.6-2.2-0.6-2.2s-14-5.2-27.1-3.9
              C302.5,424.4,302,425.7,302,425.7z"/>
            <path fill="#E8E8F0" d="M310.7,329.5l20.6,17.7c0,0,17.4,12.1,24,7.1s-23.6-30.7-23.6-30.7l0,0c6.7,2.7,12.8,6.7,18.1,11.5
              l3.6,3.3c9.4,8.8,11.6,8.8,11.9,21.6c0.1,3.2-1,7.7-1.8,8.5c-3.1,3-5.2,24.8-4.7,27.3s-5.7,24.6-3.7,30.7s-25-3.1-29.6,2.8
              S302,425,302,425s10.5-26.8,13-28.5s5.6-15.5,0-21.9s-6.1-12.5-6.1-12.5s20.4,1.3,16.3-12.5c-2.3-8.1-8.1-14.7-15.7-18.2
              L310.7,329.5z"/>
            <path opacity="0.1" d="M338.2,374.6c0,0-7.9,14.9-18.1,18.8c-0.3,0.1-0.6,0.2-1,0.3
              C308.4,397,339,379.3,338.2,374.6z"/>
            <path fill="#FBBEBE" d="M227.6,242.7c0,0,3.8,9.7,6.9,13s25.7,36.5,25.7,36.5l1.9,4l1.6,3.4l14.5-1.5l1-5.9l-0.6-0.7
              c-1.5-1.8-5.4-6.5-8.1-8.5c-3.3-2.5-9.9-11-9.9-11s-20.6-21.4-22.2-27.3s-22.2-41-26.8-34.9S227.6,242.7,227.6,242.7z"/>
            <g opacity="0.1">
              <path d="M269.7,400.4c-1.7-0.2-3.5,0.1-5.1,0.7c-3.6,1.8-4.3,6.5-6.3,10c-2.3,4.1-6.8,6.6-11.4,7.5c-0.9,0.2-1.8,0.3-2.8,0.4
                c2.9,0.3,5.9,0.2,8.8-0.4c4.6-0.9,9-3.4,11.4-7.5c2-3.5,2.7-8.2,6.3-10c0.9-0.4,2-0.7,3-0.7L269.7,400.4z"/>
              <path d="M305.3,323.7c0-8,5.2-14.8,9.4-21.5s7.8-15.2,4.5-22.4c-2.9-6.1-20.2-14.4-30.3-12.8c9.8,1.3,21.9,7.7,24.3,12.8
                c3.4,7.2-0.2,15.7-4.5,22.4s-9.5,13.6-9.4,21.5c0,10.3-0.7,18-3.5,27.9c-0.8,2.9,7,7.2,5.8,9.9c-4.1,9-2.2,19.4-2.8,29.2
                c0,1.9-0.5,3.8-1.4,5.5c-2,3.3-6.1,4.1-10.1,4.3h3.5c4.7,0,10.2-0.4,12.6-4.3c0.9-1.7,1.4-3.6,1.4-5.5c0.6-9.8-1.2-20.2,2.8-29.2
                c1.2-2.7-6.6-7-5.8-9.9C304.6,341.7,305.3,333.9,305.3,323.7z"/>
            </g>
            <path opacity="0.1" d="M262.1,296.2l1.6,3.4l14.5-1.5l1-5.9l-0.6-0.7
              C277.2,292.3,265.6,297.9,262.1,296.2z"/>
            <path opacity="0.1" d="M315.8,334.5c0,0-3.6-5.6-5.6-4.1s-2.8-4.6-4-3.6s-4.6,3.9-6-0.1
              c-1.1-3.2-13.2-23.1-18.1-31.2c-1.3-2.1-2-3.4-2-3.4s-13.8,6.9-17.1,4.6v4.8l2.8,0.3l15,28c0,0,4.7,7.5,4.4,10.1s6,14.1,7.1,14.9
              s12,13.5,12,13.5s5.6,27.5,0.8,35.2s-13.2,13.2-13.2,13.2s-2.5,7.4-2.2,8.2s-2.9,4.1-1.4,5.1s3.6,8.2,3.6,8.2s7.1-11.5,11-13.2
              s8.8-25.2,8.8-25.2s1.4-13.8,1.5-22.7S312.7,334.5,315.8,334.5z"/>
            <path fill="#65617D" d="M315,334.5c0,0-3.6-5.6-5.6-4.1s-2.8-4.6-4-3.6s-4.6,3.9-6-0.1c-1.1-3.2-13.2-23.1-18.1-31.2l-2-3.4
              c0,0-13.8,6.9-17.1,4.6v4.8l2.8,0.3l15,28c0,0,4.7,7.5,4.4,10.1s6,14.1,7.1,14.9s12,13.5,12,13.5s5.6,27.5,0.8,35.2
              c-2.6,3.8-5.7,7.3-9.2,10.2c-3,2.5-5.1,5.8-6,9.6c-0.2,0.5-0.2,1.1-0.2,1.6c0.4,0.8-2.9,4.1-1.4,5.1s3.6,8.2,3.6,8.2
              s7.1-11.5,11-13.2s8.8-25.2,8.8-25.2s1.4-13.8,1.5-22.7S312,334.5,315,334.5z"/>
            <path opacity="0.1" d="M280.1,450.5c0,0,10.7,2,15,5.1s-16.3-4.8-16.3-4.8L280.1,450.5z"/>
            <path opacity="0.1" d="M305.8,477.6c0,0,11.2,9.3,9.2,17.7s-7.2,29.6-7.2,29.6L305.8,477.6z"/>
            <path opacity="0.1" d="M208.1,459.7c0,0-15.7,1.7-14.7,3.8s14.7-1.9,14.7-1.9V459.7z"/>
            <path opacity="0.1" d="M329,537.1c0,0,1,1.3-4.8,0s-1.5-5.2-1.5-5.2L329,537.1z"/>
            <path opacity="0.1" d="M327,554.7c0,0-8,1.3-12.3,0S327,554.7,327,554.7z"/>
            <path opacity="0.1" d="M310.5,623.5c0,0-1.4-1.5-8.4,1.3s-9.8,2.9-11.9,2S310.5,623.5,310.5,623.5z"
              />
            <path opacity="0.1" d="M318.3,457.5l-9.9,13.8C308.4,471.4,313.7,459.3,318.3,457.5z"/>
            <path opacity="0.1" d="M299,330.4c0,0-0.4,4.6-6.8,6.4s-7.6,3.2-7.6,3.2"/>
            <path opacity="0.1" d="M282.3,297.1c0,0-8.5,6-17.3,4.7"/>
            <path opacity="0.1" d="M288.8,425.6c0,0,2.5,1.5,8.4-5.2"/>
            <path opacity="0.1" d="M350.4,353.7c0.2-1.2-0.6-2.4-1.4-3.4l-1.7-2c-0.3-0.4-0.7-0.8-1.1-1.1
              c-1-0.8-2.3-1-3.3-1.7"/>
                      <g id="Celebration_BalloonGroup">
          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="389.4801" y1="405.8868" x2="402.5929" y2="405.8868" gradientTransform="matrix(1 0 0 -1 -180.4 565.54)">
            <stop offset="0" style={{ stopColor: '#808080', stopOpacity: '0.25' }}/>
            <stop offset="0.54" style={{ stopColor: '#808080', stopOpacity: '0.12' }}/>
            <stop offset="1" style={{ stopColor: '#808080', stopOpacity: '0.1' }} />
          </linearGradient>
          <path fill="none" stroke="url(#SVGID_1_)" strokeWidth="2" strokeMiterlimit="10" d="M216.9,107.5c-3.2,4.5-6.5,9.3-6.8,14.7
            c-0.4,8.4,6.3,15.3,9.3,23.2c2.6,7,2.3,14.8-0.9,21.6c-1.5,3-3.6,6.1-3.3,9.4c0.2,2.1,1.2,3.9,2,5.8c4.1,9.6,3.4,20.6-1.9,29.6"/>
          <path fill="none" stroke="#535461" strokeWidth="2" strokeMiterlimit="10" d="M217.3,107.5c-3.2,4.5-6.5,9.3-6.8,14.7
            c-0.4,8.4,6.3,15.3,9.3,23.2c2.6,7,2.3,14.8-0.9,21.6c-1.5,3-3.6,6.1-3.3,9.4c0.2,2.1,1.2,3.9,2,5.8c4.1,9.6,3.4,20.6-1.9,29.6"/>
          <polygon fill="#FE9236" points="173.3,141.9 164.6,141.9 166.1,136.6 171.4,136.6 		"/>
          <path fill="#FE9236" d="M204.9,86.7c0,20-16.2,49.3-36.2,49.3s-36.2-29.3-36.2-49.3c0-20,16.2-36.2,36.2-36.2
            S204.9,66.7,204.9,86.7L204.9,86.7z"/>
          <polygon fill="#FE841D" points="171.4,136.8 166.1,136.8 163.1,135.1 174.4,135.1 		"/>
          <path opacity="0.1" fill="#FFFFFF" d="M155.7,58.6c0,0-15.3,8.3-17.4,24.8"/>
          <polygon fill="#4EA39A" points="220.6,110.1 211.9,110.1 213.5,104.8 218.7,104.8 		"/>
          <path fill="#4EA39A" d="M252.1,54.1c0.4,20-15.1,49.6-35.1,50s-36.8-28.5-37.3-48.4c-0.4-20,15.4-36.5,35.4-37
            S251.7,34.1,252.1,54.1L252.1,54.1z"/>
          <polygon fill="#428880" points="218.7,105 213.5,105 210.4,103.3 221.7,103.3 		"/>
          <path opacity="0.1" fill="#FFFFFF" d="M203,26.8c0,0-15.3,8.3-17.4,24.8"/>
          <polygon fill="#3AD29F" points="267.6,150.6 258.8,150.6 260.4,145.4 265.6,145.4 		"/>
          <path fill="#3AD29F" d="M299.2,95.5c0,20-16.2,49.3-36.2,49.3s-36.2-29.3-36.2-49.3c0-20,16.2-36.2,36.2-36.2
            S299.2,75.5,299.2,95.5z"/>
          <polygon fill="#32B78B" points="265.6,145.6 260.4,145.6 257.3,143.8 268.6,143.8 		"/>
          <path opacity="0.1" fill="#FFFFFF" d="M249.9,67.3c0,0-15.3,8.3-17.4,24.8"/>
          <path fill="none" stroke="#535461" strokeWidth="2" strokeMiterlimit="10" d="M168.7,141.7c0,5.5,0.1,11.3,3,16
            c4.6,7.1,14.1,8.8,21.1,13.4c6.2,4.2,10.5,10.7,11.8,18.1c0.6,3.3,0.6,7,2.8,9.6c1.6,3.4,4.1,11.5,5.8,12.6
            c8.9,5.4,11.3,13.4,12.2,23.8"/>
          <path fill="none" stroke="#535461" strokeWidth="2" strokeMiterlimit="10" d="M216.9,211.3c2.2-2.6,9.8-12.5,10.3-15.8
            c1.3-7.4,5.6-13.9,11.8-18.1c7-4.7,16.5-6.3,21.1-13.4c3-4.6,3-10.4,3-16"/>
        </g>
          </g>
        </g>
      </svg>
    );
  }

}

export default Celebration;