import React, { Component } from 'react';
import { WithTrans } from  '../i18n/i18nWrapper';

import icons from '../assets/icons.svg';
const BookIcon = icons + '#book';
const LightbulbIcon = icons + '#lightbulb';

class Information extends Component {

  state = {
    articles: [
      {
        id: 1,
        title: this.props.t('information.guide_for_mentee'),
        type: 'tips',
        url: this.props.i18n.language === 'da' ? 'https://careermentor.dk/wp-content/uploads/guide_til_mentee_v1.pdf' : 'https://careermentor.dk/wp-content/uploads/guide_til_mentee_v1.pdf'
      },
      {
        id: 2,
        title: this.props.t('information.guide_for_mentor'),
        type: 'tips',
        url: this.props.i18n.language === 'da' ? 'https://careermentor.dk/wp-content/uploads/guide_til_mentor_v1.pdf' : 'https://careermentor.dk/wp-content/uploads/guide_til_mentor_v1.pdf'
      },
      {
        id: 3,
        title: this.props.t('information.faq'),
        type: 'tips',
        url: '/help/#faq'
      },
      {
        id: 4,
        title: this.props.t('information.codex'),
        type: 'guide',
        url: this.props.i18n.language === 'da' ? 'https://careermentor.dk/wp-content/uploads/CareerMentor-adfaerdskodeks.pdf' : 'https://careermentor.dk/wp-content/uploads/CareerMentor_Code_of_Conduct_v1.pdf'
      },
      {
        id: 5,
        title: this.props.t('information.security'),
        type: 'guide',
        url: this.props.i18n.language === 'da' ? 'https://careermentor.dk/wp-content/uploads/CareerMentor-sikkerhedsguide.pdf' : 'https://careermentor.dk/wp-content/uploads/CareerMentor_Mentoring_Safety_Guidelines_v1.pdf'
      }
    ],
  };

  render() {
    return (
      <div className="Information">
        <ul className="list-unstyled">
          {this.state.articles.map((article, index) =>
            <li key={index}>
              <a href={article.url} target="_blank" rel="noopener noreferrer">
                <svg>
                  <use xlinkHref={article.type === 'guide' ? BookIcon : LightbulbIcon}></use>
                </svg>
                <div>
                  <p>{article.title}</p>
                </div>
              </a>
            </li>
          )}
        </ul>
      </div>
    )
  }

}

export default WithTrans(Information);