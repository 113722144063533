import React from 'react'

import Profile from '../Profile';

const PageProfile = (props) => {
  return (
    <div className="Page">
      <Profile subpage={props.match.params.subpage} />
    </div>
  )
}

export default PageProfile;