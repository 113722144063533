import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { firebasecore, firestore } from '../firebase';

import { debounce, getDateString } from '../util';

import WithConversations from '../components/HOC/WithConversations';
import WithUser from '../components/HOC/WithUser';
import { WithTrans } from '../i18n/i18nWrapper';

import Loader from './Loader';
import Message from './Message';
import Tooltip from './Tooltip';
import Report from './Report';
import Modal from './Modal';

import avatarPlaceholder from '../assets/avatar-placeholder.jpg'

import icons from '../assets/icons.svg';
const Arrow = icons + '#arrow';
// const Profile = icons + '#profile';
const owlIcon = icons + '#owl';
const sproutIcon = icons + '#sprout';
const warningIcon = icons + '#warning';

class Conversation extends Component {

  constructor(props) {
    super(props);
    this.updateLastReadTime = this.updateLastReadTime.bind(this);
    this.updateLastMessageTime = this.updateLastMessageTime.bind(this);
  }

  state = {
    user: {},
    currentUser: {},
    messages: [],
    focused: false,
    message: '',
    reportModalOpen: false,
    conversationNotFound: false,
  };

  chatWindow = React.createRef();

  unsubscribe = null;

  updateLastReadTimeDebounced = debounce(this.updateLastReadTime, 500);
  updateLastMessageTimeDebounced = debounce(this.updateLastMessageTime, 500);

  componentDidMount() {
    this.fetchMessages(this.props.match.params.id);
  }

  componentWillUnmount = () => {
    if(this.unsubscribe) {
      this.unsubscribe();
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.fetchMessages(this.props.match.params.id);
    }
    this.scrollToBottom();
  }

  fetchMessages(conversationID) {
    this.unsubscribe = firestore.collection('conversations').doc(conversationID).collection('messages').orderBy('timestamp').limit(150).onSnapshot(snapshot => {
      const messages = snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      // Update messages
      this.setState({ messages });
      // Update last read time debounced
      this.updateLastReadTimeDebounced();
      // Scroll to bottom
      this.scrollToBottom()
    });
  }

  updateLastReadTime() {
    const conversationDocumentRef = firestore.collection('conversations').doc(this.props.match.params.id);
    const key = 'lastRead-' + this.props.user.uid;
    conversationDocumentRef.update({
      [key]: firebasecore.firestore.FieldValue.serverTimestamp()
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
      this.setState({ conversationNotFound: true });
      alert('Samtale ikke fundet');
    });
  }

  updateLastMessageTime() {
    const conversationDocumentRef = firestore.collection('conversations').doc(this.props.match.params.id);
    conversationDocumentRef.update({
      lastMessageTimestamp: firebasecore.firestore.FieldValue.serverTimestamp(),
      lastMessage: this.state.messages[this.state.messages.length - 1].message
    })
    .catch(function(error) {
      console.error("Error updating document: ", error);
    });
  }

  toggleFocus() {
    this.setState(prevState => ({
      focused: !prevState.focused
    }));
  }

  handleChange = event => {
    const { value } = event.target;
    this.setState({ message: value });
  };

  sendMessage() {
    if(this.state.message) {
      firestore.collection('conversations').doc(this.props.match.params.id).collection('messages').add({
        message: this.state.message,
        user: this.props.user.uid,
        timestamp: firebasecore.firestore.FieldValue.serverTimestamp()
      }).then(() => {
        this.setState({ message: '' });
        // Update last message time debounced
        this.updateLastMessageTimeDebounced();
      }).catch((error) => {
        console.error('Error writing new message to database', error);
      });
    }
  }

  scrollToBottom() {
    const scrollHeight = this.messageList.scrollHeight;
    const height = this.messageList.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  }

  handleKeyPress = (event) => { 
    if(event.key === 'Enter' && !event.shiftKey){
      this.sendMessage();
    }
  }

  toggleReportModal() {
    this.setState(prevState => ({
      reportModalOpen: !prevState.reportModalOpen
    }));
  }

  render() {
    const messages = this.state.messages;
    const conversations = this.props.conversations;
    let user = false;
    let userId = 0;
    let conversationMentor = false;
    let conversationLocked = false;
    if(conversations && conversations.length > 0) {
      const conversation = conversations.find(conversation => conversation.user ? conversation.user.conversationId === this.props.match.params.id : null);
      if(conversation) {
        user = conversation.user;
        userId = user.userId;
        if(conversation.mentor) {
          conversationMentor = conversation.mentor;
        }
        if(conversation && conversation.locked) {
          conversationLocked = true;
        }
      }
    }
    const lastSignIn = user.lastSignIn ? getDateString(user.lastSignIn, this.props.i18n.language) : false;
    const inputClass = classNames('Conversation__input', {
      'Conversation__input--focused': this.state.focused,
    });
    const conversationClass = classNames('Conversation', {
      'Conversation--locked': conversationLocked,
    });
    const viewAs = conversationMentor === this.props.user.uid ? '?viewAs=mentor' : '?viewAs=mentee';
    return (
      <div className={conversationClass}>
        <div className="Conversation__top">
          <Link to={'/conversations'}>
            <svg>
              <use xlinkHref={Arrow}></use>
            </svg>
          </Link>
          <div className="Conversation__person">
            { user ? (
              <>
                <Link to={`/profile/${userId + viewAs}`}>
                  <figure className="Conversation__image">
                    <img src={user.profileImageURL ? user.profileImageURL : avatarPlaceholder} alt={user.name}/>
                  </figure>
                  {conversationMentor ? (
                    <div className="Conversation__image__badge">
                      {conversationMentor === this.props.user.uid ? (
                        <Tooltip text={user.name + this.props.t('conversations.is_mentee_in_conversation')} placement="left">
                          <svg className="Conversation__image__badge__type">
                            <use xlinkHref={sproutIcon}></use>
                          </svg>
                        </Tooltip>
                      ) : (
                        <Tooltip text={user.name + this.props.t('conversations.is_mentor_in_conversation')} placement="left">
                          <svg className="Conversation__image__badge__type--mentor">
                            <use xlinkHref={owlIcon}></use>
                          </svg>
                        </Tooltip>
                      )}
                    </div>
                  ) : null }
                </Link>
                <div>
                  <Link to={`/profile/${userId + viewAs}`}><h3 className="Conversation__name">{user.name}</h3></Link>
                  <h3 className="Conversation__title"><span>{user.title}</span> | <span>{user.company}</span></h3>
                  {lastSignIn ? <span className="Conversation__lastonline">{this.props.t('conversations.last_seen_online')} {lastSignIn}</span> : null}
                </div>
              </>
            ) : (
              <Loader color='green' />
            ) }
          </div>
          {/* { user ? (
            <Link to={`/profile/${userId + viewAs}`}>
              <svg>
                <use xlinkHref={Profile}></use>
              </svg>
            </Link>
          ) : (
            <Loader color='green' />
          ) } */}
          {!this.state.conversationNotFound ? (
            <button className="Conversation__report" onClick={() => this.toggleReportModal()}>
              <svg>
                <use xlinkHref={warningIcon}></use>
              </svg>
            </button>
          ) : null}
        </div>
        <div className="Conversation__inner" ref={(div) => { this.messageList = div; }}>
          { messages && messages.length > 0 ? (
            <div>
              {this.state.messages.map((message, index) => {
                const isFromCurrentUser = message.user === this.props.user.uid ? true : false;
                const userAvatar = isFromCurrentUser ? this.props.user.profileImageURL : user.profileImageURL;
                const userName = isFromCurrentUser ? this.props.user.name : user.name;
                return <Message message={message} photo={userAvatar} name={userName} currentUserId={this.props.user.uid } key={index} />
              })}
            </div>
          ) : (
            <Loader color='green' />
          ) }
        </div>
        {!this.state.conversationNotFound ? (
          <>
            {!conversationLocked ? (
              <div className={inputClass}>
                <textarea type="text" placeholder={this.props.t('conversations.write_a_message')} onChange={this.handleChange} onFocus={() => this.toggleFocus()} onBlur={() => this.toggleFocus()} value={this.state.message} onKeyPress={this.handleKeyPress}/>
                <button className="Button Button--outline" onClick={() => this.sendMessage()}>{this.props.t('conversations.send')}</button>
              </div>
            ) : null}
          </>
        ) : null}
        <Modal toggleModal={() => this.toggleReportModal()} open={this.state.reportModalOpen} title={this.props.t('conversations.block_title')}>
          <Report closeReportModal={() => this.toggleRequestModal()} userToReport={userId} conversationId={this.props.match.params.id}/>
        </Modal>
      </div>
    )
  }

}

export default WithUser(WithConversations(WithTrans(Conversation)));