import React from 'react';

const Owl = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path d="M38.7,24.4c-0.8,0.5-1.5,1.2-2.2,1.8c-2.2,2.4-3.6,5.5-3.6,9c0,3.4,1.3,6.6,3.6,8.9c0.7,0.7,1.4,1.3,2.2,1.9
        c0.2,0.2,0.6,0,0.6-0.3v-21C39.2,24.4,38.9,24.2,38.7,24.4z"/>
      <path d="M39.2,14.1C39.2,6.3,32.9,0,25.2,0h-4.1C13.3,0,7,6.3,7,14.1v9.7C7,37.1,17.9,48,31.2,48h4.9c0.2,0,0.3-0.2,0.1-0.4
        c-0.9-0.7-1.7-1.5-2.4-2.4c-2.3-2.8-3.6-6.3-3.6-10.1c0-5.1,2.4-9.7,6.3-12.6c0.7-0.5,1.4-1,2.2-1.4c0.4-0.2,0.6-0.5,0.6-0.9
        L39.2,14.1L39.2,14.1z M31,21.6c-0.1,0-0.2,0-0.2,0.1h-3.9c-1,0-2.1,0.4-2.8,1.2l-0.6,0.6c-0.2,0.2-0.5,0.2-0.7,0l-0.6-0.6
        c-0.7-0.7-1.7-1.2-2.8-1.2h-3.5c0,0-0.1,0-0.1,0c-3.3-0.2-5.8-3-5.7-6.3c0.2-3,2.6-5.4,5.6-5.6c3.2-0.2,6,2.1,6.4,5.3
        c0.1,0.6,0.5,1,1.1,1s1-0.4,1.1-1c0.4-3.2,3.2-5.5,6.4-5.3c2.9,0.2,5.3,2.6,5.6,5.5C36.4,18.4,34.1,21.2,31,21.6z"/>
      <circle cx="16.2" cy="15.7" r="2.5"/>
      <circle cx="30" cy="15.7" r="2.5"/>
    </svg>
  )
}

export default Owl;