import React, { Component } from 'react';
import Competence from './Competence';

import { WithTrans } from '../../i18n/i18nWrapper';

import allCompetenceCategories from './competenceCategories.json';

class CompetenceSelector extends Component {
  render() {
    return(
      <div className="CompetenceSelector">
        <ul className="list-unstyled">
          {allCompetenceCategories.map((competenceCategory) => (
            <li className="Accordion CompetenceSelector__category" key={competenceCategory.id}>
              <input type="checkbox" id={ `accordion-${this.props.profile}-${competenceCategory.id}` } className="Accordion__checkbox"/>
              <label htmlFor={ `accordion-${this.props.profile}-${ competenceCategory.id}` } aria-label="Toggle accordion" className="Accordion__label">
                <span>{ this.props.i18n.language === 'en' ? competenceCategory.title_en : competenceCategory.title }</span>
                <div className="Accordion__icon">
                  <div></div>
                  <div></div>
                </div>
              </label>
              <div className="Accordion__content">
                <ul className="list-unstyled CompetenceSelector__competencies">
                  {competenceCategory.competencies.map((competence) =>
                    <Competence key={competence.id} id={competence.id} title={this.props.i18n.language === 'en' ? competence.title_en : competence.title} handleClick={this.props.handleClick} profile={this.props.profile} competencies={this.props.competencies}/>
                  )}
                </ul>
              </div>
            </li>
            )
          )}
        </ul>
      </div>
    )
  }

}


export default WithTrans(CompetenceSelector);