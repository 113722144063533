import React, { Component } from 'react'

import { WithTrans } from  '../i18n/i18nWrapper';

import avatarPlaceholder from '../assets/avatar-placeholder.jpg'

import { getUserDocument } from '../firebase';
import { getDateString } from '../util';


import Modal from './Modal';
import Request from './Request';
import Tooltip from './Tooltip';
import Loader from './Loader';

import icons from '../assets/icons.svg';
const owlIcon = icons + '#owl';
const sproutIcon = icons + '#sprout';

class RequestPreview extends Component {
  
  state = {
    open: false,
    user: false,
    loading: true,
  };

  toggleRequestModal() {
    this.setState(prevState => ({
      open: !prevState.open
    }));
    if(!this.state.open) {
      this.props.childOpen(true);
    } else {
      this.props.childOpen(false);
    }
  }

  componentDidMount = async () => {
    const userDocument = await getUserDocument(this.props.request.userId);
    if(userDocument) {
      this.setState({
        user: userDocument,
        loading: false,
      });
    } else {
      // error
      console.error('No user found on request');
    }
  }

  render() {
    const title = this.props.request.type === 'mentor' ? this.props.t('requests.mentoring_request') : this.props.t('requests.mentee_request');
    const user = this.state.user;
    return (
      <>
        {this.state.loading ? (
          <li>
            <Loader color='green'/>
          </li>
        ) : (
          <li className="RequestPreview" onClick={() => this.toggleRequestModal()}>
            <figure className="RequestPreview__image">
              <img src={user.profileImageURL ? user.profileImageURL : avatarPlaceholder} alt={user.name}/>
            </figure>
            <div className="RequestPreview__person">
              <p className="RequestPreview__name">{user.name}</p>
              <p className="RequestPreview__title">{user.title}</p>
            </div>
            <div className="RequestPreview__right">
              {this.props.direction === 'ingoing' ? (
                <>
                {this.props.request.type === 'mentor' ? (
                  <Tooltip text="Anmodning fra mentee" placement="left">
                    <svg className="RequestPreview__type">
                      <use xlinkHref={sproutIcon}></use>
                    </svg>
                  </Tooltip>
                ) : (
                  <Tooltip text="Anmodning fra mentor" placement="left">
                    <svg className="RequestPreview__type">
                      <use xlinkHref={owlIcon}></use>
                    </svg>
                  </Tooltip>
                ) }
                </>
              ) : (
                <div className="RequestPreview__status">
                  <span>Status:</span>
                  <span>
                    {this.props.request.status === 'pending' ? this.props.t('requests.pending') : null}
                    {this.props.request.status === 'accepted' ? this.props.t('requests.accepted') : null}
                    {this.props.request.status === 'ignored' ? <span className="ignored">{this.props.t('requests.declined')}</span> : null}
                  </span>
                </div>
              )}
              <p className="RequestPreview__date">{getDateString(this.props.request.date, this.props.i18n.language)}</p>
            </div>
            <Modal toggleModal={() => this.toggleRequestModal()} open={this.state.open} title={title}>
              <Request fromUser={this.state.user} fromUserId={this.props.request.userId} request={this.props.request} direction={this.props.direction} closeRequestModal={() => this.toggleRequestModal()} />
            </Modal>
          </li>
        ) }
      </>
    )
  }

}

export default WithTrans(RequestPreview);