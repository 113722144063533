import React, { Component } from 'react';
import classNames from 'classnames';

import { firestore } from '../firebase';

import WithUser from '../components/HOC/WithUser';
import { WithTrans } from '../i18n/i18nWrapper';

import Notification from './Notification';
import Popups from './Popups';

import icons from '../assets/icons.svg';
const BellIcon = icons + '#bell';

class Notifications extends Component {

   constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.markAsRead = this.markAsRead.bind(this);
    this.markAsNotifiedViaPopup = this.markAsNotifiedViaPopup.bind(this);
  }

  unsubscribeFromNotifications = null;

  state = {
    open: false,
    unread: false,
    notifications: []
  };

  componentDidMount = () => {
    // Listen for notifications
    this.unsubscribeFromNotifications = firestore.collection('users').doc(this.props.user.uid).collection('notifications').orderBy('timestamp', 'desc').limit(50).onSnapshot(snapshot => {
      const notifications = snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      // Update messages
      this.setState({ notifications })
      if (notifications.some(notification => notification.read === false)) {
        // At least one notification is unread
        this.setState({ unread: true })
      } else {
        this.setState({ unread: false })
      }
    });
  }

  componentWillUnmount = () => {
    // Unsubscribe from firebase firestore db
    if(this.unsubscribeFromNotifications) {
      this.unsubscribeFromNotifications();
    }
  }

  toggleNotifications() {
    this.setState(prevState => ({
      open: !prevState.open
    }));
    setTimeout(() => {
      if(this.state.open) {
        document.addEventListener('click', this.handleClick);
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    setTimeout(() => {
      if (prevState.open) {
        // Closing, remove mouse down listener
        document.removeEventListener('click', this.handleClick);
      }
    });
  };

  handleClick = (e) => {
    if(this.node.contains(e.target)) {
      // click inside
      return;
    }
    // Click outside, close notifications
    this.toggleNotifications();
  }

  markAsRead(id, alreadyRead) {
    if(alreadyRead) {
      // console.log('already read!')
    } else {
      const notificationDocumentRef = firestore.collection('users').doc(this.props.user.uid).collection('notifications').doc(id);
      notificationDocumentRef.update({
        read: true
      }).catch(function(error) {
        console.error("Error updating document: ", error);
      });
    }

    // Close notifications
    this.toggleNotifications();
  }

  markAllAsRead() {
    // console.log('Mark all as read!');
    if(this.state.unread) {
      this.state.notifications.forEach(notification => {
        if (!notification.read) {
          // console.log('Notification not read: ', notification.id);
          this.markAsRead(notification.id);
        }
      });
    } else {
      // console.log('All notifications already read')
    }
  }

  markAsNotifiedViaPopup(id) {
    const notificationDocumentRef = firestore.collection('users').doc(this.props.user.uid).collection('notifications').doc(id);
    notificationDocumentRef.update({
      notifiedViaPopup: true
    }).catch(function(error) {
      console.error("Error updating document: ", error);
    });
  }

  render() {
    const notifications = this.state.notifications;
    const notificationsClass = classNames('Notifications', {
      'Notifications--unread': this.state.unread,
      'Notifications--open': this.state.open,
    });
    const notificationsInnerClass = classNames('Notifications__inner', {
      'Notifications__inner--open': this.state.open,
    });
    return (
      <>
        <div className={notificationsClass} ref={node => this.node = node}>
          <button className="Notifications__toggle" onClick={() => this.toggleNotifications()}>
            <svg>
              <use xlinkHref={BellIcon}></use>
            </svg>
          </button>
          <div className={notificationsInnerClass}>
            <div className="Notifications__header">
              <h3 className="Notifications__title">{this.props.t('notifications.notifications')}</h3>
              <span onClick={() => this.markAllAsRead()}>{this.props.t('notifications.mark_all_as_read')}</span>
            </div>
            <div className="Notifications__scroll">
              {notifications && notifications.length > 0 ? (
                <ul className="list-unstyled">
                  {notifications.map((notification) =>
                    <Notification notification={notification} key={notification.id} onClick={this.markAsRead}/>
                  )}
                </ul>
              ) : (
                <p className="Notifications__notfound">{this.props.t('notifications.no_notifications_found')}</p>
              )}
            </div>
          </div>
        </div>
        <Popups notifications={this.state.notifications} markNotificationAsNotifiedViaPopup={this.markAsNotifiedViaPopup}/>
      </>
    )
  }

}

export default WithUser(WithTrans(Notifications));