import React, { Component } from 'react';

import icons from '../assets/icons.svg';
const BurgerIcon = icons + '#burger';

class Burger extends Component {

  sidebar = null;
  topbar = null;

  state = {
    sidebarOpen: false,
  }

  componentDidMount() {
    this.sidebar = document.getElementById("Sidebar");
    this.topbar = document.getElementById("Topbar");
  }

  toggleSidebar() {
    this.sidebar.classList.toggle('Sidebar--open');
    this.topbar.classList.toggle('Sidebar--open');
  }

  render() {
    return (
      <button className="Topbar__burger" onClick={() => this.toggleSidebar()}>
        <svg>
          <use xlinkHref={BurgerIcon}></use>
        </svg>
        <div className="Topbar__scrim"></div>
      </button>
    )
  }

}

export default Burger;