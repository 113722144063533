import React from 'react';

import LogoIconSVG from '../assets/logo-icon.svg';

const LogoIcon = () => {
  return (
    <figure className="LogoIcon">
      <img src={LogoIconSVG} alt="CareerMentor logo icon"/>
    </figure>
  )
}

export default LogoIcon;