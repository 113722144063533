import React, { Component, createContext } from 'react'

import axios from 'axios';

export const CollabsContext = createContext({ collabs: [] });

const restURL = 'https://careermentor.dk/wp-json/wp/v2/';

class CollaborationsProvider extends Component {

  state = {
    collabs: [],
  };

  componentDidMount = async () => {
    axios.get(restURL + 'collaboration?orderby=menu_order&order=asc&per_page=50')
    .then(response => {
      // handle success
      this.setState({ collabs: response.data });
    })
    .catch(function (error) {
      // handle error
      console.log('error fetching collabs: ', error);
    })
  };


  render() {
    const { collabs } = this.state;
    const { children } = this.props;

    return (
      <CollabsContext.Provider value={collabs}>{children}</CollabsContext.Provider>
    )
  }
}

export default CollaborationsProvider;