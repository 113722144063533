import React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/browser';
import './styles/app.scss';

import './i18n/config';

import Application from './components/Application';

if(process.env.NODE_ENV === 'production') { 
  Sentry.init({dsn: "https://0a1953da1e594097add5b92ea3157b9c@o410322.ingest.sentry.io/5284157"});
}

render(<Application />, document.getElementById('root'));

if(process.env.NODE_ENV === 'production') { 
  console.log('Made with 💚💚💚 by https://hhb.dk');
}