import React, { Component } from 'react';
import { TweenLite, TimelineMax, Elastic } from "gsap/TweenMax";

class Loading extends Component {

  componentDidMount() {
    // Objects
    const smallOne = document.getElementById('SmallOne');
    const smallTwo = document.getElementById('SmallTwo');
    const big = document.getElementById('Big');

    setTimeout(() => {

      // Set initial state
      TweenLite.set(smallOne, {
        scale: 0,
        transformOrigin: '0% 100%',
      });
      TweenLite.set(smallTwo, {
        scale: 0,
        transformOrigin: '0% 100%',
      });
      TweenLite.set(big, {
        scale: 0,
        transformOrigin: '0% 100%'
      });

      // Timeline
      const timeline = new TimelineMax({ paused: true, repeat: -1, yoyo: true, repeatDelay: 0.3 });

      timeline.to(smallOne, 0.4, {
        scale: 1,
        ease: Elastic.easeOut.config(1,0.8),
      }).to(smallTwo, 0.4, {
        scale: 1,
        ease: Elastic.easeOut.config(1,0.8),
      },'-=0.4').to(big, 0.4, {
        scale: 1,
        ease: Elastic.easeOut.config(1,0.8),
      });

    }, 500);

  }

  render() {
    return (
      <div className="Loading">
        <svg id="Loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.6 20">
          <linearGradient id="SmallTwo_1_" gradientUnits="userSpaceOnUse" x1="-5.1486" y1="20.73" x2="3.1959" y2="12.1329" gradientTransform="matrix(1 1.998856e-04 -1.998856e-04 1 6.1881 -2.0891)">
            <stop offset="1.364087e-03" style={{ stopColor: '#FF7F45' }}/>
            <stop offset="1" style={{ stopColor: '#FFAA45' }}/>
          </linearGradient>
          <path id="SmallTwo" fill="url(#SmallTwo_1_)" style={{ transform: 'translateY(0.5%)' }} d="M1.4,6.4c-0.2,0-0.3,0.1-0.3,0.3l0,11.8c0,0.2,0.1,0.3,0.3,0.3l10.9,0
            c0.2,0,0.3-0.1,0.3-0.3C12.6,11.9,7.6,6.4,1.4,6.4z"/>
          <linearGradient id="Big_1_" gradientUnits="userSpaceOnUse" x1="1.6804" y1="20.6287" x2="13.5713" y2="8.3779" gradientTransform="matrix(1 1.998856e-04 -1.998856e-04 1 6.1881 -2.0891)">
            <stop offset="0.1452" style={{ stopColor: '#4FA39A' }}/>
            <stop offset="1" style={{ stopColor: '#4FB59A' }}/>
          </linearGradient>
          <path id="Big" fill="url(#Big_1_)" d="M8.4,1.1C8.2,1.1,8,1.3,8,1.6l0,16.9c0,0.2,0.2,0.4,0.4,0.4l15.5,0c0.2,0,0.4-0.2,0.4-0.4 C24.3,8.9,17.2,1.1,8.4,1.1z"/>
          <linearGradient id="SmallOne_1_" gradientUnits="userSpaceOnUse" x1="-5.1486" y1="20.73" x2="3.1959" y2="12.1329" gradientTransform="matrix(1 1.998856e-04 -1.998856e-04 1 6.1881 -2.0891)">
            <stop offset="1.364087e-03" style={{ stopColor: '#FF7F45' }}/>
            <stop offset="1" style={{ stopColor: '#FFAA45' }}/>
          </linearGradient>
          <path id="SmallOne" opacity="0.7" fill="url(#SmallOne_1_)" style={{ transform: 'translateY(0.5%)' }}  d="M1.4,6.4c-0.2,0-0.3,0.1-0.3,0.3l0,11.8c0,0.2,0.1,0.3,0.3,0.3 l10.9,0c0.2,0,0.3-0.1,0.3-0.3C12.6,11.9,7.6,6.4,1.4,6.4z"/>
        </svg>
      </div>
    );
  }

}

export default Loading;



