import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Owl from './Illustrations/Owl';
import Sprout from './Illustrations/Sprout';
import TempUserContext from "../../providers/TempUserProvider";

import { WithTrans } from '../../i18n/i18nWrapper';

class StepTwo extends Component {

  componentDidUpdate() {
    if(this.props.tempUser.mentor || this.props.tempUser.mentee) {
      this.props.navReady();
    } else {
      this.props.navNotReady();
    }
  };

  componentWillUnmount = () => {
    this.props.navNotReady();
  }

  render() {
    const mentorClass = classNames('SignUpStep__mentor', {
      'SignUpStep__mentor--selected': this.props.tempUser.mentor
    });
    const menteeClass = classNames('SignUpStep__mentee', {
      'SignUpStep__mentor--selected': this.props.tempUser.mentee
    });
    return (
      <div className="SignUpStep">
        <div className="SignUpStep__content">
          <h2 className="SignUpStep__title">{this.props.t('signup.select_mentor_mentee_or_both')}</h2>
          <div className="SignUpStep__mm">
            <TempUserContext.Consumer>
              {({ tempUser, updateTempUser }) => (
                <button className={mentorClass} onClick={() => updateTempUser({mentor: !tempUser.mentor})}>
                  <Owl />
                  <h3>{this.props.t('mentor')}</h3>
                </button>
              )}
            </TempUserContext.Consumer>
            <TempUserContext.Consumer>
              {({ tempUser, updateTempUser }) => (
                <button className={menteeClass} onClick={() => updateTempUser({mentee: !tempUser.mentee})}>
                  <Sprout />
                  <h3>{this.props.t('mentee')}</h3>
                </button>
              )}
            </TempUserContext.Consumer>
          </div>
        </div>
      </div>
    );
  };

}

StepTwo.propTypes = {
  tempUser: PropTypes.object.isRequired,
  navReady: PropTypes.func.isRequired,
  navNotReady: PropTypes.func.isRequired,
};

export default WithTrans(StepTwo);