import React, { Component } from 'react';
import classNames from 'classnames';

import { firestore } from '../firebase';

import WithUser from './HOC/WithUser';
import { WithTrans } from '../i18n/i18nWrapper';

import icons from '../assets/icons.svg';
const GlobeIcon = icons + '#globe';
const ChevronIcon = icons + '#chevron';

class LangSwitcher extends Component {

  handleLangChange(language) {
    this.props.i18n.changeLanguage(language);

    if(this.props.user) {
      // Update user lang preference (if logged in)
      const currentUserDocumentRef = firestore.collection('users').doc(this.props.user.uid);
      // Get user document
      currentUserDocumentRef.get().then((docSnapshot) => {
        if (docSnapshot.exists) {
          currentUserDocumentRef.update({
            language: language
          }).then(() => {
            // User updated
            // console.log("User profile language updated, ", language);
          }).catch(function(error) {
            // Something went wrong with update of user (probably timed out or not allowed)
            console.error("Error: ", error);
          });
        }
      }).catch(function(error) {
        // User probably doesn't exist
        console.error("Error: ", error);
      });
    }
  };

  render() {
    const currentLangName = this.props.i18n.language === 'da' ? 'Dansk' : 'English';
    const langSwitcherClass = classNames('LangSwitcher', {
      'LangSwitcher--login': this.props.loginStyle,
    });
    return (
      <div className={langSwitcherClass}>
        <label htmlFor="languages">
          <svg>
            <use xlinkHref={GlobeIcon}></use>
          </svg>
          <span>{ currentLangName }</span>
          <svg>
            <use xlinkHref={ChevronIcon}></use>
          </svg>
        </label>
        <select 
          name="languages" 
          id="languages" 
          defaultValue={this.props.i18n.language}
          onChange={(e) => this.handleLangChange(e.target.value)}
        >
          <option value="da">Danish</option>
          <option value="en">English</option>
        </select>
    </div>
    );
  }
}


export default WithUser(WithTrans(LangSwitcher));