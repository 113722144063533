
import React, { Component } from 'react';

import WithUser from './HOC/WithUser';
import { WithTrans } from  '../i18n/i18nWrapper';

import { AllAchievements } from './AllAchievements';
import Achievement from './Achievement'

class Achievements extends Component {

  state = {
    userAchievements: [],
    loading: true,
  };

  componentDidMount() {
    if(this.props.user && this.props.user.achievements) {
      this.setState({
        userAchievements: this.props.user.achievements,
        loading: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.user.achievements !== this.props.user.achievements) {
      this.setState({
        userAchievements: this.props.user.achievements,
      });
    }
  }

  render() {
    const userAchievements = this.state.userAchievements ? this.state.userAchievements : [];
    return (
      <div className="module bg-white border-gray">
        <h3 className="module-title">{this.props.t('achievements.your_achievements')}</h3>
        <ul className="Achievements">
          {AllAchievements.map((achievement) =>
            <Achievement achievement={achievement} userAchievements={userAchievements} key={achievement.id} loading={this.state.loading} />
          )}
        </ul>
      </div>
    )
  }
}

export default WithUser(WithTrans(Achievements));