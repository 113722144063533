import React, { Component, createContext } from 'react'
import { firestore, newAchievement } from '../firebase';

import WithUser from '../components/HOC/WithUser';
import { getUserDocument } from '../firebase';

export const ConversationsContext = createContext();

class ConversationsProvider extends Component {
  
  state = {
    conversations: [],
    conversationsWithUser: [],
    loading: true,
  }

  unsubscribeFromFirestore = null;

  componentDidMount = () => {
    // Listen for conversations at the beginning
    if(this.props.user) {
      this.listenForConversations(this.props.user.uid);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.user) {
      // There was a previous user
      if(this.props.user) {
        if(this.props.user.uid !== prevProps.user.uid) {
          // User updated, with new user
          // reset
          this.unsubscribeFromFirestore();
          this.setState({
            conversations: [],
            conversationsWithUser: [],
          });
          setTimeout( () => {
            // new listener
            this.listenForConversations(this.props.user.uid);
          }, 200);
        } else {
          // User updated, with same user
          // do nothing
        }
      } else {
        // There was a update with previous user, but no new user
        this.unsubscribeFromFirestore();
      }
    } else {
      // User changed, but no previous user
      if(this.props.user) {
        // reset
        if(this.state.conversations.length > 0) {
          if(this.unsubscribeFromFirestore) {
            this.unsubscribeFromFirestore();
          }
        }
        this.setState({
          conversations: [],
          conversationsWithUser: [],
        });
        setTimeout( () => {
          // new listener
          this.listenForConversations(this.props.user.uid);
        }, 100);
      } else {
        // There was update, with no prev user and no new user
        this.unsubscribeFromFirestore();
      }
    }

    // Check if user should get some achievements from matches/conversations
    if(prevState.conversations !== this.state.conversations) {
      if(this.state.conversations.length > 0) {
        // We have at least one match/conversation
        const userAchievements = this.props.user.achievements ? this.props.user.achievements : [];
        if(this.state.conversations.length > 0) {
          // User has at least one mentor match
          const oneMatchAchievementId = 4;
          if(!userAchievements.includes(oneMatchAchievementId)) {
            // User has a match and haven't previously been awarded the 1 match achievement
            newAchievement(this.props.user.uid, oneMatchAchievementId);
          }
        }
        if(this.state.conversations.length > 2) {
          // User has at least 3 mentor matches
          const threeMatchesAchievementId = 5;
          if(!userAchievements.includes(threeMatchesAchievementId)) {
            // User has 3 matches and haven't previously been awarded the 3 matches achievement
            newAchievement(this.props.user.uid, threeMatchesAchievementId);
          }
        }
        if(this.state.conversations.length > 4) {
          // User has at least 5 mentor matches
          const fiveMatchesAchievementId = 6;
          if(!userAchievements.includes(fiveMatchesAchievementId)) {
            // User has 5 matches and haven't previously been awarded the 5 matches achievement
            newAchievement(this.props.user.uid, fiveMatchesAchievementId);
          }
        }
      }
    }

  };

  componentWillUnmount = () => {
    if(this.unsubscribeFromFirestore) {
      this.unsubscribeFromFirestore();
    }
  }

  listenForConversations = (userID) => {
    // If we got a logged in user, listen for conversations
    if(userID) {
      // Listen to all conversations involving the current user
      // TODO Order by last updated
      this.unsubscribeFromFirestore = firestore.collection('conversations').where("users", "array-contains", userID).onSnapshot(async snapshot => {
        let conversations = snapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });

        // Get users for conversations
        let conversationsWithUser = [];
        // Only get users if they haven't been fetched already or if new conversations are added
        if(conversations.length > this.state.conversationsWithUser.length) {
          await Promise.all(conversations.map(async (conversation) => {
            const conversationParticipants = conversation.users.filter(user => user !== userID);
            const user = await getUserDocument(conversationParticipants[0]);
            if(user) {
              const conversationUser = {
                id: conversation.id,
                user: user,
                userId: conversationParticipants[0]
              }
              conversationsWithUser.push(conversationUser);
            }
          }));
        }

        // If new conversations get the users and update them along with conversations
        if(conversationsWithUser && conversationsWithUser.length > 0) {
          this.setState({ 
            conversations,
            conversationsWithUser,
            loading: false,
          });
        } else {
          // If same amount of conversations just get conversations not users
          this.setState({ 
            conversations,
            loading: false,
          });
        }

      });
    }
  }

  render() {
    const { conversationsWithUser } = this.state;
    let { conversations } = this.state;
    const { children } = this.props;
    conversations.forEach(conversation => {
      conversationsWithUser.forEach(conversationUser => {
        if(conversation.id === conversationUser.id) {
          conversation.user = conversationUser.user;
          conversation.user.userId = conversationUser.userId;
          conversation.user.conversationId = conversationUser.id;
        }
      })
    })
    // Sort conversations by latest message
    conversations.sort((a, b) => (a.lastMessageTimestamp < b.lastMessageTimestamp) ? 1 : -1)
    if(this.state.loading && conversations.length === 0) {
      conversations.push('loading conversations');
    }
    return (
      <ConversationsContext.Provider value={conversations}>{children}</ConversationsContext.Provider>
    )
  }
}

export default WithUser(ConversationsProvider);