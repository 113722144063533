import React, { Component, createContext } from 'react'

import axios from 'axios';

export const NewsContext = createContext({ news: [] });

const restURL = 'https://careermentor.dk/wp-json/wp/v2/';

class NewsProvider extends Component {

  state = {
    news: [],
  };

  componentDidMount = async () => {
    axios.get(restURL + 'posts?_embed&per_page=5')
    .then(response => {
      // handle success
      this.setState({ news: response.data });
    })
    .catch(function (error) {
      // handle error
      console.log('error fetching latest news: ', error);
    })
  };


  render() {
    const { news } = this.state;
    const { children } = this.props;

    return (
      <NewsContext.Provider value={news}>{children}</NewsContext.Provider>
    )
  }
}

export default NewsProvider;