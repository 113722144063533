import React from 'react';

const MailValidated = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 748 719">
      <polygon fill="#6C63FF" points="125,410 0,268 374,0 748,268 621,410 "/>
      <polygon fill="#3D7F76" points="125,410 0,268 374,0 748,268 621,410 "/>
      <rect fill="#78C1B6" y="286"  width="730" height="319"/>
      <polygon fill="#64A19A" points="748,719 0,719 0,268 374,494 748,268 "/>
      <polygon fill="#FF8C3E" points="652.6,116.8 573.3,56 363.1,330 246.4,240.6 185.6,319.9 341.9,439.6 341.9,439.6 382,469.6 "/>
    </svg>
  )
}

export default MailValidated;