import React from 'react';

import Celebration from './SignUp/Illustrations/Celebration';
import MentorTrophy from './SignUp/Illustrations/MentorTrophy';
import MenteeTrophy from './SignUp/Illustrations/MenteeTrophy';
import HighFive from './SignUp/Illustrations/HighFive';
import HighFiveTwo from './SignUp/Illustrations/HighFiveTwo';
import HighFiveThree from './SignUp/Illustrations/HighFiveThree';
import Mentor2020Trophy from './SignUp/Illustrations/Mentor2020Trophy';
import Mentor2020NomineeTrophy from './SignUp/Illustrations/Mentor2020NomineeTrophy';

export const AllAchievements = [
  {
    id: 1,
    title: 'Mentor',
    title_en: 'Mentor',
    text: 'Du er mentor, fantastisk! Tak for at dele din viden og være med til at forme talenter i det danske erhvervsliv.',
    text_en: 'You are a mentor, fantastic! Thank you for sharing your knowledge and helping talents develop. You are an inspiration.',
    lockedText: 'Du kan låse dette trofæ op ved at være mentor',
    lockedText_en: 'You can unlock this trophy by creating a mentor profile',
    illustration: <MentorTrophy/>,
  },
  {
    id: 2,
    title: 'Mentee',
    title_en: 'Mentee',
    text: 'Du er mentee, stærkt! Tak fordi du har mod på udvikle dig selv og være en del af fælleskabet.',
    text_en: 'You are a mentee, well done! You have the courage to take on new challenges and thank you for being part of the CareerMentor community.',
    lockedText: 'Du kan låse dette trofæ op ved at være mentee',
    lockedText_en: 'You can unlock this trophy by creating a mentee profile',
    illustration: <MenteeTrophy/>,
  },
  {
    id: 3,
    title: 'Early adopter',
    title_en: 'Early adopter',
    text: 'Du har været med fra start! Tak for støtten, vi vil gøre vores bedste for at CareerMentor lever op til dine forventninger.',
    text_en: 'You have been part of CareerMentor from the beginning! Thank you for your support, we will do our best to meet your expectations.',
    lockedText: 'Du kan låse dette trofæ op ved at være oprettet på CareerMentor fra begyndelsen',
    lockedText_en: 'You can unlock this trophy if you have been part of CareerMentor since the early days',
    illustration: <Celebration/>,
  },
  {
    id: 4,
    title: 'Du har et MentorMatch',
    title_en: 'You made a MentorMatch',
    text: 'Du har et accepteret match med en mentor eller mentee!',
    text_en: 'You have succesfully created or accepted a match with a mentor or mentee!',
    lockedText: 'Du kan låse dette trofæ op ved at have et accepteret match med enten en mentor eller en mentee',
    lockedText_en: 'You can unlock this trophy by creating or accepting a match with a mentor or mentee',
    illustration: <HighFive/>,
  },
  {
    id: 5,
    title: 'Du har 3 MentorMatches',
    title_en: 'You got 3 MentorMatches',
    text: 'Du har 3 accepterede matches med en mentee eller mentor!',
    text_en: 'You got 3 accepted matches with a mentee or mentor!',
    lockedText: 'Du kan låse dette trofæ op ved at have 3 accepterede matches med enten en mentor eller en mentee',
    lockedText_en: 'You can unlock this trophy by having 3 accepted matches with either a mentor or mentee',
    illustration: <HighFiveTwo/>,
  },
  {
    id: 6,
    title: 'Du har 5 MentorMatches',
    title_en: 'You got 5 MentorMatches',
    text: 'Du har 5 accepterede matches med en mentor eller mentee!',
    text_en: 'You are a mentor, fantastic! Thank you for sharing your knowledge and helping talents develop. You are an inspiration.',
    lockedText: 'Du kan låse dette trofæ op ved at have 5 accepterede matches med enten en mentor eller en mentee',
    lockedText_en: 'You can unlock this trophy by having 5 accepted matches with either a mentor or mentee',
    illustration: <HighFiveThree/>,
  },
  {
    id: 7,
    title: 'Årets mentor 2020!',
    title_en: 'You are mentor of the year 2020!',
    text: 'Du er blevet nomineret og har vundet prisen som årets mentor 2020 på CareerMentor, tillykke!',
    text_en: 'You have been nominated and have won the award as Mentor of the Year 2020 at CareerMentor, congratulations!',
    lockedText: 'Du kan låse dette trofæ op ved at vinde prisen som årets mentor 2020',
    lockedText_en: 'You can unlock this trophy by winning the award Mentor of the Year 2020',
    illustration: <Mentor2020Trophy/>,
  },
  {
    id: 8,
    title: 'Nomineret til årets mentor 2020',
    title_en: 'Nominated for mentor of the year 2020',
    text: 'Du er blevet nomineret til årets mentor 2020, tillykke!',
    text_en: 'You have been nominated for Mentor of the Year 2020, congratulations!',
    lockedText: 'Du kan låse dette trofæ op ved at blive nomineret til årets mentor 2020',
    lockedText_en: 'You can unlock this trophy by being nominated for Mentor of the Year 2020',
    illustration: <Mentor2020NomineeTrophy/>,
  }
];