import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { getUserDocument } from '../firebase';

import { getDateString } from '../util';

import { WithTrans } from  '../i18n/i18nWrapper';

import avatarPlaceholder from '../assets/avatar-placeholder.jpg'
import LogoIcon from './LogoIcon';

class Notification extends Component {

  state = {
    image: '',
  }

  componentDidMount = async () => {
    const imageId = this.props.notification.image;
    let image = this.props.notification.image;
    if(imageId !== 'careermentor') {
      // Get user image
      const user = await getUserDocument(imageId);
      image = user.profileImageURL;
    }
    this.setState({ image });
  }

  render() {
    const notification = this.props.notification;
    const notificationClass = classNames('Notification', {
      'Notification--unread': !notification.read,
    });
    const date = getDateString(notification.timestamp, this.props.i18n.language);
    let text = notification.text.length > 80 ? notification.text.substr(0, 80) + '...' : notification.text;
    if(this.props.i18n.language === 'en' && text === 'Du har et nyt MentorMatch! Gå til samtale.') {
      // Translating this in view because it's generated in cloud function unaware of language
      text = 'You have a new MentorMatch! Go to conversation.'
    }
    return (
      <li className={notificationClass}>
        <Link to={notification.link} onClick={() => this.props.onClick(notification.id, notification.read)}>
          <figure className="Notification__image">
            {this.state.image === 'careermentor' ? (
              <LogoIcon />
            ) : (
              <img src={this.state.image ? this.state.image : avatarPlaceholder} alt="Notification"/>
            )}
          </figure>
          <p className="Notification__text">{text}</p>
          <p className="Notification__time">{date}</p>
        </Link>
      </li>
    );

  }

}

export default WithTrans(Notification);