import React, {Component} from 'react';

import Match from './Match';
import { WithTrans } from '../i18n/i18nWrapper';

import icons from '../assets/icons.svg';
const chevronIcon = icons + '#chevron';

class Matches extends Component {

  constructor(props) {
    super(props);
    this.handleClickNumber = this.handleClickNumber.bind(this);
    this.handleClickPagination = this.handleClickPagination.bind(this);
  }

  state = {
    currentPage: 1,
    matchesPerPage: 4,
    openMatch: 99,
  };

  handleClickNumber(e) {
    this.setState({
      currentPage: Number(e.target.getAttribute('data-page')),
      openMatch: 99,
    });
  }

  handleClickPagination(e, pageNumbers) {
    const direction = e.currentTarget.getAttribute('data-direction');
    let page = this.state.currentPage;
    if(direction === 'forward' && page < pageNumbers.length) {
      page++;
    }
    if(direction === 'backward' && page > 1) {
      page--;
    }
    this.setState({ currentPage: page, openMatch: 99 });
  }

  handleOpenMatch(index) {
    // console.log('open match: ', index);
    if (index === this.state.openMatch) {
      // If clicking the same then close it
      this.setState({ openMatch: 99 });
    } else {
      this.setState({ openMatch: index });
    }
  }

  render() {
    const matches = this.props.matches;
    // Logic needed for pagination
    const { currentPage, matchesPerPage } = this.state;
    const indexOfLastMatch = currentPage * matchesPerPage;
    const indexOfFirstMatch = indexOfLastMatch - matchesPerPage;
    const currentMatches = matches.slice(indexOfFirstMatch, indexOfLastMatch);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(matches.length / matchesPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="Matches">
        {this.props.matches && this.props.matches.length > 0 ? (
          <>
            <ul className="list-unstyled">
              {currentMatches.map((match, index) =>
                <Match match={match} open={this.state.openMatch === index ? true : false} key={match.uid} openMatch={() => this.handleOpenMatch(index)} requestType={this.props.requestType} />
              )}
            </ul>
            <div className="Matches__pagination">
              <button className={this.state.currentPage === 1 ? 'disabled' : undefined} onClick={this.handleClickPagination} data-direction="backward">
                <svg>
                  <use xlinkHref={chevronIcon}></use>
                </svg>
              </button>
              <ul className="list-unstyled">
                {pageNumbers.map(number => (
                  <li key={number} className={number === this.state.currentPage ? 'current' : undefined}>
                    <button data-page={number} onClick={this.handleClickNumber}>{number}</button>
                  </li>
                ))}
              </ul>
              <button className={this.state.currentPage === pageNumbers.length ? 'disabled' : undefined} onClick={(e) => this.handleClickPagination(e, pageNumbers)} data-direction="forward">
                <svg>
                  <use xlinkHref={chevronIcon}></use>
                </svg>
              </button>
            </div>
          </>
        ) : (
          <p style={{ minWidth: 300 }}>{ this.props.t('matchMaker.no_matches') }</p>
        )}
      </div>
    )
  }
}

export default WithTrans(Matches);