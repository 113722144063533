import React, { useContext } from 'react'
import { UserContext } from '../providers/UserProvider';
import { NavLink } from 'react-router-dom';
import { WithTrans } from '../i18n/i18nWrapper';

import Loader from './Loader';

const Greeting = (props) => {
  const user = useContext(UserContext);
  const d = new Date();
  const n = d.getHours();
  let greeting = 'Hej';
  if (n < 4) { greeting = 'Godnat'; }
  else if (n < 10 && n > 3) { greeting = props.t('greeting.good_morning'); } 
  else if (n > 9 && n < 12) { greeting = props.t('greeting.good_formiddag'); } 
  else if (n > 11 && n < 18) { greeting = props.t('greeting.good_afternoon'); } 
  else if (n > 17 && n < 24) { greeting = props.t('greeting.good_evening'); }
  const firstName = user.name ? user.name.replace(/ .*/,'') : 'user name not found';
  return (
    <>
      <div className="Greeting module module-small bg-lightgreen">
        { user && user.name ? <><p><span>{`${greeting} ${firstName}. ${props.t('greeting.welcome')}`}</span><span role="img" aria-label="Vinker">👋</span></p><p>{props.t('greeting.we_recommend')} <NavLink to="/information/">{props.t('greeting.tips_and_guides')}</NavLink> {props.t('greeting.to_get_started')}.</p></> : <Loader /> }
      </div>
    </>
  )
}



export default WithTrans(Greeting);