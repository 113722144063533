import React, { useContext } from 'react';

import { FaqsContext } from '../providers/FaqsProvider';

import Accordion from './Accordion';
import Loader from './Loader';

const Faqs = () => {
  const faqs = useContext(FaqsContext);
  return (
    <div className="Faqs" id="Faq">
      {faqs && faqs.length > 0 ? (
        <ul className="list-unstyled">
          {faqs.map((faq, index) =>
            <Accordion id={faq.id} title={faq.title.rendered} content={faq.cmb2.faq_metabox._cm_faq_answer} key={index} />
          )}
        </ul>
      ) : <Loader color='green' />}
    </div>
  )
}

export default Faqs;