import React, { useContext } from 'react';

import { ConversationsContext } from '../providers/ConversationsProvider';
import ConversationShortcut from './ConversationShortcut';
import Loader from './Loader';

const ConversationShortcuts = () => {
  const conversations = useContext(ConversationsContext);
  const maxConversationShorcuts = 10;
  return (
    <div className="ConversationShortcuts">
      {conversations && conversations.length > 0 ? (
        <>
          {conversations[0] !== 'loading conversations' ? (
            <ul className="list-unstyled">
              {conversations.map((conversation, i) =>
                i < maxConversationShorcuts + 1 ? <ConversationShortcut conversation={conversation} key={conversation.id} /> : null
              )}
            </ul>
          ) : <Loader color='green'/> }
        </>
      ) : null }
    </div>
  )
}

export default ConversationShortcuts;
