import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import MailValidated from './SignUp/Illustrations/MailValidated';

import { WithTrans } from '../i18n/i18nWrapper';
import { firestore } from '../firebase';

class EmailValidation extends Component {

  state = {
    validRequest: false,
    invalidRequest: false,
    signUpEmail: '',
    signUpID: this.props.match.params.id,
  };

  componentDidMount() {
    this.validate();
  }

  validate() {
    const documentRef = firestore.collection('signups').doc(this.state.signUpID);
    documentRef.get().then((docSnapshot) => {
      if (!docSnapshot.exists) {
        // This signup ID doesn't exist in the DB
        this.setState({ invalidRequest: true });
      } else {
        if(!docSnapshot.data().validated) {
          // Validate e-mail in database
          documentRef.update({ validated: true });
        }
        this.setState({
          validRequest: true,
          signUpEmail: docSnapshot.data().email,
        });
      }
    });
  }

  render() {
    const message = this.state.invalidRequest ? this.props.t('profile.error_unknown') : this.props.t('wait') ;
    return (
      <div className="EmailValidation">
        <div className="EmailValidation__inner">
          <Logo />
          {this.state.validRequest ? (
            <>
              <h1>{this.props.t('signup.your_email_has_been_validated')}</h1>
              <h2>{this.props.t('signup.you_can_return_to_signup')}</h2>
              <Link to={'/signups/' + this.state.signUpID}>{this.props.t('signup.continue_signup')}</Link>
              <MailValidated />
            </>
          ) : (
            <h1>{message}</h1>
          )}
        </div>
      </div>
    );
  }

}

export default WithTrans(EmailValidation);