import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { debounce } from '../util';

import Popup from './Popup';

class Popups extends Component {

  constructor(props) {
    super(props);
    this.checkForNewMatches = this.checkForNewMatches.bind(this);
  }

  state = {
    popups: [],
  };

  checkForNewMatchesDebounced = debounce(this.checkForNewMatches, 2000);

  componentDidUpdate() {
    this.checkForNewMatchesDebounced();
  }

  checkForNewMatches() {
    // Check for new matches via notification system
    const notifications = this.props.notifications;
    if(notifications && notifications.length > 0) {
      notifications.forEach((notification) => {
        if(notification.type && notification.type === 'newMatch') {
          // New match notification
          if(!notification.notifiedViaPopup) {
            // User has not yet been notified of new match via popup
            // Mark notification as notified with popup
            this.props.markNotificationAsNotifiedViaPopup(notification.id);
            // Show the popup
            this.setState(prevState => ({
              popups: [...prevState.popups, {
                type: 'newMatch',
                link: notification.link,
                userId: notification.image,
              }]
            }))
          }
        }
      });
    }
  }

  render() {
    const { popups } = this.state;
    return (
      <div className="Popups">
        {popups && popups.length > 0 ? (
          <>
            {popups.map((popup, i) =>
              <Popup popup={popup} key={i} />
            )}
          </>
        ) : null}
      </div>
    )
  }

}

Popups.propTypes = {
  notifications: PropTypes.array.isRequired,
};

export default Popups;