import React, { Component } from 'react';

import { firestore, newAchievement, removeAchievement} from '../firebase';

import { getLocationName, getGenderName, getStatusName, validateString, getYearFromTimestamp, getBirthYears, getBirthdayFromYear } from '../util';

import { WithTrans } from '../i18n/i18nWrapper';

import icons from '../assets/icons.svg';
const PencilIcon = icons + '#pencil';

class ProfileGeneral extends Component {

  state = {
    // -- Current profile fields --
    // name: this.props.user.name,
    // title: this.props.user.title,
    // company: this.props.user.company,
    // location: this.props.user.location,
    // gender: this.props.user.gender,
    // status: this.props.user.status, 
    // birthday: this.props.user.birthday,
    // linkedInUrl: this.props.user.linkedInUrl,
    // mentee: this.props.user.mentee,
    // mentor: this.props.user.mentor,
    // -- New profile fields --
    newName: this.props.user.name,
    newTitle: this.props.user.title,
    newCompany: this.props.user.company,
    newLocation: this.props.user.location,
    newGender: this.props.user.gender,
    newStatus: this.props.user.status, 
    newBirthday: this.props.user.birthday,
    newLinkedInUrl: this.props.user.linkedInUrl,
    newMentee: this.props.user.mentee,
    newMentor: this.props.user.mentor,
    // State logic
    editMode: false,
    showErrorMessage: false,
    showSuccessMessage: false,
    showMentorMessage: false,
    showMenteeMessage: false,
    loading: false,
    validationFailed: false,
    birthYears: getBirthYears().years,
    validationErrors: [],
  }

  componentDidMount() {
    // Convert birthyear db timestamp to year
    const newBirthYear = getYearFromTimestamp(this.props.user.birthday);
    this.setState({ newBirthday: newBirthYear });
  }

  startEditMode() {
    this.setState({ editMode: true });
  }

  stopEditMode() {
    this.setState({ editMode: false });
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleToggle = event => {
    const { name, value } = event.target;
    const bool = value === 'true' ? true : false;
    this.setState({ [name]: bool });
  }

  handleSubmit = event => {
    // Prevent form submit from refreshing page
    event.preventDefault();
    // Reset validation errors if any and indicate loading
    this.setState({ validationErrors: [], validationFailed: false, loading: true, showSuccessMessage: false, showErrorMessage: false, showMenteeMessage: false, showMentorMessage: false});
    // Validate various fields
    this.validateMentorMentee();
    this.validateName();
    this.validateGender();
    this.validateStatus();
    this.validateCompany();
    this.validateLocation();
    this.validateBirthday();
    this.validateLinkedInUrl();

    setTimeout(() => {
      // Show errors if failed
      if(this.state.validationFailed) {
        // console.log('validation failed');
        this.setState({ showErrorMessage: true, loading: false });
      }

      // All validation passed, check if anything has changed
      if(!this.state.validationFailed) {
        // console.log("Validation passed");
        const menteeFieldHasChanged = this.state.newMentee !== this.props.user.mentee ? true : false;
        // console.log('mentee field has changed: ', menteeFieldHasChanged);
        const mentorFieldHasChanged = this.state.newMentor !== this.props.user.mentor ? true : false;
        // console.log('mentor field has changed: ', mentorFieldHasChanged);
        const nameFieldHasChanged = this.state.newName !== this.props.user.name ? true : false;
        // console.log('name field has changed: ', nameFieldHasChanged);
        const genderFieldHasChanged = this.state.newGender !== this.props.user.gender ? true : false;
        // console.log('gender field has changed: ', genderFieldHasChanged);
        const statusFieldHasChanged = this.state.newStatus !== this.props.user.status ? true : false;
        // console.log('status field has changed: ', statusFieldHasChanged);
        const titleFieldHasChanged = this.state.newTitle !== this.props.user.title ? true : false;
        // console.log('title field has changed: ', titleFieldHasChanged);
        const companyFieldHasChanged = this.state.newCompany !== this.props.user.company ? true : false;
        // console.log('company field has changed: ', companyFieldHasChanged);
        const locationFieldHasChanged = this.state.newLocation !== this.props.user.location ? true : false;
        // console.log('location field has changed: ', locationFieldHasChanged);
        const birthdayFieldHasChanged = this.state.newBirthday !== getYearFromTimestamp(this.props.user.birthday) ? true : false;
        // console.log('birthday field has changed: ', birthdayFieldHasChanged);
        const linkedinFieldHasChanged = this.state.newLinkedInUrl !== this.props.user.linkedInUrl ? true : false;
        // console.log('linkedin field has changed: ', linkedinFieldHasChanged);

        // Check if anything has changed
        if(menteeFieldHasChanged || mentorFieldHasChanged || nameFieldHasChanged || genderFieldHasChanged || companyFieldHasChanged || statusFieldHasChanged || titleFieldHasChanged || locationFieldHasChanged || birthdayFieldHasChanged || linkedinFieldHasChanged) {
          // console.log("Something has changed!");
          // New birthday date object
          let newBirthdayDateObject = null;
          if(birthdayFieldHasChanged) {
            newBirthdayDateObject = getBirthdayFromYear(this.state.newBirthday);
          }
          // Something has changed, try saving to database
          const currentUserDocumentRef = firestore.collection('users').doc(this.props.user.uid);
          // Get user document
          currentUserDocumentRef.get().then((docSnapshot) => {
            if (docSnapshot.exists) {
              // console.log("Retrieved user");
              // User exists and is retrieved
              // Update user with changed fields
              currentUserDocumentRef.update({
                ...menteeFieldHasChanged && { mentee: this.state.newMentee },
                ...mentorFieldHasChanged && { mentor: this.state.newMentor },
                ...nameFieldHasChanged && { name: this.state.newName },
                ...genderFieldHasChanged && { gender: this.state.newGender },
                ...statusFieldHasChanged && { status: this.state.newStatus },
                ...titleFieldHasChanged && { title: this.state.newTitle },
                ...companyFieldHasChanged && { company: this.state.newCompany },
                ...locationFieldHasChanged && { location: parseInt(this.state.newLocation) },
                ...birthdayFieldHasChanged && { birthday: newBirthdayDateObject },
                ...linkedinFieldHasChanged && { linkedInUrl: this.state.newLinkedInUrl },
              }).then(() => {
                // User updated
                // console.log("User profile updated");
                // Changes were successfully saved, show success message
                let showMentorMessage = mentorFieldHasChanged && this.state.newMentor? true : false;
                let showMenteeMessage = menteeFieldHasChanged && this.state.newMentee ? true : false;
                this.setState({ showSuccessMessage: true, loading: false, editMode: false, showMentorMessage, showMenteeMessage});
              }).catch(function(error) {
                // Something went wrong with update of user (probably timed out or not allowed)
                console.error("Error: ", error);
                this.setState(prevState => ({
                  showErrorMessage: true,
                  loading: false,
                  validationErrors: [...prevState.validationErrors, this.props.t('profile.error_unknown')]
                }))
              });
            }
          }).catch(function(error) {
            // User probably doesn't exist
            console.error("Error: ", error);
            this.setState(prevState => ({
              showErrorMessage: true,
              loading: false,
              validationErrors: [...prevState.validationErrors, this.props.t('profile.error_unknown')]
            }))
          });

          // Check if mentor/mentee achievements need to be updated
          if(menteeFieldHasChanged) {
            const menteeAchievementId = 2;
            if(this.state.newMentee) {
              // User is now mentee
              newAchievement(this.props.user.uid, menteeAchievementId);
            } else {
              // User is no longer mentee
              removeAchievement(this.props.user.uid, menteeAchievementId);
            }
          }
          if(mentorFieldHasChanged) {
            const mentorAchievementId = 1;
            if(this.state.newMentor) {
              // User is now mentor
              newAchievement(this.props.user.uid, mentorAchievementId);
            } else {
              // User is no longer mentor
              removeAchievement(this.props.user.uid, mentorAchievementId);
            }
          }
        } else {
          // Nothing has changed!
          console.log("Nothing has changed!");
          this.setState({ loading: false, editMode: false });
        }

      }

    }, 500)

  }

  validateMentorMentee() {
    if(!this.state.newMentee && !this.state.newMentor) {
      console.error('User has to be mentor or mentee');
      this.setState(prevState => ({
        validationFailed: true,
        validationErrors: [...prevState.validationErrors, this.props.t('profile.error_no_mentee_mentor')]
      }))
    }
  }

  validateName() {
    const valid = validateString(this.state.newName);
    if(!valid) {
      this.setState(prevState => ({
        validationFailed: true,
        validationErrors: [...prevState.validationErrors, this.props.t('profile.error_no_name')]
      }))
    }
  }

  validateGender() {
    const validGenders = ['male', 'female', 'other'];
    if(!validGenders.includes(this.state.newGender)) {
      console.error('Not a valid gender');
      this.setState(prevState => ({
        validationFailed: true,
        validationErrors: [...prevState.validationErrors, this.props.t('profile.error_no_gender')]
      }))
    }
  }

  validateStatus() {
    const validStatus = ['employed', 'self-employed', 'student', 'other'];
    if(!validStatus.includes(this.state.newStatus)) {
      console.error('Not a valid status');
      this.setState(prevState => ({
        validationFailed: true,
        validationErrors: [...prevState.validationErrors, this.props.t('profile.error_no_status')]
      }))
    }
  }

  validateCompany() {
    const valid = validateString(this.state.newCompany);
    if(!valid) {
      this.setState(prevState => ({
        validationFailed: true,
        validationErrors: [...prevState.validationErrors, this.props.t('profile.error_no_company')]
      }))
    }
  }

  validateLocation() {
    const validLocations = [1, 2, 3, 4, 5, 6];
    if(!validLocations.includes(parseInt(this.state.newLocation))) {
      console.error('Not a valid location');
      this.setState(prevState => ({
        validationFailed: true,
        validationErrors: [...prevState.validationErrors, this.props.t('profile.error_no_location')]
      }))
    }
  }

  validateBirthday = () => {
    const valid = this.state.birthYears.includes(parseInt(this.state.newBirthday));
    if(!valid) {
      this.setState(prevState => ({
        validationFailed: true,
        validationErrors: [...prevState.validationErrors, this.props.t('profile.error_no_birthday')]
      }))
    }
  }

  validateLinkedInUrl() {
    const string = 'https://www.linkedin.com';
    const startsWithLinkedin = this.state.newLinkedInUrl.startsWith(string);
    if (!startsWithLinkedin) {
      if (this.state.newLinkedInUrl === '#') {
        // allowed as no profile url
      } else {
        console.error('Not a valid LinkedIn url');
        this.setState(prevState => ({
          validationFailed: true,
          validationErrors: [...prevState.validationErrors, this.props.t('profile.error_no_linkedin')]
        }))
      }
    }
  }

  render() {
    const editMode = this.state.editMode;
    const {newName, newTitle, newCompany, newLocation, newGender, newStatus, newBirthday, newLinkedInUrl, newMentee, newMentor } = this.state;
    const { name, title, company, location, gender, status, birthday, linkedInUrl, mentee, mentor } = this.props.user;
    const birthYear = getYearFromTimestamp(birthday);
    const locationName = getLocationName(location);
    const genderName = getGenderName(gender);
    const statusName = getStatusName(status);
    return (
      <div className="Profile__general">
        <form className="Form" onSubmit={(e) => this.handleSubmit(e)}>
          {this.state.showSuccessMessage ? (
            <>
              {this.state.showMenteeMessage ? (
                <div className="Form__notification">
                  <p>ⓘ - {this.props.t('profile.mentee_tip')}</p>
                </div>
              ) : null }
              {this.state.showMentorMessage ? (
                <div className="Form__notification">
                  <p>ⓘ - {this.props.t('profile.mentor_tip')}</p>
                </div>
              ) : null }
              <div className="Form__successMessage Form__successMessage--showMessage">
                <p>{this.props.t('profile.success_updated')}</p>
              </div>
            </>
          ) : null }
          {editMode ? (
            <>
              <div className="Profile__save">
                <button className="Button Button--green--outline" onClick={() => this.stopEditMode()}>{this.props.t('profile.cancel')}</button>
                <input type="submit" className="Button" value={this.state.loading ? this.props.t('profile.wait') : this.props.t('profile.save_changes')} disabled={this.state.loading} style={{ cursor: this.state.loading ? 'wait' : 'pointer'}} />
              </div>
              {this.state.showErrorMessage ? (
                <div className="Form__errorMessage Form__errorMessage--showMessage">
                  <ul>
                    {this.state.validationErrors.map((error, i) =>
                      <li key={i}>{error}</li>
                    )}
                  </ul>
                </div>
              ) : null }
            </>
          ) : (
            <button className="Button Button--icon Profile__editbtn" onClick={() => this.startEditMode()}>
              <span>{this.props.t('profile.edit')}</span>
              <svg>
                <use xlinkHref={PencilIcon}></use>
              </svg>
            </button>
          )
          }
          <h3 className="Profile__headline">{this.props.t('profile.your_info')}</h3>
          <hr/>
          <div>
            <p className="Profile__subheadline">{this.props.t('mentee')}:</p>
            {editMode ? (
              <div className="Form__radioWrapper">
                <label>
                  <input type="radio" name="newMentee" value={true} onChange={this.handleToggle} defaultChecked={newMentee === true ? true : false}/>
                  <span>{this.props.t('profile.yes')}</span>
                </label>
                <label>
                  <input type="radio" name="newMentee" value={false} onChange={this.handleToggle} defaultChecked={newMentee === false ? true : false}/>
                  <span>{this.props.t('profile.no')}</span>
                </label>
              </div>
            ) : (
              <p className="Profile__info">{mentee ? this.props.t('profile.yes') : this.props.t('profile.no')}</p>
            )
            }
          </div>
          <hr/>
          <div>
            <p className="Profile__subheadline">{this.props.t('mentor')}:</p>
            {editMode ? (
              <div className="Form__radioWrapper">
                <label>
                  <input type="radio" name="newMentor" value={true} onChange={this.handleToggle} defaultChecked={newMentor === true ? true : false}/>
                  <span>{this.props.t('profile.yes')}</span>
                </label>
                <label>
                  <input type="radio" name="newMentor" value={false} onChange={this.handleToggle} defaultChecked={newMentor === false ? true : false}/>
                  <span>{this.props.t('profile.no')}</span>
                </label>
              </div>
            ) : (
              <p className="Profile__info">{mentor ? this.props.t('profile.yes') : this.props.t('profile.no')}</p>
            )
            }
          </div>
          <hr/>
          <div>
            <p className="Profile__subheadline">{this.props.t('profile.name')}:</p>
            {editMode ? (
              <input type="text" placeholder="John Doe" name="newName" id="name" required onChange={this.handleChange} defaultValue={newName}/>
            ) : (
              <p className="Profile__info">{name}</p>
            )
            }
          </div>
          <hr/>
          <div>
            <p className="Profile__subheadline">{this.props.t('profile.gender')}:</p>
            {editMode ? (
              <div className="Form__radioWrapper">
                <label>
                  <input type="radio" name="newGender" value="male" onChange={this.handleChange} defaultChecked={newGender === 'male'} required/>
                  <span>{this.props.t('profile.gender_male')}</span>
                </label>
                <label>
                  <input type="radio" name="newGender" value="female" onChange={this.handleChange} defaultChecked={newGender === 'female'} />
                  <span>{this.props.t('profile.gender_female')}</span>
                </label>
                <label>
                  <input type="radio" name="newGender" value="other" onChange={this.handleChange} defaultChecked={newGender === 'other'} />
                  <span>{this.props.t('profile.gender_other')}</span>
                </label>
              </div>
            ) : (
              <p className="Profile__info">{genderName}</p>
            )
            }
          </div>
          <hr/>
          <div>
            <p className="Profile__subheadline">{this.props.t('profile.employment')}:</p>
            {editMode ? (
              <div className="Form__radioWrapper">
                <label>
                  <input type="radio" name="newStatus" value="employed" defaultChecked={newStatus === 'employed'} onChange={this.handleChange} required />
                  <span>{this.props.t('profile.employment_employed')}</span>
                </label>
                <label>
                  <input type="radio" name="newStatus" value="self-employed" defaultChecked={newStatus === 'self-employed'} onChange={this.handleChange} />
                  <span>{this.props.t('profile.employment_entrepreneur')}</span>
                </label>
                <label>
                  <input type="radio" name="newStatus" value="student" defaultChecked={newStatus === 'student'} onChange={this.handleChange} />
                  <span>{this.props.t('profile.employment_student')}</span>
                </label>
                <label>
                  <input type="radio" name="newStatus" value="other" defaultChecked={newStatus === 'other'} onChange={this.handleChange} />
                  <span>{this.props.t('profile.employment_other')}</span>
                </label>
              </div>
            ) : (
              <p className="Profile__info">{statusName}</p>
            )
            }
          </div>
          <hr/>
          <div>
            <p className="Profile__subheadline">{this.props.t('profile.title')}:</p>
            {editMode ? (
              <input type="text" placeholder="Manager" name="newTitle" id="title" required onChange={this.handleChange} defaultValue={newTitle}/>
            ) : (
              <p className="Profile__info">{title}</p>
            )
            }
          </div>
          <hr/>
          <div>
            <p className="Profile__subheadline">{this.props.t('profile.company')}:</p>
            {editMode ? (
              <input type="text" placeholder={this.props.t('profile.company_placeholder')} name="newCompany" id="company" required onChange={this.handleChange} defaultValue={newCompany}/>
            ) : (
              <p className="Profile__info">{company}</p>
            )
            }
          </div>
          <hr/>
          <div>
            <p className="Profile__subheadline">{this.props.t('profile.area')}:</p>
            {editMode ? (
              <div className="Form__selectWrapper">
                <select id="location" name="newLocation" onChange={this.handleChange} defaultValue={newLocation} required>
                  <option value="1">{this.props.t('profile.area_copenhagen')}</option>
                  <option value="2">{this.props.t('profile.area_zealand')}</option>
                  <option value="3">{this.props.t('profile.area_funen')}</option>
                  <option value="4">{this.props.t('profile.area_northern_jutland')}</option>
                  <option value="5">{this.props.t('profile.area_central_jutland')}</option>
                  <option value="6">{this.props.t('profile.area_southern_jutland')}</option>
                </select>
              </div>
            ) : (
              <p className="Profile__info">{locationName}</p>
            )
            }
          </div>
          <hr/>
          <div>
            <p className="Profile__subheadline">{this.props.t('profile.birth_year')}:</p>
            {editMode ? (
              <>
              <div className="Form__selectWrapper">
                <select id="birthday" name="newBirthday" onChange={this.handleChange} required value={newBirthday}>
                  {this.state.birthYears.map(year =>
                    <option value={year} key={year}>{year}</option>
                  )}
                </select>
              </div>
              </>
            ) : (
              <p className="Profile__info">{birthYear}</p>
            )
            }
          </div>
          <hr/>
          <div>
            <p className="Profile__subheadline">{this.props.t('profile.linkedin_profile')}:</p>
            {editMode ? (
              <input type="text" placeholder="https://www.linkedin.com/in/BRUGERNAVN/" name="newLinkedInUrl" id="linkedInURL" required onChange={this.handleChange} defaultValue={newLinkedInUrl}/>
            ) : (
              <p className="Profile__info"><a href={linkedInUrl} target="_blank" rel="noopener noreferrer">{linkedInUrl}</a></p>
            )
            }
          </div>
        </form>
      </div>
    )
  }

}

export default WithTrans(ProfileGeneral);