import React from 'react';
import logo from '../assets/logo.png'
import logo2x from '../assets/logo-x2.png';

const Logo = () => {
  return (
    <img 
      src={logo}
      alt="CareerMentor logo"
      className="Logo"
      srcSet={`
        ${logo} 1x,
        ${logo2x} 2x
      `}
      sizes="220px"
    />
  )
}

export default Logo;