import React, { Component } from 'react';
import { TweenLite, TimelineLite, Elastic } from "gsap/TweenMax";

class HighFive extends Component {

  componentDidMount() {
    // Objects
    const illustration = document.getElementById('HighFive');
    const firework = document.getElementById('HighFive_Firework');

    // Check if used in a achievement context and if its locked
    const parentElement = illustration.parentElement;
    if(parentElement.classList.contains('Achievement__illustration--locked')) {
      // Dont play yet
    } else {
      // Set initial state
      TweenLite.set(firework, {
        opacity: 0,
        y: 50,
        scale: 0,
        transformOrigin: '50% 50%'
      });

      // Timeline
      const timeline = new TimelineLite({ paused: true });

      timeline.to(firework, 0.6, {
        opacity: 1,
        y: 0,
        scale: 1,
        ease: Elastic.easeOut.config(1,0.5),
      });

      // Play
      setTimeout(() => {
        timeline.play();
      }, 500);
    }

  }

  render() {
    return (
      <svg id="HighFive" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1009.5 803.4">
        <path opacity="0.1" fill="#FE9236" d="M239.8,739.5c-8.5-0.6-17.4-1.2-25.4,0.3s-14.7,6-12.3,10.3
          c5.3,9.5,36.7,2.9,51.1,9.4c5.2,2.3,7.2,6,11.2,8.8c6.5,4.6,17.7,6.8,28.5,8c65,7.1,139.1-11.2,196.1,6.5
          c18.5,5.7,33,14.8,53.2,18.7c26.8,5.1,56.2-0.3,83.9-3.8c34.6-4.3,69.8-5.5,104.9-3.7c25.9,1.4,60.4,1.8,68.7-11
          c5.3-8.2-6-17.4-21-21.3s-32.6-3.8-49.3-3.4c-32.3,0.8-64.7,2.4-97,1.6c-62.5-1.5-121.6-18.3-182.5-18.9
          C379.1,740.3,311,744.3,239.8,739.5z"/>
        <path opacity="0.2" fill="#FE9236" d="M586.6,196.4c-64.7-1.9-126.4-20-185.2-39.8
          S284.2,114.9,221,104.4c-40.6-6.7-87.1-7.7-119.8,11.2C69.7,133.7,59.5,165,54,194.1c-4.1,21.9-6.5,44.9,4.7,65.3
          c7.8,14.2,21.7,26.1,31.4,39.8c33.5,47.4,9.8,105.7-26.4,152c-17,21.7-36.8,42.4-49.9,65.5c-13.1,23.1-19.2,49.5-7.7,73.1
          C17.5,613,44.6,630.5,74,642.9c59.7,25,130,32.2,198.6,36.3c151.8,9,304.5,5.1,456.7,1.2c56.3-1.4,112.9-2.9,168.3-10.4
          c30.8-4.2,62.6-10.8,84.9-26.9c28.4-20.4,35.4-54.8,16.4-80.4c-31.9-42.8-120-53.5-142.3-99.4c-12.3-25.3,0.3-53.5,18.2-76.9
          c38.2-50.3,102.3-94.5,105.7-152c2.3-39.5-28.5-79.1-76.1-97.8c-49.9-19.6-119.2-17.1-156,15.3C710.4,185.2,643.7,198.1,586.6,196.4
          z"/>
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="625.7714" y1="96.5353" x2="625.7714" y2="777.4159" gradientTransform="matrix(1 0 0 -1 -95.23 899.3163)">
          <stop  offset="0" style={{stopColor: '#808080', stopOpacity: 0.25}}/> 
          <stop  offset="0.54" style={{stopColor: '#808080', stopOpacity: 0.12}}/> 
          <stop  offset="1" style={{stopColor: '#808080', stopOpacity: 0.1}}/> 
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M801.1,384.6c-2.8,0.7-5.1,2.7-6.1,5.4l-1-0.2l-3.7-0.6c0,0-19.8-1.6-23.5-1.4s-6-3.4-6-3.4
          l-14.7-12.4l-1.1-1c1.6-0.6,2.6-0.9,2.6-0.9s-39.4-49-47.9-50s-22.5-12.4-22.7-12.5l-0.2-0.8c-0.3-1.6-0.5-3.3-0.7-5l2.1,1.3l0,0
          c0-0.6,0-1.2,0.1-1.7l0,0c0.3-6,2.4-11.9,5.9-16.8c4.1-5.7,10.2-10.2,12.6-16.8c1-2.8,1.4-5.8,1.3-8.8c0.1-5.4-1.4-10.7-4.1-15.3
          c-2.8-4.7-7.5-7.9-12.9-8.9c-1.4-0.2-2.8-0.2-4.2-0.6c-8.2-1.9-11.2-12.9-19.2-15.6c-3.7-1.3-7.9-0.4-11.5,1.1s-6.8,3.8-10.3,5.5
          s-7.5,3-10.7,5.4c-0.3,0.2-0.7,0.6-1,0.9l0,0l-0.6,0.6l0,0c-1.3,1.3-2.5,2.8-3.4,4.4l-0.2,0.2c-0.1,0.2-0.2,0.4-0.3,0.5
          s-0.2,0.3-0.2,0.4s-0.2,0.3-0.2,0.5l-0.2,0.5l-0.2,0.5c-0.1,0.2-0.1,0.3-0.2,0.5s-0.1,0.3-0.1,0.4l-0.2,0.5l-0.1,0.4
          c0,0.2-0.1,0.4-0.1,0.6s0,0.3,0,0.4s0,0.4,0,0.6s0,0.2,0,0.4s0,0.4,0,0.6s0,0.2,0,0.3c0,0.3,0.1,0.6,0.2,0.9l0,0
          c0.5,1.8,2.4,3.7,3.8,5.4c-0.2,0.1-0.3,0.3-0.5,0.4c-6.8,6.6-10.7,15.6-10.7,25.1c0,2.1,0.2,4.2,0.6,6.2c-1.8-1.4-3.8-2.6-5.8-3.5
          c-6.8-3.2-14.2-5-21.7-5c-12.2-0.1-29.5-4.7-29.5-4.7c0.4,0.8,0.8,1.7,1,2.6c-0.8-0.4-1.6-0.9-2.5-1.4c-7.2-3.9-15-6.5-23-7.7
          c0,0-5.1,0.7-5.8-10c-0.2-3.3-0.8-6.7-1.8-9.9c-2.9-9.8-5-19.8-6.1-30l-1.7-15.7c0,0,7.7-41,3.1-58.5c1.5-5.5,0.7-10.5-4.3-14.1
          c-19.4-14.3-38.9,41.2-38.9,41.2l-7.3,7.1c-1.9-1.2-4-2-6.2-2.4c0,0-3.9,4-6.6,14.9c-1.4,5.8-11.3,15.5-20.2,23.2
          c-6.3,5.5-12.4,11.3-18.5,17.1c-4.5,4.3-14.5,10.4-36.4,13.9c-3.4,0.5-6.5,1.1-9.4,1.5c0.7-2.6,1.1-5.3,1.1-7.9
          c-0.1-13.9-9.8-25.9-23.4-28.9c0.1-0.7,0.1-1.4,0-2.1c-0.4-1.5-1.1-2.9-2.2-4c-4.6-5.7-9.6-11.6-16.5-14.3c-2.8-1.1-5.9-1.6-8.9-1.4
          h-1.5c-0.3,0-0.6,0-0.9,0l0,0c-6.5,0.4-12.6,3.5-16.7,8.7c-2.5,3.3-3.9,7.2-6.1,10.7c-3.9,6.5-10,11.5-16.6,15.5
          c-4,2.4-8.1,4.4-11.8,7.2c-13.5,9.8-19.8,28-16.4,44.2c1.5,7.1,4.7,14.1,3.8,21.4c-0.7,5.6-3.7,10.6-6.8,15.4s-6.3,9.6-7.6,15.1
          s0.1,12,4.7,15.3c1.7,1.2,3.8,2,5.2,3.5c1,1.1,1.8,2.4,2.4,3.8c5,9,13.4,15.5,23.3,18.1c1.6,0.4,3.2,0.6,4.8,0.5h0.2h0.3
          c3.7,0.2,7.4-0.8,8.7-4c0.2-0.7,0.5-1.5,0.8-2.1c0.3-0.5,0.9-0.9,1.5-1c1.2,0.3,2.3,0.7,3.4,1.3c5.1,2.7,10.7,4.3,16.5,4.7
          c1,0.1,1.9,0.1,2.9,0c2,0.2,4-0.1,5.9-0.8c1.8-0.9,3.4-2.2,4.5-3.9c0.3-0.3,0.5-0.7,0.8-1c-1.3,8-3.8,15.7-7.4,23c0,0-6,10-5.6,20.1
          c-0.2,3.7,0.5,7.3,2.2,10.6c0,0,0.2-0.4,0.5-1.1c1.7,2.9,7.4,12.1,11.1,15.3c0.2,9.5,0.1,21.4-1,23.9c-2,4.2-8.3,34,18.9,54.3
          l6.3,13.7l-25.8,1.8c-2.6-0.4-36.4-4.9-36.5,1.7c-0.2,6.9,5.1,73.8,22.6,69.2c16.3-4.2,10.9-23.7,10.1-26.3l0.6-0.2l-0.6-0.2
          l13-12.4c0.2-1.5-0.1-3-1-4.3l0.1-0.1l31.5,1.4l7.4,16.1c0,0,12.6,19.1,2.4,35.2s-20.7,41.2-22.4,56.5
          c-1.4,12.5-10.6,24.7-13.9,28.8c-5.9,2-28.6,10.9-19.5,24.4c10.4,15.4,44,44.8,57.6,43.8s8.1-10.9,8.1-10.9l-11.4-11
          c0,0-2-23.5-1.2-30.3c0.4-3.6-2.2-6.1-5-7.2c1.1-7.7,3.8-22.2,9.6-30.2c8.1-11.2,34.3-60.7,34.3-60.7s8.3-7.4,6.4-29.3
          c-0.8-8.9-1.2-16.6-1.4-22.8l29.6,1.3c0,0,31.8-3.2,10.2-37.2s-67.5-67.4-67.5-67.4l-0.4-21.1c2.1,0.5,3.3,0.8,3.3,0.8
          s-0.2-0.8-0.5-2.1l0.5,0.1c0,0-6.6-28.7-8.7-51.5l1.9,0.9c19.7,22.8,33,3.5,33,3.5s41.6-58.5,37.5-55.8c-0.9,0.4-1.8,0.5-2.7,0.2
          l3-9.6c0,0,12.3-18.8-5.5-29.3c-14.6-8.6-14.2,18.4-13.5,28.6l-0.7-0.6c0,0-13.9,21-21.4,32.7s-13.8,4-13.8,4l-9.5-10.6
          c1.6-4.3,2.3-8.8,2.2-13.3c0.4-6.4-1.9-12.7-6.4-17.3c-7.2-7.4-9.3-14.5-9.5-19.9c0.1-2.8,0.7-5.6,1.9-8.2l49.1-26
          c0,0,10.4,0.2,24.3-21.3c9-13.7,19-26.8,29.7-39.2c0,0-0.3-0.4-0.8-1.1c0.5-0.6,0.8-0.9,0.8-0.9s-0.6-0.8-1.5-2.1l7.6-8.7
          c-0.2,3.9-0.3,7.9-0.1,11.9l3.9,13.2l2.2,73.8c0,0-2.4,7,12.9,14.1c12.9,6,33.4,16,39.4,19c-0.2,0.5-0.3,0.8-0.3,0.8l1.8-0.1
          l0.3,0.1l0,0c29.9-2.7,28.9,27.2,28.9,27.2s2,60.7-7.1,81.8s-7.8,47.3-7.8,47.3s0.9-4.5,6,1.4c-0.1,0.5-0.2,1.1-0.3,1.6
          c-2.9,14.6-9,45.8-10.1,58.4c-1.2,11.5-3.5,22.9-6.6,34l-3,55.8l19.1,0.9l-0.2,2c-0.2,2.1-1.4,3.9-3.2,4.9c-6.5,3.5-22,16-20.8,54.4
          l-0.7,6.2c-1.1,10.5-4.2,20.6-9.3,29.8c-0.4,0.8-0.8,1.5-1.2,2.2c0.1-0.4,0.2-0.8,0.5-1.1c0,0-9.9,2.1-7.9,28.9s5.1,31.5,9.2,31.8
          s24,5.2,22.9-4.7s-2-31.9-0.7-35.2s6.7-12.4,1.6-14.6l0,0c1.5-6.2,2.2-12.5,2.2-18.8c0,0,10-15.8,17.2-21.6s11-15.8,13.6-28.7
          c0.2-0.8,21.1-14.8,18-26.3c-0.5-2-0.9-3.8-1.2-5.4c-0.1-0.7-0.2-1.4-0.3-2h0.4l4.2-25.5v24.8l2.5,0.3l-0.1,2l-1.1,23.8
          c-0.2,4.6-1.2,9.2-3,13.5c-4.5,10.7-12.8,34.4-10.5,60l-0.2,22.9c-0.7,0.1-11.1,2.6-9.1,28.5c2,26.8,5.1,31.5,9.2,31.8
          s24,5.2,22.9-4.7s-2-31.9-0.7-35.2s3.7-12.2-1.4-14.4c0-3,0.8-6,2.1-8.6c2.2-3.8,9.7-21.9,15.3-35.8c5.8-14.1,9-29.1,9.5-44.3
          c0-0.2,0-0.3,0-0.5c0.2-5.1,1.3-10.2,3.2-14.9c2.3-5.3,4.1-10.8,5.4-16.4c0.1-0.7,0.2-1.3,0.3-2l4.9,0.7l16.7-72.1
          c0,0,4.6-39.1,2.3-56.7c-0.1-0.7-0.2-1.4-0.3-2l1,0.1c0,0,3.4-8.7,2.4-45.9s6.8-49,6.8-49c4.2-13.4,8.9-17.9,11.6-19.4l0,0
          c0.7-0.4,1.5-0.7,2.3-0.8l12.6,14.4l0,0l2.3,2.7c0-0.1,0-0.1,0-0.2l1,1.2c-0.1-0.6-0.1-1.2-0.1-1.8c6.7,3.9,22.3,13,31.6,19.6
          c12.1,8.5,15.4,7.5,26,6.7c6.4-0.5,15.1-4.1,20.9-6.9l0.5-0.2c1.6,0.8,3.2,1.5,4.9,2c0,0,17.1,14.5,18.7-11.9
          S801.1,384.6,801.1,384.6z M358.1,692.2c-1.7-0.5-3.4-1.2-5-2.1L358.1,692.2z M331.3,406.3l0.1,3.8l-0.3,0.1
          C331.2,408.9,331.3,407.6,331.3,406.3z M598.5,741v1.5C598.1,742.1,598.1,741.4,598.5,741z"/>
        <rect x="624" y="301.6" fill="#3F897D" width="29" height="30.5"/>
        <path fill="#8E5362" d="M588.8,653.8c-2.5,12.8-6.3,22.7-13.3,28.5s-16.8,21.5-16.8,21.5c-0.2,20.8-5.7,27.2-5.7,27.2l-5.7,1.5h-14
          c0,0-2.5-7-3-11.2c-0.2-1.4,0.8-3.7,2.2-6.2c5-9.2,8.1-19.2,9.2-29.6l0.7-6.2c-1.2-38.2,14-50.6,20.4-54.1c1.8-1,3-2.8,3.2-4.8l0,0
          l1.1-10.9l37.7,1.9c0,0-0.8,3.3,0.6,11c0.3,1.6,0.7,3.4,1.2,5.3C609.4,639.1,588.9,653,588.8,653.8z"/>
        <path fill="#8E5362" d="M652.6,627.4c-1.3,5.6-3.1,11-5.3,16.3c-2,4.7-3,9.7-3.2,14.8c0,0.2,0,0.3,0,0.5c-0.5,15.1-3.6,30-9.3,44.1
          c-5.5,13.8-12.9,31.8-15.1,35.6c-3.7,6.3-1.5,17-1.5,17l-21.2-1.5l0.4-35.7c-2.2-25.5,5.9-49,10.3-59.6c1.8-4.3,2.8-8.8,3-13.4
          l1-23.7l0.3-7c0,0,37.5-5.3,40.6,3.2C653.6,620.4,653.4,623.7,652.6,627.4z"/>
        <path opacity="0.1" d="M605.2,622.3l-39.5-1.9l1.1-10.9l37.7,1.8C604.6,611.3,603.8,614.6,605.2,622.3z
          "/>
        <path opacity="0.1" d="M652.6,627.4l-40.9-5.5l0.3-7c0,0,37.5-5.3,40.6,3.2
          C653.6,620.4,653.4,623.7,652.6,627.4z"/>
        <path fill="#BDD2E8" d="M674.1,554.5l-16.3,71.7l-48.3-6.5V595l-4.2,25.3l-58-2.8l3-55.5c3.1-11.1,5.3-22.4,6.5-33.8
          c1.1-12.5,7.1-43.5,9.9-58c1-4.8,1.6-7.8,1.6-7.8s99.3,20.7,105.8,28.2c1.1,1.3,1.8,4,2.3,7.6C678.6,515.6,674.1,554.5,674.1,554.5z
          "/>
        <path opacity="0.1" d="M558.1,559.1c0,0,39.1-20.5,48.6-16.2L558.1,559.1z"/>
        <path opacity="0.1" d="M579.4,492.1c0,0,32.5,24.7,33.5,31.3L579.4,492.1z"/>
        <path opacity="0.1" d="M570.6,577.1c0,0,23.5-1,31,5L570.6,577.1z"/>
        <path opacity="0.1" d="M612.9,550.8c0,0,11.2,36.6,6.3,46.6s-9.8-2.3-9.8-2.3L612.9,550.8z"/>
        <path fill="#494457" d="M597.2,741.3c0,0-11,1.7-9,28.3s5,31.3,9,31.7s23.5,5.2,22.5-4.7s-2-31.7-0.7-35s3.6-12.1-1.4-14.3l-1.1,5.1
          c0,0-10.7-9.8-17-2.7c-0.3-1.7-0.7-3.5-1.3-5.1C597.6,743.1,596.2,742.3,597.2,741.3z"/>
        <path fill="#8E5362" d="M798.6,414.1c0,0-2.5,1.4-6.2,3.2c-5.7,2.7-14.2,6.3-20.5,6.8c-10.4,0.9-13.6,1.9-25.5-6.6
          c-9.1-6.5-24.4-15.6-31-19.5l-2.9-1.7l-0.3-0.2V396l5.5-15.1l19.5-10.4l4.8,4.1l14.4,12.4c0,0,2.2,3.6,5.9,3.4s23,1.4,23,1.4
          l3.7,0.6l7.7,1.3L798.6,414.1z"/>
        <path opacity="0.1" d="M798.6,414.1c0,0-2.5,1.4-6.2,3.2c-3.9-2-8.8-5.8-6.9-11.9
          c1.7-5.5,2.2-9.9,3.5-13l7.7,1.3L798.6,414.1z"/>
        <path fill="#8E5362" d="M795.9,387.1c0,0,21.8-6.2,20.1,20S797.7,419,797.7,419s-14.2-3.8-11.2-13.6S788.6,389.5,795.9,387.1z"/>
        <path fill="#8E5362" d="M570.4,302.1l-22.7,9.5l-0.3,0.1l-0.2-0.1l-1.4-0.7c-5.9-2.9-26-12.9-38.7-18.8c-15-7-12.7-14-12.7-14
          l-2.2-73.3l-3.8-13.2c0,0-2.7-44.6,19-55.8c21.7-11.2,7.7,64.7,7.7,64.7l1.7,15.8c1.1,10.1,3.1,20,6,29.8c0.9,3.2,1.5,6.5,1.8,9.8
          c0.7,10.7,5.7,9.9,5.7,9.9c7.9,1.2,15.5,3.8,22.5,7.7c2.6,1.4,5.1,3,7.5,4.7L570.4,302.1z"/>
        <path fill="#8E5362" d="M651.1,337.1l-19.3-2c0,0,5.7-8.5,8.2-16.7c0.4-1.1,0.6-2.3,0.8-3.5c0.6-3.3,0.4-6.3-1.1-8.5
          c-5.7-8.3,33.7-20,33.7-20c-0.5,4.6-0.6,9.2-0.4,13.9c0.1,3.2,0.4,6.4,1,9.6c1.2,6.5,3.6,12.6,8.1,15.7
          C694.4,334.1,651.1,337.1,651.1,337.1z"/>
        <path opacity="0.1" d="M651.1,337.1l-19.3-2c0,0,5.7-8.5,8.2-16.7c2.2,3.2,3,3.1,6.1,3.7
          c0,0,19.5,2.9,27.9-12.2c1.2,6.5,3.6,12.6,8.1,15.7C694.4,334.1,651.1,337.1,651.1,337.1z"/>
        <path opacity="0.1" d="M715.4,398c0,0.6,0,1.2,0.1,1.8l-3-3.5l-0.2-0.3l5.5-15.1l19.5-10.4l4.8,4.1
          C735.1,377.3,715.7,385.7,715.4,398z"/>
        <path opacity="0.1" d="M570.4,302.1l-22.7,9.5h-0.6l-1.8,0.1c0,0,0.1-0.3,0.3-0.8
          c1.9-4.6,10.7-27,6.9-37.6c2.6,1.4,5.1,3,7.5,4.7L570.4,302.1z"/>
        <path opacity="0.1" d="M676.4,498.1c-8.8-0.8-97.1-9-105.3-21.9c-1.3-2.1-2.8-4.1-4.4-6
          c1-4.8,1.6-7.8,1.6-7.8s99.3,20.7,105.8,28.2C675.2,491.7,675.9,494.5,676.4,498.1z"/>
        <path fill="#49A597" d="M646.1,323.1c0,0,19.7,2.9,28.1-12.4c0,0,13.9,11.4,22.3,12.4s47,49.7,47,49.7s-31,10-29,26l-14.7-17
          c0,0-7.3-0.3-13.7,20c0,0-7.7,11.7-6.7,48.7s-2.3,45.7-2.3,45.7s-97.3-8.3-106-22s-10-7-10-7s-1.3-26,7.7-47s7-81.3,7-81.3
          s1-29.7-28.3-27c0,0,12.3-28.7,6.7-39.7c0,0,16.9,4.6,28.9,4.7c7.4,0.1,14.6,1.8,21.3,5c3.6,1.8,6.9,4,8.8,6.7c5.7,8,15,4.3,15,4.3
          c2.4,7.3,5.3,14.5,8.7,21.3c0.4,0.7,0.8,1.5,1.2,2.1c0.6,1,1.2,2,1.8,2.8C642.2,322.6,642.8,322.5,646.1,323.1z"/>
        <path opacity="0.1" d="M673,300.3c-1.1,1.5-2.2,2.9-3.5,4.2c-6.6,6.7-15.6,10.5-25,10.5
          c-1.2,0-2.4-0.1-3.6-0.2c0.6-3.3,0.4-6.3-1.1-8.5c-5.7-8.3,33.7-20,33.7-20C673,291,672.8,295.7,673,300.3z"/>
        <path fill="#8E5362" d="M679.4,279.1c0,19.3-15.7,35-35,35s-35-15.7-35-35s15.7-35,35-35c19.3-0.1,34.9,15.5,35,34.7
          C679.4,279,679.4,279,679.4,279.1z"/>
        <path opacity="0.1" d="M696.8,359.1l0.7,23.4c0,0,10.5-8.4,10.8-16.4S696.8,359.1,696.8,359.1z"/>
        <path opacity="0.1" d="M612.4,382.6c0,0,27.5,37.8,43.5,41.6S618.4,394.8,612.4,382.6z"/>
        <path opacity="0.1" d="M641.6,386.5c0,0,15.3,17.3,26.3,19.5L641.6,386.5z"/>
        <path opacity="0.1" d="M587.6,430.1c0,0,28,27.5,44,29.2L587.6,430.1z"/>
        <path opacity="0.1" d="M679.4,279.1c0,9.1-3.6,17.9-10,24.5l-3.8-2.4c-1.5-0.8-2.8-1.9-3.7-3.2
          c-1.3-2.1-0.9-4.8-0.8-7.2c0.2-5.2-1.2-10.9-5.3-14.2c-6.6-5.3-17.3-2.4-24-7.6c-1.6-1.4-3.1-3-4.2-4.7l-6.3-8.5
          c-0.4-0.5-0.8-1-1.3-1.6c13.8-13.6,35.9-13.4,49.5,0.4C675.8,261.1,679.4,270,679.4,279.1L679.4,279.1z"/>
        <path fill="#362133" d="M623.3,234.3c3.1-2.4,7-3.6,10.5-5.4s6.6-4,10.2-5.5s7.6-2.4,11.2-1.1c7.8,2.7,10.8,13.6,18.8,15.6
          c1.4,0.3,2.8,0.4,4.1,0.6c5.3,1,9.9,4.2,12.7,8.9c2.7,4.6,4.1,9.9,4,15.2c0.1,3-0.3,5.9-1.3,8.7c-2.4,6.5-8.3,11-12.4,16.6
          c-3.8,5.4-5.8,11.9-5.8,18.5l-9.8-6.1c-1.5-0.8-2.8-1.9-3.7-3.2c-1.3-2.1-0.9-4.8-0.8-7.2c0.2-5.2-1.2-10.9-5.3-14.2
          c-6.6-5.3-17.3-2.4-24-7.6c-1.6-1.4-3.1-3-4.2-4.7l-6.3-8.5c-1.4-1.8-4-4.1-4.6-6.4C615.3,243.6,619.8,237,623.3,234.3z"/>
        <path opacity="0.1" d="M666.7,299.1c-1.5-0.8-2.8-1.9-3.7-3.2c-1.3-2.1-0.9-4.8-0.8-7.2
          c0.2-5.2-1.2-10.9-5.3-14.2c-6.6-5.3-17.3-2.4-24-7.6c-1.6-1.4-3.1-3-4.2-4.7l-6.3-8.5c-1.4-1.8-4-4.1-4.6-6.4
          c-1.1-3.9,1.7-8.9,4.6-12.1c-3.3,3.1-6.9,8.9-5.7,13.2c0.6,2.2,3.2,4.5,4.6,6.4l6.3,8.5c1.2,1.8,2.6,3.4,4.2,4.7
          c6.7,5.2,17.3,2.3,24,7.6c4.1,3.3,5.5,8.9,5.3,14.2c-0.1,2.5-0.5,5.1,0.8,7.2c1,1.4,2.3,2.5,3.7,3.2l9.8,6.1c0-0.6,0-1.2,0.1-1.7
          L666.7,299.1z"/>
        <path fill="#494457" d="M531.8,716.3c0,0-9.7,2-7.7,28.7s5,31.3,9,31.7s23.5,5.2,22.5-4.7s-2-31.7-0.7-35s6.6-12.3,1.6-14.5
          l-4.1,5.3c0,0-10.7-9.8-17-2.7C535.4,725.1,529.5,718.6,531.8,716.3z"/>
        <path opacity="0.1" d="M538,732.8c2.1,1,4.1,2.2,6.2,3.2c2.1,1.1,4.4,1.6,6.7,1.6c1,0,2-0.4,2.7-1"/>
        <path opacity="0.1" d="M602.1,758.9c1.2,1.2,2.2,2.6,3.6,3.6c2,1.3,4.6,1.4,7,1.5c0.4,0,0.8,0,1.2-0.2
          c0.4-0.2,0.8-0.6,1.1-1l1.3-1.7"/>
        <path fill="#FFC1C7" d="M437.8,315.5c0,0-4.5-42.7,13-32.2s5.5,29.2,5.5,29.2l-4.1,13.2L437.8,315.5z"/>
        <path fill="#FFC1C7" d="M465.8,179.1l10-9.8c0,0,19.2-55.2,38.2-41c19,14.2-24.1,50-24.1,50l-11.4,13.3L465.8,179.1z"/>
        <path fill="#3F897D" d="M319.4,397.3l-0.5,11.3c0,0,9.2,16,13,17.2s3.5-33.8,3.5-33.8L319.4,397.3z"/>
        <path opacity="0.1" d="M319.4,397.3l-0.5,11.3c0,0,9.2,16,13,17.2s3.5-33.8,3.5-33.8L319.4,397.3z"/>
        <polygon fill="#FFC1C7" points="334.8,384.9 335.4,426.6 381.9,426.6 378.4,402.3 "/>
        <path fill="#575988" d="M345.6,489.1l2.2,13.3l27.2,60.3c0,0,12.3,19,2.3,35s-20.3,41-22,56.2s-14.8,30-14.8,30l28.7,12.2
          c0,0,2-23.8,10-35s33.7-60.3,33.7-60.3s8.2-7.3,6.3-29.2s-1.7-37.2-1.7-37.2l-2.8-34.8L345.6,489.1z"/>
        <path opacity="0.1" d="M345.6,489.1l2.2,13.3l27.2,60.3c0,0,12.3,19,2.3,35s-20.3,41-22,56.2
          s-14.8,30-14.8,30l28.7,12.2c0,0,2-23.8,10-35s33.7-60.3,33.7-60.3s8.2-7.3,6.3-29.2s-1.7-37.2-1.7-37.2l-2.8-34.8L345.6,489.1z"/>
        <path fill="#C4747B" d="M342.9,682.1c0,0-30.7,9.3-20.5,24.7s43.2,44.5,56.5,43.5s8-10.8,8-10.8l-11.2-11c0,0-2-23.3-1.2-30.2
          s-9.5-10-10.8-6S342.9,682.1,342.9,682.1z"/>
        <path fill="#575988" d="M362.3,408.9l-24.3,2.7c-4.5,0.5-7.9,4.4-7.8,8.9c0.3,9.7,0.4,25.2-0.9,28c-2,4.2-8.2,33.8,18.5,54
          l36.2,11.5l-58,4.2l1.8,32.2l9-5l110,5c0,0,31.2-3.2,10-37s-66.2-67-66.2-67l-0.5-26.3l-3.3-7.7L362.3,408.9z"/>
        <path opacity="0.1" d="M392.4,513.5c8-3.5,17-3.5,25,0H392.4z"/>
        <path fill="#C4747B" d="M328.8,518c0,0-35.8-5.2-36,1.7s5,73.3,22.2,68.8s9.8-26.5,9.8-26.5l12.8-12.3c0,0,1.3-6.8-7.2-6.2
          L328.8,518z"/>
        <path fill="#3F897D" d="M388.1,331.5l14.2,16.2c0,0,6.2,7.7,13.5-4c7.3-11.7,21-32.5,21-32.5s15.2,13.3,19.2,10.7
          s-36.8,55.5-36.8,55.5s-13,19.2-32.3-3.5l-5.7-2.7L388.1,331.5z"/>
        <circle fill="#FFC1C7" cx="344.8" cy="236.3" r="29.5"/>
        <path opacity="0.1" d="M344.8,252.1c0,0-25.4,12-22.4,33s3.5,47.5,3.5,47.5s11.5,17-3.8,49.5
          c0,0-9.8,16.5-3.2,28.5c0,0,7.2-19,25.9-5s48.6,20.5,48.6,20.5s-15.5-68-6-77.5c0,0,16.5-24.5,1.5-40s-7.5-30-7.5-30l48.2-25.8
          c0,0,10.2,0.2,23.8-21.2c8.8-13.6,18.6-26.7,29.2-39c0,0-10.3-15.5-20-16.7c0,0-3.8,4-6.5,14.8c-1.4,5.8-11.1,15.4-19.8,23.1
          c-6.2,5.5-12.2,11.2-18.2,17c-4.4,4.2-14.3,10.3-35.7,13.8C347.1,250.3,344.8,252.1,344.8,252.1z"/>
        <path fill="#49A597" d="M344.8,250.1c0,0-25.4,12-22.4,33s3.5,47.5,3.5,47.5s11.5,17-3.8,49.5c0,0-9.8,16.5-3.2,28.5
          c0,0,7.2-19,25.9-5s48.6,20.5,48.6,20.5s-15.5-68-6-77.5c0,0,16.5-24.5,1.5-40s-7.5-30-7.5-30l48.2-25.8c0,0,10.2,0.2,23.8-21.2
          c8.8-13.6,18.6-26.7,29.2-39c0,0-10.3-15.5-20-16.7c0,0-3.8,4-6.5,14.8c-1.4,5.8-11.1,15.4-19.8,23.1c-6.2,5.5-12.2,11.2-18.2,17
          c-4.4,4.2-14.3,10.3-35.7,13.8C347.1,248.3,344.8,250.1,344.8,250.1z"/>
        <path opacity="0.1" d="M366.6,375.8c0,0,19.7,29,20.2,34C386.8,409.8,383.8,378.6,366.6,375.8z"/>
        <path opacity="0.1" d="M340.8,278.1c0,0,13.7,16.5,33.5,2.7C363.2,284.6,351.1,283.6,340.8,278.1z"/>
        <path opacity="0.1" d="M351.4,205.4c-0.4-1.5-1.1-2.9-2.2-4c-4.5-5.7-9.4-11.5-16.2-14.2
          c-9.2-3.4-19.5-0.5-25.5,7.3c-2.4,3.3-3.9,7.1-6,10.6c-3.9,6.5-9.8,11.4-16.2,15.4c-3.9,2.4-8,4.4-11.6,7.1
          c-13.3,9.8-19.4,27.8-16.1,44c1.5,7.1,4.6,14.1,3.7,21.2c-0.6,5.6-3.6,10.6-6.6,15.3s-6.2,9.5-7.4,15s0.1,11.9,4.6,15.2
          c1.7,1.2,3.7,2,5.1,3.5c0.9,1.1,1.7,2.4,2.4,3.7c4.8,8.9,13.1,15.4,22.8,18c4.3,1.1,10.1,0.7,11.6-3.4c0.2-0.7,0.5-1.4,0.8-2.1
          c1.4-2,4.5-0.9,6.8,0.3c5,2.6,10.5,4.2,16.1,4.6c2.2,0.3,4.5,0,6.6-0.8c1.8-0.9,3.3-2.2,4.5-3.9c5.3-6.9,6.8-15.8,7.7-24.4
          c0.6-5.6,1-11.3-0.1-16.8c-1.7-8.9-7.1-17.1-6.3-26.1c0.9-9.6,8.6-17.4,9.8-27c0.6-5-0.6-10-3.5-14.2c-1.1-1.3-2-2.7-2.7-4.2
          c-0.7-1.5-0.4-3.4,0.8-4.6c0.6-0.4,1.3-0.8,2.1-1c6.4-2.5,9.9-8.9,10.3-15.5c0.2-2.5,0-4.8,0.7-7.2
          C348.6,213.5,352,209.4,351.4,205.4z"/>
        <path fill="#BB7684" d="M349.4,205.4c-0.4-1.5-1.1-2.9-2.2-4c-4.5-5.7-9.4-11.5-16.2-14.2c-9.2-3.4-19.5-0.5-25.5,7.3
          c-2.4,3.3-3.9,7.1-6,10.6c-3.9,6.5-9.8,11.4-16.2,15.4c-3.9,2.4-8,4.4-11.6,7.1c-13.3,9.8-19.4,27.8-16.1,44
          c1.5,7.1,4.6,14.1,3.7,21.2c-0.6,5.6-3.6,10.6-6.6,15.3s-6.2,9.5-7.4,15s0.1,11.9,4.6,15.2c1.7,1.2,3.7,2,5.1,3.5
          c0.9,1.1,1.7,2.4,2.4,3.7c4.8,8.9,13.1,15.4,22.8,18c4.3,1.1,10.1,0.7,11.6-3.4c0.2-0.7,0.5-1.4,0.8-2.1c1.4-2,4.5-0.9,6.8,0.3
          c5,2.6,10.5,4.2,16.1,4.6c2.2,0.3,4.5,0,6.6-0.8c1.8-0.9,3.3-2.2,4.5-3.9c5.3-6.9,6.8-15.8,7.7-24.4c0.6-5.6,1-11.3-0.1-16.8
          c-1.7-8.9-7.1-17.1-6.3-26.1c0.9-9.6,8.6-17.4,9.8-27c0.6-5-0.6-10-3.5-14.2c-1.1-1.3-2-2.7-2.7-4.2c-0.7-1.5-0.4-3.4,0.8-4.6
          c0.6-0.4,1.3-0.8,2.1-1c6.4-2.5,9.9-8.9,10.3-15.5c0.2-2.5,0-4.8,0.7-7.2C346.6,213.5,350,209.4,349.4,205.4z"/>
        <g opacity="0.1">
          <path d="M289.8,358c-0.4,0.7-0.6,1.4-0.8,2.1c-0.9,2.5-3.4,3.6-6.1,3.9c3.8,0.4,7.8-0.5,9.1-3.9c0.2-0.7,0.5-1.4,0.8-2.1
            c0.3-0.4,0.6-0.7,1-0.9C292.2,356.7,290.7,356.7,289.8,358z"/>
          <path d="M349.4,205.4c-0.3-1.5-1.1-2.9-2.2-4c-4.5-5.7-9.4-11.5-16.2-14.2c-2.9-1.1-6.1-1.6-9.2-1.4c2.1,0.1,4.2,0.6,6.2,1.4
            c6.7,2.7,11.6,8.5,16.2,14.2c1.1,1.1,1.8,2.5,2.2,4c0.6,4-2.8,8.1-3.9,11.9c-0.7,2.4-0.5,4.7-0.7,7.2c-0.5,6.6-3.9,13-10.3,15.5
            c-0.7,0.2-1.4,0.6-2.1,1c-1.2,1.2-1.5,3-0.8,4.6c0.7,1.5,1.6,2.9,2.7,4.2c2.9,4.1,4.1,9.2,3.5,14.2c-1.2,9.6-9,17.4-9.8,27
            c-0.8,9,4.6,17.3,6.3,26.1c1.1,5.5,0.7,11.2,0.1,16.8c-0.9,8.6-2.4,17.5-7.7,24.4c-1.1,1.6-2.7,2.9-4.4,3.9c-1,0.5-2.1,0.7-3.3,0.8
            c2.1,0.3,4.3,0,6.3-0.8c1.8-0.9,3.3-2.2,4.5-3.8c5.3-6.8,6.8-15.8,7.7-24.4c0.6-5.6,1-11.3-0.1-16.8c-1.7-8.9-7.1-17.1-6.3-26.1
            c0.9-9.6,8.6-17.4,9.8-27c0.6-5-0.7-10-3.5-14.2c-1.1-1.3-1.9-2.7-2.7-4.2c-0.7-1.6-0.4-3.4,0.8-4.6c0.6-0.4,1.3-0.8,2.1-1
            c6.4-2.5,9.9-8.9,10.3-15.5c0.2-2.5,0-4.8,0.7-7.2C346.7,213.5,350,209.4,349.4,205.4z"/>
        </g>
        <path opacity="0.1" d="M311.6,558.3c3.5,3.8,8.9,5.3,13.8,3.8"/>
        <path opacity="0.1" d="M375.7,729.3c-1.9,1.6-4.5,2.2-6.9,1.7"/>
        <ellipse opacity="0.1" fill="#FE9236" cx="105.2" cy="755.8" rx="26.9" ry="4.5"/>
        <g opacity="0.6" id="HighFive_Firework">
          <path opacity="0.5" fill="#FE9236" d="M484.5,30.2c-1.2-1.5-3.5-1.8-5-0.5c-1.4,1.1-1.7,3.1-0.8,4.7
            c0.7,1,1.4,2,2.1,2.9c8.4,11.3,12.7,25,16.5,38.6c0.9,3.3,1.8,6.9,0.7,10.2c1.3-8.9,0.7-18-1.8-26.6c-2.5-8.6-5.6-17-9.4-25.1
            C486.2,32.9,485.4,31.5,484.5,30.2z"/>
          <path fill="#FE9236" d="M430.1,54c-5.7-0.2-6.4,8.4-0.7,8.8c1.5,0.1,3,0.2,4.4,0.2c17.3,0.5,33.7,6.7,49.4,13.5
            c3.9,1.7,7.9,3.6,10.2,7.1C486,75.5,477,69.2,467,65c-10.1-4.2-20.5-7.6-31.1-10C434,54.4,432.1,54.1,430.1,54z"/>
          <path opacity="0.2" fill="#FE9236" d="M431.7,75c-3.6,0.1-2.8,5.2,0.8,5.2c0.9,0,1.8,0,2.8,0
            c10.9-0.4,22.1,2.6,33,5.9c2.7,0.6,5.3,1.9,7.4,3.7c-5.8-4.4-12.3-7.8-19.3-9.9c-6.9-2.1-13.8-3.6-20.9-4.7
            C434.2,75.1,432.9,75,431.7,75z"/>
          <path opacity="0.5" fill="#FE9236" d="M504.7,169.3c1,3.4,5.7,1.4,4.9-2.1c-0.2-0.9-0.5-1.8-0.8-2.7
            c-3.1-10.5-3-22-2.5-33.4c-0.1-2.8,0.5-5.6,1.8-8.1c-2.9,6.7-4.5,13.8-4.9,21.1c-0.3,7.1,0,14.3,0.7,21.4
            C504.1,166.9,504.4,168.1,504.7,169.3z"/>
          <path opacity="0.2" fill="#FE9236" d="M438.2,25.4c-2.2-0.4-2.3,2.8-0.2,3.2l1.7,0.3
            c6.6,1.1,12.9,4.3,18.9,7.7c1.6,0.7,2.9,1.8,3.9,3.2c-2.9-3.4-6.3-6.2-10.2-8.3c-3.8-2.1-7.8-3.9-11.9-5.4
            C439.7,25.8,438.9,25.6,438.2,25.4z"/>
          <path fill="#FE9236" d="M405,108.3c-5.4,2.8-0.8,10.5,4.8,8c1.5-0.7,2.9-1.4,4.2-2.1c16.7-8.7,36-11.8,55-14.3
            c4.7-0.6,9.7-1.1,14,0.7c-11.9-3.1-24.3-3.8-36.4-2.2c-12,1.6-23.9,4.2-35.6,7.7C409,106.6,406.9,107.4,405,108.3z"/>
          <path opacity="0.5" fill="#FE9236" d="M420.6,138.3c-3.9,3.6,2.2,8,6.3,4.5c1.1-0.9,2.1-1.9,3.1-2.8
            c11.9-11.2,27.7-19.2,43.4-26.5c3.9-1.8,19.6-3.6,24-3.6c-11.1,1-33.7,4.4-43.7,8.9c-9.9,4.7-19.4,10.1-28.6,16.1
            C423.5,135.9,422,137,420.6,138.3z"/>
          <path fill="#FE9236" d="M448.5,186.2c-1.1,5.2,6.3,5.4,7.7,0c0.4-1.4,0.7-2.7,0.9-4.1c3.4-16.1,11.9-31.5,20.6-46.5
            c2.1-3.7,14.2-14.3,17.7-16.5c-8.5,7.1-25.2,22.8-30.8,32.3c-5.4,9.5-10.2,19.3-14.3,29.4C449.6,182.5,449,184.3,448.5,186.2z"/>
          <path fill="#FE9236" d="M504.8,2.5c-2.7-5.4-10.3-1-7.9,4.6c0.6,1.4,1.3,2.9,2,4.2c8.2,16.6,11,35.8,13.2,54.7
            c0.5,4.6,1,9.6-0.9,13.8c3.2-11.7,4.1-24,2.7-36.1c-1.4-12-3.8-23.8-7-35.4C506.4,6.4,505.7,4.4,504.8,2.5z"/>
          <path fill="#FE9236" d="M462.2,11.7c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.5-0.3,0.6-0.6c0.7-1.5,0.3-3.3,1.3-4.6c1,1.3,1.9,2.7,2.5,4.2
            l3.9-1.2c0.4,1.5,0.1,3.2-0.7,4.5c0.7,0.3,1.3,0.7,1.8,1.2c0.5,0.6,0.7,1.3,0.5,2.1c-0.9,0.2-1.8,0.3-2.8,0.3
            c0.2,1.7,0.1,3.4-0.3,5.1c0,0.3-0.1,0.5-0.3,0.7c-0.3,0.1-0.7,0.1-0.9-0.2c-1.4-0.8-2.5-2-3.2-3.4l-1.4,3.5
            c-0.1,0.4-0.3,0.7-0.7,0.9c-0.5,0.2-1,0-1.2-0.4c-0.2-0.4-0.4-0.9-0.4-1.3l-0.6-3.3c0-0.2-0.1-0.3-0.2-0.5
            c-0.2-0.1-0.4-0.2-0.6-0.2c-1.4,0-2.8,0-4.1,0c-0.2-0.7,0.5-1.4,1.2-1.8c0.7-0.4,1.5-0.6,1.9-1.2c0.7-1.4-1.1-3.4-0.3-4.6
            C459.3,9.9,461.1,11.1,462.2,11.7z"/>
          <path opacity="0.5" fill="#FE9236" d="M537.8,30.2c1.1-1.6,3.4-2,5-0.8c1.6,1.1,2,3.4,0.8,5
            c-0.7,1-1.4,2-2.1,2.9c-8.4,11.3-12.7,25-16.5,38.6c-0.9,3.3-1.8,6.9-0.8,10.2c-1.3-8.9-0.7-18,1.8-26.6c2.4-8.6,5.6-17,9.4-25.1
            C536.2,32.9,536.9,31.5,537.8,30.2z"/>
          <path fill="#FE9236" d="M592.2,54c5.6-0.2,6.4,8.4,0.7,8.8c-1.5,0.1-3,0.2-4.4,0.2c-17.3,0.5-33.7,6.7-49.4,13.5
            c-3.9,1.7-7.9,3.6-10.2,7.1c7.3-8.1,16.3-14.5,26.3-18.7c10.1-4.2,20.5-7.6,31.1-10C588.3,54.4,590.3,54.1,592.2,54z"/>
          <path opacity="0.5" fill="#FE9236" d="M590.8,75c3.6,0.1,2.7,5.2-0.8,5.2c-0.9,0-1.9,0-2.8,0
            c-10.9-0.4-22.1,2.6-33,5.9c-2.7,0.6-5.3,1.9-7.4,3.7c5.8-4.5,12.3-7.8,19.3-9.9c6.9-2,13.9-3.6,21-4.6
            C588.2,75.1,589.5,75,590.8,75z"/>
          <path opacity="0.2" fill="#FE9236" d="M584.2,25.4c2.2-0.4,2.3,2.8,0.2,3.2l-1.7,0.3
            c-6.6,1.1-12.9,4.3-18.9,7.7c-1.6,0.7-2.9,1.8-3.9,3.2c2.9-3.4,6.3-6.2,10.2-8.3c3.8-2.1,7.8-3.9,11.9-5.4
            C582.7,25.8,583.4,25.6,584.2,25.4z"/>
          <path opacity="0.2" fill="#FE9236" d="M528.3,175.8c-0.5,2.1-3.4,1.1-3-1.1c0-0.6,0.2-1.1,0.4-1.7
            c1.5-6.5,0.9-13.5,0.2-20.4c-0.1-1.7-0.5-3.4-1.4-4.9c2,4,3.3,8.2,3.7,12.7c0.4,4.4,0.6,8.7,0.4,13.1
            C528.5,174.3,528.4,175.1,528.3,175.8z"/>
          <path opacity="0.5" fill="#FE9236" d="M582.8,161.7c1.7,2.8-2.4,2.6-4.2-0.2c-0.5-0.7-0.9-1.5-1.3-2.2
            c-5.1-8.7-12.9-17.3-20.7-25.6c-1.8-2.2-4-4-6.4-5.6c6.1,4.2,11.8,9,16.8,14.4c5,5.4,9.5,10.7,13.8,16.2
            C581.5,159.7,582.2,160.7,582.8,161.7z"/>
          <path opacity="0.2" fill="#FE9236" d="M433.5,161.7c-1.7,2.8,2.4,2.6,4.3-0.2c0.5-0.7,0.9-1.5,1.3-2.2
            c5.1-8.7,12.9-17.3,20.7-25.6c1.9-2.2,4-4,6.4-5.6c-6.1,4.2-11.8,9-16.8,14.4c-5,5.4-9.5,10.7-13.8,16.2
            C434.8,159.7,434.1,160.7,433.5,161.7z"/>
          <path opacity="0.2" fill="#FE9236" d="M488.1,175.8c0.5,2.1,3.4,1.1,3.1-1.1c-0.1-0.6-0.2-1.1-0.4-1.7
            c-1.5-6.5-1-13.5-0.2-20.4c0-1.7,0.5-3.4,1.4-4.9c-2,4-3.3,8.2-3.7,12.7c-0.5,4.4-0.6,8.7-0.4,13.1
            C487.8,174.3,487.9,175.1,488.1,175.8z"/>
          <path opacity="0.2" fill="#FE9236" d="M567,46.8c2.1-0.4,2.3,2.7,0.2,3.2c-0.6,0.1-1.1,0.2-1.7,0.3
            c-6.6,1.1-12.9,4.3-18.9,7.7c-1.6,0.7-2.9,1.8-3.9,3.2c2.9-3.4,6.3-6.2,10.2-8.4c3.8-2.1,7.8-3.9,11.9-5.4
            C565.5,47.2,566.2,46.9,567,46.8z"/>
          <path opacity="0.2" fill="#FE9236" d="M546.9,4.8c1.8-1.2,3.2,1.7,1.4,2.9l-1.4,0.9
            c-5.7,3.6-10.3,8.8-14.7,14.3c-1.2,1.2-2,2.8-2.5,4.4c1.4-4.2,3.6-8.1,6.4-11.6c2.8-3.4,5.8-6.5,9-9.5
            C545.6,5.8,546.2,5.3,546.9,4.8z"/>
          <path fill="#FE9236" d="M617.3,108.3c5.4,2.8,0.8,10.5-4.8,8c-1.5-0.7-2.9-1.4-4.3-2.1c-16.7-8.7-36-11.8-55-14.3
            c-4.7-0.6-9.7-1.1-14,0.7c11.9-3.1,24.3-3.8,36.4-2.2c12,1.6,23.9,4.2,35.6,7.7C613.4,106.7,615.4,107.4,617.3,108.3z"/>
          <path opacity="0.5" fill="#FE9236" d="M601.7,138.3c3.9,3.6-2.2,8-6.3,4.5c-1.1-0.9-2.1-1.9-3.1-2.8
            c-11.9-11.2-27.7-19.2-43.4-26.5c-3.8-1.8-19.7-3.6-24-3.6c11.1,1,33.7,4.4,43.7,8.9c9.9,4.7,19.4,10.1,28.6,16.1
            C598.9,135.9,600.4,137,601.7,138.3z"/>
          <path fill="#FE9236" d="M555.6,192.1c0.5,5.4-7,4.5-7.7-0.9c-0.2-1.4-0.3-2.8-0.4-4.1c-1.4-16.3-7.9-32.8-14.7-48.7
            c-1.7-3.9-12.3-15.9-15.5-18.6c7.6,8.1,22.1,25.8,26.6,35.9c4.2,10.1,7.7,20.4,10.6,30.9C555,188.4,555.4,190.2,555.6,192.1z"/>
          <path fill="#FE9236" d="M560.1,11.7c-0.1,0.1-0.3,0.1-0.5,0.1c-0.3-0.1-0.5-0.3-0.6-0.6c-0.7-1.5-0.3-3.3-1.3-4.6
            c-1,1.3-1.9,2.7-2.5,4.2l-3.9-1.2c-0.4,1.5-0.1,3.2,0.7,4.5c-0.7,0.3-1.3,0.7-1.8,1.2c-0.5,0.5-0.7,1.3-0.5,2.1
            c0.9,0.2,1.8,0.3,2.8,0.3c-0.1,1.7,0,3.4,0.3,5.1c0,0.3,0.1,0.5,0.3,0.7c0.3,0.1,0.7,0.1,0.9-0.2c1.4-0.8,2.5-2,3.2-3.4l1.4,3.5
            c0.1,0.4,0.3,0.7,0.6,0.9c0.5,0.2,1,0,1.2-0.4c0.2-0.4,0.4-0.9,0.4-1.3l0.6-3.3c0-0.2,0.1-0.3,0.2-0.5c0.2-0.1,0.4-0.2,0.6-0.2
            c1.4,0,2.8,0,4.1,0c0.2-0.7-0.5-1.4-1.2-1.8c-0.7-0.4-1.6-0.6-1.9-1.2c-0.7-1.4,1.2-3.4,0.4-4.6C563,9.9,561.3,11.1,560.1,11.7z"/>
          <path opacity="0.2" fill="#FE9236" d="M571.9,173c-0.1,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.5-0.3-0.6-0.6
            c-0.7-1.4-0.3-3.3-1.3-4.6c-1.1,1.2-1.9,2.7-2.5,4.2l-3.9-1.2c-0.4,1.5-0.1,3.1,0.7,4.5c-0.7,0.3-1.3,0.7-1.8,1.3
            c-0.5,0.5-0.7,1.3-0.5,2.1c0.9,0.2,1.8,0.3,2.8,0.3c-0.1,1.7-0.1,3.4,0.3,5.1c0,0.3,0.1,0.5,0.3,0.7c0.3,0.2,0.7,0,1-0.2
            c1.4-0.8,2.5-2,3.2-3.4l1.5,3.5c0.1,0.4,0.3,0.7,0.6,0.9c0.5,0.2,1,0,1.2-0.4c0.2-0.4,0.4-0.9,0.4-1.3l0.5-3.3
            c0-0.2,0.1-0.3,0.2-0.5c0.2-0.1,0.4-0.2,0.6-0.2c1.4,0,2.8,0,4.1,0c0.2-0.7-0.5-1.4-1.2-1.8s-1.6-0.6-1.9-1.2
            c-0.7-1.4,1.1-3.3,0.4-4.6C574.8,171.1,573.1,172.4,571.9,173z"/>
          <path fill="#FE9236" d="M605,64.9c-0.1,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.5-0.3-0.6-0.6c-0.7-1.4-0.3-3.3-1.3-4.6
            c-1,1.3-1.9,2.7-2.5,4.2l-3.9-1.2c-0.4,1.5-0.1,3.2,0.7,4.5c-0.7,0.3-1.3,0.7-1.8,1.3c-0.5,0.5-0.7,1.3-0.5,2.1
            c0.9,0.2,1.8,0.3,2.8,0.3c-0.1,1.7-0.1,3.4,0.3,5.1c0,0.3,0.1,0.5,0.3,0.7c0.3,0.1,0.7,0.1,0.9-0.2c1.4-0.8,2.5-2,3.2-3.4l1.5,3.5
            c0.1,0.4,0.3,0.7,0.6,0.9c0.5,0.2,1,0,1.2-0.4c0.2-0.4,0.4-0.9,0.4-1.3l0.6-3.3c0-0.2,0.1-0.3,0.2-0.5c0.2-0.1,0.4-0.2,0.6-0.2
            c1.4,0,2.8,0,4.2,0c0.2-0.7-0.5-1.4-1.2-1.8c-0.7-0.4-1.5-0.6-1.9-1.2c-0.7-1.4,1.1-3.4,0.4-4.6C607.9,63.1,606.2,64.3,605,64.9z"
            />
          <path fill="#FE9236" d="M472.4,189.1c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0-0.7-0.4-0.9-0.8c-0.9-2-0.4-4.6-1.8-6.3
            c-1.4,1.7-2.6,3.7-3.5,5.8l-5.5-1.7c-0.5,2.1-0.1,4.4,1,6.2c-1,0.4-1.8,1-2.6,1.8c-0.7,0.8-1,1.9-0.7,2.9c1.3,0.3,2.6,0.4,3.9,0.4
            c-0.2,2.4-0.1,4.8,0.4,7.1c0,0.4,0.2,0.7,0.5,0.9c0.4,0.2,0.9,0,1.3-0.2c1.9-1.2,3.4-2.8,4.5-4.7l2,4.8c0.1,0.5,0.5,0.9,0.9,1.2
            c0.7,0.3,1.4,0,1.8-0.6c0.3-0.5,0.5-1.2,0.6-1.8l0.7-4.6c0-0.5,0.4-0.9,0.9-0.9c0.1,0,0.1,0,0.2,0c1.9,0,3.8,0,5.8,0
            c0.3-1-0.7-2-1.7-2.4c-1-0.4-2.1-0.7-2.6-1.8c-1-1.9,1.6-4.7,0.5-6.3C476.2,186.4,474.1,188.3,472.4,189.1z"/>
          <path fill="#FE9236" d="M417.2,68.1c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0-0.7-0.4-0.9-0.8c-0.9-2-0.4-4.6-1.8-6.3
            c-1.4,1.7-2.6,3.7-3.5,5.8l-5.5-1.7c-0.5,2.1-0.1,4.4,1,6.2c-1,0.4-1.8,1-2.6,1.8c-0.7,0.8-1,1.9-0.7,2.9c1.3,0.3,2.5,0.4,3.8,0.4
            c-0.2,2.4-0.1,4.8,0.4,7.1c0,0.4,0.2,0.7,0.5,0.9c0.4,0.3,0.9,0,1.3-0.2c1.9-1.2,3.4-2.8,4.5-4.8l2,4.9c0.2,0.5,0.5,0.9,0.9,1.2
            c0.6,0.2,1.3,0,1.7-0.6c0.3-0.5,0.5-1.2,0.6-1.8l0.7-4.6c0-0.2,0.1-0.5,0.3-0.6c0.2-0.2,0.5-0.3,0.8-0.2c1.9,0,3.8,0,5.8,0
            c0.3-1-0.7-2-1.7-2.4c-1-0.4-2.1-0.8-2.6-1.8c-1-1.9,1.6-4.7,0.5-6.3C421,65.4,418.9,67.3,417.2,68.1z"/>
          <path opacity="0.2" fill="#FE9236" d="M584.7,143c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.5-1-3.4c-0.7,0.9-1.4,2-1.8,3.1l-2.9-0.9c-0.3,1.1-0.1,2.3,0.6,3.3c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.4,1.5
            c0.7,0.2,1.4,0.2,2,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.3,0.5,0.5,0.6c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.7,0.3-1l0.4-2.5c0-0.3,0.2-0.5,0.5-0.5c0,0,0.1,0,0.1,0
            c1,0,2,0,3.1,0c0.2-0.5-0.4-1-0.9-1.3c-0.5-0.2-1.2-0.4-1.4-0.9c-0.5-1,0.8-2.5,0.2-3.4C586.8,141.7,585.6,142.6,584.7,143z"/>
          <path opacity="0.2" fill="#FE9236" d="M509.7,186.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.4-1-3.4c-0.8,0.9-1.4,2-1.8,3.1l-2.9-0.9c-0.3,1.1-0.1,2.3,0.6,3.3c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.4,1.5
            c0.7,0.2,1.4,0.2,2,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.3,0.5,0.5,0.6c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.5c0-0.3,0.2-0.5,0.5-0.5c0,0,0.1,0,0.1,0
            c1,0,2,0,3.1,0c0.2-0.5-0.4-1.1-0.9-1.3c-0.5-0.2-1.2-0.4-1.4-0.9c-0.5-1,0.8-2.5,0.2-3.4C511.9,185,510.6,185.9,509.7,186.3z"/>
          <path opacity="0.5" fill="#FE9236" d="M437.2,34.8c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.5-1-3.4c-0.8,0.9-1.4,2-1.8,3.1l-3-1c-0.3,1.1-0.1,2.3,0.5,3.3c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.4,1.5
            c0.7,0.2,1.4,0.2,2.1,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.2,0.5,0.5,0.6c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.4c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.3-0.1,0.4-0.1
            c1,0,2,0,3.1,0c0.2-0.6-0.4-1.1-0.9-1.3c-0.5-0.2-1.1-0.4-1.4-0.9c-0.5-1,0.8-2.5,0.3-3.4C439.3,33.5,438.1,34.4,437.2,34.8z"/>
          <path opacity="0.5" fill="#FE9236" d="M475.8,120.2c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.5-1-3.4c-0.8,0.9-1.4,2-1.8,3.1l-2.9-0.9c-0.3,1.1-0.1,2.3,0.6,3.3c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.4,1.5
            c0.7,0.2,1.4,0.2,2.1,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.2,0.5,0.5,0.6c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.4c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.3-0.1,0.4-0.1
            c1,0,2.1,0,3.1,0c0.2-0.6-0.4-1.1-0.9-1.3c-0.5-0.2-1.1-0.4-1.4-0.9c-0.5-1,0.9-2.5,0.3-3.4C477.9,118.8,476.7,119.8,475.8,120.2z"
            />
          <path opacity="0.5" fill="#FE9236" d="M447.7,86.9c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.5-1-3.4c-0.8,0.9-1.4,2-1.8,3.1l-2.9-0.9c-0.3,1.1-0.1,2.3,0.6,3.3c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.4,1.5
            c0.7,0.2,1.4,0.2,2.1,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.3,0.5,0.5,0.6c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.4c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.3-0.1,0.4-0.1
            c1,0,2.1,0,3.1,0c0.2-0.5-0.4-1.1-0.9-1.3c-0.5-0.2-1.1-0.4-1.4-0.9c-0.5-1,0.9-2.5,0.3-3.4S448.6,86.5,447.7,86.9z"/>
          <path opacity="0.5" fill="#FE9236" d="M568.1,94.8c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.4-1-3.4c-0.8,0.9-1.4,2-1.8,3.1l-2.9-0.9c-0.3,1.1-0.1,2.3,0.5,3.3c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.4,1.5
            c0.7,0.2,1.4,0.2,2.1,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.3,0.5,0.5,0.6c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.4c0-0.3,0.2-0.5,0.5-0.5c0,0,0.1,0,0.1,0
            c1,0,2,0,3.1,0c0.2-0.5-0.4-1.1-0.9-1.3s-1.1-0.4-1.4-0.9c-0.5-1,0.8-2.5,0.3-3.4C570.3,93.5,569,94.3,568.1,94.8z"/>
          <path opacity="0.2" fill="#FE9236" d="M509.7,88.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.5-1-3.4c-0.7,0.9-1.4,2-1.8,3.1l-2.9-0.9c-0.3,1.1-0.1,2.3,0.6,3.3c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.4,1.5
            c0.7,0.2,1.4,0.2,2,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.3,0.5,0.5,0.6c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.5c0-0.3,0.2-0.5,0.5-0.5c0,0,0.1,0,0.1,0
            c1,0,2,0,3.1,0c0.2-0.5-0.4-1-0.9-1.3c-0.5-0.2-1.2-0.4-1.4-0.9c-0.5-1,0.8-2.5,0.2-3.4C511.9,86.9,510.6,87.8,509.7,88.2z"/>
          <path opacity="0.3" fill="#FE9236" d="M491.4,96.2c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.5-1-3.4c-0.8,0.9-1.4,2-1.8,3.1l-2.9-0.9c-0.3,1.1-0.1,2.3,0.6,3.3c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.4,1.5
            c0.7,0.2,1.4,0.2,2,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.2,0.5,0.5,0.7c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.4c0-0.3,0.2-0.5,0.5-0.5c0,0,0.1,0,0.1,0
            c1,0,2,0,3.1,0c0.2-0.5-0.4-1.1-0.9-1.3c-0.5-0.2-1.1-0.4-1.4-0.9c-0.5-1.1,0.8-2.5,0.2-3.4S492.3,95.7,491.4,96.2z"/>
          <path opacity="0.2" fill="#FE9236" d="M474.3,47.9c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4
            c-0.5-1.1-0.2-2.5-1-3.4c-0.7,0.9-1.4,2-1.8,3.1l-2.9-0.9c-0.3,1.1-0.1,2.3,0.6,3.3c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.4,1.5
            c0.7,0.2,1.4,0.2,2,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.3,0.5,0.5,0.6c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.4c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.3-0.1,0.4-0.1
            c1,0,2,0,3.1,0c0.2-0.6-0.4-1.1-0.9-1.3c-0.5-0.2-1.2-0.4-1.4-0.9c-0.5-1,0.8-2.5,0.2-3.4C476.4,46.6,475.2,47.6,474.3,47.9z"/>
          <path opacity="0.3" fill="#FE9236" d="M508,110.9c-0.2,0.2-0.6,0.1-0.7-0.1c0-0.1-0.1-0.1-0.1-0.2
            c-0.5-1.1-0.2-2.5-1-3.4c-0.7,0.9-1.4,2-1.8,3.1l-2.9-0.9c-0.3,1.1-0.1,2.3,0.5,3.3c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.5,1-0.4,1.5
            c0.7,0.2,1.4,0.2,2.1,0.2c-0.1,1.3,0,2.6,0.2,3.8c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.5,0,0.7-0.1c1-0.6,1.8-1.5,2.4-2.5l1.1,2.6
            c0.1,0.3,0.3,0.5,0.5,0.6c0.3,0.1,0.7,0,0.9-0.3c0.2-0.3,0.3-0.6,0.3-1l0.4-2.5c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.3-0.1,0.4-0.1
            c1,0,2.1,0,3.1,0c0.2-0.5-0.4-1-0.9-1.3c-0.5-0.2-1.1-0.4-1.4-0.9c-0.5-1,0.9-2.5,0.3-3.4S508.8,110.5,508,110.9z"/>
        </g>
      </svg>
    );
  }

}

export default HighFive;