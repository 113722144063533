import React from 'react'

import WithUser from '../HOC/WithUser';
import { WithTrans } from  '../../i18n/i18nWrapper';

import Module from '../Module';
import Greeting from '../Greeting';
import Matchmaker from '../Matchmaker';
import News from '../News';
import Requests from '../Requests';

const PageHome = (props) => {
  return (
    <div className="Page Page--home">
      <Greeting />
      <div className="grid-x grid-padding-x--small">
        <div className="cell small-12 large-6">
          {props.user.mentee ? (
            <Module title={props.t('find_mentor')}>
              <Matchmaker type="mentor" />
            </Module>
          ) : null }
          {props.user.mentor ? (
            <Module title={props.t('find_mentee')}>
              <Matchmaker type="mentee" />
            </Module>
          ) : null }
        </div>
        <div className="cell small-12 large-6">
          <Module title={props.t('requests.requests')}>
            <Requests />
          </Module>
          <Module title={props.t('news.latest_news')}>
            <News />
          </Module>
        </div>
      </div>
    </div>
  )
}

export default WithUser(WithTrans(PageHome));