import React from 'react';
import { WithTrans } from  '../i18n/i18nWrapper';

const NewsArticle = (props) => {
  const article = props.article;
  const dateSliced = article.date.slice(0, -4);
  let datePart = dateSliced.match(/\d+/g),
  year = datePart[0].substring(2),
  month = datePart[1], day = datePart[2];
  const dateFormatted = day+'/'+month+'/'+year;
  return (
    <li className="NewsArticle">
      <a href={article.link} target="_blank" rel="noopener noreferrer">
        <figure className="NewsArticle__image">
          {article._embedded["wp:featuredmedia"] && article._embedded["wp:featuredmedia"][0].media_details ? (
            <img src={article._embedded["wp:featuredmedia"][0].media_details.sizes['entry-480'].source_url} alt={article.title.rendered} />
          ) : (
            <img src="https://careermentor.dk/wp-content/uploads/careermentor-balloon-480x320.jpg" alt={article.title.rendered} />
          )}
        </figure>
        <div className="NewsArticle__info">
          <p className="NewsArticle__title">{article.title.rendered}</p>
          <p className="NewsArticle__dateauthor"><span>{dateFormatted}</span> {props.t('news.by')} <span>{article._embedded.author[0].name}</span></p>
        </div>
      </a>
    </li>
  )
}

export default WithTrans(NewsArticle);