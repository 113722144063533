import React, { Component, createContext } from 'react'

import axios from 'axios';

export const FaqsContext = createContext({ faqs: [] });

const restURL = 'https://careermentor.dk/wp-json/wp/v2/';

class FaqsProvider extends Component {

  state = {
    faqs: [],
  };

  componentDidMount = async () => {
    axios.get(restURL + 'faqs')
    .then(response => {
      // handle success
      this.setState({ faqs: response.data });
    })
    .catch(function (error) {
      // handle error
      console.log('error fetching faqs: ', error);
    })
  };


  render() {
    const { faqs } = this.state;
    const { children } = this.props;

    return (
      <FaqsContext.Provider value={faqs}>{children}</FaqsContext.Provider>
    )
  }
}

export default FaqsProvider;